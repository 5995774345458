import { parseISO } from 'date-fns';
import { CalculatorValues } from '../Calculator';
const result: CalculatorValues = {
  company: {
    identified: false,
    branch: '3217',
    workforce: '2',
    location: 'pdl',
    recruited: null,
    apprenticeShipMass: null,
  },
  beneficiary: {
    identified: true,
    birthdate: parseISO('2003-12-17T09:00:00.000Z'),
    age: 18,
    situation: ["handicap"],
    apprenticeShip: false,
  },
  formation: {
    formation: '32122301',
    tutorAge: true,
    details: null,
  },
  contract: {
    apprenticeShip: { cost: null, duration: null },
    pro: { cost: null, durationMonth: null, durationHour: null },
  },
  config: {
    userType: "student"
  },
  targettedJob:{
    startDate: parseISO('2021-05-02T09:00:36.949Z'),
    duration: 16,
    wantedLevel: '2',
    salaryRow: {
      id: '211',
      name: 'Industries de carrières et de matériaux',
      category: 'Cadres',
      subCategory: 'X',
      subSubCategory: '2',
      amount: 1548.40,
      amountBase: 'byMonth151',
      opco: undefined,
      anneesRange: [1, 2, 3, 4]
    },
  }
};

export default result