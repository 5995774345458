import PapaParse from 'papaparse';
import { range } from 'lodash';

const csvString = `ID CC,Années,Annee1,Annee2,Annee3,Annee4
default,16-17,27,39,55,55
default,18-20,43,51,67,67
default,21-25,53,61,78,78
default,26,100,100,100,100
489,16-17,35,45,55,55
993,16-17,27,39,67,67
993,18-20,43,51,78,78
993,21-25,53,61,100,100
993,26,100,100,100,100
567,16-17,25,45,60,60
567,18-20,35,55,70,70
567,21-25,35,55,70,70
567,26,100,100,100,100
1513,16-17,25,45,100,100
1513,18-20,35,55,100,100
1513,21-23,45,65,100,100
1513,24-25,55,75,100,100
1513,26,100,100,100,100
1810,16-17,40,50,65,65
1810,18-20,55,65,80,80
1810,21-25,70,80,85,85
1810,26,100,100,100,100
1880,16-17,30,45,58,58
1880,18-20,46,54,70,70
1880,21-25,58,66,83,83
1880,26,100,100,100,100
18,16-17,30,40,55,55
18,18-20,45,55,70,70
18,21-25,55,65,80,80
18,26,100,100,100,100
3233,16-17,33,43,55,55
3233,18-20,43,53,67,67
3233,21-25,55,67,79,79
3233,26,100,100,100,100
3227,16-17,40,50,60,60
3227,18-20,50,60,70,70
3227,21-25,60,70,85,85
3227,26,100,100,100,100
7013,16-17,25,37,53,53
7013,18-20,41,49,65,65
7013,21-25,55,65,85,85
7013,26,100,100,100,100
2420,16-17,40,50,60,60
2420,18-20,50,60,70,70
2420,21-25,55,65,80,80
2420,26,100,100,100,100
2609,16-17,40,50,60,60
2609,18-20,50,60,70,70
2609,21-25,55,65,80,80
2609,26,100,100,100,100
1597,16-17,40,50,60,60
1597,18-20,50,60,70,70
1597,21-25,55,65,80,80
1597,26,100,100,100,100
1596,16-17,40,50,60,60
1596,18-20,50,60,70,70
1596,21-25,55,65,80,80
1596,26,100,100,100,100
1518,16-17,30,42,58,58
1518,18-20,46,54,70,70
1518,21-25,56,64,81,81
1518,26,103,103,103,103
1261,16-17,30,42,58,58
1261,18-20,46,54,70,70
1261,21-25,56,64,81,81
1261,26,100,100,100,100
1000,16-17,27,39,55,55
1000,18-20,43,51,67,67
1000,21-25,53,61,78,67
1000,26,100,100,100,100
1170,16-17,40,50,60,60
1170,18-20,50,60,70,70
1170,21-25,60,70,85,85
1170,26,100,100,100,100
1411,16-17,35,40,55,55
1411,18-20,45,55,65,65
1411,21-25,55,65,80,80
1411,26,100,100,100,100
1618,16-17,30,45,60,60
1618,18-20,45,55,65,65
1618,21-25,55,65,80,80
1618,26,100,100,100,100
3043,16-17,40,50,65,65
3043,18-20,55,65,80,80
3043,21-25,70,80,85,85
3043,26,100,100,100,100
7017,16-17,25,41,53,53
7017,18-20,41,49,61,61
7017,21-25,53,65,78,78
7017,26,100,100,100,100
9441,16-17,25,50,60,60
9441,18-20,41,60,70,70
9441,21-23,53,70,80,80
9441,24-25,55,80,90,90
9441,26,100,100,100,100
9491,16-17,50,60,60,60
9491,18-20,50,60,65,65
9491,21-25,53,61,78,78
9491,26,100,100,100,100
9494,16-17,25,37,53,53
9494,18-20,41,49,65,65
9494,21-25,53,61,78,78
9494,26,100,100,100,100
9531,16-17,50,55,60,60
9531,18-20,60,65,70,70
9531,21-25,60,70,80,80
9531,26,100,100,100,100
9492,16-17,25,50,53,53
9492,18-20,41,50,65,65
9492,21-25,53,61,78,78
9492,26,100,100,100,100
9721,16-17,35,41,53,53
9721,18-20,41,49,65,65
9721,21-25,53,61,78,78
9721,26,100,100,100,100
16,16-17,35,47,63,63
16,18-20,51,59,75,75
16,21-25,55,65,85,85
16,26,100,100,100,100
formationSpeciale,16-17,30,45,55,55
formationSpeciale,18-20,45,60,75,67
formationSpeciale,21-25,65,75,85,78
formationSpeciale,26,100,100,100,100`;

const { data } = PapaParse.parse(csvString, {
  header: true,
});

const pourcentages = data.map((element: any) => {
  const idCc = element['ID CC'] as string;
  const annees = element['Années'] as string;

  let ageRange: number[] = [];

  if (annees === '26') {
    ageRange = range(26, 100);
  } else {
    const [startAge, endAge] = annees.split('-').map(x => parseInt(x, 10));
    ageRange = range(startAge, endAge + 1);
  }

  return {
    idCc,
    ageRange,
    annee1: parseFloat(element['Annee1']),
    annee2: parseFloat(element['Annee2']),
    annee3: parseFloat(element['Annee3']),
    annee4: parseFloat(element['Annee4']),
  };
});

export { pourcentages };
