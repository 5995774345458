import { Yup } from "../utils/localizedYup";
import { BeneficiaryValues, validateBeneficiarySchema } from "./Beneficiary";
import { CompanyValues, validateCompanySchema } from "./Company";
import { ContractValues, validateContractSchema } from "./Contract";
import { FormationValues, validateFormationSchema } from "./Formation";
import empty from "./mocks/empty";
import example from "./mocks/example";
import exampleStudent from "./mocks/exampleStudent";
import nineteen from "./mocks/nineteen";
import animation from "./mocks/animation";
import tuteurPourcentageSmicDifferent from "./mocks/tuteur-pourcentageSMICdifferent_1";
import { TargettedJobValues, validateTargettedJobSchema } from "./TargettedJob";

export function findGetParameterWhenNotInReactContext(parameterName: string) {
    /** @type {string} */
    var result = null;
    /** @type {string} */
    const location = window.location.search;
    location.substr(1)
        .split("&")
        .forEach(function (item) {
            const tmp = item.split("=");
            if (tmp[0] === parameterName){
                result = decodeURIComponent(tmp[1]);
            }
        });
    return result;
}

const mocks = {
    example,
    empty,
    exampleStudent,
    nineteen,
    animation,
    ["tuteur-pourcentageSMICdifferent_1"]: tuteurPourcentageSmicDifferent
}

export const tabsToDisplay = ["pro", "student"];
export type UserType = "pro" | "student";
export interface CalculatorConfig{
    userType: UserType;
}

export interface CalculatorValues {
    company: CompanyValues;
    beneficiary: BeneficiaryValues;
    formation: FormationValues;
    contract: ContractValues;
    config: CalculatorConfig;
    targettedJob: TargettedJobValues;
}
const persona = findGetParameterWhenNotInReactContext("persona");
const userType = findGetParameterWhenNotInReactContext("userType");

export const initialValues: CalculatorValues = mocks[persona || "empty"] || mocks["empty"];

if(!persona && userType){
    initialValues.config.userType = userType;
}

if(userType === "student"){
    initialValues.beneficiary.identified = true;
}else{
    initialValues.company.identified = true;
}

export const validateCalculatorSchema = initialValues.config.userType === "pro" ? Yup.object({
    company: validateCompanySchema,
    beneficiary: validateBeneficiarySchema,
    formation: validateFormationSchema,
    contract: validateContractSchema,
    targettedJob: validateTargettedJobSchema
}) : Yup.object({
    beneficiary: validateBeneficiarySchema,
    contract: validateContractSchema,
    targettedJob: validateTargettedJobSchema
});