import PapaParse from 'papaparse';
import { uniqBy, orderBy, range } from 'lodash';
import { opcos } from './opco';

const conventionsCsvString = `Date de MAJ,Date entrée en vigueur,Année,ID CC,Nom CC,Nationale,Locale,Source,Catégorie,Sous-catégorie,Sous-sous-catégorie,Salaire,Base,Coefficients,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,VIII,1,28140,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,IX,1,41770,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,X,1,55970,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,VIII,2,35310,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,IX,2,48390,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,X,2,61370,byYear218,,,,,,,
2/18/2021,7/10/2008,,211,Industries de carrières et de matériaux,,,"cf. Accord du 10 juill. 2008 (NOR : ASET0850993M), étendu par A. 27 avr. 2009 : JO 6 mai 2009 en vigueur le 1er janvier 2010 (Dispositions impératives) cf. Avenant n° 46 du 21 mars 2019 (NOR : ASET1951202M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur le 1er janv. 2019",Cadres,VIII,3,37460,byYear218,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 1,Niv. A,,18583,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 2,Niv. A,,20349,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 3,Niv. A,,24571,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 4,Niv. A,,29583,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 5,Niv. A,,35477,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 6,Niv. A,,35483,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 1,Niv. B,,18845,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 2,Niv. B,,21367,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 3,Niv. B,,25724,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 4,Niv. B,,30852,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 5,Niv. B,,36211,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 6,Niv. B,,41642,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 7,Niv. B,,47516,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 8,Niv. B,,54643,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 9,Niv. B,,62224,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 1,Niv. C,,20349,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 2,Niv. C,,24571,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 3,Niv. C,,29583,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 4,Niv. C,,35477,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 5,Niv. C,,41642,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 6,Niv. C,,47516,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 7,Niv. C,,54643,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 8,Niv. C,,62224,byYear1607,,,,,,,
2/18/2021,1/1/2019,,303,Couture parisienne,,,cf. Avenant du 25 janv. 2002 (NOR : ASET0250203M) étendu par A. 9 juill. 2002 : JO 18 juill. 2002 ; cf. Avenant n° 14 du 23 avr. 2019 (NOR : ASET1951059M) étendu par A. 15 janvier 2020 : JO 22 janv. 2020 et applicable à compter du 1er janvier 2019,Groupe 9,Niv. C,,71556,byYear1607,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",409,,,"29,709",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",408,,,"29,6128",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",407,,,"29,5167",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",406,,,"29,4205",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",405,,,"29,3244",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",404,,,"29,2282",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",403,,,"29,1321",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",402,,,"29,0359",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",401,,,"28,9398",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",400,,,"28,8436",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",399,,,"28,7518",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",398,,,"28,66",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",397,,,"28,5682",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",396,,,"28,4763",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",395,,,"28,3844",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",394,,,"28,2924",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",393,,,"28,2004",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",392,,,"28,1084",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",391,,,"28,0164",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",390,,,"27,9243",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",389,,,"27,8323",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",388,,,"27,7401",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",387,,,"27,648",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",386,,,"27,5558",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",385,,,"27,4636",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",384,,,"27,3714",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",383,,,"27,2791",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",382,,,"27,1869",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",381,,,"27,0945",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",380,,,"27,0022",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",379,,,"26,9098",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",378,,,"26,8174",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",377,,,"26,725",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",376,,,"26,6325",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",375,,,"26,54",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",374,,,"26,4475",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",373,,,"26,355",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",372,,,"26,2624",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",371,,,"26,1698",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",370,,,"26,0772",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",369,,,"25,9845",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",368,,,"25,8918",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",367,,,"25,7991",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",366,,,"25,7064",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",365,,,"25,6136",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",364,,,"25,5208",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",363,,,"25,428",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",362,,,"25,3351",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",261,,,"25,2422",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",360,,,"25,1493",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",359,,,"25,0563",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",358,,,"24,9634",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",357,,,"24,8704",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",356,,,"24,7773",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",355,,,"24,6843",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",354,,,"24,5912",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",353,,,"24,4981",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",352,,,"24,4049",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",351,,,"24,3117",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",350,,,"24,2185",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",349,,,"24,1253",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",348,,,"24,032",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",347,,,"23,9387",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",346,,,"23,8454",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",345,,,"23,7521",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",344,,,"23,6587",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",343,,,"23,5653",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",342,,,"23,4718",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",341,,,"23,3784",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",340,,,"23,2849",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",339,,,"23,1914",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",338,,,"23,0978",byHour,,,,,,,
23.0042,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",337,,,"23,0042",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",336,,,"22,9106",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",335,,,"22,817",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",334,,,"22,7233",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",333,,,"22,6296",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",332,,,"22,5359",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",331,,,"22,4421",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",330,,,"22,3483",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",329,,,"22,2545",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",328,,,"22,1607",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",327,,,"22,0668",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",326,,,"21,9729",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",325,,,"21,879",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",324,,,"21,785",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",323,,,"21,6911",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",322,,,"21,597",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",321,,,"21,503",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",320,,,"21,4089",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",319,,,"21,3148",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",318,,,"21,2207",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",317,,,"21,1265",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",316,,,"21,0323",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",315,,,"20,9381",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",314,,,"20,8439",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",313,,,"20,7496",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",312,,,"20,6553",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",311,,,"20,561",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",310,,,"20,4666",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",309,,,"20,3722",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",308,,,"20,2778",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",307,,,"20,1834",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",306,,,"20,0889",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",305,,,"19,9944",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",304,,,"19,8998",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",303,,,"19,8053",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",302,,,"19,7107",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",301,,,"19,6161",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",300,,,"19,5214",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",299,,,"19,4267",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",298,,,"19,332",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",297,,,"19,2373",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",296,,,"19,1425",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",295,,,"19,0477",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",294,,,"18,9529",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",293,,,"18,858",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",292,,,"18,7631",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",291,,,"18,6682",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",290,,,"18,5733",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",289,,,"18,4783",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",288,,,"18,3833",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",287,,,"18,2883",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",286,,,"18,1932",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",285,,,"18,0981",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",284,,,"18,003",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",283,,,"17,9079",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",282,,,"17,8127",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",281,,,"17,7175",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",280,,,"17,6222",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",279,,,"17,527",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",278,,,"17,4328",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",277,,,"17,3385",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",276,,,"17,2442",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",275,,,"17,1499",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",274,,,"17,0555",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",273,,,"16,9611",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",272,,,"16,8666",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",271,,,"16,7721",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",270,,,"16,6776",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",269,,,"16,583",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",268,,,"16,4884",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",267,,,"16,3937",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",266,,,"16,299",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",265,,,"16,2042",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",264,,,"16,1094",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",263,,,"16,0146",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",262,,,"15,9197",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",261,,,"15,8248",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",260,,,"15,7299",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",259,,,"15,6349",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",258,,,"15,5398",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",257,,,"15,4448",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",256,,,"15,3496",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",255,,,"15,2545",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",254,,,"15,1593",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",253,,,"15,064",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",252,,,"14,9687",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",251,,,"14,8734",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",250,,,"14,7781",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",249,,,"14,6826",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",248,,,"14,5872",byHour,,,,,,,
2/17/2012,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",247,,,"14,4917",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",246,,,"14,3962",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",245,,,"14,3006",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",244,,,"14,205",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",243,,,"14,1093",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",242,,,"14,0136",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",241,,,"13,9179",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",240,,,"13,8221",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",239,,,"13,7263",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",238,,,"13,6304",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",237,,,"13,5345",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",236,,,"13,4386",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",235,,,"13,3426",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",234,,,"13,2465",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",233,,,"13,1505",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",232,,,"13,0544",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",231,,,"12,9582",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",230,,,"12,862",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",229,,,"12,7658",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",228,,,"12,6695",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",227,,,"12,5732",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",226,,,"12,4768",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",225,,,"12,3804",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",224,,,"12,284",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",223,,,"12,1875",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",222,,,"12,91",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",221,,,"12,0323",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",220,,,"11,946",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",219,,,"11,8591",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",218,,,"11,772",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",217,,,"11,6847",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",216,,,"11,5972",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",215,,,"11,5095",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",214,,,"11,4216",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",213,,,"11,3334",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",212,,,"11,2451",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",211,,,"11,1566",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",210,,,"11,0678",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",209,,,"10,9789",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",208,,,"10,8898",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",207,,,"10,8004",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",206,,,"10,7109",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",205,,,"10,6211",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",204,,,"10,5311",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",203,,,"10,441",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",202,,,"10,3506",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",201,,,"10,26",byHour,,,,,,,
2/18/2021,12/1/2019,,454,Remontées mécaniques et domaines skiables,,,"cf. CC. n° 3122, Art. 17 bis ; cf. Avenant n° 32 du 5 juill. 2006 (NOR : ASET0650936M) étendu par A. 27 déc. 2006 : JO 9 janv. 2007 révisé par avenant n° 65 du 24 nov. 2016 (NOR : ASET1750828M) non étendu et conclu pour une durée de 5 ans (déposé le 10 août 2017) ; cf. Avenant n° 59 du 20 nov. 2012 (NOR : ASET1350251M) étendu par A. 17 avr. 2013:4mai2013;cf.Avenantn°71du28nov.2019(NOR: ASET2050382M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et en vigueur à compter de la date de sa signature (conclu pour une durée indéterminée)",200,,,"10,1692",byHour,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",350,,2506,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",315,,2283,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",290,,2130,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",275,,2036,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",260,,1942,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",240,,1817,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",220,,1702,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",210,,1641,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",200,,1606,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",195,,1592,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",190,,1578,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",185,,1564,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020","Ouvriers, employés et agents de maîtrise",180,,1550,byMonth151,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,700,,58606,byYear1607,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,600,,50556,byYear1607,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,510,,43294,byYear1607,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,470,,40052,byYear1607,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,410,,35231,byYear1607,,,,,,,
2/18/2021,4/1/2020,,489,Industries de cartonnage,,,"cf. CC. n° 3135, Art. 33 nouveau résultant de l’accord du 17 avr. 2019 (NOR : ASET1950565M) non étendu et en vigueur le 1er jour du mois civil suivant la publication de son extension) (conclu pour une durée indéterminée) ; cf. Avenant n° 158 du 5 mars 2020 (NOR : ASET2050438M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 et applicable au 1er avr. 2020",Cadres,355,,30875,byYear1607,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 1,,1665,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 1,,1646,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 1,,1599,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 1,,1578,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 2,,1806,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 2,,1753,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 2,,1721,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 2,,1687,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 3,,2140,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 3,,1995,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 3,,1886,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 3,,1832,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 4,,2543,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 4,,2394,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 4,,2229,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 4,,2187,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 5,,3318,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 5,,3201,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 5,,2913,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 5,,2719,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 6,,4331,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 6,,3908,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 6,,3561,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 6,,3343,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 4,Niveau 7,,5553,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 3,Niveau 7,,5201,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 2,Niveau 7,,4677,byMonth151,,,,,,,
2/18/2021,3/1/2020,,567,"Bijouterie, joaillerie, orfèvrerie et activités qui s'y rattachent",,,"cf. CC. n° 3051, Art. 14 ; cf. Accord du 10 sept. 2019 (NOR : ASET1951357M) étendu par A. 2 avr. 2020 : JO 9 avr. 2020 et en vigueur à son extension ou au plus tard au 1er mars 2020 (Conclu pour une durée indéterminée)",Echelon 1,Niveau 7,,4371,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,I,1,1564,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,I,2,1566,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,I,3,1568,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,II,1,1571,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,II,2,1589,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,II,3,1602,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,III,1,1608,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,III,2,1633,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,III,3,1651,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,IV,1,1654,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,IV,2,1683,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Employés,IV,3,1700,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,V,1,1796,byMonth151,,,,,,,
13/04/2021,13/04/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,V,2,1796,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,V,3,1861,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,VI,1,1873,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,VI,2,1965,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Personnel de maîtrise,VI,3,2054,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VII,1,2621,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VIII,1,2823,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VII,2,3062,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VIII,2,3223,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VII,3,3382,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,VIII,3,3541,byMonth151,,,,,,,
13/04/2021,21/01/2021,,731,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison",,,,Cadres,IX,,4366,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Employés,Catégorie 1,,1575,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Employés,Catégorie 2,,1588,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Employés,Catégorie 3,,1624,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Employés,Catégorie 4,,1687,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Agents de maîtrise,Catégorie 5,,1775,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Agents de maîtrise,Catégorie 6,,2111,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Cadres,Catégorie 7,,2472,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Cadres,Catégorie 8,,3430,byMonth151,,,,,,,
2/18/2021,1/1/2021,,733,Détaillants en chaussures,,,cf. Avenant n° 78 du 8 déc. 2014 (NOR : ASET1550197M) étendu par A. 11 déc. 2015 : JO 23 déc. 2015 et en vigueur le 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 92 du 27 mars 2019 (NOR : ASET1950726M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 96 du 2 mars 2020 (NOR : ASET2050523M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et applicable à compter du 1er jour du mois civil suivant la publication de son extension,Cadres,Catégorie 9,,4045,byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe I,,,"1436,7",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe II,,,"1456,94",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe III,,,"1477,17",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IV,,,"1550,02",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe V,,,"1796,89",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VI,,,"2468,4",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VII,,,"2692,8",byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VIII,,,2992,byMonth151,,,,,,,
2/18/2021,1/1/2011,1,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IX,,,3179,byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe I,,,"1452,89",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe II,,,"1473,13",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe III,,,"1509,55",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IV,,,"1614,77",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe V,,,"1869,74",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VI,,,"2580,6",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VII,,,"2767,6",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VIII,,,"3104,2",byMonth151,,,,,,,
2/18/2021,1/1/2011,2-3,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IX,,,"3291,2",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe I,,,"1465,03",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe II,,,"1493,36",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe III,,,"1558,12",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IV,,,"1675,48",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe V,,,"1995,2",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VI,,,"2692,8",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VII,,,"2842,4",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe VIII,,,"3216,4",byMonth151,,,,,,,
2/18/2021,1/1/2011,4,783,"Centres d'Hébergement et de Réadaptation Sociale et Services d'Accueil, d'Orientation et d'Insertion pour Adultes",,,"cf. Protocole n° 137 du 1er oct. 2001 ; cf. Protocole n° 154 du 30 sept. 2011 ; Cf. Avenant n° 154 du 30 sept. 2011 (NOR : ASET1451043M) agréé par A. 23 déc. 2011 : JO 30 déc. 2011, non étendu et applicable à compter du 1er janvier 2011 ;",Groupe IX,,,"3403,4",byMonth151,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 1,,,19841,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 2,,,20399,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 3,,,21109,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 4,,,22334,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 5,,,24349,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 6,,,26338,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 7,,,29345,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 8,,,33456,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 9,,,39476,byYear1607,,,,,,,
2/18/2021,1/1/2019,,915,Sociétés d'expertises et d'évaluations,,,"cf. Annexe III résultant de l’’avenant n° 62 du 18 déc. 2015 (NOR : ASET1650232M) étendu par A. 1er août 2017 : JO 8 août 2017 et prenant effet à partir du 1er janvier 2016 pour les entreprises adhérentes à l'une ou l'autre des organisations patronales signataires, et au 1er jour du mois civil suivant la publication de son extension pour les autres entreprises ; cf. Avenant n° 66 du 15 janv. 2018 (NOR : ASET1851082M)",Niveau 10,,,48954,byYear1607,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"10,3",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"10,43",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"10,55",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"10,87",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"11,31",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Ouvriers,,,"11,71",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Agents de maîtrise,,,12,byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Agents de maîtrise,,,"12,37",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Agents de maîtrise,,,"12,77",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Agents de maîtrise,,,"13,2",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Agents de maîtrise,,,"14,05",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Cadres,,,"16,04",byHour,,,,,,,
2/18/2021,1/1/2019,,953,Charcuterie de détail,,,"cf. CC. n° 3133, Art. 36 ; cf. Annexe II ; cf. Avenant n° 36 du 15 janv. 2019 (NOR : ASET1951189M) étendu par A. 19 mars 2020 : JO 27 mars 2020 (déposé le 29 juill. 2019)",Cadres,,,"17,31",byHour,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Personnels de service,,,1559,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,"Secrétaire administratif, aide-comptable",,,1573,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Comptable,,,1697,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Employé en prothèse dentaire,,,1576,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Auxiliaire en prothèse dentaire,,,1604,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Technicien en prothèse dentaire,,,1634,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Technicien qualifié en prothèse dentaire,TQ1,,1688,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Technicien qualifié en prothèse dentaire,TQ2,,1841,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Technicien qualifié en prothèse dentaire,TQ3,,1942,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Prothésiste dentaire hautement qualifié,PHQ1,,2048,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Prothésiste dentaire hautement qualifié,PHQ2,,2181,byMonth151,,,,,,,
2/18/2021,1/1/2019,,993,Prothésistes dentaires et personnels des laboratoires de prothèses dentaires,,,cf. Accord du 30 nov. 2018 (NOR : ASET1950074M) étendu par A. 19 juill. 2019 : JO 26 juill. 2019 et prenant effet au 1er janvier 2019,Chef de Laboratoire,,,2799,byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2018",Personnel de fabrication,160,,"1557,65",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2018",Personnel de fabrication,165,,"1568,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2019",Personnel de fabrication,170,,"1584,95",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2020",Personnel de fabrication,175,,"1594,05",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2021",Personnel de fabrication,180,,"1613,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2022",Personnel de fabrication,185,,"1662,3",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2023",Personnel de fabrication,190,,"1704,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2024",Personnel de fabrication,220,,"1956,54",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2025",Personnel de fabrication,250,,"2219,7",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2026",Personnel de fabrication,270,,"2399,42",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2027",Personnel de fabrication,290,,"2578,39",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2028",Personnel de fabrication,310,,"2755,84",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2029",Personnel de fabrication,330,,"2934,81",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2030",Personnel de fabrication,350,,"3112,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2031",Personnel de vente,160,,"1557,65",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2032",Personnel de vente,165,,"1568,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2033",Personnel de vente,170,,"1584,95",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2034",Personnel de vente,175,,"1594,05",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2035",Personnel de vente,180,,"1613,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2036",Personnel de vente,200,,"1777,57",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2037",Personnel de vente,210,,"1867,06",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2038",Personnel de vente,250,,"2221,97",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2039",Employés,160,,"1557,65",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2040",Employés,165,,"1568,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2041",Employés,170,,"1584,95",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2042",Employés,180,,"1613,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2043",Employés,190,,"1704,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2044",Ouvriers d'entretien,160,,"1557,65",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2045",Ouvriers d'entretien,165,,"1568,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2046",Ouvriers d'entretien,190,,"1704,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2047",Personnel de livraison,165,,"1568,27",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2048",Personnel de livraison,170,,"1584,95",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2049",Personnel de livraison,180,,"1613,77",byMonth151,,,,,,,
2/18/2021,1/1/2018,,1267,Patisserie,,,"cf. CC. n° 3215, Art. 32 ; cf. Avenant n° 89 du 18 janv. 2018 (NOR : ASET1850335M) étendu par A. 17août 2018 : JO 23 août 2050",Personnel de livraison,190,,"1704,77",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau A,,,1536,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau B,,,1584,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau C,,,1618,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau D,,,1749,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau E,,,1994,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau F,,,2323,byMonth151,,,,,,,
2/18/2021,1/1/2020,,1316,Tourisme social et familial,,,"cf. CC. n° 3151, Art. 26 ; cf. Avenant n° 64 du 27 nov. 2019 (NOR : ASET2050166M) étendu par A. du 26 mai 2020 : JO 3 juin 2020 en vigueur au 1er janvier 2020 (Conclu pour une durée indéterminée)",Niveau G,,,2896,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2020,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,I,"1539,45",byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2020,hors secteur des légumes frais prêts à l'emploi,Technicien/AM,I,1872,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2021,hors secteur des légumes frais prêts à l'emploi,Cadre,I,2912,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2022,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,I Bis,1552,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2023,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,II,1562,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2024,hors secteur des légumes frais prêts à l'emploi,Technicien/AM,II,2020,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2025,hors secteur des légumes frais prêts à l'emploi,Cadre de Direction,II,3440,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2026,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,III,1572,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2027,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,IV,1632,byMonth151,,,,,,,
2/18/2021,3/1/2020,,1405,Expédition et exportation de fruits et légumes,,,cf. Avenant n° 24 du 20 févr. 2020 (NOR : ASET2050482M) étendu par A. 4 août 2020 : JO 12 août 2020 et applicable au 1er mars 2028,hors secteur des légumes frais prêts à l'emploi,Ouvrier/Employé,V,1652,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,I,1,1433,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,I,2,1435,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,I,3,1437,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,II,1,1439,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,II,2,1441,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,II,3,1444,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,III,1,1450,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,III,2,1456,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,III,3,1462,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,IV,1,1479,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,IV,2,1495,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,IV,3,1505,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,V,1,1549,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,V,2,1575,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,V,3,1613,byMonth151,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VI,1,21929,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VI,2,23233,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VI,3,24609,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VII,1,25114,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VII,2,26683,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VII,3,28537,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VIII,1,29413,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VIII,2,31356,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,VIII,3,33793,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,IX,1,36169,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,IX,2,39340,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,X,1,42376,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,X,2,51661,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,XI,1,59462,byYear1607,,,,,,,
2/18/2021,1/1/2013,,1405,Expédition et exportation de fruits et légumes,,,"cf. Annexe « légumes frais prêts à l'emploi » du 7 févr. 1995,
 étendue par A. 9 oct. 1995 : JO 19 octobre 1995 ; cf. Avenant
 n° 4 du 9 janv. 2006 (NOR : ASET0650558M), étendu par A.
 20 juill. 2006 : JO 1er août 2006 ; cf. Avenant n° 9 du 17 janv.
 2013 (NOR : ASET1350503M) non étendu et en vigueur le
 1er janvier 2013 (déposé le 29 mars 2013)",secteur des légumes frais prêts à l'emploi,XI,2,68833,byYear1607,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,I,1,"1539,42",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,I,2,"1544,41",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,II,1,"1551,62",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,II,2,"1568,61",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,II,3,"1589,02",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,III,1,"1609,4",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,III,2,"1633,19",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ouvriers,III,3,"1663,78",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,I,1,"1539,42",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,I,2,"1544,41",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,II,1,"1551,62",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,II,2,"1568,61",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,II,3,"1589,02",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,III,1,"1609,4",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,III,2,"1633,19",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Employés,III,3,"1663,78",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens,IV,1,"1633,19",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens,IV,2,"1663,78",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens et agents de maîtrise,V,1,"1711,36",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens et agents de maîtrise,V,2,"1799,73",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens et agents de maîtrise,VI,1,"1966,28",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Techniciens et agents de maîtrise,VI,2,"2156,61",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ingénieurs et cadres,VII,1,"1935,83",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ingénieurs et cadres,VII,2,"2112,79",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ingénieurs et cadres,VII,3,"3185,27",byMonth151,,,,,,,
2/18/2021,1/1/2020,,1423,Navigation de plaisance,,,"cf. CC. n° 3187, Art. G-44 ; cf. Avenant du 1er juin 2020 (NOR : ASET2050585M) étendu par A. 30 sept. 2020 : JO 13 oct. 2020 et applicable au 1er juin 2020 (Conclu pour une durée indéterminée)",Ingénieurs et cadres,VII,4,"4432,49",byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,1,,1542,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,1,,1570,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,1,,1650,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,1,,1870,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,1,,2385,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,2,,1548,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,2,,1590,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,2,,1684,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,2,,2023,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,2,,2627,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,3,,1554,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,3,,1617,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,3,,1719,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,3,,2191,byMonth151,,,,,,,
2/18/2021,7/1/2020,,1492,"Production des papiers-cartons et celluloses (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,3,,2903,byMonth151,,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",140,,,"1539,42",byMonth151,140,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",150,,,"1553,1",byMonth151,150,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",160,,,"1558,33",byMonth151,160,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",170,,,"1563,56",byMonth151,170,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",180,,,"1568,79",byMonth151,180,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",200,,,"1605,94",byMonth151,200,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",225,,,"1662,91",byMonth151,225,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",250,,,"1724,88",byMonth151,250,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",275,,,"1788,61",byMonth151,275,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",300,,,"1909,57",byMonth151,300,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",330,,,"2050,59",byMonth151,330,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",370,,,"2240,28",byMonth151,370,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",410,,,"2433,34",byMonth151,410,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",460,,,2675,byMonth151,460,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",550,,,"3113,07",byMonth151,550,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",660,,,"3651,89",byMonth151,660,,,,,,
2/18/2021,6/1/2020,,1499,"Miroiterie, transformation et négoce du verre",,,"cf. CC. n° 3050, Art. 13 ; cf. Accord du 13 mars 2018 (NOR : ASET1850588M) étendu par A. 29 mai 2019 : JO 4 juin 2019 (conclu pour une durée indéterminée) ; cf. Accord du 19 mars 2019 (NOR : ASET1950646M) non étendu (conclu pour une durée indéterminée) ; cf. Accord du 20 févr. 2020 (NOR : ASET2050368M) non étendu (conclu pour une durée indéterminée, déposé le 22 avril 2020)",880,,,"4736,19",byMonth151,880,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,130,1560,byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,135,"1663,16",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,140,"1681,79",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,145,"1703,65",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,150,"1725,16",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,160,"1750,22",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,165,"1775,86",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,170,"1798,95",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,175,"1823,4",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,180,"1840,28",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,185,"1872,74",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,190,"1889,69",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Employés-ouvriers,Niveau I,195,"1922,65",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,200,"1940,05",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,205,"1973,92",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,210,"2044,76",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,220,"2067,53",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,230,"2116,12",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,240,"2153,53",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Agent de maîtrise,Niveau II,250,"2189,04",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Cadre,Niveau III,300,"2938,73",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Cadre,Niveau III,350,"3240,64",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Cadre,Niveau III,400,"3542,48",byMonth151,,,,,,,
2/18/2021,2/1/2021,,1504,"Poissonnerie (Commerce de détail, demi-gros et gros)",,,"cf. Annexe II résultant de l'avenant du 12 janv. 2006 (NOR : ASET0650288M), non étendu, en vigueur à la date de son extension ; cf. Avenant n° 92 du 17 janv. 2018 (NOR : ASET1850490M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable à partir du 1er janvier 2018, élargi au commerce de gros de la poissonnerie par A. 21 juin 2019 : JO 27 juin 2019 ; cf. Avenant du 1er sept. 2020 (NOR : ASET2050886M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension (conclu pour une durée indéterminée)",Cadre,Niveau III,450,"3842,56",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2019",I,1,,"1537,57",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2020",I,2,,"1546,44",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2021",I,3,,"1596,82",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2022",II,1,,"1640,8",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2023",II,2,,"1685,27",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2024",II,3,,"1729,6",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2025",III,1,,"1788,98",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2026",III,2,,"1832,58",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2027",III,3,,"1878,29",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2028",IV,1,,"1997,46",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2029",IV,2,,"2057,01",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2030",V,1,,"2205,81",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2031",V,2,,"2265,38",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2032",V,3,,"2324,97",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2033",VI,1,,"2473,67",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2034",VI,2,,"2592,78",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2035",VI,3,,"2772,08",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2036",VII,1,,"2979,65",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2037",VII,2,,3188,byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2038",VII,3,,"3396,33",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2039",VIII,1,,"3664,2",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2040",VIII,2,,"3932,09",byMonth151,,,,,,,
2/18/2021,3/1/2019,,1513,"Activités de production des eaux embouteillées et boissons rafraîchissantes sans alcool, et de bière ",,,"cf. CC. n° 3247, Art. 5.4 ; cf. Avenant n° 38 du 20 mars 2019 (NOR : ASET1950685M) étendu par A. 7 oct. 2019 : JO 11 oct. 2019 et applicable au 1er mars 2041",VIII,3,,"4423,26",byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,I,1,,1541,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,I,2,,1557,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,I,3,,1579,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,II,1,,1605,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,II,2,,1631,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,II,3,,1661,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,III,1,,1687,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,III,CQP,,1697,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,III,2,,1716,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,III,3,,1746,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,III,CQP,,1774,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,IV,1,,1785,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,IV,2,,1844,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,IV,3,,1941,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,IV,CQP,,1993,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,V,1,,2180,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,V,2,,2438,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,V,3,,2632,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,VI,1,,3110,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,VI,2,,3336,byMonth151,,,,,,,
2/18/2021,1/1/2019,,1536,Distributeurs conseils hors domicile (distributeurs CHD),,,cf. Avenant n° 2018-2 du 3 avr. 2018 (NOR : ASET1850718M) étendu par A. 26 juill. 2019 : JO 7 août 2019 et en vigueur au,VI,3,,3764,byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,NA E1,,"1523,01",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E2,,"1530,27",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,NB E1,,"1539,95",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E2,,"1552,05",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E3,,"1564,15",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,NC E1,,"1576,25",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E2,,"1600,45",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E3,,"1636,75",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E4,,"1685,15",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,ND E1,,"1733,55",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E2,,"1794,05",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E3,,"1854,55",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,OE,E4,,"1915,05",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,NE E1,,"1987,65",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,E2,,"2060,25",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,E3,,"2132,85",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,E4,,"2205,45",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,NF E1,,"2290,15",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,E2,,"2374,85",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,TAM,E3,,"2459,55",byMonth151,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,Cadres,NG E1,,"28498,2",byYear1607,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,Cadres,NG E2,,30531,byYear1607,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,Cadres,H,,"35032,2",byYear1607,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,Cadres,I,,"43177,92",byYear1607,,,,,,,
2/18/2021,7/1/2019,,1558,Industries céramiques de France,,,cf. Avenant du 29 sept. 2015 (NOR : ASET1550971M) étendu par A. 4 mai 2017 : JO 6 mai 2017 et en vigueur le 1er janvier 2017 (déposé le 28 oct. 2015) ; cf. Avenant n° 2 du 15 mai 2019 (NOR : ASET1951175M) non étendu et en vigueur au 1er juillet 2019,Cadres,J,,"54198,6",byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 1,1,,"1539,42",byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 1,2,,1547,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 2,1,,1557,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 2,2,,1572,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 2,3,,1589,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 3,1,,1589,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 3,2,,1610,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 3,3,,1626,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 4,1,,20405,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 4,2,,20986,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 4,3,,22730,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 5,1,,22730,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 5,2,,24739,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 5,3,,26643,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 6,1,,26643,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Niveau 6,2,,30661,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Position 1,Echelon 1,,28546,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Position 1,Echelon 2,,30661,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Position 2,,,35522,byYear1607,,,,,,,
2/19/2021,1/1/2020,,1580,Industrie de la chaussure et des articles chaussants,,,cf. Accord du 24 févr. 2020 (NOR : ASET2050428M) étendu,Position 3,,,41232,byYear1607,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2019",Employés-ouvriers,Groupe III H,,"1521,22",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2020",Employés-ouvriers,Groupe III G,,"1542,36",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2021",Employés-ouvriers,Groupe III F,,"1575,11",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2022",Employés-ouvriers,Groupe III E,,"1671,8",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2023",Employés-ouvriers,Groupe III D,,"1724,82",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2024",Employés-ouvriers,Groupe III C,,"1827,75",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2025",Employés-ouvriers,Groupe III B,,"2016,45",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2026",Employés-ouvriers,Groupe III A,,"2206,71",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2027",AMT,Groupe II-C,,"2396,87",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2028",AMT,Groupe II-B,,"2492,74",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2029",AMT,Groupe II-A,,"2684,48",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2030",Cadres,Groupe I-G,,"2780,34",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2031",Cadres,Groupe I-F,,"2876,22",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2032",Cadres,Groupe I-E,,"2953,18",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2033",Cadres,Groupe I-D,,"3681,56",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2034",Cadres,Groupe I-C,,"3834,96",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2035",Cadres,Groupe I-B,,"4314,36",byMonth151,,,,,,,
2/19/2021,5/1/2019,,1611,Entreprises de logistique de communication écrite directe,,,"cf. Accord du 5 févr. 2018 (NOR : ASET1850436M) étendu par
 A. 21 déc. 2018 : JO 27 déc. 2018 (déposé le 9 mars 2018) ; cf.
 Accord du 31 janv. 2019 (NOR : ASET1950409M) étendu par
 A. 2 août 2019 : JO 8 août 2019 et en vigueur le 1er mai 2036",Cadres,Groupe I-A,,"4927,94",byMonth151,,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",135,,,"1495,66",byMonth151,135,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",140,,,"1515,28",byMonth151,140,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",145,,,"1534,9",byMonth151,145,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",150,,,"1554,52",byMonth151,150,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",155,,,"1574,12",byMonth151,155,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",160,,,"1593,74",byMonth151,160,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",165,,,"1613,36",byMonth151,165,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",170,,,"1632,99",byMonth151,170,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",175,,,"1652,6",byMonth151,175,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",180,,,"1672,24",byMonth151,180,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",185,,,"1691,84",byMonth151,185,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",190,,,"1711,45",byMonth151,190,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",195,,,"1731,07",byMonth151,195,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",200,,,"1750,69",byMonth151,200,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",205,,,"1777,38",byMonth151,205,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",210,,,"1804,09",byMonth151,210,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",215,,,"1830,77",byMonth151,215,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",220,,,"1857,46",byMonth151,220,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",225,,,"1884,17",byMonth151,225,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",230,,,"1910,87",byMonth151,230,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",235,,,"1937,58",byMonth151,235,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",240,,,"1964,27",byMonth151,240,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",250,,,"2039,92",byMonth151,250,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",260,,,"2115,6",byMonth151,260,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",270,,,"2191,25",byMonth151,270,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",280,,,"2266,93",byMonth151,280,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",290,,,"2342,59",byMonth151,290,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",300,,,"2418,27",byMonth151,300,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",330,,,"2645,29",byMonth151,330,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",360,,,"2872,31",byMonth151,360,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",400,,,"3191,44",byMonth151,400,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",450,,,"3553,34",byMonth151,450,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",500,,,"3931,66",byMonth151,500,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",550,,,"4310,04",byMonth151,550,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",600,,,"4688,38",byMonth151,600,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",700,,,"5469,78",byMonth151,700,,,,,,
2/19/2021,1/1/2017,,1621,Répartition pharmaceutique,,,"cf. CC. n° 3262, Art. J-1 ; cf. Accord du 5 mars 2012 (NOR :
 ASET1250561M) étendu par A. 6 août 2012 : JO 17 août 2012
 (déposé le 23 mars 2012) ; cf. Avenant du 31 août 2012 (NOR :
 ASET1251245M) étendu par A. 19 déc. 2012 : JO 26 déc.
 2012 ; cf. Accord du 11 juill. 2017 (NOR : ASET1750851M)
 étendu par A. 6 déc. 2017 : JO 13 déc. 2017 et prenant
 effet à compter de l'accomplissement des formalités de dépôt
 (déposé le 24 août 2017)",800,,,"6201,78",byMonth151,800,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, 
 chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,115,,1481,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,118,,1481,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,120,,1481,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, 
 chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,125,,1481,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, 
 chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,130,,1487,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,135,,1493,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,138,,1499,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,140,,1504,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,145,,1527,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,150,,1543,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,155,,1548,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,160,,1576,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,165,,1581,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,170,,1609,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,175,,1625,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,180,,1635,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,185,,1674,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Employés,190,,1700,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",200,,1771,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",210,,1815,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",212,,1859,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",230,,1916,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",250,,2041,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",260,,2101,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)","Agents de maîtrise, techniciens assimilés",270,,2155,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,280,,2267,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,290,,2319,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,310,,2450,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,325,,2531,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,330,,2621,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,380,,2834,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,450,,3140,byMonth151,,,,,,,
2/19/2021,5/1/2017,,1624,"Commerce de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des négociants-distributeurs de levure",,,"cf. Accord du 30 oct. 2017 (ASET1850043M) étendu par A.
 20 févr. 2019 : JO 26 févr. 2019 (déposé le 27 déc. 2017)",Cadres,650,,4355,byMonth151,,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",100,,,"1547,83",byMonth151,100,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",105,,,"1572,98",byMonth151,105,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",110,,,"1598,13",byMonth151,110,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",115,,,"1623,28",byMonth151,115,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",120,,,"1648,43",byMonth151,120,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",125,,,"1673,58",byMonth151,125,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",130,,,"1698,73",byMonth151,130,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",135,,,"1723,88",byMonth151,135,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",140,,,"1749,03",byMonth151,140,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",145,,,"1774,18",byMonth151,145,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",150,,,"1799,33",byMonth151,150,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",155,,,"1824,48",byMonth151,155,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",160,,,"1849,63",byMonth151,160,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",165,,,"1874,78",byMonth151,165,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",170,,,"1899,93",byMonth151,170,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",175,,,"1925,08",byMonth151,175,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",180,,,"1950,23",byMonth151,180,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",185,,,"1975,38",byMonth151,185,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",190,,,"2000,53",byMonth151,190,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",195,,,"2025,68",byMonth151,195,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",200,,,"2050,83",byMonth151,200,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",205,,,"2075,98",byMonth151,205,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",210,,,"2101,13",byMonth151,210,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",215,,,"2126,28",byMonth151,215,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",220,,,"2151,43",byMonth151,220,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",225,,,"2176,58",byMonth151,225,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",230,,,"2201,73",byMonth151,230,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",235,,,"2226,88",byMonth151,235,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",240,,,"2252,03",byMonth151,240,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",245,,,"2277,18",byMonth151,245,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",250,,,"2302,33",byMonth151,250,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",255,,,"2327,48",byMonth151,255,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",260,,,"2352,63",byMonth151,260,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",265,,,"2377,78",byMonth151,265,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",270,,,"2402,93",byMonth151,270,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",275,,,"2428,08",byMonth151,275,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",280,,,"2453,23",byMonth151,280,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",285,,,"2478,38",byMonth151,285,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",290,,,"2503,53",byMonth151,290,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",295,,,"2528,68",byMonth151,295,,,,,,
2/19/2021,9/1/2020,,1631,Hôtellerie de plein air,,,"cf. CC. n° 3271, Art. 8 ; cf. Avenant n° 40 du 21 mars 2019
 (NOR : ASET1950607M) étendu par A. 3 déc. 2019 : JO
 13 déc. 2019 et en vigueur le 1er jour du mois suivant la
 date de parution de son extension (Conclu pour une durée
 indéterminée) ; cf. Avenant n° 41 du 10 mars 2020 (NOR :
 ASET2050431M) étendu par A. 4 août 2020 : JO 12 août 2020
 et en vigueur le 1er jour du mois suivant la parution de son
 extension (conclu pour une durée indéterminée)",300,,,"2553,83",byMonth151,300,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2019",A,,,20637,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2020",B,,,20827,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2021",C,,,21518,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2022",D,,,22918,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2023",E,,,26065,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2024",F,,,28811,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2025",G,,,33776,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2026",H,,,39037,byYear1607,,,,,,,
2/19/2021,1/1/2019,,1801,Sociétés d'assistance,,,"cf. CC. n° 3279, Art. 49 ; cf. CC. n° 3279, Art. 50 ; cf. Avenant
 n° 43 du 19 avr. 2019 (NOR : ASET1950674M) étendu par A.30 oct. 2019 : JO 5 nov. 2019 et applicable à compter du 1er janv. 2027",I,,,51046,byYear1607,,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",100,,,"1529,5",byMonth151,100,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",115,,,"1542,11",byMonth151,115,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",125,,,"1548,69",byMonth151,125,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",135,,,"1562,26",byMonth151,135,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",145,,,"1581,38",byMonth151,145,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",160,,,"1610,04",byMonth151,160,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",175,,,"1638,96",byMonth151,175,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",190,,,"1667,38",byMonth151,190,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",205,,,"1697,74",byMonth151,205,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",220,,,"1726,45",byMonth151,220,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",230,,,"1745,57",byMonth151,230,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",245,,,"1876,78",byMonth151,245,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",260,,,"2014,8",byMonth151,260,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",275,,,"2152,82",byMonth151,275,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",290,,,"2290,84",byMonth151,290,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",315,,,"2496,16",byMonth151,315,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",330,,,"2759,27",byMonth151,330,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",345,,,"3205,98",byMonth151,345,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",385,,,"3273,19",byMonth151,385,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",440,,,"3492,66",byMonth151,440,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",490,,,"3891,15",byMonth151,490,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",550,,,"4333,71",byMonth151,550,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",660,,,"5064,34",byMonth151,660,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",770,,,"5794,89",byMonth151,770,,,,,,
2/19/2021,3/1/2019,,1821,"Professions regroupées du cristal, du verre et du vitrail",,,"cf. Accord du 22 févr. 2019 (NOR : ASET1950552M) étendu
 par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er mars 2019 (dispositions impératives) (conclu pour une durée indéterminée)",880,,,"6525,48",byMonth151,880,,,,,,
2/19/2021,1/1/2020,,1875,Cabinets et cliniques vétérinaires (Personnel salarié),,,"cf. Annexe II complétée par avenant n° 70 du 12 sept. 2017
 (NOR : Aset1751125m) étendu par A.13 févr. 2019 : JO 21 févr.
 2019 et en vigueur le jour de la publication de son extension ;
 cf. Accord RTT du 4 déc. 2001 (NOR : ASET0151035M) étendu
 par A. 6 déc. 2002 : JO 17 déc. 2002 ; cf. Avenant n° 74 du
 18 oct. 2018 (NOR : ASET1950003M) étendu par A. 16 avr.
 2019 : JO 24 avr. 2019 ; cf. Avenant n° 75 du 18 oct. 2018
 (NOR : ASET1950004M) étendu par A. 29 mai 2019 : JO 4 juin
 2019 et en vigueur impérativement à la date du 1er janvier
 
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant n° 78 du 22 nov. 2019 (NOR : ASET2050100M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 (déposé le 5 janv. 2020)",Personnel de nettoyage et entretien des locaux,Echelon 1,,1581,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1875,Cabinets et cliniques vétérinaires (Personnel salarié),,,"cf. Annexe II complétée par avenant n° 70 du 12 sept. 2017
 (NOR : Aset1751125m) étendu par A.13 févr. 2019 : JO 21 févr.
 2019 et en vigueur le jour de la publication de son extension ;
 cf. Accord RTT du 4 déc. 2001 (NOR : ASET0151035M) étendu
 par A. 6 déc. 2002 : JO 17 déc. 2002 ; cf. Avenant n° 74 du
 18 oct. 2018 (NOR : ASET1950003M) étendu par A. 16 avr.
 2019 : JO 24 avr. 2019 ; cf. Avenant n° 75 du 18 oct. 2018
 (NOR : ASET1950004M) étendu par A. 29 mai 2019 : JO 4 juin
 2019 et en vigueur impérativement à la date du 1er janvier
 
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant n° 78 du 22 nov. 2019 (NOR : ASET2050100M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 (déposé le 5 janv. 2020)",Personnel d'accueil et de secrétariat,Echelon 2,,"1627,5",byMonth151,,,,,,,
2/19/2021,1/1/2020,,1875,Cabinets et cliniques vétérinaires (Personnel salarié),,,"cf. Annexe II complétée par avenant n° 70 du 12 sept. 2017
 (NOR : Aset1751125m) étendu par A.13 févr. 2019 : JO 21 févr.
 2019 et en vigueur le jour de la publication de son extension ;
 cf. Accord RTT du 4 déc. 2001 (NOR : ASET0151035M) étendu
 par A. 6 déc. 2002 : JO 17 déc. 2002 ; cf. Avenant n° 74 du
 18 oct. 2018 (NOR : ASET1950003M) étendu par A. 16 avr.
 2019 : JO 24 avr. 2019 ; cf. Avenant n° 75 du 18 oct. 2018
 (NOR : ASET1950004M) étendu par A. 29 mai 2019 : JO 4 juin
 2019 et en vigueur impérativement à la date du 1er janvier
 
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant n° 78 du 22 nov. 2019 (NOR : ASET2050100M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 (déposé le 5 janv. 2020)",Auxiliaire vétérinaire 3,Echelon 3,,"1658,5",byMonth151,,,,,,,
2/19/2021,1/1/2020,,1875,Cabinets et cliniques vétérinaires (Personnel salarié),,,"cf. Annexe II complétée par avenant n° 70 du 12 sept. 2017
 (NOR : Aset1751125m) étendu par A.13 févr. 2019 : JO 21 févr.
 2019 et en vigueur le jour de la publication de son extension ;
 cf. Accord RTT du 4 déc. 2001 (NOR : ASET0151035M) étendu
 par A. 6 déc. 2002 : JO 17 déc. 2002 ; cf. Avenant n° 74 du
 18 oct. 2018 (NOR : ASET1950003M) étendu par A. 16 avr.
 2019 : JO 24 avr. 2019 ; cf. Avenant n° 75 du 18 oct. 2018
 (NOR : ASET1950004M) étendu par A. 29 mai 2019 : JO 4 juin
 2019 et en vigueur impérativement à la date du 1er janvier
 
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant n° 78 du 22 nov. 2019 (NOR : ASET2050100M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 (déposé le 5 janv. 2020)",Auxiliaire vétérinaire 4,Echelon 4,,1705,byMonth151,,,,,,,
2/19/2021,1/1/2020,,1875,Cabinets et cliniques vétérinaires (Personnel salarié),,,"cf. Annexe II complétée par avenant n° 70 du 12 sept. 2017
 (NOR : Aset1751125m) étendu par A.13 févr. 2019 : JO 21 févr.
 2019 et en vigueur le jour de la publication de son extension ;
 cf. Accord RTT du 4 déc. 2001 (NOR : ASET0151035M) étendu
 par A. 6 déc. 2002 : JO 17 déc. 2002 ; cf. Avenant n° 74 du
 18 oct. 2018 (NOR : ASET1950003M) étendu par A. 16 avr.
 2019 : JO 24 avr. 2019 ; cf. Avenant n° 75 du 18 oct. 2018
 (NOR : ASET1950004M) étendu par A. 29 mai 2019 : JO 4 juin
 2019 et en vigueur impérativement à la date du 1er janvier
 
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant n° 78 du 22 nov. 2019 (NOR : ASET2050100M) étendu par A. 7 déc. 2020 : JO 15 déc. 2020 (déposé le 5 janv. 2020)",Auxiliaire spécialisé vétérinaire,Echelon 5,,"1813,5",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 1.1,,,"1553,12",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 1.2,,,"1633,06",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 1.3,,,"1716,43",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 2.1,,,"1799,8",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 2.2,,,"1959,7",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 2.3,,,"2085,3",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 2.4,,,"2473,6",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 3.1,,,"2770,5",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 3.2,,,"3227,3",byMonth151,,,,,,,
2/19/2021,7/1/2019,,1909,Organismes de tourisme,,,cf. Avenant n° 26 du 7 déc. 2018 (NOR : ASET1950052M) étendu par A. 24 juill. 2019 : JO 3 août 2019 et prenant effet au 1er janv. 2019 (Conclu pour une durée indéterminée),Echelon 3.3,,,"3855,4",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2020",1,,,1572,byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2021",2,,,1631,byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2022",3,,,"1666,4",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2023",4,,,1690,byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2024",5,,,"1772,6",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2025",6,,,"1890,6",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2026",7,,,"1990,9",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2027",8,,,2280,byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2028",9,,,2516,byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2029",10,,,"2858,2",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2030",11,,,"3212,2",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2031",12,,,"3802,2",byMonth151,,,,,,,
2/19/2021,1/1/2019,,1921,Huissiers de justice (Personnel),,,"cf. Avenant n° 64 du 27 nov. 2018 (NOR : ASET1950037M)
 étendu par A. 16 avr. 2019 : JO 24 avr. 2019 et applicable au 1er janv. 2019 ; cf. Avenant n° 68 du 19 nov. 2019 (NOR : ASET2050347M) non étendu et applicable au 1er janvier 2032",13,,,"3979,2",byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,I,A et B,1428,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,II,1 C,1430,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,II,2 D,1435,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,III,1 E,1438,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,III,2 F,1451,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,III,3 G,1472,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,IV,1 H,1557,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,IV,2 I,1671,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Ouvriers,IV,3 J,1841,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 1,,1428,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 2,1,1435,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 2,2,1441,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 3,1,1472,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 3,2,1557,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 4,,1671,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 5,1,1784,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 5,2,1898,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 6,1,2068,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 6,2,2239,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 7,1,2523,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012","Personnel administratif, commercial et technique",ACT 7,2,2807,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Agents de maîtrise,AM 1,,1784,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Agents de maîtrise,AM 2,1,2011,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Agents de maîtrise,AM 2,2,2239,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Agents de maîtrise,AM 3,1,2523,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Agents de maîtrise,AM 3,2,2807,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 1,,2295,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 2,,2750,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 3,,3091,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 4,,3318,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 5,,3431,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 6,,3602,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 7,,3829,byMonth151,,,,,,,
2/19/2021,7/1/2012,,1947,Négoce du bois d'oeuvre et produits dérivés,,,"cf. Avenant n° 14 du 5 janv.2011 étendu par A. 25 mai 2011 :
 JO 22 juin 2011, en vigueur au 1er janvier 2011 ; cf. Avenant
 n° 15 du 6 janv. 2012 (NOR : ASET1250389M), étendu par A.
 3 mai 2012 : JO 13 mai 2012 et en vigueur au 1er janvier 2012 ;
 cf. Avenant n° 16 du 11 juill. 2012 (ASET1251272M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et en vigueur au 1er juillet 2012",Cadres,C 8,,4113,byMonth151,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,I,échelon 1,"9,77",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,I,échelon 2,"9,87",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,I,échelon 3,"9,92",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,II,échelon 1,"10,03",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,II,échelon 2,"10,16",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,II,échelon 3,"10,59",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,III,échelon 1,"10,66",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,III,échelon 2,"10,77",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Employés,III,échelon 3,"11,06",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Agents de maîtrise,IV,échelon 1,"11,06",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Agents de maîtrise,IV,échelon 2,"11,66",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Agents de maîtrise,IV,échelon 3,"12,5",byHour,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,V,échelon 1,28400,byYear1607,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,V,échelon 2,39228,byYear1607,,,,,,,
2/19/2021,7/1/2017,,2060,Cafétérias et assimilés (Chaînes),,,"cf. CC. n° 3297, Art. 38 ; cf. Accord du 7 juill. 2015 (NOR :
 ASET1550893M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016
 
 et en vigueur le 1er jour du mois qui suit la publication de
 son extension ; cf. Avenant n° 15 du 4 mai 2017 (NOR :
 ASET1750641M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,V,échelon 3,39750,byYear1607,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E4,,,1499,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E5,,,1504,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E6,,,1509,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E7,,,1515,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E8,,,1519,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",E9,,,1536,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM/T1,,,1561,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM/T2,,,1695,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM/T3,,,1826,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM/T4,,,1881,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM1,,,1557,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM2,,,1707,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM3,,,1804,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",AM4,,,1875,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C1 A,,,1927,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C1 B,,,2027,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C2 A,,,2181,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C2 B,,,2335,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C2 C,,,2466,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C3 A,,,2601,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C3 B,,,2931,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C3 C,,,3043,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C4,,,3200,byMonth151,,,,,,,
2/19/2021,9/1/2018,,2121,Edition,,,"cf. Annexe I, art. 2 ; cf. Annexe II, art. 2 ; cf. Avenant du 20 déc. 2006 (NOR : ASET0750251M), étendu par A. 3 mai 2007 : JO 17 mai 2007 ; cf. Avenant n° 6 du 15 juin 2007 (NOR : ASET0751059M) étendu par A. 11 déc. 2007 : JO 18 déc. 2007 ; cf. Avenant n° 13 du 20 juill. 2018 (NOR : ASET1850817M) étendu par A. 5 juin 2019 : JO 12 juin 2019 et applicable dès sa signature aux entreprises adhérentes, 1 jour franc suivant la publication de son extension pour les entreprises couvertes par la convention collective et non adhérentes (Dispositions impératives)",C5,,,3462,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,I,1,1526,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,I,2,1533,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,I,3,1537,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,I,4,1541,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,II,1,1545,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,II,2,1549,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,II,3,1553,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,II,4,1557,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,III,1,1562,byMonth151,,,,,,,
2/17/2012,10/1/2019,,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Ouvriers,III,2,1595,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,1,1526,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,2,1535,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,3,1538,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,4,1539,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,1,1541,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,2,1543,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,3,1543,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,4,1544,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,1,1546,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,2,1549,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,3,1554,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,4,1597,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,1,1541,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,2,1550,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,3,1553,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,I,4,1554,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,1,1562,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,2,1564,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,3,1564,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,II,4,1565,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,1,1572,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,2,1575,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,3,1580,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Employés,III,4,1623,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,2,1549,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,3,1554,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,4,1597,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,1,1720,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,2,1880,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,3,2051,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,4,2227,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,V,1,2359,byMonth151,,,,,,,
2/17/2012,10/1/2019,1-2,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,V,2,2618,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,2,1575,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,3,1580,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,III,4,1623,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,1,1752,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,2,1912,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,3,2083,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,IV,4,2259,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,V,1,2409,byMonth151,,,,,,,
2/17/2012,10/1/2019,3-4,247,Industrie de l'habillement,,,cf. Avenant n° S. 61 du 3 sept. 2019 (NOR : ASET1951380M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 (déposé le 30 oct. 2019),Techniciens - Agents de maîtrise,V,2,2668,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Ouvriers et Employés,I,1,1550,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Ouvriers et Employés,II,1,1573,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Ouvriers et Employés,II,2,1589,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Ouvriers et Employés,III,1,1612,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Ouvriers et Employés,III,2,1628,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Ouvriers et Employés,I,1,1593,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Ouvriers et Employés,II,1,1621,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Ouvriers et Employés,II,2,1645,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Ouvriers et Employés,III,1,1661,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Ouvriers et Employés,III,2,1670,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Techniciens et agents de maîtrise,IV,1,1703,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Techniciens et agents de maîtrise,IV,2,1759,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Techniciens et agents de maîtrise,IV,1,1729,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Techniciens et agents de maîtrise,IV,2,1787,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Cadres,V,1,2184,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Cadres,V,2,2340,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Cadres,VI,1,2600,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Cadres,VI,2,3078,byMonth151,,,,,,,
2/17/2012,1/1/2020,1,759,Pompes Funèbres,,,,Cadres,VII,1,3819,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Cadres,V,1,2201,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Cadres,V,2,2359,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Cadres,VI,1,2621,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Cadres,VI,2,3103,byMonth151,,,,,,,
2/17/2012,1/1/2020,3,759,Pompes Funèbres,,,,Cadres,VII,1,3849,byMonth151,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Niveau maîtrise - MP,Echelon MP5,"18,46",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Niveau maîtrise - MP,Echelon MP4,"17,08",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Niveau maîtrise - MP,Echelon MP3,"15,32",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Niveau maîtrise - MP,Echelon MP2,"13,82",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Niveau maîtrise - MP,Echelon MP1,"13,07",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Chef d'équipe - CE,Echelon 3,"13,02",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Chef d'équipe - CE,Echelon 2,"12,88",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,Chef d'équipe - CE,Echelon 1,"12,18",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 3 A,"12,61",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 3 B ,"12,88",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 2 A,"11,73",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 2 B,"11,93",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 1 A,"11,11",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,ATQS,Echelon 1 B ,"11,29",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 3 A,"10,9",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 3 B ,"11,1",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 2 A,"10,8",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 2 B,11,byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 1 A,"10,71",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AQS,Echelon 1 B ,"10,89",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 3 A,"10,65",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 3 B ,"10,84",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 2 A,"10,59",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 2 B,"10,78",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 1 A,"10,56",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation ,AS,Echelon 1 B ,"10,72",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA3,"18,29",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA2,"17,33",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA1,"15,28",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 4,"13,74",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 3,"12,56",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 2 ,"11,41",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 1,"10,64",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 6,"4886,92",byMonth151,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 5,"4472,75",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 4,"4214,38",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 3,"3645,72",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 2,"3262,24",byHour,,,,,,,
11/2/2021,2/1/2021,,1810,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels ,Cadres - CA,Echelon CA 1,"2765,45",byHour,,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2020,130 ,,,"1676,33",byMonth165,130 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2020,140 ,,,"1696,69",byMonth165,140 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2021,150 ,,,"1717,05",byMonth165,150 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2022,160 ,,,"1737,41",byMonth165,160 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2023,175 ,,,"1767,95",byMonth165,175 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2024,190 ,,,"1798,49",byMonth165,190 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2025,205 ,,,"1829,03",byMonth165,205 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2026,225 ,,,"1869,75",byMonth165,225 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2027,235 ,,,"1952,85",byMonth165,235 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2028,250 ,,,"2077,50",byMonth165,250 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2029,275 ,,,"2285,25",byMonth165,275 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2030,300 ,,,"2493,00",byMonth165,300 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2031,325 ,,,"2700,75",byMonth165,325 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2032,360 ,,,"2991,60",byMonth165,360 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2033,350 ,,,"2908,50",byMonth165,350 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2034,400 ,,,"3324,00",byMonth165,400 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2035,460 ,,,"3822,60",byMonth165,460 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2036,480 ,,,"3988,80",byMonth165,480 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2037,510 ,,,"4238,10",byMonth165,510 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2038,550 ,,,"4570,50",byMonth165,550 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2039,660 ,,,"5484,60",byMonth165,660 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2040,770 ,,,"6398,70",byMonth165,770 ,,,,,,
11/2/2021,1/3/2020,,44,Industries chimiques,,,cf. Accord du 10 déc. 2016 (NOR : ASET1750117M) étendu par A. 14 avr. 2017 : JO 21 avr. 2017 ; cf. Accord du 12 févr. 2020 (NOR : ASET2050812M) non étendu et en vigueur à compter du 1er mars 2041,880 ,,,"7312,80",byMonth165,880 ,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,E1,,20013,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,E2,,"20 495,00",byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,E3,,20752,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,AM1,,21054,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,AM2,,23052,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,C1,,24119,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,C2,,32642,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,,Hors résidences de tourisme,C3,,38894,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Hors résidences de tourisme,C4,,43802,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,E1,,20013,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,E2,,20402,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,E3,,20625,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,AM1,,21023,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,AM2,,23052,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,C1,,24192,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,C2,,32556,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,C3,,38743,byYear1607,,,,,,,
11/2/2021,1/1/2020,,1527,"Immobilier (Administrateurs de biens, sociétés immobilières, agents immobiliers...)",,,"cf. CC. n° 3090, Art. 37 ; cf. CC. n° 3090, Art. 37 résultant de l’avenant n° 83 du 2 déc. 2019 (NOR : ASET2050291M) non étendu et prenant effet, pour les parties signataires, au 1er juin 2020 ; cf. CC. n° 3090, Art. 37 RT (résidences de tourisme) résultant de l'avenant du 12 oct. 2007 (NOR : ASET0850399M), étendu par A. 4 déc. 2008 : JO 11 déc. 2008, en vigueur 1 an suivant la publication de son extension avec effet rétroactif au 1er janvier 2007 (dispositions exclues de l'extension) ; cf. Avenant du 28  nov. 2018 (NOR  : ASET1950093M) étendu par A. 29  mai 2019  : JO 4  juin 2019 et en vigueur au 1er  janv. 2019 (résidences de tourisme) (Conclu pour une durée indéterminée)",Résidences de tourisme,C4,,43679,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 1,,,16120,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 2,,,17120,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 3,,,19750,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 4 ,,,23440,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 5 ,,,27720,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 6 ,,,366300,byYear1607,,,,,,,
11/2/2021,1/1/2008,,1672,Sociétés d’assurances,,,,Classe 7 ,,,49810,byYear1607,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau I,Echelon A,,"10,15",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau I,Echelon B ,,"10,18",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau II,Echelon A,,"10,31",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau II,Echelon B ,,"10,52",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau III,Echelon A,,"10,72",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau III,Echelon B ,,"10,74",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau III,Echelon C,,"11,65",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau IV,Echelon A,,"12,32",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau IV,Echelon B ,,"12,61",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau IV,Echelon C,,"13,19",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau IV,Echelon D,,"14,32",byHour,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau V,Echelon A,,38001,byYear1652,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau V,Echelon B ,,39208,byYear1652,,,,,,,
11/2/2021,1/7/2020,,1501,Restauration rapide,,,"cf. CC. n° 3245, Art. 44 ; cf. Avenant n° 56 du 3 mars 2020 (NOR : ASET2050497M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et en vigueur le 1er jour du mois suivant la publication de son extension et au plus tard, le 1er juillet 2020 (Conclu pour une durée indéterminée)",Niveau V,Echelon C,,62186,byYear1652,,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",700,,,1551,byMonth151,700,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",710,,,1555,byMonth151,710,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",720,,,1573,byMonth151,720,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",730,,,1632,byMonth151,730,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",740,,,1713,byMonth151,740,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",750,,,1828,byMonth151,750,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",800,,,1962,byMonth151,800,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",810,,,2113,byMonth151,810,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",820,,,2322,byMonth151,820,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",830,,,2491,byMonth151,830,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",900,,,2989,byMonth151,900,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",910,,,3132,byMonth151,910,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",920,,,3599,byMonth151,920,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",930,,,4681,byMonth151,930,,,,,,
12/2/2021,1/1/2021,,292,Plasturgie,,,"cf. Accord du 16 déc. 2004 (NOR : ASET0550097M), étendu par A. 4 avr. 2005 : JO 14 avr. 2005 (Dénoncé par la fédération de la plasturgie par lettre du 11 févr. 2008) ; cf. Accord du 12 juill. 2006 (NOR : ASET0650986M) étendu par A. 14 déc. 2006 : JO 29 déc. 2006 ; cf. Accord du 12 déc. 2017 (NOR : ASET1850080M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 (déposé le 10 janv. 2018) ; cf. Accord du 28 mai 2020 (NOR : ASET2050569M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020 (conclu pour une durée indéterminée) (déposé le 23 juin 2020)",940,,,5836,byMonth151,940,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau I ,,,1550,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau II,,,1581,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau III,,,1606,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau IV,,,1628,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau V ,,,1724,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau VI,,,1891,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau VII,,,2456,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau VIII,,,3231,byMonth151,,,,,,,
12/2/2021,1/9/2020,,1517,"Commerces de détail non alimentaires – Antiquités, brocante, galeries d'art (oeuvres d'art), arts de la table, coutellerie, droguerie, équipement du foyer, bazars, commerces ménagers, modélisme, jeux, jouets, périnatalité et maroquinerie",,,cf. Avenant n°  7 du 28  mars 2019 (NOR  : ASET1950683M) étendu par A. 7  oct. 2019  : JO 11  oct. 2019 et applicable à compter du 1er jour du mois qui suit la publication de son extension  ; cf. Avenant n°  8 du 4  févr. 2020 (NOR  : ASET2050357M) étendu A. 21 juill. 2020 : JO 1er août 2020 et en vigueur le 1er jour du mois qui suivra la publication de son extension,Niveau IX,,,3646,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2019,Employés,1,,1522,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2020,Employés,2,,1528,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2021,Employés,3,,1556,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2022,Employés,4,,1618,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2023,Agents de Maitrîse,1,,1702,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2024,Agents de Maitrîse,2,,1774,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2025,Cadres,1,,2050,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2026,Cadres,2,,2255,byMonth151,,,,,,,
12/2/2021,1/9/2019,,675,Maisons à succursales de vente au détail d'habillement,,,cf. Accord du 20 juin 2016 (NOR : ASET1650818M) étendu par A. 21 mars 2017 : JO 28 mars 2017 ; cf. Accord du 23 mai 2019 (NOR : ASET1951094M) étendu par A. 16 oct. 2020 : JO 30 oct. 2020 et applicable à compter du 1er septembre 2027,Cadres,3,,2665,byMonth151,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau I ,Agent d'entretien petits travaux de jardinage,,"10,03",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau I ,Agent d'entretien petits travaux de bricolage,,"10,03",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau I ,Assistant(e) de vie,,"10,03",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau I ,Garde d'enfant(s),,"10,03",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau I ,Assistant(e) ménager(ère),,"10,03",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau II,Garde d'enfant(s) ,,"10,06",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau II,Assistant(e) ménager(ère),,"10,06",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau III,Assistant(e) de vie,,"10,09",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau III,Garde d'enfant(s),,"10,09",byHour,,,,,,,
12/2/2021,1/11/2019,,3127,Services à la personne (Entreprises),,,"cf. CC. n°  3370, Partie V, Annexe II  ; cf. Avenant n°  3 du 6  avr. 2018 (NOR  : ASET1851015M) étendu par A. 8  févr. 2019  : JO 14  févr. 2019 et à l'exclusion des entreprises relevant du régime de protection sociale agricole et applicable à compter du 1er jour du mois suivant la date de publication de son extension ; cf. Avenant n° 4 du 31 janv. 2019 (NOR : ASET1950562M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et applicable à compter du 1er jour du mois suivant la date de publication de son extension",Niveau IV,Assistant(e) de vie,,"10,19",byHour,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 1,Echelon 1 Coiffeur(se) débutant(e) Coiffeur(se) débutant(e),1534,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 1,Echelon 2 coiffeur(se),1538,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 1,Echelon 3 Coiffeur(se) confirmé(e),1544,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 2,Echelon 1 Coiffeur(se) qualifié(e),1551,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 2,Echelon 1 Technicien,1587,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 2,Echelon 2 Coiffeur(se) hautement qualifié(e) ou technicien(ne) qualifié(e),1699,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 2,Echelon 3 Coiffeur(se) très hautement qualifié(e) ou assistant manager ou technicien(ne) hautement qualifié(e),1814,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 3,Echelon 1 Manager,1950,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 3,Echelon 2 Manager confirmé(e),2760,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 3,Echelon 2 Animateur de réseau,2760,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 3,Echelon 3 Manager hautement qualifié(e),2921,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière technique de la coiffure,Niveau 3,Echelon 3 Animateur(trice) de réseau confirmé(e),2973,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,105,,1526,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,115,,1531,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,125,,1541,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,135,,1556,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,145,,1567,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,155,,1577,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Emplois de l'esthétique-cosmétique,165,,1589,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillère non technique de la coiffure ,100,,1526,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillère non technique de la coiffure ,110,,1531,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillère non technique de la coiffure ,120,,1536,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillère non technique de la coiffure ,130,,1577,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,230,,1723,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,240,,1723,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,250,,1753,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,285,,1962,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,295,,2025,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,305,,2107,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,330,,2231,byMonth151,,,,,,,
12/2/2021,31/5/2018,,2596,Coiffure et professions connexes,,,cf. avenant n°  42 du 31  mai 2018 (NOR  : ASET1850722M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et prenant effet le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Fillière administrative de la coiffure,300 et au-dessus,,2532,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",A,1,,19656,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",A,1 bis,,20492,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",A,2,,21503,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",A,3,,22606,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",B,1,,20620,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",B,1 bis,,21298,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",B,2,,22184,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",B,3,,23576,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",C,1,,21754,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",C,1 bis,,22915,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",C,2,,24997,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",C,3,,26001,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",D,1,,25191,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",D,1 bis,,26274,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",D,2,,28617,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",D,3,,30228,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",E,1,,31659,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",E,1 bis,,36271,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",E,2,,41369,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",E,3,,44020,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",F,1,,43224,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",F,2,,51873,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",G,1,,61749,byMonth151,,,,,,,
12/2/2021,1/8/2020,,2148,Télécommunications,,,"cf. CC. n° 3303, Art. 6.1.3 ; cf. Accord du 4 mars 2020 (NOR : ASET2050369M) étendu par A. 23 juill. 2020 : JO 1er août 2020 (déposé le 10 avril 2020)",G,2,,"7512,7",byMonth151,,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,160,,,1 535,byMonth151,160,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,165,,,1 538,byMonth151,165,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,170,,,1 548,byMonth151,170,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,175,,,1 563,byMonth151,175,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,180,,,1 579,byMonth151,180,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,185,,,1 595,byMonth151,185,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,190,,,1 611,byMonth151,190,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,195,,,1 631,byMonth151,195,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,200,,,1 647,byMonth151,200,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,210,,,1 671,byMonth151,210,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,215,,,1 688,byMonth151,215,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,220,,,1 709,byMonth151,220,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,235,,,1 836,byMonth151,235,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,245,,,1 888,byMonth151,245,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,260,,,2 001,byMonth151,260,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,270,,,2 077,byMonth151,270,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,290,,,2 226,byMonth151,290,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,295,,,2 263,byMonth151,295,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,300,,,2 373,byMonth151,300,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,360,,,2 749,byMonth151,360,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,420,,,3 197,byMonth151,420,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,510,,,3 870,byMonth151,510,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,600,,,4 543,byMonth151,600,,,,,,
12/2/2021,1/6/2019,,275,Transport aérien (Personnel au sol),,,,750,,,5 666,byMonth151,750,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau I ,,,"1539,45",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau II,,,"1548,55",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau III,,,"1571,30",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau IV,,,"1597,09",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau V ,,,"1657,75",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau VI,,,"1729,04",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau VII,,,"1862,51",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau VIII,,,"2021,76",byMonth151,,,,,,,
12/2/2021,1/6/2020,,1266,Personnel des entreprises de restauration de collectivités,,,"cf. CC. n° 3225, Art. 16 résultant de l'avenant n° 47 du 9 nov. 2011 (NOR : ASET9750408M) étendu par A. 2 avr. 2012 : JO 11 avr. 2012, applicable au 6e mois civil suivant la publication de son extension ; cf. Avenant n° 55 du 11 févr. 2018 (NOR : ASET1850480M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et en vigueur le 1er jour du mois civil suivant la publication de son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 57 du 17 févr. 2020 (NOR : ASET2050458M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur le 1er jour du mois civil suivant la publication de son extension et au plus tard le 1er juin 2020 (conclu pour une durée indéterminée)",Niveau IX,,,"2616,31",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau I,A10,"1553,15",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau I,A20,"1576,45",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau II,A30,"1600,10",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau II ,A40,"1624,10",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau II,A50,"1648,47",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau III,A60,"1694,61",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau III ,A70,"1742,07",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Ouvriers employés,Niveau III,A80,"1790,85",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,,Techniciens agents de maîtrise,Niveau IV,B10,"1844,57",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.21",Techniciens agents de maîtrise,Niveau IV,B20,"1927,58",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.22",Techniciens agents de maîtrise,Niveau IV,B30,"2014,33",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.23",Techniciens agents de maîtrise,Niveau V ,B40,"2104,96",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.24",Techniciens agents de maîtrise,Niveau V ,B50,"2199,68",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.25",Techniciens agents de maîtrise,Niveau V ,B60,"2298,67",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.26",Techniciens agents de maîtrise,Niveau VI ,B70,"2402,12",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.27",Techniciens agents de maîtrise,Niveau VI ,B80,"2510,21",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.28",Cadres,Niveau VII,C10,"2635,71",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.29",Cadres,Niveau VII,C20,"2899,30",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.30",Cadres,Niveau VIII,C30,"3334,19",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.31",Cadres,Niveau VIII,C40,"3834,32",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.32",Cadres,Niveau IX,C50,"4409,47",byMonth151,,,,,,,
12/2/2021,1/3/2020,,1404,"Entreprises de la maintenance, distribution et location de matériels agricoles, de travaux publics, de bâtiments, de manutention, de motoculture de plaisance et activités connexes, dite SDLM",,,"cf. CC. n° 3131, Art. 4.33",Cadres,Niveau IX,C60,"5070,87",byMonth151,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Employés spécialisés,A1,100,"18597,63",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Employés spécialisés,A2,110,"18642,95",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Employés qualifiées,B1,120,"18687,24",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Employés qualifiées,B2,145,"18732,56",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens qualifiés 1er degré,C1,171,"18815,83",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens qualifiés 1er degré,C2,186,"20025,37",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens qualifiés 2e degré,D1,200,"21483,43",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens qualifiés 2e degré,D2,220,"23566,38",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens hautement qualifiés,E1,240,"25649,32",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Techniciens hautement qualifiés,E2,270,"28773,73",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Cadres,F,310,"32939,62",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Cadres,G,350,"37105,51",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Cadres,H,450,"47520,22",byYear1607,,,,,,,
12/2/2021,7/4/2020,,1516,Organismes de formation,,,cf. Avenant du 12 sept. 2019 (NOR : ASET1951362M) étendu par A. 7 avr. 2020 : JO 12 avr. 2020 et en vigueur à compter de sa date de signature (Conclu pour une durée indéterminée),Cadres,I,600,"63142,30",byYear1607,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Employé,Niveau 1,,1534,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Employé,Niveau 2,,1548,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Employé,Niveau 3,,1562,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Employé,Niveau 4,,1630,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Technicien / agent de maîtrise,Niveau 1,,1671,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Technicien / agent de maîtrise,Niveau 2,,1720,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Technicien / agent de maîtrise,Niveau 3,,1774,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Technicien / agent de maîtrise,Niveau 4,,1882,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Cadre,Niveau 1,,2051,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Cadre,Niveau 2,,2249,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Cadre,Niveau 3,,2652,byMonth151,,,,,,,
12/2/2021,1/1/2020,,86,Entreprises de la publicité et assimilées,,,,Cadre,Niveau 4,,3472,byMonth151,,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,130,,,"1521,25",byMonth151,130,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,140,,,"1524,00",byMonth151,140,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,150,,,"1529,00",byMonth151,150,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,160,,,"1536,00",byMonth151,160,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,170,,,"1568,00",byMonth151,170,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,175,,,"1582,52",byMonth151,175,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,180,,,"1570,00",byMonth151,180,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,185,,,"1655,78",byMonth151,185,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,190,,,"1580,00",byMonth151,190,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,200,,,"1593,00",byMonth151,200,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,220,,,"1701,00",byMonth151,220,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,240,,,"1746,00",byMonth151,240,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,250,,,"1804,00",byMonth151,250,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,280,,,"1913,00",byMonth151,280,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,320,,,"2173,00",byMonth151,320,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,350,,,"2283,00",byMonth151,350,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,380,,,"2429,00",byMonth151,380,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,390,,,"2529,00",byMonth151,390,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,420,,,"2688,00",byMonth151,420,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,450,,,"2930,00",byMonth151,450,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,500,,,"3137,00",byMonth151,500,,,,,,
12/2/2021,1/4/2019,,1557,Commerce des articles de sports et équipements de loisirs,,,cf. Accord du 21 févr. 2019 (NOR : ASET1950743M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et en vigueur au 1er avril 2019,550,,,"3367,00",byMonth151,550,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau I ,Echelon 1,"1539,42",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau I ,Echelon 2,"1544,12",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau I ,Echelon 3,"1554,71",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau II,Echelon 1,"1590,52",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau II,Echelon 2,"1630,98",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau II,Echelon 3,"1671,37",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau III,Echelon 1,"1708,45",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau III,Echelon 2,"1748,79",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau III,Echelon 3,"1789,12",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau IV,Echelon 1,"1847,02",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau IV,Echelon 2,"2068,02",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),"Ouvriers, employés, agents de maîtrise",Niveau IV,Echelon 3,"2287,28",byMonth151,,,,,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Cadres,Position I,,"2077,31",byMonth151,,,"27 517,57",,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Cadres,Position II,,"2589,26",byMonth151,,,"33 789,65",,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Cadres,Position III,,"3095,01",byMonth151,,,"40 389,77",,,,
12/2/2021,1/10/2020,,1686,"Commerces et services de l'audiovisuel, de l'électronique et de l'équipement ménager ",,,cf. Avenant n°  49 du 18  avr. 2019 (NOR  : ASET1950672M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et prenant effet à compter du 1er jour du mois suivant la publication de son extension (ccnclu pour une durée indéterminée) ; cf. Avenant n° 50 du 17 sept. 2020 (NOR : ASET2050890M) non étendu et en vigueur le 1er jour du mois suivant la publication de son extension (Conclu pour une durée indéterminée),Cadres,Position IV,,"3599,52",byMonth151,,,"46 974,91",,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 1,,1505,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 2,,1510,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 3,,1520,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 4,,1534,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 5 ,,1556,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 6 ,,1592,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 7 ,,1651,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Employés,Catégorie 8 ,,1720,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Agents de maîtrise,Catégorie A1,,1834,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Agents de maîtrise,Catégorie A2,,1937,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Agents de maîtrise,Catégorie B ,,1251,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Cadres,Catégorie C ,,3311,byMonth151,,,,,,,
12/2/2021,1/3/2018,,1483,Commerce de détail de l'habillement et des articles textiles,,,"cf. Avenant n°  22 du 16  avr. 2015 (NOR  : ASET1550571M) étendu par A. 13 oct. 2015 : JO 24 oct. 2015 ; cf. Avenant n ° 23 du 21 mars 2017 (NOR : ASET1750462M) étendu par A. 3 août 2017 : JO 11 août 2017 et applicable à compter du 1er jour du mois civil suivant la publication de son extension ; cf. Avenant n° 24 du 8 févr. 2018 (NOR : ASET1850440M) non étendu et applicable à compter du 1er jour du mois civil suivant la publication de son extension, complété par avenant n° 1 du 12 juin 2018 (NOR : ASET1850716M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 et applicable à compter du 1er jour du mois civil suivant la publication de son extension",Cadres,Catégorie D,,3470,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 1,Niveau unique,,1543,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 2,Niveau 1,,1548,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 2,Niveau 2,,1550,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 2,Niveau 3,,1555,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 3,Niveau 1,,1570,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 3,Niveau 2,,1591,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 3,Niveau 3,,1618,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 4,Niveau 1,,1654,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 4,Niveau 2,,1681,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 4,Niveau 3,,1707,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 5,Niveau 1,,1781,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 5,Niveau 2,,1818,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 5,Niveau 3,,1911,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 6,Niveau 1,,2046,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 6,Niveau 2,,2111,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 6,Niveau 3,,2175,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 7,Niveau 1,,2340,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 7,Niveau 2,,2699,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 7,Niveau 3,,2899,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 8,Niveau 1,,3068,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 8,Niveau 2,,3351,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 9,Niveau 1,,3926,byMonth151,,,,,,,
12/2/2021,22/1/2021,,1880,Ameublement (Négoce),,,"cf. CC. n° 3056, Art. 30 ; cf. Accord du 1er sept. 2020 (NOR : ASET2050859M) étendu par A. 11 janv. 2021 : 22 janv. 2021 et applicable à compter du 1er oct. 2020 pour les adhérents à la FNAEM et à compter de son extension pour les entreprises non adhérentes",Groupe 9,Niveau 2,,4339,byMonth151,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 1,,,"10,08",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 2,,,"10,09",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 2,,,"10,13",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 2,,,"10,16",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 3,,,"10,16",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 3,,,"10,2",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 3,,,"10,26",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 4 ,,,"10,26",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 4 ,,,"10,57",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 4 ,,,"10,9",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 5 ,,,"10,9",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 5 ,,,"11,7",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 5 ,,,"12,28",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 6 ,,,"12,28",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 6 ,,,"12,76",byHour,,,,,,,
12/2/2021,1/7/2019,,18,Industrie textile (Dispositions générales),,,"cf. CC. n° 3106-1, Art. 73 (G.) modifié par accord du 2 déc. 2015 (NOR : ASET1650220M) étendu par A. 21 mars 2017 : JO 28 mars 2017 et en vigueur le 1er janvier 2016 (1er mai 2016, si report de l’application des nouvelles classifications) ; cf. Annexe III",Niveau 6 ,,,"13,76",byHour,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau A1,140,,1504,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau A2,150,,1514,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau A3,170,,1535,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau A4,190,,1565,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau A5,220,,1656,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau B1,240,,1758,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau B2,260,,1874,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau B3,280,,2077,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau C1,300,,2178,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau C2,360,,2938,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau C3,450,,3546,byMonth151,,,,,,,
12/2/2021,1/1/2019,,1539,"Commerce de détail de papeterie, fournitures du bureau, de bureautique et informatique et de librairie",,,Cf. Avis Commission d'interprétation du 27  mai 1993  ; cf. Accord du 27 juin 2018 (NOR : ASET1851100M) étendu par A. 13  févr. 2019  : JO 21  févr. 2019 et en vigueur dans les entreprises et établissements de la branche le 1er janv. 2019,Niveau C4,500,,4356,byMonth151,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon A1,,,29631,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon A2,,,32228,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon B ,,,33782,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon B1,,,36352,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon B2,,,38700,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon B3,,,40295,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon B4,,,43409,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon C1,,,45225,byYear1645,,,,,,,
12/2/2021,1/8/2020,,3212,Travaux publics (Cadres),,,"cf. CC. n°  3005-4, Art. 3.3  ; cf. Annexe V  ; cf. Accord du 21  nov. 2017 (NOR  : ASET1850048M) étendu par A. 5  juin 2020 : JO 26 juin 2020 (déposé le 28 décembre 2017) ; cf. Accord du 20 nov. 2018 (NOR : ASET1950076M) non étendu (déposé le 7 janv. 2019) ; cf. Accord du 20 nov. 2019 (NOR : ASET2050331M) étendu par A. 24 juill. 2020 : JO 1er août 2020 (déposé le 10 janv. 2020)",Echelon C2,,,52709,byYear1645,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,135,,1 516,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,150,,1523,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,200,,1626,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,230,,1695,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,240,,1722,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,245,,1780,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,250,,1899,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,270,,2378,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon A,300,,3308,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,135,,1543,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,150,,1550,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,200,,1655,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,230,,1725,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,240,,1752,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,245,,1812,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,250,,1933,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,270,,2421,byMonth151,,,,,,,
12/2/2021,1/3/2019,,3032,Esthétique - Cosmétique et Enseignement technique et professionnel lié aux métiers de l'Esthétique et de la Parfumerie,,,,Echelon B ,300,,3367,byMonth151,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 1,Echelon 1,"20035,08",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 1,Echelon 2,"20095,44",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 2,Echelon 1,"20155,80",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 2,Echelon 2,"20226,22",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 2,Echelon 3 ,"20296,64",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,,Ouvriers / employés,Niveau 3 ,Echelon 1,"20296,64",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 3 ,Echelon 2,"20397,12",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 3 ,Echelon 3 ,"20478,24",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 4,Echelon 1,"20478,24",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 4,Echelon 2,"20681,86",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 4,Echelon 3 ,"20885,47",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 5 ,Echelon 1,"20885,47",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 5 ,Echelon 2,"21302,70",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Ouvriers / employés,Niveau 5 ,Echelon 3 ,"21713,37",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 6 ,Echelon 1,"21713,37",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 6 ,Echelon 2,"23065,92",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 6 ,Echelon 3 ,"24127,27",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 7 ,Echelon 1,"24127,27",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 7 ,Echelon 2,"25193,63",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 7 ,Echelon 3 ,"26254,98",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 8 ,Echelon 1,"26254,98",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 8 ,Echelon 2,"28382,70",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Techniciens et Agents de Maîtrise,Niveau 8 ,Echelon 3 ,"30505,41",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Cadres,Niveau 9 ,Echelon 1,"30505,41",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Cadres,Niveau 9 ,Echelon 2,"32475,10",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Cadres,Niveau 10 ,,"41981,05",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Cadres,Niveau 11,,"50481,90",byYear1607,,,,,,,
15/2/2021,1/5/2019,,112,Industrie laitière,,,"cf. CC. n° 3124, Art. 6.2 ; cf. Accord du 31 oct. 2012 (NOR : ASET1251488M) étendu par A. 3 déc. 2013 : JO 15 déc. 2013 et en vigueur à compter du 1er jour du mois suivant la date de publication de son extension",Cadres,Niveau 12,,"59437,68",byYear1607,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 1,Echelon A,1609,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 1,Echelon B ,1619,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 1,Echelon C ,1640,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 2,Echelon A,1654,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 2,Echelon B ,1676,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 2,Echelon C ,1688,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 3 ,Echelon A,1712,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 3 ,Echelon B ,1740,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Ouvriers employés,Niveau 3 ,Echelon C ,1777,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 4 ,Echelon A ,1799,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 4 ,Echelon B ,1846,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 5,Echelon A,1930,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 5,Echelon B ,1978,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 5,Echelon C ,2071,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 6 ,Echelon A,2232,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Agents de maîtrise,Niveau 6 ,Echelon B ,2371,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Cadres,Niveau 7 ,Echelon A ,2399,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Cadres,Niveau 8 ,Echelon A ,2571,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Cadres,Niveau 9 ,Echelon A ,2949,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Cadres,Niveau 9 ,Echelon B ,3700,byMonth151,,,,,,,
15/2/2021,1/6/2020,,493,"Vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France",,,cf. Avenant n°  21 du 8  févr. 2019 (NOR  : ASET1950450M) étendu par A. 30 juill. 2019 : JO 7 août 2019 et applicable au 1er février 2019,Cadres,Niveau 10 ,Echelon A ,4504,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau I,120,"1540,97",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau I,125,"1548,55",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau I,135,"1556,13",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau II,145,"1563,72",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau II,155,"1586,47",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau II,165,"1609,22",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau III,175,"1636,52",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau III,185,"1674,44",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau III,195,"1721,45",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau IV,205,"1769,99",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau IV,215,"1815,49",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau IV,225,"1885,26",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau V,235,"1964,13",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau V,245,"2044,51",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau V,255,"2130,96",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VI,265,"2218,93",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VI,275,"2305,38",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VI,285,"2391,84",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VI,295,"2476,77",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VII,305,"2552,61",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VII,315,"2631,47",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VII,325,"2711,86",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VII,335,"2787,69",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ouvriers / Employés / Techniciens et Agents de Maîtrise,Niveau VII,345,"2865,05",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,355,"34444,55",byYear1827,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,365,"34800,44",byYear1827,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,375,"35751,05",byYear1827,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,385,"36757,58",byYear1827,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,395,"37764,10",byYear1827,,,,,,,
15/2/2021,1/3/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau VIII,535,"38733,39",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,545,"52247,24",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,555,"53197,84",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,565,"54204,37",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,575,"55173,65",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,585,"56180,18",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau IX,595,"57149,47",byYear1827,,,,,,, 
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,605,"58118,75",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,615,"59125,28",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,625,"60075,89",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,635,"61063,85",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,645,"62051,70",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,655,"63020,99",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,665,"64027,52",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,675,"64978,24",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,685,"65947,40",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,685,"66991,29",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,695,"67941,90",byYear1827,,,,,,,
15/2/2021,10/6/2020,,1396,Industries de produits alimentaires élaborés,,,"cf. Dispositions visant les ingénieurs et cadres, Art. 17  ; cf. Dispositions visant les ingénieurs et cadres, Art. 18  ; cf. Dispositions visant les ingénieurs et cadres, Art. 21  ; cf. Dispositions visant les ingénieurs et cadres, Art. 22  ; cf. Avenant n°  97 du 19  sept. 2012 (NOR  : ASET1251296M) étendu par A. 26 déc. 2012 : JO 29 déc. 2012 et prenant effet à compter de sa signature ; cf. Accord n° 112 du 28 févr. 2020 (NOR  : ASET2050448M) étendu par A. 17  sept. 2020  : JO 26 sept. 2020 et prenant effet à compter de sa signature ; cf. Avenant du 10 juin 2020 (NOR : ASET2050554M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et prenant effet à compter de sa signature",Ingénieurs / Cadres,Niveau X,700,"68668,83",byYear1827,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie A1,Hors Ile de France,,"1652,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie A1,Ile de France,,"1723,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie A2,Hors Ile de France,,"1786,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie A2,Ile de France,,"1902,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie B,Hors Ile de France,,"2036,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie B,Ile de France,,"2140,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie C,Hors Ile de France,,"2252,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie C,Ile de France,,"2365,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie D,Hors Ile de France,,"2558,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie D,Ile de France,,"2684,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie E,Hors Ile de France,,"2783,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie E,Ile de France,,"2931,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie F,Hors Ile de France,,"3082,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie E,Ile de France,,"3253,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie G,Hors Ile de France,,"3415,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie G,Ile de France,,"3654,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie H,Hors Ile de France,,"3601,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie H,Ile de France,,"3840,00",byMonth151,,,,,,,
18/02/2021,16/01/2019,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Catégorie I,Hors Ile de France,,"4251,00",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Employés - techniciens,Groupe 1,,"1532,47",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Employés - techniciens,Groupe 2,,"1626,51",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Employés - techniciens,Groupe 3,,"1746,86",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Employés - techniciens,Groupe 4,,"1876,14",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Employés - techniciens,Groupe 5,,"2014,96",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Cadres,Groupe 6,,"2136,64",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Cadres,Groupe 7,,"2362,64",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Cadres,Groupe 8,,"2670,80",byMonth151,,,,,,,
18/02/2021,1/9/2021,,3221,"Agences de presse (employés, techniciens et cadres)",cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Cadres,Groupe 9,,"3081,69",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 1,,,"1521,50",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 2,,,"1526,5",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 3,,,"1531,00",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 4,,,"1534,50",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 5,,,"1556,43",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 6,,,"1616,34",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 7,,,"1731,94",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 8a,,,"2014,64",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 8b,,,"2133,39",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 9a,,,"2230,08",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3205,Coopératives de consommation (Personnel),cf. Avenant n° 919 du 4 avr. 2019 (NOR : ASET1950676M) non étendu et applicable au 1er avr. 2019 (conclu pour une durée indéterminée),,,Groupe 9b,,,"2908,99",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,I,1,"1540,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,I,2,"1550,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,II,1,"1578,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,II,2,"1600,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,III,1,"1630,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,III,2,"1690,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ouvriers et employés,III,3,"1732,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,"Techniciens Agents de 
 maîtrise",IV,1,"1779,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,"Techniciens Agents de 
 maîtrise",IV,2,"1864,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,"Techniciens Agents de 
 maîtrise",IV,3,"1938,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ingénieurs et cadres,V,1,"2450,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ingénieurs et cadres,V,2,"3501,00",byMonth151,,,,,,,
18/02/2021,1/4/2020,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Ingénieurs et cadres,V,3,"4245,00",byMonth151,,,,,,,
18/02/2021,1/9/2021,,7520,Enseignement agricole privé (CNEAP),,,,,,,,,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Ouvriers,1,,"9,91",byHour,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Ouvriers,2,,"10,13",byHour,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Ouvriers,3,,"10,4",byHour,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Ouvriers,4,,"10,69",byHour,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Cadres,5,,"11,8",byHour,,,,,,,
18/02/2021,1/2/2019,,7019,Conchyliculture,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",,,Cadres,6,,"15,51",byHour,,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,2,140,,1530,byMonth152,140,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,3,150,,1540,byMonth152,150,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,4,160,,1550,byMonth152,160,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,5,170,,1560,byMonth152,170,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,5bis,180,,1572,byMonth152,180,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,6a,190,,1587,byMonth152,190,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,6b,205,,1649,byMonth152,205,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,7a,215,,1727,byMonth152,215,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,7b,225,,1808,byMonth152,225,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,8,235,,1888,byMonth152,235,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,245,,1974,byMonth152,245,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,255,,2125,byMonth152,255,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,270,,2172,byMonth152,270,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,290,,2336,byMonth152,290,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,310,,2506,byMonth152,310,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,335,,2709,byMonth152,335,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,OETDAM,360,,2927,byMonth152,360,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,185,,"1557,00",byMonth152,185,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,210,,"1768,00",byMonth152,210,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,230,,"1936,00",byMonth152,230,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,250,,"2104,00",byMonth152,250,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,270,,"2273,00",byMonth152,270,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,290,,"2441,00",byMonth152,290,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,310,,"2609,00",byMonth152,310,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,330,,"2777,00",byMonth152,330,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,350,,"2946,00",byMonth152,350,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,360,,"3030,00",byMonth152,360,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,370,,"3114,00",byMonth152,370,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,380,,"3198,00",byMonth152,380,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,390,,"3282,00",byMonth152,390,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,400,,"3366,00",byMonth152,400,,,,,,
18/02/2021,1/9/2021,,3233,Industrie de la fabrication des ciments,"cf. CC. n° 3280-00, Sous-titre II.D",,,Cadres,600,,"5049,00",byMonth152,600,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,I,Ech. 1,1545,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,I,Ech. 2,1558,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,I,Ech. 3,1587,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,II,Ech. 1,1596,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,II,Ech. 2,1614,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,II,Ech. 3,1645,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,III,Ech. 1,1655,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,III,Ech. 2,1676,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,III,Ech. 3,1711,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,IV,Ech. 1,1732,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,IV,Ech. 2,1767,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Ouvriers-Employés,IV,Ech. 3,1800,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,IV,Ech. 1,1811,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,IV,Ech. 2,1831,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,IV,Ech. 3,1871,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,V,Ech. 1,2036,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,V,Ech. 2,2115,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,V,Ech. 3,2198,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,VI,Ech. 1,2349,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,VI,Ech. 2,2442,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Agents de Maîtrise et Techniciens,VI,Ech. 3,2524,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VI,Ech. 1,2465,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VI,Ech. 2,2560,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VI,Ech. 3,2720,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VII,Ech. 1,3049,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VII,Ech. 2,3287,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VII,Ech. 3,3547,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VIII,Ech. 1,4016,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VIII,Ech. 2,4333,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,VIII,Ech. 3,4676,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,IX,Ech. 1,5213,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,IX,Ech. 2,5626,byMonth151,,,,,,,
18/02/2021,1/3/2018,,7001,"Coopératives et sociétés d'intérêt collectif
 agricole (SICA) – Bétail et viande","cf. Avenant n° 135 bis du 21 févr. 2018 (NOR :
 AGRS1897139M) étendu par A. 25 juill. 2018 : JO 2 août 2018
 et applicable au 1er mars 2018",,,Cadres,IX,Ech. 3,6071,byMonth151,,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Agent d'entretien,100,,"1503,05",byMonth151,100,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Agent/hôtesse d'accueil,103,,"1509,12",byMonth151,103,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Soigneur,103,,"1509,12",byMonth151,103,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Cavalier/soigneur,106,,"1518,22",byMonth151,106,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Animateur/soigneur,109,,"1534,90",byMonth151,109,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Secrétaire,111,,"1548,55",byMonth151,111,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Guide équestre,118,,"1556,13",byMonth151,118,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Soigneur responsable d'écurie,121,,"1592,54",byMonth151,121,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Enseignant/animateur,130,,"1710,84",byMonth151,130,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Guide enseignant de tourisme équestre,150,,"1710,84",byMonth151,150,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Secrétaire-comptable,150,,"1977,78",byMonth151,150,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Enseignant,150,,"1977,78",byMonth151,150,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Enseignant responsable pédagogique,167,,"2200,73",byMonth151,167,,,,,,
18/02/2021,1/9/2021,,7012,Centres équestres (Personnel),cf. Avenant n° 96 du 23 mars 2018 étendu par A. 11 juin 2018 : JO 19 juin 2018 et applicable au 1er avr. 2018,,,Directeur,193,,"3216,00",byMonth151,193,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,1,,1530.40,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,2,,1652.83,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,3,,1775.26,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,4,,1897.70,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,5,,2066.04,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,6,,2220.99,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,7,,2509.72,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,8,,2926.16,byMonth151,,,,,,,
18/02/2021,1/7/2015,1-2-3,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,9,,3225.00,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,1,,1576.31,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,2,,1702.41,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,3,,1828.52,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,4,,1954.63,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Employés,5,,2128.02,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,6,,2287.62,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,7,,2585.01,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,8,,3013.94,byMonth151,,,,,,,
18/02/2021,1/7/2015,4,3225,Editeurs de la presse magazine,,,"cf. CC. n° 0000-25, Art. 10 ; cf. Accord du 6 mai 2015 (NOR : ASET1550969M), non étendu et en vigueur le 1er juill. 2015 ; cf. Accord du 6 mai 2015 (NOR : ASET1550966M), non étendu et en vigueur le 1er juill. 2015",Cadres,9,,3321.75,byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,A 1,"1652,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,A 2,"1786,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,B,"2036,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,C,"2252,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,D,"2558,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,E,"2783,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Employés et agents de maîtrise,F,"3082,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Cadres,G,"3415,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Cadres,H,"3601,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Hors Ile de France,Cadres,I,"4251,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,A 1,"1723,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,A 2,"1902,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,B,"2140,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,C,"2365,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,D,"2684,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,E,"2931,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Employés et agents de maîtrise,F,"3253,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Cadres,G,"3654,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Cadres,H,"3840,00",byMonth151,,,,,,,
18/02/2021,16/12/2020,,3213,Entreprises d'économistes de la construction et de métreurs-vérificateurs (Collaborateurs salariés),cf. Accord du 16 janv. 2019 (NOR : ASET1950477M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 modifié par A. 17 févr. 2020 : JO 25 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée),,,Ile de France,Cadres,I,"4485,00",byMonth151,,,,,,,
18/02/2021,1/4/2019,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Commerciaux itinérants,V,Echelon 1,31164,byYear1607,,,,,,,
18/02/2021,1/4/2019,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Commerciaux itinérants,V,Echelon 2,44553,byYear1607,,,,,,,
18/02/2021,1/4/2019,,3224,Papiers-cartons (Distribution et commerce de gros),"cf. CC. n° 0000-24, Art. 10.6 ; cf. Accord du 19 nov. 2008 (NOR : ASET0950362M) étendu par A. 18 déc. 2009 : JO 15 janv. 2010, en vigueur au plus tard 1 an après sa signature (Dispositions impératives) ; cf. Avenant n° 8 du 3 mars 2020 (NOR : ASET2050516M) étendu par A. 18 déc. 2020 : JO 24 déc. 2020",,,Commerciaux itinérants,V,Echelon 3,53996,byYear1607,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,1,,,"1466,62",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,2,,,"1569,4",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,3,,,"1610,7",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,4,,,"1775,9",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,5,,,2065,byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,5bis,,,"2209,55",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,6a,,,"2395,4",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,6b,,,"2560,6",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,7a,,,"3014,9",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,7b,,,"4006,1",byMonth151,,,,,,,
18/02/2021,1/7/2016,,5507,Football (personnels administratifs et assimilés),,,,8,,,"5245,1",byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,1.1,,,"1457,52",byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,1.2,,,1539,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,1.3,,,1599,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,2.1,,,1659,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,2.2,,,1719,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,2.3,,,1779,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,3.1,,,1929,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,3.2,,,2079,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,3.3,,,2429,byMonth151,,,,,,,
18/02/2021,1/6/2015,,2372,Entreprises de la distribution directe,Cf. Avenant n° 28 du 28 juin 2013 (NOR : ASET1351031M) étendu par A. 18 déc. 2013 : JO 4 janv. 2014 ; cf. Procès verbal de désaccord du 12 juin 2014 (NOR : ASET1451055M) ; cf. Procès-verbal de désaccord du 10 juin 2015 (NOR : ASET1550844M),,,4,,,3129,byMonth151,,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,125,,,"1539,47",byMonth151,125,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,135,,,"1559,48",byMonth151,135,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,145,,,"1576,64",byMonth151,145,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,155,,,"1593,98",byMonth151,155,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,165,,,"1611,52",byMonth151,165,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,180,,,"1638,11",byMonth151,180,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,190,,,"1656,13",byMonth151,190,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,200,,,"1692,37",byMonth151,200,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,215,,,"1785,15",byMonth151,215,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,230,,,"1865,25",byMonth151,230,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,250,,,"2000,15",byMonth151,250,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,270,,,"2139,97",byMonth151,270,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,290,,,"2279,77",byMonth151,290,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,315,,,"2454,51",byMonth151,315,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,345,,,"2664,24",byMonth151,345,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,375,,,"2873,94",byMonth151,375,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,390,,,"2978,81",byMonth151,390,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,410,,,"3118,61",byMonth151,410,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,450,,,"3398,23",byMonth151,450,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,550,,,"4097,28",byMonth151,550,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,660,,,"4866,20",byMonth151,660,,,,,,
18/02/2021,1/6/2020,,669,Industries de fabrication mécanique du verre,"cf. CC. n° 3079, Art. 31 ; cf. Accord du 25 avr. 2019 (NOR : ASET1951006M) étendu par A. 15 janv. 2020 : JO 
 23 janv. 2020 et applicable à compter du 1er juill. 2019, en vigueur le 1er jour du mois suivant la publication de son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée) ; cf. Accord du 10 avr. 2020 (NOR : ASET2050478M) étendu par A. 30 juill. 2020 : JO 12 août 2020 et applicable à compter du 1er juin 2020, en vigueur le 1er jour du mois suivant celui au cours duquel est publié son extension pour les entreprises non adhérentes (conclu pour une durée indéterminée)",,,880,,,"6404,10",byMonth151,880,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,1,1,100,1563,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,1,2,110,1603,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,2,1,123,1654,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,2,2,143,1733,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,2,3,163,1812,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,3,1,176,1863,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,3,2,203,1969,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,4,1,300,2351,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,4,2,390,2706,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,5,1,457,2970,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,5,2,590,3494,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,5,3,723,4018,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1512,Promotion immobilière,"cf. CC. n° 3248, Art. 18 ; cf. Avenant n° 43 du 10 mars 2020 (NOR : ASET2050371M) étendu par A. 22 juill. 2020 : JO 1er août 2020, à l'exclusion du secteur de la construction des maisons individuelles (conclu pour une durée indéterminée) (déposé le 3 avril 2020)",,,6,787,,4270,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,A,,,20125,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,B,,,20451,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,C,,,20883,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,D,,,21819,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,E,,,22851,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,F,,,24925,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,G,,,27622,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,H,,,30468,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,I,,,37227,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,J,,,44977,byMonth151,,,,,,,
18/02/2021,1/1/2020,,3210,Banque populaire,"cf. CC. n° 0000-41, Art. 40 modifié par avenant du 26 mars 2018 (NOR : ASET1850604M) non étendu et prenant rétroactivement effet au 1er janvier 2018 (Conclu pour une durée indéterminée) ; cf. Accord du 20 déc. 2019 (NOR : ASET2050466M) non étendu (conclu pour une durée déterminée d'un an du 1er janvier 2020 au 31 décembre 2020)",,,K,,,53514,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,A,,,1532,byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,B,,,"1593,35",byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,C,,,"1673,02",byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,D,,,"1790,14",byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,E,,,"2004,95",byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,F,,,"2345,79",byMonth151,,,,,,,
18/02/2021,1/1/2020,,1710,Agences de voyages et de tourisme (Personnel),"cf. CC. n° 3061-1, Art. 27 ; cf. Avenant n° 2 du 16 juin 2008 (NOR : ASET0850848M) étendu par A. 12 févr. 2009 : JO 20 févr. 2009, applicable au 1er juillet 2008 ; cf. Accord du 15 avr. 2019 (NOR : ASET1950677M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020, applicable du 1er avr. 2019 et au 1er janv. 2020",,,G,,,"2861,87",byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,I,1.1,,1554,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,I,1.2,,1580,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,I,1.3,,1606,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,II,2.1,,1658,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,II,2.2,,1710,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,II,2.3,,1762,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,III,3.1,,1865,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,III,Intermédiaire,,1917,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,III,3.2,,1995,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,IV,4.1,,2643,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,IV,4.2,,3290,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,V,5.1,,3472,byMonth151,,,,,,,
18/02/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",,,V,5.2,,4093,byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,140,,,"1625,95",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,150,,,"1686,44",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,160,,,"1746,94",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,170,,,"1807,43",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,185,,,"1898,16",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,200,,,"1988,90",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,215,,,"2079,65",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,230,,,"2210,86",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,250,,,"2385,80",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,270,,,"2560,75",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,290,,,"2735,69",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,310,,,"2910,64",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,315,,,"2954,38",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,340,,,"3173,06",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,370,,,"3435,48",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,380,,,"3522,95",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,385,,,"3566,68",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,400,,,"3697,89",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,420,,,"3872,84",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,435,,,"4004,05",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,440,,,"4047,79",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,450,,,"4135,26",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,460,,,"4222,74",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,470,,,"4310,21",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,490,,,"4485,15",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,510,,,"4660,10",byMonth151,,,,,,,
18/02/2021,1/1/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,530,,,"4835,05",byMonth151,,,,,,,
18/02/2021,01/01/2018,,1388,Industrie du pétrole,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",,,550,,,"5009,99",byMonth151,,,,,,,
24/02/2012,01/01/2020,,0207,Industrie des cuirs et peaux ,,," Avenant n° 67 S du 16 janv. 2018 (NOR : ASET1850321M) étendu par A. 2  juill. 2018  :JO 7  juill. 2018 et en vigueur 
rétroactivement, au 1er  janvier 2018, dès sa signature  ; cf. Avenant n° 69 S du 27 janv. 2020 (NOR : ASET2050279M) non étendu et en vigueur rétroactivement, au 1er janvier 2020, dès sa signature ",ouvriers,OS1 ,,"1548,67",byMonth151,,,,,,,
24/02/2012,01/01/2020,,0207,Industrie des cuirs et peaux ,,," Avenant n° 67 S du 16 janv. 2018 (NOR : ASET1850321M) étendu par A. 2  juill. 2018  :JO 7  juill. 2018 et en vigueur 
rétroactivement, au 1er  janvier 2018, dès sa signature  ; cf. Avenant n° 69 S du 27 janv. 2020 (NOR : ASET2050279M) non étendu et en vigueur rétroactivement, au 1er janvier 2020, dès sa signature ",ouvriers,OS2,,"1570,18",byMonth151,,,,,,,
24/02/2012,01/01/2020,,0207,Industrie des cuirs et peaux ,,," Avenant n° 67 S du 16 janv. 2018 (NOR : ASET1850321M) étendu par A. 2  juill. 2018  :JO 7  juill. 2018 et en vigueur 
rétroactivement, au 1er  janvier 2018, dès sa signature  ; cf. Avenant n° 69 S du 27 janv. 2020 (NOR : ASET2050279M) non étendu et en vigueur rétroactivement, au 1er janvier 2020, dès sa signature ",ouvriers,OQ,,"1630,10",byMonth151,,,,,,,
24/02/2012,01/01/2020,,0207,Industrie des cuirs et peaux ,,," Avenant n° 67 S du 16 janv. 2018 (NOR : ASET1850321M) étendu par A. 2  juill. 2018  :JO 7  juill. 2018 et en vigueur 
rétroactivement, au 1er  janvier 2018, dès sa signature  ; cf. Avenant n° 69 S du 27 janv. 2020 (NOR : ASET2050279M) non étendu et en vigueur rétroactivement, au 1er janvier 2020, dès sa signature ",ouvriers,OHQ ,,"1746,87",byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 1,,"10,90",byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 2,,"12,04",byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 3,,"15,82",byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 4,,1530,byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 5,,1540,byMonth151,,,,,,,
24/02/2012,01/10/2007,,0698,"Presse quotidienne régionale (Ouvriers, employés)",,,cf. Protocole d'accord du 10 avr. 2006 (NOR : ASET0651244M) non étendu ; cf. Accord du 9 mai 2007 (NOR : ASET0751245M) non étendu ; cf. Annexe du 9 mai 2007 à l'accord du 9 mai 2007 (NOR : ASET0751248M) non étendu,Employés,Echelon 6,,1550,byMonth151,,,,,,,
24/02/2012,01/06/2020,1-2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier,1560,byHour,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier,1572,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier spécialisé,1587,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier spécialisé,1649,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier spécialisé,1727,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier spécialisé,1808,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier qualifié,1888,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier qualifié,1974,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier qualifié,2125,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier qualifié,2172,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier d'encadrement,2336,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier d'encadrement,2506,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier d'encadrement,2709,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Nettoyage,Ouvrier d'encadrement,2927,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier,"1557,00",byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier,"1768,00",byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier,"1936,00",byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier,"2104,00",byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier spécialisé,"2273,00",byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier spécialisé,"2441,00",byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier spécialisé,"2609,00",byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier spécialisé,"2777,00",byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier qualifié,"2946,00",byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier qualifié,"3030,00",byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier qualifié,"3114,00",byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier qualifié,"3198,00",byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier d'encadrement,"3282,00",byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier d'encadrement,"3366,00",byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier d'encadrement,"5049,00",byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,ouvriers,Manutention,Ouvrier d'encadrement,1503,byHour,,,,,,,
24/02/2012,01/06/2020,1-2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier,1527,byHour,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier,1556,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier spécialisé,1556,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier spécialisé,1582,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier spécialisé,1613,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier spécialisé,1613,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier qualifié,1643,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier qualifié,1677,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier qualifié,1698,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier qualifié,1732,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier d'encadrement,1765,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier d'encadrement,1765,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier d'encadrement,1795,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Nettoyage,Ouvrier d'encadrement,1834,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier,1996,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier,2074,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier,2155,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier,2303,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier spécialisé,2394,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier spécialisé,2489,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier spécialisé,2348,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier spécialisé,2490,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier qualifié,2682,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier qualifié,3007,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier qualifié,3242,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier qualifié,3498,byHour,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier d'encadrement,3961,byHour,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier d'encadrement,4273,byHour,,,,,,,
24/02/2012,01/06/2020,3,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier d'encadrement,4611,byHour,,,,,,,
24/02/2012,01/06/2020,4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Ouvriers – RATP,Manutention,Ouvrier d'encadrement,5141,byHour,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,I,,5548,byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,II,,5987,byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,III,,"1531,22",byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,IV,,"1754,59",byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,V,,"1879,16",byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,VI,,"2048,04",byMonth151,,,,,,,
24/02/2012,01/06/2020,,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,Employés de chantier,VII,,"2173,79",byMonth151,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Contremaître,,"2299,55",byMonth151,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Contremaître,,"2473,21",byMonth151,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Contremaître,,"2634,89",byMonth151,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de bordée,,"3377,00",byMonth151,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de bordée,,"1503,05",byMonth151,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de bordée,,"1509,12",byMonth151,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de chantier,,"1509,12",byMonth151,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de chantier,,"1518,22",byMonth151,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de chantier,,"1534,90",byMonth151,,,,,,,
24/02/2012,01/06/2020,1,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de service,,"1548,55",byMonth151,,,,,,,
24/02/2012,01/06/2020,2,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de service,,"1556,13",byMonth151,,,,,,,
24/02/2012,01/06/2020,3-4,0538,Manutention ferroviaire et travaux connexes,,,cf. Avenant n° 102 du 23 janv. 2019 (NOR : ASET1950498M) étendu par A. 12 août 2019 : JO 17 août 2019 et entrant en application à partir du 1er jour du mois suivant la parution de son extension ; cf. Avenant n° 103 du 9 oct. 2019 (NOR : ASET1951367M) étendu par A. 26 mai 2020 : JO 3 juin 2020 et applicable à partir du 1er jour du mois qui suivra la parution de son extension,"Cadres et 
 agents de maîtrise",Chef de service,,"1592,54",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,Agents de maîtrise,270,,"1710,84",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,Agents de maîtrise,285,,"1977,78",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,Agents de maîtrise,295,,"1977,78",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,310,"2200,73",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,340,"2495,72",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,395,"3216,00",byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,400,1798,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,500,1754,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",Cadres de commandement,540,1710,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,310,1663,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,315,1618,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,330,1573,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,385,1557,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,410,1532,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,430,1530,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,475,1529,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,515,1527,byMonth151,,,,,,,
24/02/2012,01/01/2012,,1016,Edition de musique (Cadres et agents de maîtrise),,,cf. Avenant n° 46 du 26 sept. 2011 (NOR : ASET1250200M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,"Cadres et 
 agents de maîtrise",cadres techniques,525,1525,byMonth151,,,,,,,
24/02/2012,01/02/2019,,1314,"Maisons d'alimentation à succursales, supermarchés, hypermarchés """" gérants mandataires """"",,,"cf. CC. n° 3007, Art. 5 modifié en dernier lieu par avenant n° 69 du 28 janv. 2019, art. 2, (NOR : ASET1950616M) non étendu et en vigueur à compter du 1er févr. 2019 (conclu pour une durée indéterminée)",gérance 1re catégorie,,,1522,byMonth151,,,,,,,
24/02/2012,01/02/2019,,1314,"Maisons d'alimentation à succursales, supermarchés, hypermarchés """" gérants mandataires """"",,,"cf. CC. n° 3007, Art. 5 modifié en dernier lieu par avenant n° 69 du 28 janv. 2019, art. 2, (NOR : ASET1950616M) non étendu et en vigueur à compter du 1er févr. 2019 (conclu pour une durée indéterminée)",gérance 2e catégorie,,,1520,byMonth151,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",210,,,"1563,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",225,,,"1569,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",240,,,"1574,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",255,,,1584,byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",270,,,"1594,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",285,,,"1605,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",300,,,"1615,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",315,,,"1625,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",330,,,"1652,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",345,,,"1746,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",360,,,"1866,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",375,,,"1987,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",390,,,"2106,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",405,,,"2274,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",420,,,"2436,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",435,,,"2603,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",450,,,"2771,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",500,,,"3179,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",600,,,"3998,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",700,,,"5028,00",byMonth152,,,,,,,
24/02/2012,01/04/2018,,1942,Textiles artificiels et synthétiques et produits assimilés,,,"cf. CC. n° 3340, Art. 37 ; cf. Annexe I, Fascicule I (ATAM) ; cf. Annexe II, § II (cadres) ; cf. Accord du 5 avr. 2018 (NOR : ASET1850661M) étendu par A. 28 déc. 2018 : JO 30 déc. 2018 et applicable à compter du 1er avril 2018",800,,,"6257,00",byMonth152,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",1,,,18617,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",2,,,20487,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",3,,,23535,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",4,,,27664,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",5,,,30288,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",6,,,33378,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",7,,,40263,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",8,,,40638,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",9,,,42493,byYear1607,,,,,,,
24/02/2012,26/05/2020,,1951,Cabinets ou entreprises d'expertises en automobiles,,,"cf. CC. n° 3295, Art. 12.13 ; cf. Avenant n° 70 du 5 févr. 2019 (NOR : ASET1950638M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019, à l'exclusion des groupements d'intérêt économique qui relèvent de la convention collective des sociétés d'assurance et prenant effet à compter du 1er février 2019 (Conclu à durée indéterminée) ; cf. Avenant n° 76 du 26 mai 2020 (NOR : ASET2050974M) non étendu et prenant effet à la date de sa signature (Conclu à durée indéterminée)",10,,,45571,byYear1607,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,125,,"1554,84",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,135,,"1657,18",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,145,,"1701,35",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,155,,"1758,74",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,165,,"1840,97",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,175,,"1936,74",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers de fabrication,190,,"2061,96",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,145,,"2193,35",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,165,,"2356,69",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,175,,"2524,61",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,195,,"2844,47",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,205,,"3112,91",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Ouvriers d'entretien,225,,"3427,05",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,125,,"3709,23",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,145,,"4078,19",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,155,,"4512,29",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,175,,"1457,52",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,185,,"1539,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,205,,"1599,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,240,,"1659,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,280,,"1719,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Employés et techniciens,325,,"1779,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Agents de maîtrise,190,,"1929,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Agents de maîtrise,220,,"2079,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Agents de maîtrise,250,,"2429,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Agents de maîtrise,290,,"3129,00",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Agents de maîtrise,335,,"1539,47",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,300,,"1559,48",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,370,,"1576,64",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,450,,"1593,98",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,540,,"1611,52",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,650,,"1638,11",byMonth151,,,,,,,
24/02/2012,07/01/2020,,2089,Industrie des panneaux à base de bois,,,"cf. CC. n° 3113, Art. 30 et s. ; cf. Accord du 15 juin 2018 (NOR : ASET1950058M) étendu par A. 5 août 2019 : JO 14 août 2019 (déposé le 26 novembre 2018)",Cadres,800,,"1656,13",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E1,,"1692,37",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E2,,"1785,15",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E3,,"1865,25",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E4,,"2000,15",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E5,,"2139,97",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E6,,"2279,77",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Employés,E7,,"2454,51",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Agents de maîtrise,E8,,"2664,24",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Agents de maîtrise,M9,,"2873,94",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Agents de maîtrise,M10,,"2978,81",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Agents de maîtrise,M11,,"3118,61",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Agents de maîtrise,M12,,"3398,23",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C13,,"4097,28",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C14,,"4866,20",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C15,,"6404,10",byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C16,,1563,byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C17,,1603,byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C18,,1654,byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C19,,1733,byMonth151,,,,,,,
15/2/2021,1/1/2020,,43,Import-export et Commerce international,,,,Cadres,C20,,1812,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,1 A,6,,1863,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,1 B ,8,,1969,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,1C/2A,10,,2351,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,2 B,14,,2706,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,2C/3A,23,,2970,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,3B,28,,3494,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,3C/4A,46,,4018,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,4B,54,,4270,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,4C/5A,77,,20125,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,5B,88,,20451,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,5C/6A,118,,20883,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,6B,132,,21819,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,6C,169,,22851,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,7A,183,,24925,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,7B,246,,27622,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,8A,260,,30468,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,8B,335,,37227,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,9A,349,,44977,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,9B,438,,53514,byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,10,494,,"1532,00",byMonth151,,,,,,,
11/2/2021,1/8/2020,,176,Industrie pharmaceutique,,,,11,550,,"1593,35",byMonth151,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe I,Echelon B ,,"1673,02",byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe I,Echelon A,,"1790,14",byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe II,,,"2004,95",byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe III,Echelon B,,"2345,79",byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe III,Echelon A,,"2861,87",byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe IV,,,1554,byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe V ,Echelon C ,,1580,byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe V ,Echelon B ,,1606,byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe V ,Echelon A,,1658,byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,Groupe VI ,Echelon B ,,1710,byMonth152,,,,,,,
15/2/2021,1/10/2020,,184,Imprimeries de labeur et industries graphiques (Personnel),cf. Accord paritaire du 6 mars 2020 (NOR : ASET2050520M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 ; cf. Accord paritaire du 14 mai 2020 (NOR : ASET2050512M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au plus tard au 1er octobre 2020,,,,Echelon A ,,1762,byMonth152,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 8,,1865,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 8,Echelon 2,1917,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 9,,1995,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 9 ,Echelon 2,2643,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 10,,3290,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 11,,3472,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Cadres,Niveau 12,,4093,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Agents de maîtrise,Niveau 6,,"1625,95",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Agents de maîtrise,Niveau 7 ,,"1686,44",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 8 ,,"1746,94",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 8 ,Echelon 2,"1807,43",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 9 ,,"1898,16",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 9,Echelon 9,"1988,90",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 10,,"2079,65",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 11,,"2210,86",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,,,Employés,Niveau 12,,"2385,80",byMonth151,,,,,,,
24/2/2021,1/12/2020,,2219,Taxis – 49.32 Z,cf. Accord du 12 mars 2020 (NOR : ASET2050690M) étendu,,,Personnels roulants non titulaires de la carte professionnelle,Echelon 1,Niveau 1,"2560,75",byMonth151,,,,,,,
24/2/2021,1/12/2020,,2219,Taxis – 49.32 Z,cf. Accord du 12 mars 2020 (NOR : ASET2050690M) étendu,,,Personnels roulants non titulaires de la carte professionnelle,Echelon 1,Niveau 2,"2735,69",byMonth151,,,,,,,
24/2/2021,1/12/2020,,2219,Taxis – 49.32 Z,cf. Accord du 12 mars 2020 (NOR : ASET2050690M) étendu,,,Personnels roulants titulaires de la carte professionnelle,Echelon 2,Niveau 1,"2910,64",byMonth151,,,,,,,
24/2/2021,1/12/2020,,2219,Taxis – 49.32 Z,cf. Accord du 12 mars 2020 (NOR : ASET2050690M) étendu,,,Personnels roulants titulaires de la carte professionnelle,Echelon 2,Niveau 2,"2954,38",byMonth151,,,,,,,
24/2/2021,1/12/2020,,2219,Taxis – 49.32 Z,cf. Accord du 12 mars 2020 (NOR : ASET2050690M) étendu,,,Personnels roulants titulaires de la carte professionnelle,Echelon 2,Niveau 3,"3173,06",byMonth151,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 1,Echelon 1,"3435,48",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 1,Echelon 2,"3522,95",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 2,Echelon 1,"3566,68",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 2,Echelon 2,"3697,89",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 3,Echelon 1,"3872,84",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 3,Echelon 2,"4004,05",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,"Ouvriers, employés",Niveau 3,Echelon 3,"4047,79",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Agents de maîtrise,Niveau 4,-,"4135,26",byYear1607,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Agents de maîtrise,Niveau 5,-,"4222,74",byYear1607,,,,,,,
25/2/2021,1/1/2020,1-2,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 6,A,"4310,21",byYear218,,,,,,,
25/2/2021,1/1/2020,3-4,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 6,B ,"4485,15",byYear218,,,,,,,
25/2/2021,1/1/2020,1-2,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 7,A ,"4660,10",byYear218,,,,,,,
25/2/2021,1/1/2020,3-4,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 7,B,"4835,05",byYear218,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 8,- ,"5009,99",byYear218,,,,,,,
25/2/2021,1/1/2020,,2494,Coopération maritime,"cf. CC. n° 3326, Art. 37 ; cf. Avenant du 29 mars 2018 (NOR : ASET1850866M) étendu",,,Cadres,Niveau 9,- ,"1570,18",byYear218,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 1,A ,,"1630,10",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 1,B ,,"1746,87",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 1,C ,,"1593,70",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 2,A ,,"1645,10",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 2,B ,,"1670,80",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 2,C ,,"1695,50",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 3,A ,,"1758,20",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 3,B ,,"1799,30",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 3,C ,,"1850,70",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 4,A ,,"2107,80",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 4,B ,,"2179,70",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 4,C ,,"2262,00",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 5,A ,,"2776,10",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 5,B ,,"2878,90",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 5,C ,,"3084,60",byMonth151,,,,,,,
25/2/2021,1/5/2019,,2411,Chaînes thématiques,cf. Annexe I art.  3  ; cf. Accord du 10  avr. 2019 (NOR  : ASET1950928M) étendu par A. 9 avr. 2020 : JO 21 avr. 2020 (déposé le 21 mai 2019) ,,,Niveau 6,A ,-,"3495,90",byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 1,1 ,300 ,1695,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 1,2 ,320 ,1808,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 2,1 ,340 ,1921,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 2,2 ,360 ,2034,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 3,1 ,400 ,2260,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 3,2 ,440 ,2486,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 3,3 ,500 ,2825,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 4,1 ,530 ,"2930,90",byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 4,2 ,560 ,"3096,80",byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 4,3 ,600 ,3318,byMonth151,,,,,,,
25/2/2021,1/1/2020,,2666,"Conseils d'architecture, d'urbanisme et de l'environnement","cf. CC. n° 3348, Art. 2.3 ; cf. Avenant n° 31 du 8 janv. 2020 (NOR  : ASET2050201M) étendu par A. 28  juill. 2020  : JO 12 août 2020 et prenant effet le 1er janv. 2020",,,Niveau 5,1 ,700 ,3822,byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),200,,"1759,89",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),210,,"1826,235",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),220,,"1892,58",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),230,,"1958,925",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),240,,"2025,27",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Ouvrier(e)s et employés(e),250,,"2091,615",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Technicien(ne)s et agent(e)s de maîtrise,300,,"2423,34",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Technicien(ne)s et agent(e)s de maîtrise,310,,"2489,685",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Technicien(ne)s et agent(e)s de maîtrise,320,,"2556,03",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,400,,"3086,79",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,410,,"3153,135",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,420,,"3219,48",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,430,,"3285,825",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,440,,"3352,17",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,450,,"3418,515",byMonth151,,,,,,,
28/2/2021,01/07/2019,,1408,"Négoce et distribution de combustibles solides, liquides, gazeux et produits pétroliers","cf. CC. n° 3004, Art. I.30 bis ; cf. Accord du 10 avr. 2019 (NOR : ASET1950962M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 (déposé le 7 mai 2019) ",,,Cadres,460,,"3484,86",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",I,1,140,"1536,5",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",I,2,145,"1537,33",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",I,3,155,"1538,42",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",II,1,170,"1540,08",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",II,2,180,"1545,5",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",II,3,190,1581,byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",III,1,215,"1637,17",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",III,2,225,"1691,42",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",III,3,240,"1742,67",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",IV,1,255,"1809,42",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",IV,2,270,"1896,75",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",IV,3,285,1984,byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",V,1,305,"2155,92",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",V,2,335,"2348,67",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",V,3,365,"2570,25",byMonth151,,,,,,,
28/02/2021,01/01/2019,,930,Métallurgie Sarthe (métallurgie et industries connexes),,72,"cf. Avenant « Mensuels », Art. 14 ; cf. Avenant « Mensuels », Art. 14 ; cf. Avenant du 18 juill. 2019 (NOR : ASET1951304M) étendu par A. 19 mars 2020 : JO 27 mars 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente ",V,4,395,"2777,08",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,1 A,120,,"1597,09",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,1 B,130,,"1609,22",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,1 C,140,,"1660,79",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,2,150,,"1710,84",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,3 (CAP) A,160,,"1774,54",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,3 B,170,,"1800,32",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,4 (BTP),190,,"1879,19",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,"Agent
de maîtrise 1° échelon",210,,"2055,13",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,"Agent
de maîtrise 2° échelon",250,,"2246,23",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,"Cadre
débutant",350,,"3354,94",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,Cadre confirmé,400,,"3670,41",byMonth151,,,,,,,
28/02/2021,19/1/2021,,1286,"détaillants, détaillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie",,,Avenant n° 37 du 12 mars 2019 à l'annexe III relative aux salaires,Cadre expert,500,,"4166,37",byMonth151,,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,employé de bureau,"1398,67",byMonth151,371,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,employé de bureau,"1409,98",byMonth151,374,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,employé de bureau,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,dactylographe,"1398,67",byMonth151,371,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,dactylographe,"1409,98",byMonth151,374,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agents de bureau,dactylographe,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,archiviste-documentaliste,"1417,52",byMonth151,376,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,archiviste-documentaliste,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,archiviste-documentaliste,"1485,38",byMonth151,394,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,commis d'économat,"1417,52",byMonth151,376,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,commis d'économat,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,commis d'économat,"1485,38",byMonth151,394,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,sténodactylographe,"1417,52",byMonth151,376,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,sténodactylographe,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,sténodactylographe,"1485,38",byMonth151,394,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,secrétaire sténodactylographe,"1417,52",byMonth151,376,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,secrétaire sténodactylographe,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,secrétaire sténodactylographe,"1485,38",byMonth151,394,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,agent administratif,"1417,52",byMonth151,376,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,agent administratif,"1436,37",byMonth151,381,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif,agent administratif,"1485,38",byMonth151,394,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,agent administratif principal,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,agent administratif principal,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,agent administratif principal,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,secrétaire sténodactylo principale,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,secrétaire sténodactylo principale,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,secrétaire sténodactylo principale,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,aide-comptable,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,aide-comptable,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,aide-comptable,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,rédacteur correspondancier,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,rédacteur correspondancier,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,rédacteur correspondancier,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,commis principal d'économat,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,commis principal d'économat,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Agent administratif principal,commis principal d'économat,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale ,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale ,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale ,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale ,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale principale ,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale principale ,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale principale ,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire médicale principale ,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire administratif (2e classe) ,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire administratif (2e classe) ,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire administratif (2e classe) ,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, secrétaire administratif (2e classe) ,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, comptable (2e classe) ,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, comptable (2e classe) ,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, comptable (2e classe) ,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, comptable (2e classe) ,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, rédacteur documentaliste ,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, rédacteur documentaliste ,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, rédacteur documentaliste ,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien qualifié, rédacteur documentaliste ,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur,comptable (1re classe),"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur,comptable (1re classe),"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur,comptable (1re classe),"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire administrative (1re classe) ,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire administrative (1re classe) ,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire administrative (1re classe) ,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, économe (2e classe) ,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, économe (2e classe) ,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, économe (2e classe) ,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire de direction (niveau II),"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire de direction (niveau II),"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Employés, techniciens et agents de maîtrise d'administration et de gestion",Technicien supérieur, secrétaire de direction (niveau II),"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Jardinière d'enfants spécialisée,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Jardinière d'enfants spécialisée,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Jardinière d'enfants spécialisée,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Aide médico-psychologique,,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Aide médico-psychologique,,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Aide médico-psychologique,,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Auxiliaire de vie sociale,,"1492,92",byMonth151,396,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Auxiliaire de vie sociale,,"1526,85",byMonth151,405,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Auxiliaire de vie sociale,,"1575,86",byMonth151,418,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec CAP,,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec CAP,,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec CAP,,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec CAP,,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec baccalauréat,,"1481,61",byMonth151,393,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec baccalauréat,,"1534,39",byMonth151,407,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire avec baccalauréat,,"1594,71",byMonth151,423,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur-éducateur,,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur-éducateur,,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur-éducateur,,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur-éducateur,,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Technicien de l'intervention sociale et familiale,,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Technicien de l'intervention sociale et familiale,,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Technicien de l'intervention sociale et familiale,,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Technicien de l'intervention sociale et familiale,,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur de jeunes enfants,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur de jeunes enfants,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur de jeunes enfants,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique,,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique,,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique,,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique,,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Monitrice d'enseignement ménager,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Monitrice d'enseignement ménager,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Monitrice d'enseignement ménager,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Conseillère en économie familiale et sociale,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Conseillère en économie familiale et sociale,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Conseillère en économie familiale et sociale,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Animateur socio-éducatif,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Animateur socio-éducatif,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Animateur socio-éducatif,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social","Animateur (titulaire du DUT, formation de niveau III)",,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social","Animateur (titulaire du DUT, formation de niveau III)",,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social","Animateur (titulaire du DUT, formation de niveau III)",,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social","Animateur (titulaire du DUT, formation de niveau III)",,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Professeur d'éducation physique et sportive,,"1711,58",byMonth151,454,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Professeur d'éducation physique et sportive,,"1760,59",byMonth151,467,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Professeur d'éducation physique et sportive,,"1877,46",byMonth151,498,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau III,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau III,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau III,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau IV,,"1549,47",byMonth151,411,,,,,,
28/02/2021,1/2/2017,2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau IV,,"1598,48",byMonth151,424,,,,,,
28/02/2021,1/2/2017,3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau IV,,"1651,26",byMonth151,438,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur sportif titulaire d'un diplôme de niveau IV,,"1707,81",byMonth151,453,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur adjoint d'animation et/ou d'activités,,"1398,67",byMonth151,371,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur adjoint d'animation et/ou d'activités,,"1409,98",byMonth151,374,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Moniteur adjoint d'animation et/ou d'activités,,"1451,45",byMonth151,385,,,,,,
28/02/2021,1/2/2017,1-2,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Assistante sociale chef,,"2175,29",byMonth151,577,,,,,,
28/02/2021,1/2/2017,3-4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Assistante sociale chef,,"2254,46",byMonth151,598,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Assistant de service social,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Assistant de service social,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Assistant de service social,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire spécialisé,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire spécialisé,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur scolaire spécialisé,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique spécialisé,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique spécialisé,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Educateur technique spécialisé,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/2/2017,1,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Enseignant technique,,"1636,18",byMonth151,434,,,,,,
28/02/2021,1/2/2017,2-3,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Enseignant technique,,"1685,19",byMonth151,447,,,,,,
28/02/2021,1/2/2017,4,413,établissements et services pour personnes inadaptées et handicapées,,,Avenant n° 340 du 29 novembre 2017 relatif à la politique salariale 2017,"Personnel éducatif, pédagogique et social",Enseignant technique,,"1802,06",byMonth151,478,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau A,,,"1551,83",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau B,,,"1692,81",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau C,,,"1828,13",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau D,,,"1986,41",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau E,,,"2142,22",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau F,,,"2374,07",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau G,,,"2644,72",byMonth151,,,,,,,
28/02/2021,1/1/2021,,2609,"employés, techniciens et agents de maîtrise du bâtiment ",,44-49-53-72-85,http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/etam.html?id_regionNiveauEtam=343,Niveau H,,,"2997,95",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.150,,,"1556,13",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.170,,,"1574,33",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.185,,,"1645,62",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.210,,,"1841,27",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.230,,,"2002,04",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.250,,,"2161,3",byMonth151,,,,,,,
28/02/2021,1/1/2021,,1597,ouvriers  du bâtiment ,,44-49-53-72-85,"http://www.paysdelaloire.ffbatiment.fr/federation-batiment-pays-de-la-Loire/en-chiffres/informations-sociales/ouvriers.html?id_region=343#:~:text=Base%2035%20heures&text=%2D%20Aucun%20salaire%20ne%20doit%20%C3%AAtre,partir%20du%201er%20janvier%202021).",Coef.270,,,"2322,07",byMonth151,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,60,,,1919,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,65,,,2079,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,70,,,2238,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,75,,,2364,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,80,,,2516,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,85,,,2667,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,90,,,2816,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,95,,,2971,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,100,,,3097,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,103,,,3188,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,108,,,3308,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,120,,,3656,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,130,,,3949,byMonth156,,,,,,,
28/02/2021,1/2/2019,,2420,cadres du bâtiment ,,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,162,,,4903,byMonth156,,,,,,,
28/02/2021,1/2/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,Ouvriers d'exécution ,position 1,150,"1525,80",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,Ouvriers d'exécution ,position 2,170,"1544,00",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,Ouvriers professionnels,position 1,185,"1613,77",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,"Compagnons 
professionnels ",position 1,210,"1809,42",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,"Compagnons 
professionnels ",position 2,230,"1965,64",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,Maîtres-ouvriers ou chefs d'équipe ,position 1,250,"2123,38",byMonth151,,,,,,,
28/02/2021,1/1/2019,,1596,ouvriers du bâtiment (- de 10 salariés),,44-49-53-72-85,https://www.capeb.fr/service/la-remuneration-des-salaries-boite-a-outils-capeb-49,Maîtres-ouvriers ou chefs d'équipe ,position 2,270,"2279,60",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau I,position 1,100,"1630,89",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau I,position 2,110,"1644,47",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau II,position 1,125,"1714,88",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau II,position 2,140,"1911,87",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau III,position 1,150,"2053,58",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau III,position 2,165,"2224,72",byMonth151,,,,,,,
28/02/2021,01/01/2020,,1702,ouvriers des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,Niveau IV,position 1,180,"2426,26",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,A,,,"1630,73",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,B,,,"1690,81",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,C,,,"1831,87",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,D,,,"2028,94",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,E,,,"2222,85",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,F,,,"2470,33",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,F (convention forfait jour sur l'année),,,"2840,89",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,G,,,"2762,60",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,G (convention forfait jour sur l'année),,,"3716,99",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,H,,,"2972,52",byMonth151,,,,,,,
28/02/2021,01/01/2020,,2614,employés des travaux publics,,44-49-53-72-85,https://www.capeb.fr/www/capeb/media//pays-de-la-loire/document/303_202001_Salaires_TP_Paysage.pdf,H (convention forfait jour sur l'année),,,"3418,37",byMonth151,,,,,,,
18/02/2021,01/01/2018,,1388,Industrie du pétrole,,,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",560,,,"5097,47",byMonth151,,,,,,,
18/02/2021,01/01/2018,,1388,Industrie du pétrole,,,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",660,,,"5972,20",byMonth151,,,,,,,
18/02/2021,01/01/2018,,1388,Industrie du pétrole,,,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",770,,,"6934,40",byMonth151,,,,,,,
18/02/2021,01/01/2018,,1388,Industrie du pétrole,,,"cf. CC. n° 3001, Art. 402 ; cf. Accord du 23 nov. 2017 (NOR : ASET1850579M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable au 1er janv. 2018",880,,,"7896,60",byMonth151,,,,,,,
28/02/2021,28/2/2021,,3217,Ferroviaire,,,,,,,,,,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,A,,,"1548,40",byMonth151,245,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,B,,,"1611,60",byMonth151,255,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,C,,,"1769,60",byMonth151,280,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,D,,,"1896,00",byMonth151,300,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,E,,,"2212,00",byMonth151,350,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,F,,,"2370,00",byMonth151,375,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,G,,,"2528,00",byMonth151,400,,,,,,
28/02/2021,1/1/2020,,1518,Animation,,,,H,,,"2844,00",byMonth151,450,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",1,1,100,"1568,00",byMonth151,100,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",II,1,104,"1630,72",byMonth151,104,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",II,2,107,"1677,76",byMonth151,107,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",II,3,110,"1724,80",byMonth151,110,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",III,1,114,"1787,52",byMonth151,114,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",III,2,118,"1850,24",byMonth151,118,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",III,3,125,"1960,00",byMonth151,125,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",III,4,132,"2069,76",byMonth151,132,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",IV,1,150,"2352,00",byMonth151,150,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",IV,2,167,"2618,56",byMonth151,167,,,,,,
28/02/2021,1/1/2020,,2149,Activités du déchet,,,"cf. CC. n° 3156, Titre III-1 ;cf. Avenant n° 20 du 11 mai 2007
(NOR : ASET0750692M), étendu par A. 6 nov. 2007 : JO
13 nov. 2007 ; cf. CC. n° 3156, Titre III-1 nouveau résultant de
l’avenant n° 62 du 16 avr. 2019 (NOR : ASET1950972M) non
étendu et prenant effet à compter de la date de publication
de son extension (conclu pour une durée indéterminée)",V,1,170,"2665,60",byMonth151,170,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Non diplômé,200,"1610,00",byMonth151,200,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Dessinateur,220,"1771,00",byMonth151,220,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Dessinateur,240,"1932,00",byMonth151,240,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 2,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 2,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Assistant de projet 2,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Chargé de projet 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Directeur de projet,500,"4025,00",byMonth151,500,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Directeur de projet,520,"4186,00",byMonth151,520,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Directeur de projet,540,"4347,00",byMonth151,540,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 1,500,"4025,00",byMonth151,500,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 1,520,"4186,00",byMonth151,520,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 1,540,"4347,00",byMonth151,540,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 2,560,"4508,00",byMonth151,560,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 2,580,"4669,00",byMonth151,580,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception en architecture,Architecte en titre 2,600,"4830,00",byMonth151,600,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Non diplômé,200,"1610,00",byMonth151,200,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Dessinateur,220,"1771,00",byMonth151,220,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Dessinateur,240,"1932,00",byMonth151,240,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Dessinateur 2,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Dessinateur 2,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Dessinateur 2,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant économique 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant économique 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant économique 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 2,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 2,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant travaux 2,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 2,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 2,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Assistant technique 2,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de travaux 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de travaux économie 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Chargé de projet technique 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de projet technique,500,"4025,00",byMonth151,500,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de projet technique,520,"4186,00",byMonth151,520,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur de projet technique,540,"4347,00",byMonth151,540,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 1,500,"4025,00",byMonth151,500,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 1,520,"4186,00",byMonth151,520,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 1,540,"4347,00",byMonth151,540,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur technique,560,"4508,00",byMonth151,560,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur technique,580,"4669,00",byMonth151,580,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Directeur technique,600,"4830,00",byMonth151,600,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 2,560,"4508,00",byMonth151,560,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 2,580,"4669,00",byMonth151,580,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception technique,Ingénieur 2,600,"4830,00",byMonth151,600,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Non diplômé,200,"1610,00",byMonth151,200,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Assistant administratif 1,220,"1771,00",byMonth151,220,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Assistant administratif 1 ,240,"1932,00",byMonth151,240,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Assistant administratif 2,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Assistant administratif 2,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Assistant administratif 2,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 2,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 2,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat technique 2,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Emploi administratif spécialisé,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Emploi administratif spécialisé,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Emploi administratif spécialisé,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 1,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 1,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 1,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 2,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 2,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Secrétariat de direction 2,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Administration et gestion,Chargé d'administration 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Agent d'entretien,200,"1610,00",byMonth151,200,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Agent d'entretien,220,"1771,00",byMonth151,220,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Agent d'entretien,240,"1932,00",byMonth151,240,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,"Assistant maintenance 1 (entretien matériel, reprographie)",260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,"Assistant maintenance 1 (entretien matériel, reprographie)",280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,"Assistant maintenance 1 (entretien matériel, reprographie)",300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Assistant maintenance 2 (réseau),320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Assistant maintenance 2 (réseau),340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Assistant maintenance 2 (réseau),360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 2 (réseaux...),440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 2 (réseaux...),460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Entretien et maintenance,Chargé de maintenance 2 (réseaux...),480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Non diplômé,200,"1610,00",byMonth151,200,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Dessinateur 1,220,"1771,00",byMonth151,220,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Dessinateur,240,"1932,00",byMonth151,240,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Dessinateur 2,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Dessinateur 2,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Dessinateur 2,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 1,260,"2093,00",byMonth151,260,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 1,280,"2254,00",byMonth151,280,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 1,300,"2415,00",byMonth151,300,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 2,320,"2576,00",byMonth151,320,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 2,340,"2737,00",byMonth151,340,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Assistant de projet 2,360,"2898,00",byMonth151,360,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 1,380,"3059,00",byMonth151,380,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 1,400,"3220,00",byMonth151,400,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 1,420,"3381,00",byMonth151,420,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 2,440,"3542,00",byMonth151,440,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 2,460,"3703,00",byMonth151,460,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Chargé de projet 2,480,"3864,00",byMonth151,480,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 1,500,"4025,00",byMonth151,500,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 1,520,"4186,00",byMonth151,520,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 1,540,"4347,00",byMonth151,540,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 2,560,"4508,00",byMonth151,560,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 2,580,"4669,00",byMonth151,580,,,,,,
28/2/2021,1/1/2020,,2332,Entreprises d'architecture,,44-49-53-72-85,,Conception spécialisée,Directeur de projet 2,600,"4830,00",byMonth151,600,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,120,,,"1541,00",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,125,,,"1545,64",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,130,,,"1549,84",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,135,,,"1554,04",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,140,,,"1558,25",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,145,,,"1562,45",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,150,,,"1566,65",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,155,,,"1570,86",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,160,,,"1575,06",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,165,,,"1579,26",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,170,,,"1583,46",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,175,,,"1587,67",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,180,,,"1591,87",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,185,,,"1608,38",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,190,,,"1639,14",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,195,,,"1680,15",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,200,,,"1684,91",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,215,,,"1752,72",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,230,,,"1839,82",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,245,,,"1924,95",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,260,,,"2026,23",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,280,,,"2127,57",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,300,,,"2239,01",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,320,,,"2366,66",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,340,,,"2488,24",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,350,,,"2502,42",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,375,,,"2674,64",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,400,,,"2836,76",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,450,,,"3140,69",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,500,,,"3444,61",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,600,,,"4042,38",byMonth151,,,,,,,
28/2/2021,1/2/2020,,1938,industries de la transformation des volailles,,,Accord du 14 février 2020 relatif aux salaires minima mensuels pour l'année 2020,700,,,"4640,12",byMonth151,,,,,,,
28/2/2021,1/1/2020,,653,producteurs salariés de base des services extérieurs de production des sociétés d'assurances,,,https://www.juristique.org/wp-content/uploads/2020/09/accord-du-19-mai-2020-des-services-exterieurs-de-production-des-societes-assurance.pdf,,,,"18990,00",byYear1607,,,,,,,
28/2/2021,28/2/2021,,3235,parfumerie sélective,,,,,,,,,,,,,,,
28/2/2021,1/10/2007,,1985,Presse quotidienne régionale (Encadrement),,,,,,,20925,byYear1607,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,I,I,1561,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,I,II,1582,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,II,I,1616,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,II,II,1660,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,III,I,1704,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,III,II,1771,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,IV,I,1865,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,IV,II,1979,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,V,I,2084,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",ouvriers,V,II,2198,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,I,I,2269,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,I,II,2485,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,II,I,2567,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,II,II,2706,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,III,II,1771,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,IV,I,1865,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,IV,II,1979,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,V,I,2084,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",agents de maîtrise,V,II,2198,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,I,I,2900,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,I,II,3144,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,II,I,3387,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,II,II,3758,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,III,I,4101,byMonth151,,,,,,,
28/2/2021,1/3/2020,,1044,horlogerie,,,"cf. Avenant n° 47 du 23 oct. 2019 (NOR : ASET1951434M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020, applicable  au titre de l'année 2020 et en vigueur à la date d'extension, au plus tard le 1er mars 2020 ",cadres,III,II,5208,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur général délégué (non mandataire social),,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur général adjoint,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Secrétaire général,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur administratif et financier,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur juridique,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur des ressources humaines et de la formation,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur de la recherche et du développement,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur de la distribution,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur de la communication,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Producteur exécutif,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur des opérations,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur du studio,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur littéraire,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Directeur exploitation,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Délégué général,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chargé de négociation/business affaires,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Contrôleur de gestion,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable administratif et financier,,3036,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable juridique,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable des ressources humaines et de la formation,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable informatique,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable exploitations dérivées,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de studio,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef comptable,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Juriste,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chargé de communication,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable des sites web,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de projet édition,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Ingénieur RD,,2565,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de projet vidéo/VOD,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de projet licensing,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de projet nouveaux médias,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chef de projet recherche et développement,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Chargé des ventes internationales,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant de direction,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Attaché de presse,,2271,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Contrôleur de gestion junior,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Administrateur de royautés,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Développeur,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Comptable,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Responsable de la paye,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Webmestre,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Adjoint du directeur de studio,,2138,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Adjoint du chef de studio,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Adjoint du directeur littéraire,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant juridique,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Régisseur,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Traffic manager,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Secrétaire-standardiste,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant administratif,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant comptable,,1678,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant de communication,,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant commercial,,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Assistant développeur,,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Hôte standardiste,,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Coursier,,1531,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Gardien,,1531,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Poste administratif et commercial,Agent d'entretien,,1531,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"Réalisateur 
Réalisatrice",3392,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"Directeur de l'image/photo 
Directrice de l'image/photo",2885,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"Directeur artistique 
Directrice artistique",2885,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"Directeur d'écriture 
Directrice d'écriture",2900,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Directeur/superviseur de projet directrice/superviseuse de projet,2885,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Directeur/superviseur de projet adjoint directrice/superviseur de projet adjointe,2885,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Story-boarder Chef,2900,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,story-boardeuse Confirmé,2617,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Assistant story-boarder,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"1er assistant réalisateur 
1er assistante réalisatrice",2229,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Scripte scripte,1704,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,"2e assistant réalisateur 
2e assistante réalisatrice",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Réalisation,Coordinateur d'écriture coordinatrice d'écriture,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Directeur décor 
Directrice décor",2743,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Dessinateur d'animation - chef,2512,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Dessinateur d'animation - confirmé,1844,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Superviseur pipeline 
Superviseuse pipeline",2228,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Infographiste pipeline 
Infographiste pipeline",1983,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Assistant infographiste pipeline Assistante infographiste pipeline,1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Directeur rigging/set up 
Directrice rigging/set up",2910,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Infographiste rigging/set up Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Infographiste rigging/set up Confirmé
",1983,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Assistant infographiste rigging/set up Assistante infographiste rigging/set up,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Décorateur Chef,2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments",Décorateur Confirmé,1876,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Assistant décorateur 
Assistante décoratrice",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,"Conception/ 
fabrication des 
éléments","Coloriste 
Coloriste",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Lay Out,"Directeur/superviseur lay-out 
",2826,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Lay Out,"Infographiste lay-out Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Lay Out,Infographiste lay-out,1854,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Lay Out,"Assistant infographiste lay-out 
",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Animation,Directeur/superviseur d'animation ,2900,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Animation,"Chef assistants animateurs 
",2155,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Animation,"Animateur Chef
",2607,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Animation,"Animateur Confirmé
",2187,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Animation,"Assistant animateur 
",1797,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Compositing,Directeur/superviseur compositing Directrice/superviseuse compositing,2743,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Compositing,"Infographiste compositing Chef
",2334,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Compositing,"Infographiste compositing Confirmé
",1704,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Compositing,Assistant infographiste compositing Assistante infographiste compositing,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Directeur technique post prod 
Directrice technique post prod",2732,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Ingénieur du son 
",2477,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Responsable technique post prod,2429,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Bruiteur 
",2430,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Directeur stéréographe 
",2732,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Stéréographe Chef
",2477,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Stéréographe Confirmé
",1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Assistant stéréographe ,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Monteur d'image/son/animatique ,2477,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Monteur d'image/son/animatique Chef,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Monteur d'image/son/animatique Confirmé,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Etalonneur numérique Chef
",2606,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Etalonneur numérique Confirmé
",1983,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Assistant étalonneur numérique ,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Détecteur d'animation 
",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,"Opérateur son 
",2259,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Postproduction,Assistant opérateur son,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Infographiste développeur 
",1876,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Responsable d'exploitation 
",2209,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,Administrateur système et réseaux ,2210,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Technicien système, réseau et 
maintenance",1876,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Opérateur système réseau et 
maintenance",1721,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Superviseur data et calcul 
",2228,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Technique,"Opérateur data et calcul 
",1704,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Directeur de production 
",2795,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,Superviseur de production,"2491,00",byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Administrateur de production 
",2165,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Charge de production 
",1880,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Comptable de production 
",1704,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Coordinateur de production 
",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Assistant de production 
",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Directeur technique 
",2795,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,"Infographiste technique 
",1983,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Tronc commun,Production,Assistant infographiste technique ,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Conception/ 
Fabrication des 
éléments","Chef modèles couleurs 
",2160,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Conception/ 
Fabrication des 
éléments","Assistant dessinateur 
",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,Lay out,"Dessinateur lay-out Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,Lay out,"Dessinateur lay-out Confirmé
",1983,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,Animation,"Animateur feuilles d'exposition Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,Animation,"Animateur feuilles d'exposition Confirmé
",2047,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,Animation,"Intervalliste 
Intervalliste",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Vérificateur d'animation Chef
",2218,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation",Vérificateur d'animation Confirmé,1704,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Vérificateur trace colorisation Chef
",2165,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Vérificateur trace Confirmé
",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Responsable scan 
",1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Traceur 
",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Gouacheur 
",1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",2D,"Traçage, scan et colorisation","Opérateur scan 
",1531,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Directeur/superviseur de 
modélisation 
",2910,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Directeur/superviseur textures et shading 
",2910,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Directeur effets dynamiques et des simulations 
",2910,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Designer 
",2160,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Sculpteur 3D Chef
",2160,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Sculpteur 3D Confirmé
",1844,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Assistant sculpteur 3D 
",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Infographiste de modélisation Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Infographiste de modélisation Confirmé
",1844,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Assistant infographiste de 
modélisation 
",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments",Infographiste textures et shading Chef,2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments",Infographiste textures et shading Confirmé,1844,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Assistant infographiste texture et shading 
",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Infographiste d'effets dynamiques/ simulations Chef
",2460,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Infographiste d'effets dynamiques/ simulations Confirmé
",1844,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Conception et 
fabrication des 
éléments","Assistant infographiste d'effets dynamiques/simulations 
",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage","Directeur/superviseur rendu 
éclairage 
",2743,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage","Infographiste rendu éclairage 
",1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage","Assistant infographiste rendu 
éclairage ",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage","Directeur matte painting 
",2743,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage","Infographiste matte painter 
",2305,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Rendu et 
éclairage",Assistant infographiste matte painter ,1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Effets visuels 
numériques
","Directeur des effets visuels 
numériques ",2826,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Effets visuels 
numériques
",Infographiste des effets visuels Chef,2607,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Effets visuels 
numériques
",Infographiste des effets visuels numériques Confirmé,"2294,00",byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",3D,"Effets visuels 
numériques
","Assistant infographiste des effets visuels numériques 
",1651,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Animateur volume Chef
",,2607,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Animateur volume Confirmé
",,2187,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant animateur volume 
",,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Décorateur volume Chef
",,2303,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Décorateur volume Confirmé
",,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant décorateur volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Opérateur volume Chef
",,2303,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Opérateur volume Confirmé
",,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant opérateur volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Plasticien volume Chef
",,2303,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Plasticien volume Confirmé
",,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant plasticien volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Accessoiriste volume Chef
",,2007,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Accessoiriste volume Confirmé
",,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant accessoiriste volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,Technicien effets spéciaux volume,,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Mouleur volume Chef
",,2007,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Mouleur volume Confirmé
",,1665,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant mouleur volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Mécanicien volume Chef
",,2007,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Mécanicien volume Confirmé
",,1779,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Volume,"Assistant mécanicien volume 
",,1583,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Superviseur MOCAP 
",2743,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,Opérateur capture de mouvement ,1648,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,Assistant opérateur capture de,1545,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Assistant opérateur capture de 
mouvement",1545,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,Opérateur retouche en temps réel,1648,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Assistant opérateur retouche en temps réel 
",1545,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,Opérateur traitement et intégration ,1648,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Assistant opérateur traitement et 
intégration ",1545,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Opérateur HEADCAM 
",1648,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",Motion Capture,Tournage MOCAP,"Assistant opérateur HEADCAM 
",1545,byMonth151,,,,,,,
28/2/2021,1/2/2018,,2412,Production de films d'animation ,,,"cf. CC. n° 3314, Art. 32 modifié en dernier lieu par avenant n ° 11 du 8 févr. 2018 (NOR : ASET1850569M) étendu par A. 
17 févr. 2020 : JO 25 févr. 2020 et entrant en application à sa date d'extension, sauf pour les dispositions concernant les minima effectives au 1er févr. 2018 ; cf. Avenant n° 9 du 3 mars 2015 (NOR : ASET1550524M) étendu par A. 10 août 2015 : JO 16 sept. 2015 ; 
",artiste de complément,"Figurant MOCAP 
",,1843,byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau I,échelon A,"1657,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau I,échelon A,"1657,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau I,échelon B,"1677,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau I,échelon B,"1677,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon A,"1694,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon A,"1694,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon A,"1694,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon B,"1718,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon B,"1718,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon B,"1718,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon B,"1718,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon B,"1718,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau II,échelon C,"1746,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon A,"1852,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon A,"1852,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon A,"1852,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon A,"1852,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon A,"1852,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon B,"1893,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon B,"1893,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon B,"1893,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau III,échelon C,"1965,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon A,"1973,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon B,"2035,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon C,"2064,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon C,"2064,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon C,"2064,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon C,"2064,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,"Ouvrier(e)s, employé(e)s",Niveau IV,échelon D,"2167,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau V,responsable de laboratoire adjoint,"2350,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau V,responsable de point de vente adjoint,"2350,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VI,échelon A,"2564,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VI,échelon A,"2564,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VI,échelon A,"2564,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VI,échelon B,"2580,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VI,échelon C,"2890,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VII,échelon A,"3291,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VII,échelon A,"3291,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VII,échelon A,"3291,00",byMonth151,,,,,,,
1/3/2021,2/1/2021,,992,"Boucherie, boucherie-charcuterie et boucherie hippophagique (Commerces de détail de boucherie)",,,cf. Avenant n° 58 du 17 mai 2018 (NOR : ASET1850715M) étendu par A. 5 juin 2019 : JO 13 juin 2019 (déposé le 15 juin 2018) ; cf. Avenant n° 62 du 19 févr. 2020 (NOR : ASET2050410M) étendu par A. 22 juill. 2020 : JO 1er août 2020 et prenant effet le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 63 du 11 sept. 2020 (NOR : ASET2050853M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et prenant effet le 1er jour du mois suivant la publication de son extension,Agents de maîtrise et cadres,Niveau VII,échelon B,"3374,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Ouvriers – Employés,I,1,"1534,90",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Ouvriers – Employés,I,2,"1548,61",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Ouvriers – Employés,II,1,"1556,34",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Ouvriers – Employés,II,2,"1562,52",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Ouvriers – Employés,III,1,"1658,34",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Employés – Techniciens – Agents de maitrise,IV,1,"1798,98",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Employés – Techniciens – Agents de maitrise,IV,2,"1992,17",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Employés – Techniciens – Agents de maitrise,V,1,"2191,54",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Employés – Techniciens – Agents de maitrise,VI,1,"2384,73",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Cadres,VII,1,"2679,93",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Cadres,VIII,1,"2973,58",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1561,Cordonnerie multiservice,,,"cf. CC. n° 3015, Art. 5 ; cf. CC. n° 3015, Art. 6 ; cf. Avenant du 21 janv. 2019 (NOR : ASET1950416M) étendu par A. 30 juill. 2019 : JO 10 août 2019 ; modifié par A. 23 sept. 2019 : JO 27 sept. 2019 et applicable le 1er janvier 2019",Cadres,VIII,2,"3169,87",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,I,"1529,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,I,"1569,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,I,"1576,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,II,"1597,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,II,"1610,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,II,"1622,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,III,"1666,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,III,"1726,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,III,"1825,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,IV,"1893,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,IV,"2036,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,IV,"2218,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,V,"2332,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,V,"2470,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Ouvriers et collaborateurs,V,"2688,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,I a,"2532,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,I b,"2960,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,I c,"3308,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,II a,"3562,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,II b,"3725,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,II c,"3867,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,III a,"4111,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,BROSSERIE,Cadres,III b,"4404,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,I,AB,"1522,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,II,1 C,"1526,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,II,2 D,"1537,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,III,1 E,"1547,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,III,2 F,"1562,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,III,3 G,"1589,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,IV,1 H,"1639,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,IV,2 I,"1746,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,EMBALLAGES EN BOIS,IV,3 J,"1922,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,I - AB,"1522,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,II - 1C,"1526,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,II - 2D,"1537,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,III- 1F,"1547,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,III - 2F,"1562,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,III - 3G,"1589,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,IV - 1H,"1639,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,IV - 2I,"1746,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Ouvriers,IV - 3J,"1922,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 1,"1522,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 2 - 1,"1537,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 2 - 2,"1557,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 3 - 1,"1589,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 3 - 2,"1639,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 4,"1746,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 5 - 1,"1859,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 5 - 2,"1972,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 6 - 1,"2154,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 6 - 2,"2335,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 7 - 1,"2630,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,"Personnel administratif, commercial et technique",ACT 7 - 2,"2930,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Agents de maîtrise,AM 1,"1859,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Agents de maîtrise,AM 2 - 1,"2090,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Agents de maîtrise,AM 2 - 2,"2335,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Agents de maîtrise,AM 3 - 1,"2630,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Agents de maîtrise,AM 3 - 2,"2930,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 1,"2389,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 2,"2869,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 3,"3225,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 4,"3466,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 5,"3585,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 6,"3766,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 7,"4005,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,Industries du bois - Scieries - Travail mécanique du bois - Palettes en bois,Cadres,C 8,"4305,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,I - AB,"1522,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,II - 1C,"1526,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,II - 2D,"1537,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,III - 1E,"1547,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,III - 2F,"1562,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,III - 3G,"1589,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,IV - 1H,"1639,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,IV - 2I,"1746,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Ouvriers,IV - 3J,"1922,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 1,"1522,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 2 - 1,"1537,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 2 - 2,"1557,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 3 - 1,"1589,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 3 - 2,"1639,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 4,"1746,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 5 - 1,"1859,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 5 - 2,"1972,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 6 - 1,"2154,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 6 - 2,"2335,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 7 - 1,"2630,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,"Personnel administratif, commercial et technique",ACT 7 - 2,"2930,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Agents de maîtrise,AM 1,"1859,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Agents de maîtrise,AM 2 - 1,"2090,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Agents de maîtrise,AM 2 - 2,"2335,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Agents de maîtrise,AM 3 - 1,"2630,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Agents de maîtrise,AM 3 - 2,"2930,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 1,"2389,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 2,"2869,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 3,"3225,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 4,"3466,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 5,"3585,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 6,"3766,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 7,"4005,00",byMonth151,,,,,,,
1/3/2021,7/1/2019,,158,"Travail mécanique du bois, des scieries, du négoce et de l'importation des bois",,,,IMPORTATION,Cadres,C 8,"4305,00",byMonth151,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel d'entretien,,,"10,15",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel administratif,Réceptionniste ou hôtesse d'accueil,2.1,"10,15",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel administratif,Secrétaire (ST),2.2,"11,39",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Aide dentaire,3.1 Aide dentaire,"10,38",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Assistante dentaire,3.2. Assistante dentaire,"11,52",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Niveau 1,3.3.2001,"10,70",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Niveau 2,3.3.2002,"13,51",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Niveau 3,3.3.2003,"16,71",byHour,,,,,,,
1/3/2021,1/1/2020,,1619,Cabinets dentaires,,,"cf. CC. n° 3255, Art. 3.14 ; cf. Annexes I et II ; cf. Accord du 21 mars 2019 (NOR : ASET1950868M) étendu par A. 31 janv. 2020 : JO 6 févr. 2020 et applicable au 1er janv. 2019 ; cf. Accord du 16 janv. 2020 (NOR : ASET2050403M) non étendu (déposé le 11 mars 2020)",Personnel technique,Niveau 4,3.3.2004,"18,19",byHour,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",I,1.1,300,"1554,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",I,1.2,305,"1580,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",I,1.3,310,"1606,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",II,2.1,320,"1658,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",II,2.2,330,"1710,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",II,2.3,340,"1762,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",III,3.1,360,"1865,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",III,Intermédiaire,370,"1917,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",III,3.2,385,"1995,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",IV,4.1,510,"2643,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",IV,4.2,635,"3290,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",V,5.1,670,"3472,00",byMonth151,,,,,,,
1/3/2021,1/1/2019,,1982,Négoce et prestations de services dans les domaines médico-techniques,,,"cf. CC. n° 3286, Art. 13 ; cf. CC. n° 3286, Art. 14 ; cf. Accord du 6 déc. 2018 (NOR : ASET1950139M) étendu par A. 9 juill. 2019 : JO 13 juill. 2019 et prenant effet à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",V,5.2,790,"4093,00",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,I,,"1557,92",byMonth151,,,,,,,
2/25/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,II,,"1557,92",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,III,,"1573,27",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,IV,,"1585,55",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,V,,"1642,34",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,VI,,"1818,86",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,VII,,"2305,42",byMonth151,,,,,,,
2/24/2021,10/1/2020,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Mareyeurs-expéditeurs,VIII,,"3031,43",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,I,,"1339,25",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,II,,"1342,28",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,III,,"1346,83",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,IV,,"1374,13",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,V,,"1430,25",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,VI,,"1571,30",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,VII,,"1935,31",byMonth151,,,,,,,
2/24/2021,7/1/2009,,1589,Mareyeurs-expéditeurs,,,"cf. Avenant n° 29 bis du 26 juin 2009 (NOR : ASET0950882M)
 étendu par A. 27 oct. 2009 : JO du 3 nov. 2009 modifié par A.
 14 déc. 2009 : JO du 23 déc. 2009 – Saleurs-saurisseurs ; cf.
 Avenant du 24 janv. 2019 (NOR : ASET1950499M) étendu par
 A. 12 août 2019 : JO 17 août 2019 et en vigueur au 1er mars
 2019 (Conclu pour une durée indéterminée) ; cf. Avenant
 du 1er oct. 2020 (NOR : ASET2051029M) non étendu et en
 vigueur au 1er oct. 2020 (Conclu pour une durée indéterminée)",Saleurs-saurisseurs,VIII,,"2541,99",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G1,,,"1581,55",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G1,,,"1638,51",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G1,,,"1696,00",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G1,,,"1752,43",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G2,,,"1855,48",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G2,,,"2015,30",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G2,,,"2251,89",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G2,,,"2409,27",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G3,,,"2640,59",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G3,,,"3386,29",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G3,,,"4850,23",byMonth151,,,,,,,
2/24/2021,11/21/2020,,1605,"Entreprises de désinfection, désinsectisation,",,,"cf. Accord du 21 janv. 2019 (NOR : ASET1950403M) étendu
 par A. 2 août 2019 : JO 8 août 2019 et applicable le 1er jour
 suivant son extension ; cf. Accord du 15 juin 2020 (NOR :
 ASET2050580M) étendu par A. 20 nov. 2020 : JO 1er déc.
 2020 et applicable au 1er jour suivant la date de l'arrêté
 d'extension",G3,,,"5566,36",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2020",115,,,"1545,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2021",118,,,"1552,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2022",123,,,"1559,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2023",130,,,"1566,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2024",138,,,"1575,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2025",143,,,"1582,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2026",155,,,"1596,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2027",170,,,"1636,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2028",180,,,"1691,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2029",190,,,"1749,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2030",200,,,"1811,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2031",212,,,"1890,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2032",220,,,"1945,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2033",255,,,"2174,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2034",290,,,"2407,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2035",310,,,"2536,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2036",330,,,"2666,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2037",370,,,"3314,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2038",440,,,"3394,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2039",480,,,"3660,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2040",520,,,"3922,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,1-2-3,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2041",560,,,"4186,00",byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2020",115,,,1568,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2021",118,,,1575,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2022",123,,,1583,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2023",130,,,1591,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2024",138,,,1600,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2025",143,,,1608,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2026",155,,,1624,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2027",170,,,1666,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2028",180,,,1723,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2029",190,,,1782,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2030",200,,,1845,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2031",212,,,1926,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2032",220,,,1982,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2033",255,,,2216,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2034",290,,,2454,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2035",310,,,2585,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2036",330,,,2718,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2037",370,,,3371,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2038",440,,,3461,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2039",480,,,3732,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2040",520,,,4000,byMonth151,,,,,,,
2/24/2021,5/1/2020,4,1607,"Industries des jeux, jouets, articles de fêtes et
 ornements de Noël, articles de puériculture
 et voitures d'enfants, modélisme et industries
 connexes",,,"cf. CC. n° 3130, Art. VI-1 ; cf. Avenant n° 87 du 16 juill. 2020
 (NOR : ASET2050716M) étendu par A. 20 nov. 2020 : JO
 28 nov. 2020 et prenant effet à compter du 1er mai 2041",560,,,4269,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2020",Exécution,A,,"19058,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2021",Exécution,B,,"19810,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2022",Exécution,C,,"20778,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2023",Maîtrise,D,,"21899,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2024",Maîtrise,E,,"23263,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2025",Maîtrise,F,,"24862,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2026",Maîtrise,G,,"26769,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2027",Maîtrise,H,,"29113,00",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2028",Cadre,I,,"31974,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2029",Cadre,J,,"35425,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2030",Cadre,K,,"39482,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2031",Cadre,L,,"44893,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2032",Cadre,M,,"48959,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2033",Cadre,N,,"54447,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2034",Cadre,O,,"60063,00",byYear218,,,,,,,
2/24/2021,1/1/2020,,2583,"Sociétés concessionnaires ou exploitantes
 d'autoroutes ou d'ouvrages routiers",,,"cf. CC. n° 3336, Art. 37 ; cf. CC. n° 3336, Art. 38 ; cf.
 Annexe III ; cf. Accord du 2 sept. 2020 (NOR : ASET2050820M)
 étendu par A. 11 janv. 2021 : 22 janv. 2021 et prenant effet
 rétroactivement au 1er janv. 2020 (Conclu pour l'année civile
 2035",Cadre,P,,"65679,00",byYear218,,,,,,,
2/24/2021,3/1/2019,,2717,Entreprises techniques au service de la création,,,cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018,Catégorie 1,,,"1523,00",byMonth151,,,,,,,
2/24/2021,3/2/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2019",Catégorie 2,,,"1614,00",byMonth151,,,,,,,
2/24/2021,3/3/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2019",Catégorie 3,,,"1827,00",byMonth151,,,,,,,
2/24/2021,3/4/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2020",Catégorie 4,,,"2043,00",byMonth151,,,,,,,
2/24/2021,3/5/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2021",Catégorie 5,,,"2257,00",byMonth151,,,,,,,
2/24/2021,3/6/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2022",Catégorie 6,,,"2365,00",byMonth151,,,,,,,
2/24/2021,3/7/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2023",Catégorie 7,,,"2580,00",byMonth151,,,,,,,
2/24/2021,3/8/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2024",Catégorie 8,,,"2794,00",byMonth151,,,,,,,
2/24/2021,3/9/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2025",Catégorie 9,,,"3010,00",byMonth151,,,,,,,
2/24/2021,3/10/2019,,2717,Entreprises techniques au service de la création,,,"cf. Titre VII ; cf. Avenant n° 14 du 14 mars 2018
 (ASET1850563M) étendu par A. 21 déc. 2018 : JO 27 déc.
 2018 ; cf. Avenant n° 16 du 8 mars 2019 (NOR :
 ASET1950431M) non étendu et applicable au 1er mars 2026",Catégorie 10,,,"3214,00",byMonth151,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2020","Ouvriers, employés","1, niveau
 A",,"19722,76",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2021","Ouvriers, employés","1, niveau
 B",,"20094,11",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2022","Ouvriers, employés","2, niveau
 A",,"20551,54",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2023","Ouvriers, employés","2, niveau
 B",,"21101,68",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2024","Ouvriers, employés","3, niveau
 A",,"21755,59",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2025","Ouvriers, employés","3, niveau
 B",,"22517,33",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2026","Ouvriers, employés","4, niveau
 A",,"23395,12",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2027","Ouvriers, employés","4, niveau
 B",,"24400,87",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2028","Agents de maîtrise,
 techniciens","5, niveau
 A",,"25547,57",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2029","Agents de maîtrise,
 techniciens","5, niveau
 B",,"26850,64",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2030","Agents de maîtrise,
 techniciens","6, niveau
 A",,"28327,89",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2031","Agents de maîtrise,
 techniciens","6, niveau
 B",,"29999,39",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2032","Agents de maîtrise,
 techniciens","7, niveau
 A",,"31888,84",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2033",Cadres,8,,"36441,35",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2034",Cadres,9,,"43729,13",byYear1 593,,,,,,,
2/24/2021,7/1/2020,,2728,"Sucreries, sucreries-distilleries et raffineries de",,,"cf. Avenant n° 9 du 31 janv. 2018 (NOR : ASET1850864M)
 étendu par A. 17 janv. 2019 : JO 9 mars 2019 et en vigueur
 le 1er février 2018 ; cf. Avenant n° 10 du 7 juill. 2020 (NOR :
 ASET2050673M) non étendu et en vigueur le 1er juillet 2035",Cadres,10,,"54662,04",byYear1 593,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,I4,,"4423,18",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,I3,,"4285,21",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,I2,,"4150,38",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,I1,,"4021,82",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,H4,,"3893,26",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,H3,,"3767,84",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,H2,,"3645,55",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,H1,,"3526,40",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,G4,,"3410,38",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,G3,,"3297,50",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,G2,,"3187,75",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,G1,,"3078,01",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,F4,,"2974,53",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,F3,,"2874,19",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,F2,,"2776,99",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",CADRE,F1,,"2682,92",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",AM,E4,,"2591,99",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",AM,E3,,"2504,19",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",AM,E2,,"2419,53",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",AM,E1,,"2334,87",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Techniciens,D4,,"2256,48",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Techniciens,D3,,"2181,22",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Techniciens,D2,,"2109,11",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Techniciens,D1,,"2030,72",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,C3,,"1958,60",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,C2,,"1883,34",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,C1,,"1801,82",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,B2,,"1723,43",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,B1,,"1638,77",byYear1607,,,,,,,
2/24/2021,12/31/2017,,2847,Pôle emploi,,,"cf. CC. n° 3367, Art. 12 ; cf. Accord du 14 févr. 2014 (NOR :
 ASET1450431M) non étendu et prenant effet à compter de sa
 signature ; cf. Accord du 8 sept. 2014 (NOR : ASET1451121M)
 non étendu et prenant effet à compter de sa signature ;
 cf. Procès-verbal de désaccord du 29 févr. 2016 (NOR :
 ASET1650477M) non étendu (déposé le 7 avr. 2016) ; cf.
 Accord du 28 févr. 2017 (NOR : ASET1750334M) non étendu
 et prenant effet à compter de sa signature",Employées,A,,"1557,24",byYear1607,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",I.A,,,1622,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",I.B,,,2019,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",II.A,,,2436,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",II.B,,,2673,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",III.A,,,2880,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",III.B,,,3369,byMonth151,,,,,,,
2/24/2021,1/1/2020,,2931,Activités de marchés financiers,,,"cf. Annexe I art. G-2 ; cf. Accord du 3 déc. 2019 (NOR :
 ASET2050098M) non étendu (déposé le 30 déc. 2019)",III.C,,,4228,byMonth151,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Employé,I A (moins de 6 mois),,"18824,36",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Employé,I B (plus de 6 mois),,"19448,19",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Employé,II,,"20006,36",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Employé,III,,"20914,75",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Agent de maîtrise,IV,,"22326,58",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Agent de maîtrise,V,,"25172,13",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Agent de maîtrise,VI,,"29549,88",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Cadre,VII,,"32833,21",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Cadre,VIII,,"41588,73",byYear1607,,,,,,,
2/24/2021,5/19/2020,,2972,"Personnel sédentaire des entreprises de
 navigation",,,"cf. CC. n° 3216, Art. 5.1 ; cf. Avenant n° 1 du 7 mars 2012
 (NOR : ASET1250597M) étendu 2012 : JO 17 juill. 2012 ;",Cadre,IX,,"50344,25",byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)","60 (63 au
 1er juillet
 2020,
 accord du
 9 juill.
 2020 non
 étendu)",,,29710,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",68,,,32068,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",75,,,35369,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",80,,,37727,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",90,,,42442,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",95,,,44800,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",105,,,49516,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",115,,,54232,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",120,,,56590,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",140,,,66021,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",160,,,75453,byYear1607,,,,,,,
2/25/2021,7/1/2020,,1256,"Equipements thermiques – Entreprises de
 gestion d'équipements thermiques et de
 climatisation (Cadres, ingénieurs et assimilés)",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951350M) étendu par
 A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill.
 2019 (conclu pour une durée indéterminée) ; cf. Accord du
 9 juill. 2020 (NOR : ASET2050724M) non étendu et prenant
 effet le 1er juillet 2020 (conclu pour une durée indéterminée)",180,,,84885,byYear1607,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",215,,,"1668,72",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",225,,,"1680,17",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",235,,,"1754,92",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",245,,,"1828,10",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",255,,,"1941,38",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",265,,,"2017,21",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",275,,,"2049,21",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",285,,,"2153,71",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",295,,,"2198,71",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",305,,,"2278,12",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",315,,,"2357,54",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",325,,,"2427,61",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",335,,,"2502,35",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",345,,,"2573,84",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",355,,,"2576,72",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",405,,,"2937,62",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",455,,,"3306,76",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",505,,,"3674,36",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",555,,,"4041,95",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",605,,,"4407,99",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",655,,,"4777,13",byMonth151,,,,,,,
2/25/2021,1/1/2019,,200,Exploitations frigorifiques,,,"cf. Avenant n° 87 du 17 avr. 2018 (NOR : ASET1850668M)
 étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le
 15 mai 2018) ; cf. Avenant n° 88 du 28 mai 2019 (NOR :
 ASET1951058M) non étendu (déposé le 5 juillet 2019)",705,,,"5144,72",byMonth151,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 1,Niveau A,19896,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 1,Niveau B,20396,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 1,Niveau C,20483,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 1,Niveau D,21007,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 2,Niveau A,21024,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 2,Niveau B,21660,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 2,Niveau C,22528,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 2,Niveau D,23640,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 3,Niveau A,23688,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 3,Niveau B,24230,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 3,Niveau C,25511,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 3,Niveau D,27036,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 4,Niveau A,27086,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 4,Niveau B,27881,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 4,Niveau C,29515,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 4,Niveau D,31729,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 5,Niveau A,31775,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 5,Niveau B,33025,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 5,Niveau C,35655,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Accord du 13 févr. 2004 (NOR : ASET0450289M) étendu par
 A. 25 nov. 2004 : JO 11 déc. 2004 ; cf. Avenant n° 13 du 2 juin
 2017 (NOR : ASET1750954M) étendu par A. 26 déc. 2017 : JO
 30 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 14
 du 8 oct. 2020 (NOR : ASET2051028M) non étendu (déposé
 le 4 déc. 2020)","Ouvriers et Employé, Techniciens, Agents de maîtrise",Groupe 5,Niveau D,38840,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,I,300,"1880,70",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,I,322,"2018,62",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,I,344,"2156,54",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,366,"2294,45",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,388,"2432,37",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,410,"2570,29",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,432,"2708,21",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,454,"2846,13",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,476,"2984,04",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,II,498,"3121,96",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,520,"3259,88",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,542,"3397,80",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,564,"3535,72",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,586,"3673,63",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,608,"3811,55",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,630,"3949,47",byMonth152,,,,,,,
2/25/2021,1/1/2020,,1170,Industrie des tuiles et briques,,,"cf. Avenant n° 51 du 23 mai 2014 (NOR : ASET1451009M)
 étendu par A. 5 janv. 2015 ; JO 10 janv. 2015 (déposé le
 31 juillet 2014) ; cf. Avenant n° 52 du 8 oct. 2020 (NOR :
 ASET2051027M) non étendu (déposé le 4 déc. 2020)",cadre,III,652,"4087,39",byMonth152,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2012",216,,,"1402,95",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2013",218,,,"1415,08",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2014",219,,,"1422,66",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2015",220,,,"1428,73",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2016",223,,,"1448,45",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2017",224,,,"1454,52",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2018",226,,,"1468,17",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2019",228,,,"1480,30",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2020",230,,,"1493,95",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2021",234,,,"1519,73",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2022",239,,,"1551,58",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2023",245,,,"1591,02",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2024",256,,,"1662,30",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2025",267,,,"1733,59",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2026",281,,,"1824,59",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2027",311,,,"2018,73",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2028",318,,,"2064,23",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2029",351,,,"2279,60",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2030",363,,,"2355,95",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2031",375,,,"2434,30",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2032",423,,,"2746,74",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2033",435,,,"2824,10",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2034",544,,,"3532,39",byMonth151,,,,,,,
2/25/2021,1/1/2012,,673,Fourrure,,,"cf. CC. n° 3067, Art. 30 ; cf. Avenant n° 69 du 27 juill. 2009
 (NOR : ASET1050039M) étendu par A. 4 mars 2010 : JO
 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant n° 70 du
 27 juill. 2009 (NOR : ASET1050042M) étendu par A. 4 mars
 2010 : JO 13 mars 2010 (NOR : MTST1006442A) ; cf. Avenant
 n° 71 du 10 mars 2011 (NOR : ASET1150944M) étendu par
 A. 20 oct. 2011 : JO 27 oct. 2011 ; cf. Avenant n° 72 du
 10 mars 2011 (NOR : ASET1150943M) étendu par A. 20 oct.
 2011 : JO 27 oct. 2011 ; cf. Avenant n° 73 du 20 mars 2012
 (ASET1251050M) étendu par A. 29 oct. 2012 : JO 6 nov. 2012 ;
 cf. Avenant n° 74 du 20 mars 2012 (ASET1251051M) étendu
 par A. 29 oct. 2012 : JO 6 nov. 2035",629,,,"4084,47",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2016",100,,,"1466,62",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2017",125,,,"1474,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2018",135,,,"1477,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2019",150,,,"1480,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2020",160,,,"1486,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2021",175,,,"1518,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2022",200,,,"1560,00",byMonth151,,,,,,,
2/25/2021,5/1/2016,,1543,Boyauderie,,,"cf. Avenant n° 20 du 14 avr. 2016 (NOR : ASET1650630M)
 étendu par A. 22 juill. 2016 : JO 29 juill. 2016, applicable au
 1er mai 2023",220,,,"1640,00",byMonth151,,,,,,,
2/25/2021,11/1/2019,,1563,"Presse hebdomadaire régionale d'information
 (Cadres)",,,"cf. Accord du 30 juin 1999, étendu par A. 17 nov. 1999 :
 JO du 23 nov. 1999, applicable à compter de sa signature.
 Accord d'application directe pour les entreprises répondant
 aux conditions légales et qui mettront en œuvre la RTT : pour
 les entreprises ayant plus de 20 et jusqu'à 49 salariés, durant
 les 3 mois courant à compter de la publication au JO de
 l'arrêté d'extension de l'accord ; pour les entreprises ayant
 jusqu'à 20 salariés, avant le 31 octobre 2001 ; à défaut
 d'application directe, le présent accord pourra
 donner lieu à un accord d'entreprise avec les
 représentants du personnel ou les salariés
 mandatés par des syndicats (Dispositions exclues de
 l'extension, arrêté du 17 nov. 1999). Accord inapplicable aux
 VRP statutaires. ; cf. Protocole d'accord du 7 nov. 2019 (NOR :
 ASET2050095M) non étendu et applicable au 1er nov. 2019",Niveau I. – Cadre opérationnel,cadre opérationnel IA,158,"2036,62",byMonth151,,,,,,,
2/25/2021,11/2/2019,,1563,"Presse hebdomadaire régionale d'information
 (Cadres)",,,"cf. Accord du 30 juin 1999, étendu par A. 17 nov. 1999 :
 JO du 23 nov. 1999, applicable à compter de sa signature.
 Accord d'application directe pour les entreprises répondant
 aux conditions légales et qui mettront en œuvre la RTT : pour
 les entreprises ayant plus de 20 et jusqu'à 49 salariés, durant
 les 3 mois courant à compter de la publication au JO de
 l'arrêté d'extension de l'accord ; pour les entreprises ayant
 jusqu'à 20 salariés, avant le 31 octobre 2001 ; à défaut
 d'application directe, le présent accord pourra
 donner lieu à un accord d'entreprise avec les
 représentants du personnel ou les salariés
 mandatés par des syndicats (Dispositions exclues de
 l'extension, arrêté du 17 nov. 1999). Accord inapplicable aux
 VRP statutaires. ; cf. Protocole d'accord du 7 nov. 2019 (NOR :
 ASET2050095M) non étendu et applicable au 1er nov. 2020",Niveau I. – Cadre opérationnel,cadre opérationnel IB,170,"2191,3",byMonth151,,,,,,,
2/25/2021,11/3/2019,,1563,"Presse hebdomadaire régionale d'information
 (Cadres)",,,"cf. Accord du 30 juin 1999, étendu par A. 17 nov. 1999 :
 JO du 23 nov. 1999, applicable à compter de sa signature.
 Accord d'application directe pour les entreprises répondant
 aux conditions légales et qui mettront en œuvre la RTT : pour
 les entreprises ayant plus de 20 et jusqu'à 49 salariés, durant
 les 3 mois courant à compter de la publication au JO de
 l'arrêté d'extension de l'accord ; pour les entreprises ayant
 jusqu'à 20 salariés, avant le 31 octobre 2001 ; à défaut
 d'application directe, le présent accord pourra
 donner lieu à un accord d'entreprise avec les
 représentants du personnel ou les salariés
 mandatés par des syndicats (Dispositions exclues de
 l'extension, arrêté du 17 nov. 1999). Accord inapplicable aux
 VRP statutaires. ; cf. Protocole d'accord du 7 nov. 2019 (NOR :
 ASET2050095M) non étendu et applicable au 1er nov. 2021",Niveau II. – Cadre de direction,,190,"2449,1",byMonth151,,,,,,,
2/25/2021,11/4/2019,,1563,"Presse hebdomadaire régionale d'information
 (Cadres)",,,"cf. Accord du 30 juin 1999, étendu par A. 17 nov. 1999 :
 JO du 23 nov. 1999, applicable à compter de sa signature.
 Accord d'application directe pour les entreprises répondant
 aux conditions légales et qui mettront en œuvre la RTT : pour
 les entreprises ayant plus de 20 et jusqu'à 49 salariés, durant
 les 3 mois courant à compter de la publication au JO de
 l'arrêté d'extension de l'accord ; pour les entreprises ayant
 jusqu'à 20 salariés, avant le 31 octobre 2001 ; à défaut
 d'application directe, le présent accord pourra
 donner lieu à un accord d'entreprise avec les
 représentants du personnel ou les salariés
 mandatés par des syndicats (Dispositions exclues de
 l'extension, arrêté du 17 nov. 1999). Accord inapplicable aux
 VRP statutaires. ; cf. Protocole d'accord du 7 nov. 2019 (NOR :
 ASET2050095M) non étendu et applicable au 1er nov. 2022",Niveau III. – Cadre dirigeant,,210,"2706,9",byMonth151,,,,,,,
2/25/2021,1/1/2020,1,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",hors barreaux de Paris et d'Ile-de-France,,,26066,byYear218,,,,,,,
2/25/2021,1/1/2020,2,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",hors barreaux de Paris et d'Ile-de-France,,,28229,byYear218,,,,,,,
2/25/2021,1/1/2020,3,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",hors barreaux de Paris et d'Ile-de-France,,,31319,byYear218,,,,,,,
2/25/2021,1/1/2020,4,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",hors barreaux de Paris et d'Ile-de-France,,,35172,byYear218,,,,,,,
2/25/2021,1/1/2020,1,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",Barreaux de Paris et d'Ile-de-France,,,28177,byYear218,,,,,,,
2/25/2021,1/1/2020,2,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",Barreaux de Paris et d'Ile-de-France,,,30856,byYear218,,,,,,,
2/25/2021,1/1/2020,3,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",Barreaux de Paris et d'Ile-de-France,,,35327,byYear218,,,,,,,
2/25/2021,1/1/2020,4,1850,Avocats salariés (Cabinets d'avocats),,,"cf. CC. n° 3078-2, Art. 4.1 à 4.3 ; cf. Avenant RTT n° 7 du 7 avr.
 2000 (NOR : ASET0050350M) étendu par A. 25 avr. 2001 :
 JO 5 mai 2001 ; cf. Avenant n° 17 du 27 janv. 2017 (NOR :
 ASET1750466M) étendu par A. 3 août 2017 : JO 11 août
 2017 (déposé le 26 avril 2017) ; cf. Avenant n° 23 du 29 nov.
 2019 (NOR : ASET2050286M) étendu par A. 11 janv. 2021 :
 JO 22 janv. 2021 et applicable pour les cabinets d'avocats
 membres d'une organisation employeur signataire du présent
 avenant, au 1er janvier 2020, et, pour les cabinets d'avocats
 non-membres d'une organisation « employeur » signataire, au
 1er jour du mois civil suivant la publication de son extension",Barreaux de Paris et d'Ile-de-France,,,39643,byYear218,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 1,,,1564,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 2,,,1579,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 3,,,1641,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 4,,,1803,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 5,,,2007,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 6,,,2575,byMonth151,,,,,,,
2/25/2021,4/1/2020,,2021,Golf,,,"cf. Avenant n° 78 du 20 janv. 2020 (NOR : ASET2050264M)
 étendu par A. 10 juill. 2020 : JO 11 août 2020 et applicable
 dès la date de publication de son extension ; cf. Avenant n
 ° 79 du 20 janv. 2020 (NOR : ASET2050265M) étendu par A",Groupe 7,,,3046,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2020","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 B",,1602,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2021","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 C","échel.
 1",1667,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2022","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 C","échel.
 2",1776,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2023","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 C","échel.
 3",1886,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2024","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 D","échel.
 1",2007,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2025","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 D","échel.
 2",2117,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2026","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 AM","échel.
 1",2403,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2027","ouvriers filière exploitation titulaire d'au moins
 2 CQP d'ouvrier docker","Niv.
 AM","échel.
 2",2549,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2028",autres salariés,Niv. B,,1554,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2029",autres salariés,Niv. C,"échel.
 1",1575,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2030",autres salariés,Niv. C,"échel.
 2",1685,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2031",autres salariés,Niv. C,"échel.
 3",1792,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2032",autres salariés,"Niv.
 D","échel.
 1",1892,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2033",autres salariés,"Niv.
 D","échel.
 2",2002,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2034",autres salariés,Niv. AM,"échel.
 1",2266,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2035",autres salariés,Niv. AM,"échel.
 2",2411,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2036",autres salariés,Cadre,"échel.
 1",2411,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2037",autres salariés,Cadre,"échel.
 2",2676,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2038",autres salariés,Cadre,"échel.
 3",3227,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2039",autres salariés,"Niv.
 B",,1662,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2040",autres salariés,"Niv.
 C","échel.
 1",1678,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2041",autres salariés,"Niv.
 C","échel.
 2",1702,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2042",autres salariés,"Niv.
 C","échel.
 3",1745,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2043",autres salariés,"Niv.
 D","échel.
 1",1786,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2044",autres salariés,"Niv.
 D","échel.
 2",1829,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2045",autres salariés,"Niv.
 AM","échel.
 1",1911,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2046",autres salariés,"Niv.
 AM","échel.
 2",2245,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2047",autres salariés,"Niv.
 AM","échel.
 3",2296,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2048",autres salariés,Cadre,"échel.
 1",2870,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2049",autres salariés,Cadre,"échel.
 2",3348,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2050",autres salariés,Cadre,"échel.
 3",3728,byMonth151,,,,,,,
2/25/2021,1/1/2020,,3017,Ports et manutention,,,"cf. CC. n° 0000-23, Art. 5 ; cf. Avenant n° 2 du 13 sept. 2012
 (NOR : ASET1750620M) non étendu (déposé le 14 juin 2017) ;
 cf. Avenant n° 11 du 11 déc. 2018 (NOR : ASET1950702M)
 étendu par A. 23 déc. 2019 : JO 27 déc. 2019 et applicable à
 compter du 1er janv. 2019 ; cf. Avenant n° 12 du 30 juin 2020
 (NOR : ASET2050959M) non étendu et applicable à compter
 du 1er Janvier 2051",autres salariés,Cadre,"échel.
 4",5166,byMonth151,,,,,,,
2/25/2021,1/1/2020,,1679,Inspection d’assurance ,,,"cf. CC. n° 3267, Art. 29 ; cf. CC. n° 3267, Art. 30 ; cf. CC. n° 3267, Art. 32 ; ; cf. Accord du 14 mai 2019 (NOR :
 ASET1951056M) étendu et applicable au 1er janv. 20179 ; cf. Protocole d'accord du 19 mai 2020 (NOR :
 ASET2050548M) non étendu et applicable au 1er janv. 2020",5,,,32090,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1679,Inspection d’assurance ,,,"cf. CC. n° 3267, Art. 29 ; cf. CC. n° 3267, Art. 30 ; cf. CC. n° 3267, Art. 32 ; ; cf. Accord du 14 mai 2019 (NOR :
 ASET1951056M) étendu et applicable au 1er janv. 20179 ; cf. Protocole d'accord du 19 mai 2020 (NOR :
 ASET2050548M) non étendu et applicable au 1er janv. 2021",6,,,42040,byYear1600,,,,,,,
2/25/2021,1/1/2020,,1679,Inspection d’assurance ,,,"cf. CC. n° 3267, Art. 29 ; cf. CC. n° 3267, Art. 30 ; cf. CC. n° 3267, Art. 32 ; ; cf. Accord du 14 mai 2019 (NOR :
 ASET1951056M) étendu et applicable au 1er janv. 20179 ; cf. Protocole d'accord du 19 mai 2020 (NOR :
 ASET2050548M) non étendu et applicable au 1er janv. 2022",7,,,58640,byYear1600,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2019",Niveau I,Echelon 1,300,"1603,32",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2020",Niveau I,Echelon 2,310,"1656,76",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2021",Niveau I,Echelon 3,320,"1710,21",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2022",Niveau II,Echelon 1,331,"1769,00",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2023",Niveau II,Echelon 2,341,"1822,44",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2024",Niveau II,Echelon 3,351,"1875,88",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2025",Niveau III,Echelon 1,372,"1988,12",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2026",Niveau III,Echelon 2,393,"2100,35",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2027",Niveau III,Echelon 3,413,"2207,24",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2028",Niveau IV,Echelon 1,434,"2319,47",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2029",Niveau IV,Echelon 2,475,"2538,59",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2030",Niveau IV,Echelon 3,517,"2763,05",byMonth151,,,,,,,
2/26/2021,3/1/2019,,240,Greffes des tribunaux de commerce (Personnel,,,"cf. CC. n° 0000-38, Art. 28 remplacé par avenant n° 77
 du 11 janv. 2013 (NOR : ASET1350347M) non étendu et
 en vigueur le 1er mai 2013 ; cf. Avenant n° 89 du 7 mars
 2018 (NOR : ASET1850516M) non étendu et applicable au
 1er mars 2018 ; cf. Avenant n° 92 du 12 mars 2019 (NOR :
 ASET1950601M) non étendu et applicable avec effet au
 1er mars 2031",Niveau IV,"Echelon 4 (Greffier
 salarié)",660,"3527,30",byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Ouvriers,10,,1515,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Ouvriers,10.3,,1535,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Ouvriers,10.7,,1616,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Employés,20,,1530,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Employés,20.3,,1610,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Employés,20.7,,1680,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension","Agents de
 maîtrise",30,,1828,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension","Agents de
 maîtrise",30.5,,2044,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Cadres,40,,2100,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Cadres,40.3,,2654,byMonth151,,,,,,,
2/26/2021,7/1/2019,,706,Personnel de la reprographie,,,"cf. Accord du 14 avr. 2016 (NOR : ASET1650548M) étendu par
 A. 4 juill. 2016 : JO 12 juill. 2016 et applicable à compter du
 1er jour du mois suivant son extension ; cf. Accord du 12 févr.
 2018 (NOR : ASET1850365M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 et applicable à compter du 1er jour du mois suivant
 son extension",Cadres,40.5,,3311,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A1,,,"1554,58",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A2,,,"1628,03",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A3,,,"1737,41",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A4,,,"1917,67",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A5,,,"2099,97",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A6,,,"2334,02",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A7,,,"2545,49",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A8,,,"2746,02",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A9,,,"2910,09",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1588,Sociétés coopératives d'H.L.M. (Personnel),,,"cf. CC. n° 3191, Art. 20 remplacé par l’avenant n° 11 du 14 mai
 2012 (NOR : ASET1350175M) non étendu et applicable à partir
 de sa date de signature, modifié par avenant n° 15 du 8 juin
 2015 (NOR : ASET1550939M) non étendu et applicable à partir
 de sa date de signature ; cf. CC. n° 3191, Art. 21 cf. Accord du
 1er déc. 2016 (NOR : ASET1750163M) non étendu (déposé le
 15 févr. 2017) ; cf. Protocole d'accord du 8 déc. 2017 (NOR :
 ASET1850303M) non étendu",A10,,,"3183,55",byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I","Pilotes d'essais Expérimentaux (FTR1 ou
 PEX)",1er échelon,,4981,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I","Pilotes d'essais Expérimentaux (FTR1 ou
 PEX)",2e échelon,,5478,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I","Pilotes d'essais Expérimentaux (FTR1 ou
 PEX)",3e échelon,,6228,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I","Pilotes d'essais Expérimentaux (FTR1 ou
 PEX)",4e échelon,,6973,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I","Pilotes d'essais Expérimentaux (FTR1 ou
 PEX)",5e échelon,,7469,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",Pilotes d'essais (FTR2 ou PE),1er échelon,,3990,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",Pilotes d'essais (FTR2 ou PE),2e échelon,,4390,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",Pilotes d'essais (FTR2 ou PE),3e échelon,,4945,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",Pilotes d'essais (FTR2 ou PE),4e échelon,,5584,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",Pilotes d'essais (FTR2 ou PE),5e échelon,,5982,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 * ou INE-A),1er échelon,,4661,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 * ou INE-A),2e échelon,,5359,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 * ou INE-A),3e échelon,,6059,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 * ou INE-A),4e échelon,,6757,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 * ou INE-A),5e échelon,,7219,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-2 ou MNR ou ENE-B),1er échelon,,3117,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-2 ou MNR ou ENE-B),2e échelon,,3583,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-2 ou MNR ou ENE-B),3e échelon,,4051,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-2 ou MNR ou ENE-B),4e échelon,,4516,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-2 ou MNR ou ENE-B),5e échelon,,4828,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 – MNE ou ENA-A),1er échelon,,3155,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 – MNE ou ENA-A),2e échelon,,3630,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 – MNE ou ENA-A),3e échelon,,4102,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 – MNE ou ENA-A),4e échelon,,4585,byMonth151,,,,,,,
2/26/2021,1/1/2018,,1612,"Travail aérien (Personnel navigant des essais et
 réceptions)",,,"cf. CC. n° 3259, Art. 19 modifié par avenant n° 6 du 1er fév.
 2012 (NOR : ASET1250603M), non étendu et par avenant n
 ° 7 du 15 mai 2018 (NOR : ASET1850931M) non étendu ; cf.
 Annexe I",(LFTE-1 – MNE ou ENA-A),5e échelon,,4886,byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2020",128,,,"1521,25",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2021",131,,,"1522,46",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2022",135,,,"1523,37",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2023",140,,,"1524,89",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2024",145,,,"1526,41",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2025",150,,,"1529,59",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2026",155,,,"1534,29",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2027",158,,,"1537,33",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2028",160,,,"1542,03",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2029",166,,,"1545,06",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2030",168,,,"1548,25",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2031",170,,,"1552,95",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2032",175,,,"1582,52",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2033",181,,,"1624,69",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2034",185,,,"1655,78",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2035",196,,,"1727,52",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2036",200,,,"1758,77",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2037",203,,,"1774,39",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2038",210,,,"1808,66",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2039",215,,,"1842,94",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2040",221,,,"1875,70",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2041",225,,,"1908,46",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2042",230,,,"1941,22",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2043",240,,,"2008,26",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2044",250,,,"2072,12",byMonth151,,,,,,,
2/26/2021,4/1/2019,,1618,Camping,,,"cf. Accord n° S 36 du 5 févr. 2019 (NOR : ASET1950935M)
 étendu par A. 17 févr. 2020 : JO 22 févr. 2045",270,,,"2187,54",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2017",120,,,"1510,63",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2018",125,,,"1542,48",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2019",130,,,"1551,58",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2020",140,,,"1560,68",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2021",150,,,"1619,84",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2022",160,,,"1650,17",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2023",170,,,"1683,54",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2024",190,,,"1754,82",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2025",240,,,"1829,24",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2026",270,,,"2010,89",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2027",300,,,"2137,63",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2028",400,,,"2669,93",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2029",500,,,"3266,65",byMonth151,,,,,,,
2/26/2021,4/1/2017,,1659,Rouissage-teillage de lin,,,"cf. Avenant n° 29 du 2 févr. 2017 (NOR : ASET1850249M)°
 étendu par A. 30 juill. 2018 : JO 28 août 2018 et applicable au
 1er février 2030",600,,,"3785,22",byMonth151,,,,,,,
2/26/2021,1/1/2019,1,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH/CPL (H),,,3053,byMonth151,,,,,,,
2/26/2021,1/1/2019,2,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH/CPL (H),,,3114,byMonth151,,,,,,,
2/26/2021,1/1/2019,3,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH/CPL (H),,,3145,byMonth151,,,,,,,
2/26/2021,1/1/2019,4,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH/CPL (H),,,3187,byMonth151,,,,,,,
2/26/2021,1/1/2019,1,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH IFR/CPL (H) IR,,,3724,byMonth151,,,,,,,
2/26/2021,1/1/2019,2,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH IFR/CPL (H) IR,,,3798,byMonth151,,,,,,,
2/26/2021,1/1/2019,3,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH IFR/CPL (H) IR,,,3835,byMonth151,,,,,,,
2/26/2021,1/1/2019,4,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PPH IFR/CPL (H) IR,,,3873,byMonth151,,,,,,,
2/26/2021,1/1/2019,1,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PLH/ATPL (H),,,4762,byMonth151,,,,,,,
2/26/2021,1/1/2019,2,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PLH/ATPL (H),,,4857,byMonth151,,,,,,,
2/26/2021,1/1/2019,3,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PLH/ATPL (H),,,4905,byMonth151,,,,,,,
2/26/2021,1/1/2019,4,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Avenant n° 19 du 15 avr. 2018 (NOR : ASET1850704M)
 étendu par A. 2 janv. 2019 : JO 10 janv. 2019 et applicable,
 dès sa signature, aux entreprises adhérentes à l'organisation
 professionnelle d'employeurs signataire, 1 jour franc suivant la
 publication de son extension aux entreprises non adhérentes
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 20
 du 11 déc. 2018 (NOR : ASET1950248M) étendu par A.
 27 août 2019 : JO 3 sept. 2019 et applicable, dès sa signature,
 aux entreprises adhérentes à l'organisation professionnelle
 d'employeurs signataire, 1 jour franc suivant la publication de
 son extension aux entreprises non adhérentes (Conclu pour
 une durée indéterminée) ; cf. Avenant n° 21 du 5 juill. 2019
 (NOR : ASET1951194M) non étendu (déposé le 7 août 2019)",PLH/ATPL (H),,,4954,byMonth151,,,,,,,
2/26/2021,6/1/2019,1,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951193M) étendu par
 A. 18 sept. 2020 : JO 7 oct. 2020 (déposé le 7 août 2019)
 L’accord du 5 juill. 2019 s'inscrit dans le nouveau cadre législatif
 institué par A. 2 oct. 2018 : JO 9. oct. 2018 modifiant
 l'A. 29 sept. 2015 relatif aux membres d'équipage technique
 des opérations d'hélitreuillage et des opérations du service
 médical d'urgence par hélicoptère. Depuis cette date, les
 membres d'équipage technique des opérations du service
 médical d'urgence par hélicoptère, ou technical crew member
 (TCM), ci-après désignés MET/TCM, sont intégrés dans la CCN
 du personnel navigant technique des exploitants d'hélicoptères",MET/TCM,,,1522,byMonth151,,,,,,,
2/26/2021,6/1/2019,2,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951193M) étendu par
 A. 18 sept. 2020 : JO 7 oct. 2020 (déposé le 7 août 2019)
 L’accord du 5 juill. 2019 s'inscrit dans le nouveau cadre législatif
 institué par A. 2 oct. 2018 : JO 9. oct. 2018 modifiant
 l'A. 29 sept. 2015 relatif aux membres d'équipage technique
 des opérations d'hélitreuillage et des opérations du service
 médical d'urgence par hélicoptère. Depuis cette date, les
 membres d'équipage technique des opérations du service
 médical d'urgence par hélicoptère, ou technical crew member
 (TCM), ci-après désignés MET/TCM, sont intégrés dans la CCN
 du personnel navigant technique des exploitants d'hélicoptères",MET/TCM,,,1552,byMonth151,,,,,,,
2/26/2021,6/1/2019,3,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951193M) étendu par
 A. 18 sept. 2020 : JO 7 oct. 2020 (déposé le 7 août 2019)
 L’accord du 5 juill. 2019 s'inscrit dans le nouveau cadre législatif
 institué par A. 2 oct. 2018 : JO 9. oct. 2018 modifiant
 l'A. 29 sept. 2015 relatif aux membres d'équipage technique
 des opérations d'hélitreuillage et des opérations du service
 médical d'urgence par hélicoptère. Depuis cette date, les
 membres d'équipage technique des opérations du service
 médical d'urgence par hélicoptère, ou technical crew member
 (TCM), ci-après désignés MET/TCM, sont intégrés dans la CCN
 du personnel navigant technique des exploitants d'hélicoptères",MET/TCM,,,1567,byMonth151,,,,,,,
2/26/2021,6/1/2019,4,1944,"Personnel navigant technique des exploitants
 d'hélicoptères",,,"cf. Accord du 5 juill. 2019 (NOR : ASET1951193M) étendu par
 A. 18 sept. 2020 : JO 7 oct. 2020 (déposé le 7 août 2019)
 L’accord du 5 juill. 2019 s'inscrit dans le nouveau cadre législatif
 institué par A. 2 oct. 2018 : JO 9. oct. 2018 modifiant
 l'A. 29 sept. 2015 relatif aux membres d'équipage technique
 des opérations d'hélitreuillage et des opérations du service
 médical d'urgence par hélicoptère. Depuis cette date, les
 membres d'équipage technique des opérations du service
 médical d'urgence par hélicoptère, ou technical crew member
 (TCM), ci-après désignés MET/TCM, sont intégrés dans la CCN
 du personnel navigant technique des exploitants d'hélicoptères",MET/TCM,,,1582,byMonth151,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",135,Au 1er janv. 2019,,"10,16",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",140,,,"10,2",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",145,,,"10,25",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",150,,,"10,29",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",155,,,"10,33",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",160,,,"10,38",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",165,,,"10,42",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",170,,,"10,46",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",175,,,"10,51",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",180,,,"10,55",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",185,,,"10,59",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",190,,,"10,63",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",195,,,"10,75",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",200,,,"10,93",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",210,,,"11,34",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",220,,,"11,72",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",230,,,"12,13",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",240,,,"12,51",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",250,,,"12,91",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",260,,,"13,26",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",270,,,"13,65",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",280,,,"14,02",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",290,,,"14,4",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",300,,,"14,78",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",310,,,"15,17",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",320,,,"15,55",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",330,,,"15,93",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",340,,,"16,33",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",350,,,"16,71",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",400,,,"18,65",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",500,,,"22,55",byHour,,,,,,,
2/26/2021,1/1/2019,,1987,"Pâtes alimentaires sèches et couscous non
 préparé",,,"cf. CC. n° 3294, Art. 58 ; cf. Avenant n° 2017-01 du 19 déc.
 2017 (NOR : ASET1850494M) étendu par A. 29 mai 2019 : JO
 4 juin 2019 (Dispositions impératives) ; cf. Avenant n° 2018-02
 du 6 déc. 2018 (NOR : ASET1950701M) étendu par A. 23 déc.
 2019 : JO 27 déc. 2019 (Dispositions impératives)",600,,,"26,43",byHour,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature",Agent exécution 1,,,1522,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature",Agent exécution 2,,,1534,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature",Agent qualifié,,,1546,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature","Agent thermal CQP
 branche",,,1561,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature","Agent hautement
 qualifié",,,1641,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature","Agent de maîtrise
 1",,,1671,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature","Agent de maîtrise
 2",,,1741,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature","Agent de maîtrise
 3",,,1811,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature",Cadre 1,,,2500,byMonth151,,,,,,,
2/26/2021,5/1/2019,,2104,Thermalisme,,,"Cf. Titre XI, art. C. ; cf. Avenant n° 30 du 21 juin 2018 (NOR :
 ASET1851134M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019
 et prenant effet à compter du 1er août 2018 ; cf. Avenant n
 ° 31 du 16 avr. 2019 (NOR : ASET1951089M) non étendu et
 prenant effet à compter du 1er jour du mois suivant la date de
 signature",Cadre 2,,,2880,byMonth151,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,I,,"1800,9",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,II,,"1813,08",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,III,,"1844,4",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,IV,,"1882,68",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,V,,"1917,48",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,VI,,"2011,44",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,VII,,"2063,64",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,VIII,,"2131,5",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,IX,,"2256,78",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,X,,"2394,24",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,XI,,"2550,84",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",sans certificat de la branche,XII,,"2716,14",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,I,,"1854,84",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,II,,"1867,02",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,III,,"1900,08",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,IV,,"1938,36",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,V,,"1944,04",byMonth174,,,,,,,
14/04/2021,25/1/2021,,2111,Salariés du particulier employeur,,,"cf. Avenant n° S 41 du 9 janv. 2019 (NOR : ASET1950503M)
 étendu par A. 2 août 2019 : JO 9 août 2019 et applicable le 1er
 jour du mois suivant la date de publication de son extension ;
 cf. Avenant n° S 42 du 14 févr. 2020 (NOR : ASET2050492M)
 étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable
 le 1er jour calendaire du mois suivant la date de parution de
 son extension",avec certificat de la branche,VI,,"2091,48",byMonth174,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2016",6 à 8,C 1,,21411,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2017",9 à 11,C 2,,22267,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2018",12 à 14,C 3,,23158,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2019",15 à 17,C 4,,24084,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2020",18 à 20,C 5,,25048,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2021",21 à 23,C 6,,26300,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2022",24 à 26,C 7,,27615,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2023",27 à 29,C 8,,28996,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2024",30 à 32,C 9,,30446,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2025",33 à 35,C 10,,31968,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2026",36 à 38,C 11,,35165,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2027",39 à 41,C 12,,38681,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2028",42 à 44,C 13,,42550,byYear1600,,,,,,,
2/26/2021,7/1/2018,,2526,Organisations professionnelles de l'habitat social,,,"cf. CC. n° 3330, Art. 12 remplacé par avenant n° 18 du 20 sept. 2017 (NOR : ASET1750982M) étendu par A.
 16 oct. 2019 : JO 23 oct. 2019 ; cf. Avenant n° 16 du 1er déc. 2015 (NOR : ASET1650267M) étendu par A. 3 mai
 2016 : JO 11 mai 2029",45 à 48,C 14,,46804,byYear1600,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",Non-cadres et cadres intégrés,Elève non-cadre,1,2015,byMonth151,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",Non-cadres et cadres intégrés,Cadre débutant,2,2325,byMonth151,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",Non-cadres et cadres intégrés,Cadre confirmé A,3,2790,byMonth151,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",Non-cadres et cadres intégrés,Cadre confirmé B,4,3255,byMonth151,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",Non-cadres et cadres intégrés,Cadre spécialisé,5,3720,byMonth151,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",cadre autonomes,Elève non-cadre,1,2015,byYear216,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",cadre autonomes,Cadre débutant,2,2325,byYear216,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",cadre autonomes,Cadre confirmé A,3,2790,byYear216,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",cadre autonomes,Cadre confirmé B,4,3255,byYear216,,,,,,,
2/26/2021,1/1/2020,,2564,Vétérinaires praticiens salariés,,,"cf. CC. n° 3332, Art. 53 ; cf. Annexe II ; cf. Avenant n° 47 du
 10 oct. 2018 (NOR : ASET1950002M) étendu par A. 10 mai
 2019 : JO 17 mai 2019 ; cf. Avenant n° 48 du 22 nov. 2019
 (NOR : ASET2050102M) étendu par A. 7 déc. 2020 : JO 15 déc.
 2020 (déposé le 5 janv. 2020)",cadre autonomes,Cadre spécialisé,5,3720,byYear216,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,A,,18614,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,B,,18852,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,C,,19183,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,D,,20569,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,E,,21541,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,F,,23496,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Techniciens,G,,26039,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",cadre,H,,28722,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",cadre,I,,35093,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",cadre,J,,42399,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",cadre,K,,50447,byYear1600,,,,,,,
2/26/2021,2/1/2016,,2622,Crédit maritime mutuel,,,"cf. CC. n°3342, Art. 39 ; cf. CC. n°3342, Art. 40 ; cf. CC. n
 °3342, Art. 41 modifié par avenant n° 15 du 12 oct. 2011 (NOR :
 ASET1250783M), non étendu ; cf. Avenant n° 14 du 12 oct.
 2011 (NOR : ASET1250781M) non étendu ; cf. Accord du 14
 janv. 2016 (NOR : ASET1650298M) non étendu ; cf. Avenant n
 ° 17 du 5 oct. 2016 (NOR : ASET1651064M) non étendu",Cadre,hors classification,,55000,byYear1600,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A1,1519,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A2a,1562,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A2b,1682,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A2c,1724,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A3a,1768,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A3b,1872,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – Employé,A3c,1980,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – TAM,A4a,2039,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – TAM,A4b,2148,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière administrative. – TAM,A4c,2495,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T2a,1562,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T2b,1671,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T2c,1724,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T3a,1784,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T3b,1872,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T3c,2039,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – Employé,T3d,2148,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – TAM,T4a,2365,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – TAM,T4b,2538,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière technique. – TAM,T4c,2826,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière collaborateur (EMP/ETAM,C2b,1821,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière collaborateur (EMP/ETAM,C3a,2168,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière collaborateur (EMP/ETAM,C3b,2495,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière stagiaires,S2b,1843,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière stagiaires,S3a,2061,byMonth151,,,,,,,
2/26/2021,4/1/2019,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",non-cadres,Filière stagiaires,S3b,2061,byMonth151,,,,,,,
2/26/2021,1/1/2010,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",cadre,"Cadre collaborateur,
 niveau 1",C 4a,2740,byMonth151,,,,,,,
2/26/2021,1/1/2010,,2706,"Personnel des administrateurs et des
 mandataires judiciaires",,,"cf. CC. n° 3353, Art. 21 ; cf. Avenant n° 11 du 27 nov. 2014
 (NOR : ASET1550414M) étendu par A. 2 juill. 2015 : JO 24 juill.
 2015 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 janv. 2015) ; cf. Avenant n° 11 bis du 17 sept.
 2015 à l'avenant n° 11 du 27 nov. 2014 (NOR : ASET1550954M)
 étendu par A. 7 avr. 2016 : JO 20 avr. 2016, modifiant cet
 avenant et en vigueur dès les formalités de dépôt accomplies
 (déposé le 26 oct. 2015) (Avenant rectifiant l’erreur matérielle
 que comporte l’avenant n° 11, qui en fausse significativement
 la compréhension) ; cf. Avenant n° 13 du 29 octobre 2015
 (NOR : ASET1650070M) étendu par A. 21 mars 2016 : JO 9 avr.
 2016 et en vigueur dès les formalités de dépôt accomplies
 (déposé le 8 janv. 2016) ; cf. Avenant n° 22 du 19 déc. 2017
 (NOR : ASET1850537M) étendu par A. 21 déc. 2018 : JO
 26 déc. 2018 et applicable à compter du 1er janvier 2018
 (Conclu pour une durée indéterminée) ; cf. Avenant n° 28
 du 12 mars 2019 (NOR : ASET1950709M) non étendu et
 applicable à compter du 1er avril 2019 (conclu pour une durée
 indéterminée)",cadre,"Cadre collaborateur,
 niveau 2",C 4b,2850,byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",160,,,"1643,66",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",165,,,"1646,50",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",180,,,"1789,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",190,,,"1884,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",195,,,"1931,50",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",200,,,"1979,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",210,,,"2074,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",220,,,"2169,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",230,,,"2264,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",245,,,"2406,50",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",275,,,"2691,50",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",290,,,"2834,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",300,,,"2929,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",330,,,"3214,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",350,,,"3404,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",365,,,"3546,50",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",370,,,"3594,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",380,,,"3689,00",byMonth151,,,,,,,
2/26/2021,10/1/2020,,2785,"Commissaires-priseurs judiciaires et sociétés
 de ventes volontaires de meubles aux enchères
 publiques",,,"cf. Accord du 1er oct. 2020 (NOR : ASET2050852M) étendu
 par A. 11 janv. 2021 : JO 26 janv. 2021 (déposé le 21 oct. 2020)",450,,,"4354,00",byMonth151,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 14",Organismes de catégorie I,IV,,1296,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 15",Organismes de catégorie I,III,,996,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 16",Organismes de catégorie I,II,,896,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 17",Organismes de catégorie I,I,,816,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 22",Organismes de catégorie II,IV,,1156,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 23",Organismes de catégorie II,III,,896,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 24",Organismes de catégorie II,II,,816,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 25",Organismes de catégorie II,I,,736,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 30",Organismes de catégorie III,IV,,1056,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 31",Organismes de catégorie III,III,,816,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 32",Organismes de catégorie III,II,,736,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 33",Organismes de catégorie III,I,,676,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 38",Personnel de direction autre qu'en mission,IV,,1296,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 39",Personnel de direction autre qu'en mission,III,,1556,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 40",Personnel de direction autre qu'en mission,II,,1156,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 41",Personnel de direction autre qu'en mission,I,,1406,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 46",Personnel de direction en mission,Directeur de mission équivalent à directeur d'organisme de catégorie I,,1296,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 48",Personnel de direction en mission,Directeur de mission équivalent à directeur d'organisme de catégorie II,,1056,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 50",Personnel de direction en mission,Directeur de mission équivalent à directeur d'organisme de catégorie III,,1056,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 52",Personnel de direction en mission,"Agent de direction en mission équivalent à directeur adjoint ou agent comptable
 d'organisme de catégorie I",,996,byYear211,,,,,,,
2/26/2021,5/1/2012,,2796,"Régime Social des Indépendants (personnel de
 direction)",,,"cf. Accord du 2 juin 2009 (NOR : ASET0950980M) en vigueur
 dans les conditions de son art. 54",Personnel de direction en mission,"Agent de direction en mission équivalent à directeur adjoint ou agent comptable
 d'organisme de catégorie II",,816,byYear211,,,,,,,
2/26/2021,10/27/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agent de recherche privée base CQP,I,1,"1343,78",ByMonth151,,,,,,,
2/26/2021,10/28/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agent de recherche privée base CQP,I,2,"1516,7",ByMonth151,,,,,,,
2/26/2021,10/29/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agent de recherche privée base CQP,I,3,1744,ByMonth151,,,,,,,
2/26/2021,10/30/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agence de recherche privée base titre ou licence,II,1,"1516,7",ByMonth151,,,,,,,
2/26/2021,10/31/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agence de recherche privée base titre ou licence,II,2,1744,ByMonth151,,,,,,,
2/26/2021,11/1/2010,,2978,"Agences de recherches privées (Personnel
 salarié)",,,cf. Annexe II,Agence de recherche privée base titre ou licence,II,3,"1895,88",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2020",Niveau VI,3e échelon,,"2809,41",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2021",Niveau VI,2e échelon,,"2655,92",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2022",Niveau VI,1er échelon,,"2505,51",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2023",Niveau V,3e échelon,,"2404,19",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2024",Niveau V,2e échelon,,"2252,24",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2025",Niveau V,1er échelon,,"2150,94",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2026",Niveau IV,3e échelon,,"2049,63",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2027",Niveau IV,2e échelon,,"1897,68",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2028",Niveau IV,1er échelon,,"1847,03",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2029",Niveau III,2e échelon,,"1745,73",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2030",Niveau III,1er échelon,,"1695,08",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2031",Niveau II,2e échelon,,"1644,43",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2032",Niveau II,1er échelon,,"1593,81",ByMonth151,,,,,,,
2/26/2021,1/1/2020,,3203,"Pêche de loisir et protection du milieu
 aquatique (Structures associatives)",,,"cf. CC. n° 0000-61, Art. 9.2 ; cf. Avenant n° 9 du 29 janv. 2020
 (NOR : ASET2050345M) non étendu et applicable à compter
 du 1er janvier 2033",Niveau I,,,"1573,73",ByMonth151,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2019","Capitaines et chefs mécaniciens
 15e catégorie ENIM",,,"2566,66",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2020","Capitaines et chefs mécaniciens
 12e catégorie ENIM",,,"2211,94",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2021","Patrons de vedettes inférieures à
 50 ums",,,"1746,42",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2022",Maîtres pont et machine,,,"1693,48",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2023","Mécaniciens, ouvriers
 mécaniciens, timoniers",,,"1598,44",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2024","Matelots qualifiés, graisseurs",,,"1539,93",byYear1607,,,,,,,
2/26/2021,1/1/2019,,3228,"Groupement des armateurs de services de
 passages d'eau (personnel navigant)",,,"cf. Annexe I ; cf. Protocole d'accord du 18 févr. 2019 (NOR :
 ASET1950772M) non étendu et applicable à compter du
 1er janvier 2025","Matelots, matelots légers",,,"1522,48",byYear1607,,,,,,,
2/26/2021,6/1/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2019",100,,,"1522,76",ByMonth151,,,,,,,
2/26/2021,6/2/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2020",130,,,"1527,31",ByMonth151,,,,,,,
2/26/2021,6/3/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2021",140,,,"1544,00",ByMonth151,,,,,,,
2/26/2021,6/4/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2022",150,,,"1560,68",ByMonth151,,,,,,,
2/26/2021,6/5/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2023",160,,,"1584,95",ByMonth151,,,,,,,
2/26/2021,6/6/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2024",170,,,"1610,73",ByMonth151,,,,,,,
2/26/2021,6/7/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2025",180,,,"1641,06",ByMonth151,,,,,,,
2/26/2021,6/8/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2026",225,,,"1754,82",ByMonth151,,,,,,,
2/26/2021,6/9/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2027",250,,,"1894,35",ByMonth151,,,,,,,
2/26/2021,6/10/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2028",300,,,"2027,82",ByMonth151,,,,,,,
2/26/2021,6/11/2019,,7010,Elevages aquacoles (Personnels),,,"cf. Avenant n° 11 du 26 juin 2019 (NOR : AGRS1997180M)
 étendu par A. 24 sept. 2019 : JO 1er oct. 2029",400,,,"2291,73",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2019",Personnel hors encadrement,100,,"1521,25",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2020",Personnel hors encadrement,110,,"1539,45",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2021",Personnel hors encadrement,120,,"1563,72",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2022",Personnel hors encadrement,140,,"1589,50",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2023",Personnel hors encadrement,160,,"1610,74",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2024",Personnel hors encadrement,175,,"1744,21",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2025",Agent de maîtrise,225,,"1879,19",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2026",Personnel d'encadrement,IV,250,"1945,93",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2027",Personnel d'encadrement,III,300,"2082,43",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2028",Personnel d'encadrement,II,350,"2218,93",ByMonth151,,,,,,,
2/26/2021,4/1/2019,,7017,"Parcs et jardins zoologiques privés ouverts au
 public",,,"cf. Avenant n° 30 du 14 févr. 2019 (NOR : AGRS1997157M)
 étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et en vigueur à
 compter du 1er avr. 2029",Personnel d'encadrement,I,400,"2355,44",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,1,,"1233,76",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,2,,"1275,82",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,3,,"1317,88",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,4,,"1359,94",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,5,,"1402,00",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,6,,"1444,06",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,7,,"1486,12",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,AGENT TECHNIQUE,8,,"1528,18",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",1,"1254,79",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",2,"1296,85",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",3,"1338,91",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",4,"1380,97",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",5,"1423,03",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",6,"1465,09",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",7,"1507,15",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,"Employé de
 bureau",8,"1549,21",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,1,"1373,96",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,2,"1416,02",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,3,"1458,08",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,4,"1500,14",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,5,"1542,20",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,6,"1584,26",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,7,"1626,32",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire,8,"1668,38",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,1,"1493,13",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,2,"1535,19",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,3,"1577,25",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,4,"1619,31",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,5,"1661,37",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,6,"1703,43",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,7,"1745,49",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Secrétaire Technique,8,"1787,55",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,1,"1591,27",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,2,"1633,33",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,3,"1675,39",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,4,"1717,45",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,5,"1759,51",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,6,"1801,57",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,7,"1843,63",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ADMINISTRATIF,Responsable Administratif,8,"1885,69",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,1,"1493,13",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,2,"1577,25",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,3,"1661,37",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,4,"1745,49",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,5,"1801,57",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,6,"1843,63",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,7,"1885,69",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,TECHNICIEN,8,"1927,75",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,1,"1591,27",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,2,"1675,39",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,3,"1759,51",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,4,"1843,63",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,5,"1899,71",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,6,"1941,77",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,7,"1983,83",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur Technique,8,"2025,89",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,1,"1710,44",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,2,"1794,56",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,3,"1878,68",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,4,"1962,80",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,5,"2018,88",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,6,"2060,94",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,7,"2103,00",ByMonth151,,,,,,,
2/26/2021,1/1/2014,,7514,Réseau confédération paysanne,,,cf. Annexe II,ANIMATEUR,Animateur-coordinateur,8,"2145,06",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",120,,,"1541,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",130,,,"1553,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",150,,,"1586,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",160,,,"1631,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",180,,,"1662,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",200,,,"1698,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",220,,,"1773,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",240,,,"1786,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",260,,,"1848,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",280,,,"1925,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",325,,,"2249,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",375,,,"2598,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",450,,,"3205,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",600,,,"3421,00",ByMonth151,,,,,,,
2/26/2021,5/14/2019,,635,Convention collective du Négoce en fournitures dentaires -,,,"cf. CC. n° 3033, Annexe II ; cf. Accord du 14 mai 2019 (NOR : ASET1951037M) étendu par A. 15 janv. 2020 : JO 24 janv. 2020 et prenant effet un mois après sa publication et à compter de la date de son extension pour toutes les entreprises de la branche",800,,,"3865,00",ByMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 1,Echelon 1,"1543,00",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 1,Echelon 2,"1546,00",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 1,Echelon 3,"1551,00",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 2,Echelon 1,"1555,00",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 2,Echelon 2,"1561,00",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 2,Echelon 3,"1570,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 3,Echelon 1,"1574,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 3,Echelon 2,"1579,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 3,Echelon 3,"1588,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 4,Echelon 1,"1607,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 4,Echelon 2,"1622,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Employés-Ouvriers,Niveau 4,Echelon 3,"1638,50",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 5,Echelon 1,"1723,27",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 5,Echelon 2,"1756,53",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 5,Echelon 3,"1789,79",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 6,Echelon 1,"1857,39",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 6,Echelon 2,"1912,12",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Agent de maîtrise,Niveau 6,Echelon 3,"1967,91",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 7,Echelon 1,"2135,30",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 7,Echelon 2,"2268,36",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 7,Echelon 3,"2401,41",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 8,Echelon 1,"2668,59",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 8,Echelon 2,"2935,77",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 8,Echelon 3,"3325,28",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 9,Echelon 1,"3970,16",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 9,Echelon 2,"4358,60",byMonth151,,,,,,,
24/2/2021,1/1/2020,,2075,"Oeufs et industries en produits d'oeufs
(Centres immatriculés de conditionnement, de
commercialisation et de transformation)",,,cf. Accord du 1er  mars 2019 (NOR  : ASET1950967M) non étendu,Cadres,Niveau 9,Echelon 3,"4857,55",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 1,160 ,"1539,42",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 2,170 ,"1569,17",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 2,185 ,"1628,42",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 3,200 ,"1687,68",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 3,210 ,"1727,18",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 3,225 ,"1786,44",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 4,260 ,"1924,70",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu","Ouvriers, employés",Niveau 4,280 ,"2003,71",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Techniciens et agents de maîtrise ,Niveau 4 ,260 ,"1924,70",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Techniciens et agents de maîtrise ,Niveau 4 ,280 ,"2003,71",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Techniciens et agents de maîtrise ,Niveau 5,430 ,"2596,27",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Techniciens et agents de maîtrise ,Niveau 5,580 ,"3188,82",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Techniciens et agents de maîtrise ,Niveau 6,760 ,"3899,89",byMonth151,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Cadres,Niveau 5,430 ,"31155,19",byYear218,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Cadres,Niveau 5,580 ,"38265,87",byYear218,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Cadres,Niveau 6,760 ,"46798,69",byYear218,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Cadres,Niveau 7,1 120 ,"63864,33",byYear218,,,,,,,
24/2/2021,1/3/2020,,2272,Assainissement et maintenance industrielle,,,"cf. CC. n° 3309, Art. 5.2 ; cf. CC. n° 3309, Art. 5.5 ; cf. Avenant n° 34 du 11 févr. 2020 (NOR : ASET2050317M) étendu",Cadres,Niveau 8,1 470 ,"80455,93",byYear218,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 1,140 ,,18492,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 1,145 ,,18501,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 1,155 ,,18511,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 2,170 ,,18520,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 2,180 ,,18627,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 2,190 ,,19027,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 3,215 ,,19719,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 3,225 ,,20082,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 3,240 ,,20615,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 4,255 ,,21102,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 4,270 ,,22123,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 4,285 ,,23136,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 5,305 ,,24765,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 5,335 ,,27085,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 5,365 ,,29173,byYear1607,,,,,,,
24/2/2021,10/3/2020,,2344,Sidérurgie,,,"cf. Avenant "" mensuels "" art. 25 ; cf. Avenant du 10 mars 2020 (NOR : ASET2050399M) non étendu (déposé le 14 avril 2020)",Niveau 5,395 ,,31285,byYear1607,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 1,Echelon 1,"1558,91",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 2,Echelon 1,"1626,11",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 2,Echelon 2,"1752,45",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 2,Echelon 3,"1873,30",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 3,Echelon 1,"2010,61",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 3,Echelon 2,"2329,22",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Non-cadres,Niveau 3,Echelon 3,"2801,64",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Cadres,Niveau 4,Echelon 1,"3066,03",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Cadres,Niveau 4,Echelon 2,"3453,19",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Cadres,Niveau 4,Echelon 3,"3883,38",byMonth151,,,,,,,
25/2/2021,16/12/2020,,2543,"Cabinets ou entreprises de géomètres-experts, géomètres-topographes, photogrammètres et experts fonciers",,,cf. Accord du 22  janv. 2020 (NOR  : ASET2050440M) non étendu et prenant effet immédiatement pour l'ensemble des entreprises adhérentes à un syndicat signataire (conclu pour une durée indéterminée) ; cf. Accord du 22 janv. 2020 (NOR : ASET2050517M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et prenant effet le lendemain du jour de la publication de son extension ou le 1er jour du mois suivant dans le cas où cette publication a lieu à compter du 16 du mois (Conclu pour une durée indéterminée) ,Cadres,Niveau 5,Echelon 1,"4356,57",byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Employés,I ,,1522,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Employés,II ,,1534,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Employés,III ,,1546,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Employés,IV ,,1558,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Agents de maîtrise,V ,,1693,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Agents de maîtrise,VI ,,1827,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Agents de maîtrise,VII ,,2001,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Agents de maîtrise,VIII ,,2206,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Cadres,IX ,,2428,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Cadres,X ,,2949,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Cadres,XI ,,3377,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3013,Librairie,,,cf. Accord du 10  mai 2017 (NOR  : ASET1750688M) étendu par A. 18 oct. 2017 : JO 26 oct. 2017 et en vigueur dans les entreprises et établissements de la branche le 1er jour du mois suivant la date de publication de son extension  ,Cadres,XII ,,3729,byMonth151,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,140 ,,1530,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,150 ,,1540,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,160 ,,1550,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,170 ,,1560,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,180 ,,1572,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,190 ,,1587,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,205 ,,1649,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,215 ,,1727,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,225 ,,1808,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,235 ,,1888,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,245 ,,1974,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,255 ,,2125,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,270 ,,2172,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,290 ,,2336,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,310 ,,2506,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,335 ,,2709,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. II.2.1",OETDAM,360 ,,2927,byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.1",Cadres,185 ,,"1557,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.2",Cadres,210 ,,"1768,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.3",Cadres,230 ,,"1936,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.4",Cadres,250 ,,"2104,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.5",Cadres,270 ,,"2273,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.6",Cadres,290 ,,"2441,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.7",Cadres,310 ,,"2609,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.8",Cadres,330 ,,"2777,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.9",Cadres,350 ,,"2946,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.10",Cadres,360 ,,"3030,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.11",Cadres,370 ,,"3114,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.1",Cadres,380 ,,"3198,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.2",Cadres,390 ,,"3282,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.3",Cadres,400 ,,"3366,00",byMonth152,,,,,,,
25/2/2021,1/1/2019,,3233,Industrie de la fabrication des ciments,,,"cf. CC. n° 3280-00, Art. III.2.2.4",Cadres,600 ,,"5049,00",byMonth152,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,110 M ,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,115 M,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,118 M,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,120 M,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,128 M,"18990,12",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,138 M,"19027,61",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,150 M,"19477,52",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,110 M,"19312,55",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,115 M,"19312,55",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,118 M,"19312,55",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,120 M,"19312,55",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,128 M,"19369,92",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,138 M,"19408,16",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels ouvriers roulants et sédentaires,150 M,"19867,07",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,105,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,110,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,115,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,120,"18933,88",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,125,"18952,62",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,"132,5","18990,12",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,140,"19046,35",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,"148,5","19477,52",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,105,"19501,89",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,110,"19501,89",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,115,"19501,89",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,120,"19501,89",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,125,"19521,20",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,"132,5","19559,82",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,140,"19617,75",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels employés,"148,5","20061,85",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,150,"19721,23",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,"157,5","19946,18",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,165,"20902,25",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,175,"22177,01",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,185,"23414,27",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,200,"25326,40",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,215,"27219,79",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,225,"28532,04",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,150,"20312,86",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,"157,5","20544,57",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,165,"21529,32",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,175,"22842,32",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,185,"24116,70",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,200,"26086,19",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,215,"28036,38",byYear1607,,,,,,,
26/2/2021,1/6/2019,3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,"Transport routier de marchandises
et activités auxiliaires de transport",Personnels techniciens et agents de maîtrise,225,"29388,00",byYear1607,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,100,"2190,89",byMonth151,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,"106,5","2333,08",byMonth151,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,113,"2475,31",byMonth151,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,119,"2606,50",byMonth151,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,132,"2890,91",byMonth151,,,,,,,
26/2/2021,1/6/2019,1-2-3-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant du 30 juin 2004 (NOR : ASET0450819M) étendu par A. 4 janv. 2005 : JO 22 janv. 2005 ; cf. Avenant n° 1 du 1er juin 2005 (NOR : ASET0550943M) étendu par A. 18 nov. 2005 : JO 29 nov. 2005,Personnels ingénieurs et cadres,Personnels ingénieurs et cadres,145,"3175,33",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",A ,,,"1522,00",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",B ,,,"1559,79",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",C ,,,"1655,77",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",D ,,,"1809,14",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",E ,,,"1997,98",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",F ,,,"2224,17",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",G ,,,"2441,81",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",H ,,,"2899,96",byMonth151,,,,,,,
26/2/2021,1/1/2020,,614,Industries de la sérigraphie et des procédés d'impression numérique connexes,,,"cf. CC. n° 3137, Art. 109 ; cf. Accord du 7 janvier 2019 (NOR : ASET1950098M) étendu par A. 2 août 2019 : JO 17 août 2019 et applicable à compter du 1er janv. 2019",I ,,,"3462,26",byMonth151,,,,,,,
26/2/2021,1/7/2020,1-2,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,Débutant,80,,1873,byMonth151,,,,,,,
26/2/2021,1/7/2020,3-4,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,Débutant,80,,2093,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,A,80,,2608,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,B ,80,,3007,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,C,80,,3938,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,A,70,,2283,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,B ,70,,2632,byMonth151,,,,,,,
26/2/2021,1/7/2020,,707,Transformation des papiers-cartons et de la pellicule cellulosique (Ingénieurs et cadres),,,cf. Avenant n°  10 du 4  avr. 2018 (NOR  : ASET1850906M) étendu par A.13 févr. 2019 : JO 21 févr. 2019 et en vigueur au 1er juin 2018 ; cf. Avenant n° 5 du 22 juin 2020 (NOR : ASET2050636M) non étendu et en vigueur au 1er juillet 2020 ,C,70,,3446,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1000 ,,,1550,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1020 ,,,1555,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1040 ,,,1605,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1060 ,,,1660,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1080 ,,,1720,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1100 ,,,1776,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1125 ,,,1861,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1150 ,,,1986,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1175 ,,,2107,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1200 ,,,2225,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1225 ,,,2341,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1250 ,,,2464,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1300 ,,,2613,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1350 ,,,3395,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1500 ,,,3568,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1700 ,,,4517,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",1900 ,,,5467,byMonth151,,,,,,,
26/2/2021,1/8/2020,,715,Instruments à écrire et industries connexes,,,"cf. Accord du 28 oct. 2002 (NOR : ASET0250937M), étendu par A. 5 déc. 2003 : JO du 13 déc. 2003 (NOR : SOCT0311931A) ; cf. Avenant n°  46 du 8  sept. 2020 (NOR  : ASET2050858M) étendu par A. 26 janv. 2021 : JO 3 févr. 2021 et en vigueur au 1er août 2020 (Conclu pour une durée indéterminée) ",2200 ,,,6895,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Comptabilité,1 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Comptabilité,2 ,1722,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Comptabilité,3 ,2285,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Comptabilité,4 ,3508,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Contrôle de gestionanalyste financier,5 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Contrôle de gestionanalyste financier,6 ,2268,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Contrôle de gestionanalyste financier,7 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Contrôle de gestionanalyste financier,8 ,3508,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Trésorerie,9 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Trésorerie,10 ,2285,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Trésorerie,11 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Recouvrement,12 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Recouvrement,13 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Recouvrement,14 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Reversement,15 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Reversement,16 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Reversement,17 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Audit interne,18 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Audit interne,19 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Audit interne,20 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,Audit interne,21 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",gestion/finances,DAF,22 ,4634,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Paies,23 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Paies,24 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Paies,25 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Ressources humaines,26 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Ressources humaines,27 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Ressources humaines,28 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Ressources humaines,29 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Formationrecrutement,30 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",ressources humaines,Formationrecrutement,31 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",administratif,Administration,32 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",administratif,Administration,33 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",juridique,juridique,34 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",juridique,juridique,35 ,2318,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",juridique,juridique,36 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",juridique,juridique,37 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",informatique,Informatiquesystèmes d'information,38 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",informatique,Informatiquesystèmes d'information,39 ,2268,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",informatique,Informatiquesystèmes d'information,40 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",informatique,Informatiquesystèmes d'information,41 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Chauffeur(e) de direction,42 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Accueilsécurité,43 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Accueilsécurité,44 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Accueilsécurité,45 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Services généraux,46 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Services généraux,47 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",services généraux,Services généraux,48 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Pressecommunication/ événementiel,49 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Pressecommunication/ événementiel,50 ,2268,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Promotionpartenariats,51 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Trade marketing,52 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Trade marketing,53 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Marketing/communication,Trade marketing,54 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Ventesventes internationales (y compris TVvidéoVAD),55 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Ventesventes internationales (y compris TVvidéoVAD),56 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Ventesventes internationales (y compris TVvidéoVAD),57 ,2591,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Ventesventes internationales (y compris TVvidéoVAD),58 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Ventesventes internationales (y compris TVvidéoVAD),59 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Programmation,60 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Programmation,61 ,1914,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Programmation,62 ,2727,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Programmation,63 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Commercial/vente,Programmation,64 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Acquisitions,Acquisitions,65 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Acquisitions,Acquisitions,66 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Acquisitions,Acquisitions,67 ,4119,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Opérations,Logistique,68 ,1500,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Opérations,Logistique,69 ,1899,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Opérations,Logistique,70 ,2904,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Opérations,Logistique,71 ,3990,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Opérations,Projectionniste,72 ,1601,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Missions transverses,Missions transverses,73 ,2990,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Missions transverses,Missions transverses,74 ,2414,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Missions transverses,Missions transverses,75 ,2414,byMonth151,,,,,,,
26/2/2021,20/11/2017,,716,Industrie cinématographique (Distribution des films) (Employés et ouvriers),,,"cf. CC. n° 3174-1, Art. 21 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature",Missions transverses,Missions transverses,76 ,4634,byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,A,I,122,"1328,20",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,A,II,124,"1349,98",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,B,I,126,"1371,73",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,B,II,128,"1393,51",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,C,,132,"1437,06",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,D,I,135,"1469,73",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,D,II,140,"1524,15",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,E,,145,"1578,59",byMonth151,,,,,,,
26/2/2021,1/11/2012,,1194,Edition de musique (Employés),,,cf. Avenant n° 33 du 26 sept. 2011 (NOR : ASET1250198M) étendu par A. 28 mars 2012 : JO 5 avr. 2012,F,,160,"1741,89",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de presse,102 ,400 ,1572,byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature","Employé d'entretien, de manutention",102 ,400 ,1572,byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",employé de manutention confirmé,106 ,407 ,"1599,51",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de fabrication,106 ,407 ,"1599,51",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Coursier – chauffeur,106 ,407 ,"1599,51",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Coursier – chauffeur confirmé,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Secrétaire d'accueil,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de presse 1er échelon,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Aide-comptable 1er échelon,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Assistant en publicité,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Animateur des ventes 1er ,109 ,414 ,"1627,02",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de fabrication 1er échelon,112 ,421 ,"1654,53",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Correcteur,112 ,421 ,"1654,53",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de presse 2e échelon,116 ,428 ,"1682,04",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Aide-comptable 2e échelon,116 ,428 ,"1682,04",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Attaché comptable 2e échelon,116 ,428 ,"1682,04",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Attaché commercial 1er échelon,116 ,428 ,"1682,04",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Animateur des ventes 2e échelon,116 ,428 ,"1682,04",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de fabrication 2e échelon,120 ,435 ,"1709,55",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Correcteur confirmé,120 ,435 ,"1709,55",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de presse 3e échelon,124 ,447 ,"1756,71",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Comptabilité 1er échelon,124 ,447 ,"1756,71",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de fabrication 3e échelon,128 ,462 ,"1815,66",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Attaché commercial 2e échelon,128 ,462 ,"1815,66",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Animateur des ventes 3e échelon,128 ,462 ,"1815,66",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de presse 4e échelon,132 ,480 ,"1886,4",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Secrétaire de direction,132 ,480 ,"1886,4",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Employé de fabrication 4e échelon ,136 ,500 ,1965,byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Comptable 2e échelon,140 ,520 ,"2043,6",byMonth151,,,,,,,
26/2/2021,11/11/2019,,1281,Presse hebdomadaire régionale (Employés),,,"cf. Accord du 30  juin 1999, étendu par A. 17  nov. 1999  : JO 23  nov. 1999, applicable à compter de sa signature",Assistant commercial,140 ,520 ,"2043,6",byMonth151,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",3 ,,,"62216,49",byYear218,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",4 ,,,"68357,39",byYear218,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",5 ,,,"74386,86",byYear218,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",6 ,,,"79987,40",byYear218,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",7 ,,,"85623,05",byYear218,,,,,,,
26/2/2021,1/2/2007,,2727,Omnipraticiens exerçant dans les centres de santé miniers,,,"cf. CC. n° 3358, Art. 19 et s. ; cf. Annexe I – Calcul du salaire",8 ,,,"91135,05",byYear218,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau I,1 ,201 ,"1 483,38",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau I,2 ,206 ,"1 520,28",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau I,3 ,210 ,"1 549,80",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau II,1 ,220 ,"1 623,60",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau II,2 ,226 ,"1 667,88",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau II,3 ,230 ,"1 697,40",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau III,1 ,245 ,"1 808,10",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau III,2 ,250 ,"1 845,00",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau III,3 ,255 ,"1 881,90",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau IV,1 ,270 ,"1 992,60",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau IV,2 ,280 ,"2 066,40",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau V,1 ,295 ,"2 177,10",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau V,2 ,310 ,"2 287,80",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau VI,1 ,340 ,"2 509,20",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau VI,2 ,427 ,"3 151,26",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau VII,,480 ,"3 542,40",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau VIII,,550 ,"4 059,00",byMonth151,,,,,,,
26/2/2021,23/3/2015,,2754,Magasins prestataires de services de cuisine à usage domestique,,,"cf. CC. n°3359, Art. 14.4 ; cf. Avenant n° 10 du 12 nov. 2014 (NOR : ASET1550346M), non étendu (déposé le 23 mars 2015)",Niveau IX,,600 ,"4 428,00",byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",I,1 ,255 ,1528,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",I,2 ,262 ,1548,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",II,1 ,278 ,1635,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",II,2 ,301 ,1763,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",III,1 ,371 ,2155,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",III,2 ,452 ,2610,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",IV,1 ,625 ,3586,byMonth151,,,,,,,
26/2/2021,1/1/2019,,3220,Offices publics de l'habitat (Personnel),,,"cf. CC. n° 0000-71, Chap. III, Sous-chap. V, I, art. 1er à 3 ; cf. Avenant n° 3 du 20 mars 2019 (NOR : ASET1951098M) étendu ",IV,2 ,880 ,5040,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N1 E unique ,100 ,1481,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N2 E1 ,115 ,1482,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N2 E2 ,120 ,1484,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N2 E3 ,125 ,1486,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N3 E1 ,135 ,1490,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N3 E2 ,140 ,1494,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N3 E3 ,150 ,1504,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N4 E1 ,160 ,1508,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N4 E2 ,170 ,1528,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N4 E3 ,180 ,1552,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Ouvriers et employés,N5 E unique ,190 ,1748,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N5 E1 ,185 ,1505,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N5 E2 ,190 ,1613,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N5 E3 ,210 ,1762,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N6 E1 ,230 ,1930,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N6 E2 ,265 ,2210,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N6 E3 ,300 ,2503,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Techniciens et agents de maîtrise,N7 E unique ,310 ,2584,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N7 E1 ,305 ,2544,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N7 E2 ,310 ,2584,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N8 E1 ,345 ,2872,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N8 E2 ,375 ,3080,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N8 E3 ,420 ,3493,byMonth151,,,,,,,
27/2/2021,19/1/2017,,3222,"Menuiseries, charpentes et constructions industrialisées et portes planes",,,"cf. CC. n° 0000-58, Art. 19.6",Cadres,N8 E4 ,480,4021,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N15 ,A1,1798,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N14 ,A1,1754,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N13 ,A1,1710,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N12 ,A1,1663,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N11 ,A1,1618,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N10 ,A1,1573,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N9 ,A1,1557,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N8 ,A1,1532,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N7 ,A1,1530,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N6 ,A1,1529,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N5 ,A1,1527,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N4 ,A1,1525,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N3 ,A1,1522,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N2 ,A1,1520,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Employés,N1 ,A1,1518,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,14,A1,3205,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,13 ,A1,2889,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,12 ,A1,2757,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,11 ,A1,2623,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,10 ,A1,2486,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,9 ,A1,2186,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,8 ,A1,2094,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,7 ,A1,1999,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,6 ,A1,1907,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,5 ,A1,1842,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,4 ,A1,1842,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,3 ,A1,1786,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,2 ,A1,1786,byMonth151,,,,,,,
27/2/2021,1/1/2019,1-2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres,1,A1,1731,byMonth151,,,,,,,
27/2/2021,1/1/2019,1,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres débutants,,,1731,byMonth151,,,,,,,
27/2/2021,1/1/2019,2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Cadres débutants,,,1805,byMonth151,,,,,,,
27/2/2021,1/1/2019,1,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Techniciens débutants,,,1632,byMonth151,,,,,,,
27/2/2021,1/1/2019,2-3-4,3230,Presse d'information spécialisée,,,cf. Accord du 22  nov. 2018 (NOR  : ASET1950194M) non étendu (déposé le 24 janv. 2019,Techniciens débutants,,,1710,byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,130 ,,"1689,18",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,145 ,,"1720,35",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,155 ,,"1741,15",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,160 ,,"1751,53",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,170 ,,"1772,31",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,185 ,,"1847,15",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Ouvriers,205 ,,"2036,04",byMonth151,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,150 ,,"19295,67",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,155 ,,"19471,34",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,160 ,,"19672,11",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,165 ,,"19897,99",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,170 ,,"20148,64",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,175 ,,"20424,85",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,180 ,,"20725,86",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,185 ,,"21052,13",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,190 ,,"21403,63",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,195 ,,"21780,24",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,200 ,,"22181,79",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,205 ,,"22608,12",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,210 ,,"23059,86",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,215 ,,"23536,70",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,220 ,,"24030,42",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,225 ,,"24557,60",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,230 ,,"25109,89",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,235 ,,"25686,96",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,240 ,,"26288,81",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",ETDAM,250 ,,"26940,24",byYear1607,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,260 ,,"31993,22",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,270 ,,"33223,51",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,280 ,,"34453,79",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,300 ,,"36915,51",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,305 ,,"37530,66",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,325 ,,"39991,24",byYear218,,,,,,,
27/2/2021,1/3/2018,,3227,Industries de la fabrication de la chaux,,,"cf. CC. n° 3064-00, Art. 39 ; cf. CC. n° 3064-00, Art. 40 ; cf. Accord du 13 mars 2018 (NOR : ASET1950943M) non étendu et applicable au 1er mars 2018 ",Cadres,400 ,,"49220,67",byYear218,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",I,1 ,"1530,28",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",I,2 ,"1535,42",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",II,1 ,"1540,52",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",II,2 ,"1547,75",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",II,3 ,"1553,98",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",III,1 ,"1553,98",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",III,2 ,"1561,25",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",III,3 ,"1568,51",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",IV,1 ,"1568,51",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",IV,2 ,"1577,80",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",IV,3 ,"1589,22",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",V,1 ,"1589,22",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",V,2 ,"1601,65",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017","Ouvriers, employés",V,3 ,"1613,06",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VI,1 ,"1613,06",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VI,2 ,"1696,04",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VI,3 ,"1780,99",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VII,1 ,"1780,99",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VII,2 ,"1876,57",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VII,3 ,"1972,15",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VIII,1 ,"1972,15",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VIII,2 ,"2078,35",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",TAM,VIII,3 ,"2230,23",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",Cadres,IX,1 ,"2230,23",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",Cadres,IX,2 ,"2495,73",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",Cadres,X, ,"3079,83",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",Cadres,XI, ,"3717,04",byMonth151,,,,,,,
27/2/2021,1/6/2019,,7004,Coopératives agricoles laitières,,,"cf. CC. n° 3608, Art. 29 ; cf. Accord du 22 mars 2017 (NOR : AGRS1797126M) étendu par A. 4 août 2017 : JO 15 août 2017, applicable au 1er avr. 2017",Cadres,XII, ,"4248,06",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",A1 , , ,"1466,62",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",A2 , , ,"1480,98",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",B1 , , ,"1501,57",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",B2 , , ,"1516,02",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",C1 , , ,"1538,02",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",C2 , , ,"1566,99",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",C3 , , ,"1593,33",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",C4 , , ,"1622,70",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",D1 , , ,"1658,30",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",D2 , , ,"1694,22",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",D3 , , ,"1768,61",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",D4 , , ,"1870,96",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",E1 , , ,"1946,28",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",E2 , , ,"2046,97",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",E3 , , ,"2206,79",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",E4 , , ,"2349,63",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",F , , ,"2554,25",byMonth151,,,,,,,
27/2/2021,1/3/2016,,7003,Conserveries coopératives et S.I.C.A.,,,"cf. CC. n° 3607, Art. 20 ; cf. Avenant n° 122 du 19 févr. 2016 (NOR : AGRS1697107M) étendu par A. 2 juin 2016 : JO 10 juin 2016",G , , ,"3596,44",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019","Ouvrier, employé",100 , ,"1611,49",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019","Ouvrier, employé",108 , ,"1641,31",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019","Ouvrier, employé",116 , ,"1671,12",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Techniciens et agents de maîtrise,244 , ,"2148,11",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Techniciens et agents de maîtrise,300 , ,"2356,80",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Cadres type 1 ,630 (échelon 1) , ,"2177,15",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Cadres type 1 ,700 (échelon 2) , ,"2419,06",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Cadres type 1 ,800 (échelon 3) , ,"2764,64",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Cadres type 2 ,900 , ,"3110,22",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7007,Coopératives agricoles et SICA de teillage du lin,,,"cf. CC. n° 0000-11, Art. 22 ; cf. Avenant n° 49 du 25 juin 2019 (NOR : AGRS2097036M) étendu par A. 3 avr. 2020 : JO 10 avr. 2020 et prenant effet à compter du 1er juill. 2019",Cadres type 3 ,1200 , ,"4146,96",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,I. – Ouvriers et employés non spécialisés ,,1 ,"1540,93",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,II. – Ouvriers et employés spécialisés,– échelon 1,2 ,"1559,13",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,II. – Ouvriers et employés spécialisés,– échelon 2 ,3 ,"1575,82",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"III. – Ouvriers qualifiés, chef d'équipe 1er degré",– échelon 1,4 ,"1590,98",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"III. – Ouvriers qualifiés, chef d'équipe 1er degré",– échelon 2,5 ,"1607,67",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"III. – Ouvriers qualifiés, chef d'équipe 1er degré",– échelon 3,6 ,"1624,35",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"IV. – Techniciens, chef d'équipe 2e degré",– échelon 1,7 ,"1680,47",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"IV. – Techniciens, chef d'équipe 2e degré",– échelon 2,8 ,"1765,40",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,"IV. – Techniciens, chef d'équipe 2e degré",– échelon 3,9 ,"1883,70",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,V. – Ingénieurs et cadres,– échelon 1 ,7 ,"1680,47",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,V. – Ingénieurs et cadres,– échelon 2 ,8 ,"1765,40",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,V. – Ingénieurs et cadres,– échelon 3 ,9 ,"1883,70",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,VI. – Encadrement,– chef de service ,9 ,"1883,70",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,VI. – Encadrement,– directeur de département ,10 ,"2508,57",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7009,Entreprises d'accouvage et de sélection,,,cf. Annexe III  ; cf. Avenant n°  91 du 15  janv. 2020 (NOR  : AGRS2097061M) étendu par A. 28 mai 2020 : JO 4 juin 2020 et prenant effet à compter du 1er janv. 2020 ,VI. – Encadrement,– directeur ,11 ,"3272,97",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",100 ,,,"1584,95",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",105 ,,,"1595,57",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",110 ,,,"1606,19",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",115 ,,,"1624,39",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",120 ,,,"1644,10",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",135 ,,,"1712,35",byMonth151,,,,,,,
27/2/2021,1/1/2018,,7013,Etablissements d'entraînement de chevaux de courses au trot,,,"cf. Avenant n° 53 du 15 janv. 2018 (NOR : AGRS1897215M) étendu par A. 3 janv. 2019 : JO 11 janv. 2019, applicable au 1er janvier 2018 ",150 ,,,"1838,24",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",200 ,,,"1582,53",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",220 ,,,"1607,37",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",230 ,,,"1610,59",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",300 ,,,"1632,00",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",350 ,,,"1646,99",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",400 ,,,"1664,12",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",450 ,,,"1678,04",byMonth151,,,,,,,
27/2/2021,1/7/2019,,7014,Etablissements d'entraînement de chevaux de courses au galop,,,"cf. Avenant n° 75 du 11 janv. 2019 (NOR : AGRS1997028M) étendu par A. 9 avr. 2019 : JO 16 avr. 2019 ; cf. Annexe – Barème des déplacements [annexe "" Cavaliers d'entraînement ""] ",500 ,,,"1694,10",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O1 ,,"1542,48",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O2 ,,"1547,03",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O3 ,,"1562,20",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O4 ,,"1594,05",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O5 ,,"1653,20",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",O6 ,,"1729,04",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",E1 ,,"1559,17",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",E2 ,,"1563,72",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",E3 ,,"1628,94",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension","Ouvrier, employé",E4 ,,"1729,04",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",TAM,TAM 1 ,,"1935,00",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",TAM,TAM 2 ,,"2030,00",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",TAM,TAM 3 ,,"2175,00",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",TAM,TAM 4 ,,"2385,00",byMonth151,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C ,,32352,byYear218,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C1 ,,37301,byYear218,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C2 ,,37301,byYear218,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C3 ,,39015,byYear218,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C4 ,,40188,byYear218,,,,,,,
27/2/2021,1/1/2020,,7018,Entreprises du paysage,,,"cf. Avenant n°  22 du 5  oct. 2018 (NOR  : AGRS1997001M) étendu par A. 27 févr. 2019 : JO 1er mars 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 26 du 18 sept. 2019 (NOR : AGRS1997237M) étendu par A. 10 déc. 2019 : JO 18 déc. 2019, et en vigueur le 1er jour du mois qui suit la publication de son extension",Cadres,C5 ,,42917,byYear218,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,22,224 ,725 ,"2084,6",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,22,224 ,700 ,"2084,6",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,21 ,220,699 ,"2047,4",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,21 ,220,675 ,"2047,4",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,20,216,674 ,"2010,2",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,20,216,650 ,"2010,2",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,19,212,649 ,"1973,0",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,19,212,625 ,"1973,0",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,18,208,624 ,"1935,7",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,18,208,600 ,"1935,7",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,17,204,599 ,"1898,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,17,204,575 ,"1898,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,16,200,574 ,"1861,3",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,16,200,550 ,"1861,3",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,15,196,549 ,"1824,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,15,196,525 ,"1824,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,14,192,524 ,"1786,8",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,14,192,500 ,"1786,8",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,13,188,499 ,"1749,6",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,13,188,475 ,"1749,6",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,12,184,474 ,"1712,4",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,12,184,450 ,"1712,4",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,11,180,449 ,"1675,2",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,11,180,425 ,"1675,2",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,10,178,424 ,"1656,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,10,178,400 ,"1656,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,9,176,399,"1637,9",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,9,176,375,"1637,9",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,8,174,374 ,"1619,3",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,8,174,350 ,"1619,3",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,7,172,349 ,"1600,7",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,7,172,325 ,"1600,7",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,6,170,324 ,"1582,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,6,170,300 ,"1582,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,5,168,299 ,"1563,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,5,168,275 ,"1563,5",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,4,166,274 ,"1544,9",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,4,166,250 ,"1544,9",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,3,164,249 ,"1536,8",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,3,164,225 ,"1536,8",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,2,162,224 ,"1518,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,2,162,200 ,"1518,1",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,1,160,199 ,"1499,3",byMonth151,,,,,,,
27/2/2021,19/3/2018,,7021,Entreprises relevant de la sélection et de la reproduction animale,,,cf. Avenant n° 8 du 19 mars 2018 (NOR : AGRS1897182M) par A. 25 oct. 2018 : JO 1er nov. 2018 et applicable à compter de son dépôt  ; cf. Avenant n°  9 du 1er  mars 2019 (NOR  : AGRS1997158M) étendu par A. 12 juill. 2019 : JO 19 juill. 2019 et applicable à compter de son dépôt,1,160,175 ,"1499,3",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",I,,,"1540,00",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",II,,,"1540,00",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",III,,,"1567,25",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",IV,,,"1697,04",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",V,,,"1827,92",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",VI,,,"2052,89",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",VII,,,"2515,83",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",VIII,,,"3123,69",byMonth151,,,,,,,
02/03/2021,01/01/2020,,2270,Edition phonographique,,,"cf. Annexe I art. 7 ; cf. Annexe I, sous-annexe I ; cf. Accord du 8 janv. 2020 (NOR : ASET2050779M) étendu par A.  14 déc. 2020 : JO 6 janv. 2021 et en vigueur, avec effet au 1er janvier 2020 pour les autres stipulations, à partir du  jour suivant le dépôt légal ",IX,,,"3865,66",byMonth151,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2020",Ouvrier,140,,18700,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2021",Ouvrier,145,,18730,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2022",Ouvrier,155,,18810,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2023",Ouvrier,170,,18925,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2025",Ouvrier,190,,19155,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2026",Ouvrier,215,,19675,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2028",Ouvrier,240,,20610,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2029",Ouvrier,255,,21545,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2030",Ouvrier,270,,22525,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2031",Ouvrier,285,,23660,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2038",Maîtrise d'atelier,155,,19800,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2040",Maîtrise d'atelier,180,,20850,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2041",Maîtrise d'atelier,190,,21930,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2043",Maîtrise d'atelier,225,,23800,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2044",Maîtrise d'atelier,240,,25520,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2045",Maîtrise d'atelier,255,,27685,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2046",Maîtrise d'atelier,270,,29925,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2047",Maîtrise d'atelier,285,,32160,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2052",Administratif et technicien,140,,18700,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2053",Administratif et technicien,145,,18730,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2054",Administratif et technicien,155,,18810,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2055",Administratif et technicien,170,,18925,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2056",Administratif et technicien,180,,19035,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2057",Administratif et technicien,190,,19155,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2058",Administratif et technicien,215,,19675,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2059",Administratif et technicien,225,,20040,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2060",Administratif et technicien,240,,20610,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2061",Administratif et technicien,255,,21125,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2062",Administratif et technicien,270,,22100,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2063",Administratif et technicien,285,,23125,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2064",Administratif et technicien,305,,24190,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2065",Administratif et technicien,335,,26325,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2066",Administratif et technicien,365,,28500,byYear1600,,,,,,,
1/3/2021,1/1/2020,,2489,Métallurgie Vendée (industries métallurgiques et assimilées),,85,"cf. CC. n° 3325, Art. 32 (Avenant "" mensuels "") ; cf. Accord du 9 juin 2020 [RMH] (NOR : ASET2050576M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente et en vigueur le 1er juillet 2067",Administratif et technicien,395,,30625,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",I,3,155,18456,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,1,170,18508,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,2,180,18531,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,3,190,18586,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,1,215,18786,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,2,225,18844,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,3,240,19040,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,1,255,20125,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,2,270,20788,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,3,285,21434,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,1,305,22566,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,2,335,24886,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,3,365,27747,byYear1600,,,,,,,
1/3/2021,1/1/2019,,2266,"Métallurgie Mayenne (industries métallurgiques, mécaniques, électriques, connexes et similaires)",,53,"cf. Accord du 23 mai 2019 (NOR : ASET1951050M) étendu par A. 1er avril 2020 : JO 8 avr. 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,4,395,30269,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",I,1,140,18500,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",I,2,145,18516,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",I,3,155,18538,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,1,170,18735,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,2,180,18773,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",II,3,190,18848,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,1,215,19177,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,2,225,19519,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",III,3,240,20450,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,1,255,21405,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,2,270,22650,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",IV,3,285,23909,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,1,305,25451,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,2,335,27953,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,3,365,30428,byYear1600,,,,,,,
1/3/2021,1/1/2020,,1902,"Métallurgie Maine et Loire (industries métallurgiques, mécaniques, électriques, électroniques, connexes et similaires)",,49,"cf. Avenant mensuels, Art. 8 ; cf. Avenant du 14 janv. 2020 (NOR : ASET2050246M) étendu par A. 5 juin 2020 : JO 16 juin 2020, à l'exclusion de la réparation d'appareils électriques pour le ménage non associée à un magasin de vente",V,3,395,32783,byYear1600,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 1 - EMPLOIS D’EXECUTANTS,Echelon 1,"1445,42",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 1 - EMPLOIS D’EXECUTANTS,Echelon 2,"1456,03",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 2 – EMPLOIS SPECIALISES,Echelon 1,"1465,13",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 2 – EMPLOIS SPECIALISES,Echelon 2,"1472,72",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 3 – EMPLOIS QUALIFIES,Echelon 1,"1486,37",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 3 – EMPLOIS QUALIFIES,Echelon 2,"1496,98",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 4 – EMPLOIS HAUTEMENT QUALIFIES,Echelon 1,"1521,25",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 4 – EMPLOIS HAUTEMENT QUALIFIES,Echelon 2,"1545,52",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",A – EMPLOYES DE BUREAU,Employé de bureau 1er échelon,"1456,03",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",A – EMPLOYES DE BUREAU,Employé de bureau 2ème échelon,"1465,13",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",A – EMPLOYES DE BUREAU,Employé de bureau 3ème échelon.,"1472,72",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",A – EMPLOYES DE BUREAU,Employé de bureau très qualifié,"1486,37",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",B - EMPLOYES DE MAGASIN,Vendeur non qualifié,"1456,03",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",B - EMPLOYES DE MAGASIN,Vendeur qualifié,"1472,72",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"PERSONNEL DES BUREAUX ET MAGASINS ANNEXES
 D’UNE EXPLOITATION HORTICOLE, D’UNE PEPINIERE",B - EMPLOYES DE MAGASIN,Vendeur très qualifié,"1486,37",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’ENCADREMENT,Cadre du 3ème groupe,,"1625,90",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’ENCADREMENT,Cadre du 2ème groupe,,"1810,94",byMonth151,,,,,,,
1/3/2021,3/1/2014,,9442,Exploitation horticoles et pépinières de Loire-Atlantique,,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’ENCADREMENT,Cadre du 1er groupe,,"2243,20",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 1 - Emplois d'exécution,,"1445,42",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 2 - Emplois spécialisés,Echelon 1,"1460,58",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 2 - Emplois spécialisés,Echelon 2,"1493,95",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 3 - Emplois qualifiés,Echelon 1,"1527,32",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 3 - Emplois qualifiés,Echelon 2,"1569,78",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 4 - Emplois hautement qualifiés,Echelon 1,"1656,24",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D’EXECUTION DES EXPLOITATIONS,NIVEAU 4 - Emplois hautement qualifiés,Echelon 2,"1716,90",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Emploi de techniciens et agents de maîtrise - TAM,NIVEAU 1 - Technicien et agent de maîtrise,Echelon 1 - Technicien,"1854,92",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Emploi de techniciens et agents de maîtrise - TAM,NIVEAU 1 - Technicien et agent de maîtrise,Echelon 2 - Agent de maîtrise,"1947,44",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Emploi de techniciens et agents de maîtrise - TAM,NIVEAU 2- Technicien et agent de maîtrise,,"2055,13",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,emploi de cadre,Emplois de cadre,Niveau 1,"2316,00",byMonth151,,,,,,,
1/3/2021,5/1/2014,,9441,"Polyculture, viticulture et élevage de Loire-Atlantique",,44,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,emploi de cadre,Emplois de cadre,Niveau 2,"2775,56",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 1 - EMPLOIS D'EXECUTANTS,,"1539,45",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 2 - EMPLOIS SPECIALISES,Echelon 1,"1550,07",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 2 - EMPLOIS SPECIALISES,Echelon 2,"1557,65",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 3 - EMPLOIS QUALIFIES,Echelon 1,"1572,82",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 3 - EMPLOIS QUALIFIES,Echelon 2,"1587,98",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 4 - EMPLOIS HAUTEMENT QUALIFIES,Echelon 1,"1619,84",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION DES EXPLOITATIONS,NIVEAU 4 - EMPLOIS HAUTEMENT QUALIFIES,Echelon 2,"1653,2",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,NIVEAU 1 – TAM1,Echelon 1,1726,byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,NIVEAU 1 – TAM1,Echelon 2,"1797,29",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,NIVEAU 2 – TAM2,Echelon 1,"1965,64",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES,Echelon 1,,"2159,78",byMonth151,,,,,,,
1/3/2021,5/1/2020,,9492,Exploitations horticoles et pépinières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES,Echelon 2,,"2408,52",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS D’EXECUTION,Echelon 1,101,"1280,09",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS D’EXECUTION,Echelon 2,102,"1284,64",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS SPECIALISES,Echelon 1,201,"1290,71",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS SPECIALISES,Echelon 2,202,"1296,78",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS QUALIFIES,Echelon 1,301,"1302,85",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS QUALIFIES,Echelon 2,302,"1308,91",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS HAUTEMENT QUALIFIES,Echelon 1,401,"1314,98",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS HAUTEMENT QUALIFIES,Echelon 2,402,"1354,41",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT,Echelon 1 - CONTREMAITRE,501,"1433,28",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT,Echelon 2 - CHEF DE CULTURE,502,"1689,60",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9722,Exploitations horticoles et pépinières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT,Echelon 3 - DIRECTEUR,503,"2232,58",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,OUVRIER SPECIALISE 1ère catégorie,,112,"1289,20",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,OUVRIER SPECIALISE 2ème catégorie,,116,"1295,26",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,OUVRIER QUALIFIE,,124,"1339,25",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,"OUVRIER HAUTEMENT QUALIFIE
 OU CHEF D’EQUIPE",,132,"1440,87",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,AGENT DE CONTRÔLE,,140,"1528,83",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,AGENT TECHNIQUE,,150,"1635,00",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES IIIème groupe,AGENT TECHNIQUE,170,"1845,82",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES IIIème groupe,CONTREMAÎTRE,180,"1898,91",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES IIème groupe,DIRECTEUR DE CULTURE,200,"2152,20",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CADRES IIème groupe,DIRECTEUR GENERAL DE CULTURE,225,"2369,09",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9725,Champignonnières de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,ADRES Ier groupe,DIRECTEUR D’EXPLOITATION,280,"2690,63",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 1 - AGENTS DE PRODUCTION,Echelon 1,11,"1280,09",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 1 - AGENTS DE PRODUCTION,Echelon 2,12,"1286,16",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 2 - AGENTS TECHNIQUES SPECIALISES,Echelon 1,21,"1299,81",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 2 - AGENTS TECHNIQUES SPECIALISES,Echelon 2,22,"1316,50",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 3 - AGENTS TECHNIQUES QUALIFIES,Echelon 1,31,"1339,25",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 3 - AGENTS TECHNIQUES QUALIFIES,Echelon 2,32,"1368,06",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 4 - AGENTS HAUTEMENT QUALIFIES,Echelon 1,41,"1410,53",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU 4 - AGENTS HAUTEMENT QUALIFIES,Echelon 2,42,"1466,65",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,Contremaître,185,"1527,32",byMonth151,,,,,,,
1/3/2021,7/1/2007,,9852,Exploitations horticoles et pépinières de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,Chef de culture,278,"2126,41",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier débutant,"1521,25",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier,"1522,77",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier spécialisé (O.S.1),"1537,93",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier spécialisé (O.S.2),"1560,68",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier qualifié titulaire du C.Q.P,"1618,32",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier qualifié (O.Q),"1653,2",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Ouvrier hautement qualifié (O.H.Q),"1765,44",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Agent de contrôle,"1824,59",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,A - Personnel d’exécution,Agent technique nouvellement embauché (pendant la période probatoire de 3 mois),"1965,64",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,B - Personnel d’encadrement IIIème groupe,Agent technique ayant effectué la période probatoire de trois mois,"2168,88",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,B - Personnel d’encadrement IIIème groupe,Contremaître,"2291,73",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,B - Personnel d’encadrement IIème groupe,Directeur de culture,"2482,84",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,B - Personnel d’encadrement IIème groupe,Directeur général de culture,"2767,98",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - PERSONNEL D’EXPLOITATION,B - Personnel d’encadrement Ier groupe,Directeur d’exploitation,"3432,29",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé débutant,"1521,25",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé,"1522,77",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé spécialisé (E.S. 1),"1537,93",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé spécialisé (E.S. 2),"1560,68",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé qualifié (E.Q.),"1653,2",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,A - Personnel d’exécution,Employé hautement qualifié (E.H.Q.),"1765,44",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,B - Personnel d’encadrement IIIème groupe,170,"2168,88",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,B - Personnel d’encadrement IIIème groupe,180,"2291,73",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,B - Personnel d’encadrement IIème groupe,200,"2482,84",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,B - Personnel d’encadrement IIème groupe,225,"2767,98",byMonth151,,,,,,,
1/3/2021,1/1/2019,,9493,Champignonnières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL DE BUREAU POUR LES CULTURES,B - Personnel d’encadrement Ier groupe,280,"3432,29",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,La convention collective du travail du 10 feverier 1987 concernat les exploitations et entreprises SYLVICOLES de la region PAYS DE LA LOIRE,,,,Niveau I,Niveau I,,"1556,1",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,Exploitations et entreprises sylvicoles de la région Pays de la Loire,,,,Niveau 2,Niveau 2,,"1619,8",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,Exploitations et entreprises sylvicoles de la région Pays de la Loire,,,,Niveau 3,Niveau 3,,"1680,47",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,Exploitations et entreprises sylvicoles de la région Pays de la Loire,,,,Niveau 4,Niveau 4,,"1803,32",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,Exploitations et entreprises sylvicoles de la région Pays de la Loire,,,,Agent de maîtrise,Niveau I,,"1941,34",byMonth151,,,,,,,
1/3/2021,1/1/2020,,8523,Exploitations et entreprises sylvicoles de la région Pays de la Loire,,,,Agent de maîtrise,Niveau 2,,"2065,7",byMonth151,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,200,,,"10,03",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,210,,,"10,08",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,220,,,"10,12",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,250,,,"10,24",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,270,,,"10,35",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,290,,,"10,5",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,320,,,"10,65",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,350,,,"11,04",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,380,,,"11,46",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,410,,,"11,87",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,440,,,"12,32",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,470,,,"12,73",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,500,,,"13,21",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,550,,,"13,91",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,610,,,"14,76",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,720,,,"16,35",byHour,,,,,,,
02/03/2021,1/2/2019,,7006,"coopératives agricoles, unions de coopératives agricoles et SICA de fleurs, de fruits et légumes et de pommes de terre",,,,840,,,"18,07",byHour,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,44-49-53-72-85,,100,,,"1539,45",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,120,,,"1633,49",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,140,,,"1745,72",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,160,,,"1860,99",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,180,,,"1980,81",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,200,,,"2097,6",byMonth151,,,,,,,
02/03/2021,01/01/2020,,8535,Coopératives d'utilisation de matériel agricole (C.U.M.A),,,,220,,,"2214,38",byMonth151,,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent de service de nettoyage et d'entretien,,,"1521,585",byMonth151,1377,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent de restauration,,,"1544,79",byMonth151,1398,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent administratif,,,"1564,68",byMonth151,1416,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent de maintenance,,,"1564,68",byMonth151,1416,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent d'accueil et de surveillance,,,"1587,885",byMonth151,1437,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent d'accueil et de réception,,,"1615,51",byMonth151,1462,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Cuisinier,,,"1668,55",byMonth151,1510,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent technique de maintenance,,,"1660,815",byMonth151,1503,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Agent technique d'administration et/ ou de gestion,,,"1660,815",byMonth151,1503,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Animateur,,,"1744,795",byMonth151,1579,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Technicien de maintenance,,,"1893,97",byMonth151,1714,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Assistant de gestion ou de direction,,,"1829,88",byMonth151,1656,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Chef de restauration,,,"1931,54",byMonth151,1748,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Chargé d'études et de développements,,,"1983,475",byMonth151,1795,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Intervenant social et éducatif,,,"2014,415",byMonth151,1823,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Chargé de logements et/ ou de gestion locative,,,"2014,415",byMonth151,1823,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Responsable de secteur d'activité,,,"2401,165",byMonth151,2173,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Directeur d'établissement ou de services,,,"2791,23",byMonth151,2526,,,,,,
4/3/2021,1/7/2018,,2336,Habitat et logement accompagnés,,,,Directeur d'association ou d'organisme,,,"3124,94",byMonth151,2828,,,,,,
4/3/2021,,,1031,nationale de la fédération nationale des associations familiales rurales (FNAFR),,,,,,,,,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,1,190,"1376,2498",byMonth151,190,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,2,198,"1434,19716",byMonth151,198,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,3,215,"1557,3353",byMonth151,215,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,4,240,"1738,4208",byMonth151,240,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,5A,260,"1883,2892",byMonth151,260,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,5B,285,"2064,3747",byMonth151,285,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,6,315,"2281,6773",byMonth151,315,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,7,360,"2607,6312",byMonth151,360,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,8,400,"2897,368",byMonth151,400,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,Employés et cadres,9,430,"3114,6706",byMonth151,430,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,,,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 1,190,"1376,2498",byMonth151,190,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 1,190,"1376,2498",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 2,198,"1376,2498",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 3,215,"1434,19716",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 4,240,"1557,3353",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 5,285,"1738,4208",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 6,300,"2064,3747",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 7,345,"2173,026",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 8,385,"2498,9799",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 9,410,"2788,7167",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 10,605,"2969,8022",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 11,690,"4382,2691",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,"Personnel soignant, éducatif et médical des établissements et oeuvres",Niveau 12,725,"4997,9598",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Ingénieurs Conseils,Niveau 10A,570,"5251,4795",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Ingénieurs Conseils,Niveau 10B,595,"4128,7494",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Ingénieurs Conseils,Niveau 11A,620,"4309,8349",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Ingénieurs Conseils,Niveau 11B,670,"4490,9204",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Ingénieurs Conseils,Niveau 12,700,"4853,0914",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau 1 A,215,"5070,394",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau 1 B,240,"1557,3353",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau II A,260,"1738,4208",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau II B,260,"1883,2892",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau III,291,"1883,2892",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau IV A,323,"2107,83522",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau IV B,338,"2339,62466",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau V A,352,"2448,27596",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau V B,382,"2549,68384",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau VI,397,"2766,98644",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau VII,458,"2875,63774",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau VIII,570,"3317,48636",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau IX A,618,"4128,7494",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau IX B,688,"4476,43356",byMonth151,,,,,,,
4/3/2021,30/9/2004,,218,organismes de sécurité sociale,,,http://ekladata.com/an94mPPN6IiV8XnoVi6j-lkFjHk/120418_PA_classification_emplois.pdf,Informaticiens,Niveau X,700,"4983,47296",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Employé d'entretien des bureaux,420,"5070,394",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Employé de bureau,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Standardiste-réceptionniste,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire 1er échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire 2eme échelon,450,"1542,0697",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire 3eme échelon,500,"1634,8747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire 3eme échelon / coefficient intermediaire,550,"1727,6797",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire de direction 1er échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Secrétaire de direction 2eme échelon,700,"2006,0947",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Administration,Assistant(e) de direction,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Aide-comptable,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Comptable 1er échelon,450,"1542,0697",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Comptable 2ème échelon,500,"1634,8747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Comptable 3eme échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Comptable principal,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Comptabilité,Chef comptable,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Métreur-évaluateur 1er échelon,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Métreur-évaluateur 2ème échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Vérificateur-métreur 1er échelon,550,"1727,6797",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Vérificateur-métreur 2ème échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Technicien spécialisé 1er échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Vérificateur-métreur 2eme échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Technicien hautement spécialisé,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Conducteur de travaux 1er échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Conducteur de travaux 2eme échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : bâtiment,Conducteur principal,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Dessinateur,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Dessinateur-projeteur 1er échelon,450,"1542,0697",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Dessinateur-projeteur 2eme échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Dessinateur-projeteur-compositeur 1er échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Dessinateurs,2e échelon,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Conseillers techniques,Conseillers techniques 1er échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Conseillers techniques,Conseillers techniques 2eme échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Conseillers techniques,Conseillers techniques 3eme échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Conseillers techniques,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Technique : Conseillers techniques,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion : Entretien des immeubles,Agent d'entretien,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion : Entretien des immeubles,Ouvrier d'entretien 1er échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion : Entretien des immeubles,Ouvrier d'entretien 2eme échelon,440,"1523,5087",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Ouvrier qualifié 1er échelon,440,"1523,5087",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Ouvrier qualifié 2eme échelon,450,"1542,0697",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Ouvrier qualifié 3eme échelon,460,"1560,6307",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Ouvrier hautement qualifié 1er échelon,470,"1579,1917",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Ouvrier hautement qualifié 2eme échelon,480,"1597,7527",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Chef d'équipe 1er échelon,490,"1616,3137",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Chef d'équipe 2ème échelon,500,"1634,8747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Encadrant technique 1er échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Encadrant technique 2eme échelon,700,"2006,0947",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Coordinateur technique Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion: Ouvriers qualifiés et chefs d'équipe,Coordinateur technique Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gardien d'immeuble 1er échelon,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gardien d'immeuble 2eme échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire 1er échelon,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire 2eme échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire 3eme échelon,500,"1634,8747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire immobilier - Agent de maîtrise,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire immobilier Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire immobilier Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion immobilière,Gestionnaire immobilier principal,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent social 1er échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent social 2ème échelon,500,"1634,8747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent social 3eme échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent social 4eme échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Coordinateur social 1er échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Gestion sociale,Coordinateur social 2eme échelon,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Conseiller habitat 1er échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Conseiller habitat 2eme échelon,450,"1542,0697",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Conseiller habitat 3eme échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Conseiller habitat 4ème échelon,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,"Accueil, Conseil, Traitement des dossiers",Conseiller(ère) habitat principal,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Documentation,Documentaliste 1er échelon,430,"1504,9477",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Documentation,Documentaliste 2eme échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'information-communication 1er échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'information-communication 2eme échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise Niveau 1,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise Niveau 2,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'information-communication 3er échelon,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Enquêteur,420,"1486,3867",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Assistant d'études ou d'opérations 1er échelon,530,"1690,5577",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Assistant d'études ou d'opérations 2eme échelon,600,"1820,4847",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'études 1er échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'études 2eme échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 1er échelon,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 2eme échelon,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 3eme échelon,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chef de service études,1 000,"2562,9247",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'opérations 1er échelon,660,"1931,8507",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé d'opérations 2eme échelon,730,"2061,7777",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 1er échelon,750,"2098,8997",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 2eme échelon,800,"2191,7047",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Agent de maîtrise 3eme échelon,870,"2321,6317",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé de mission 1er échelon,900,"2377,3147",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Information – Communication,Chargé de mission 2eme échelon,1000,"2562,9247",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Responsable de service 1er échelon,1000,"2562,9247",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Responsable de service 2eme échelon,1050,"2655,7297",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Responsable d'agence 1er échelon,1000,"2562,9247",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Responsable d'agence 2ème échelon,1050,"2655,7297",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur adjoint,1100,"2748,5347",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur 1er échelon,1200,"2934,1447",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur 2ème échelon,1300,"3119,7547",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur 3eme échelon,1400,"3305,3647",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur général,1500,"3490,9747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur. – Délégué territorial,1500,"3490,9747",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1278,Personnels PACT et ARIM,,,https://www.cfdt-construction-bois.fr/images/Conv-collectives/Accords/Accord_relatif_%C3%A9volution_classif_CCN_Pact_Arim.pdf,Direction,Directeur fédéral,1500,"3490,9747",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Insertion sociale et professionnelle,Chargé de projet,488,"26820,48",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Insertion sociale et professionnelle,Chargé d'accueil,360,"19785,6",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Insertion sociale et professionnelle,Conseiller niveau 1,398,"21874,08",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Insertion sociale et professionnelle,Conseiller niveau 2,398,"21874,08",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Information et communication,Chargé de documentation,369,"20280,24",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Information et communication,Chargé d'animation,360,"19785,6",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Information et communication,Chargé d'information et de communication,389,"21379,44",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Gestion,Assistant administratif,344,"18906,24",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Gestion,Assistant de direction,389,"21379,44",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Gestion,Assistant de gestion,364,"20005,44",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Gestion,Assistant financier,389,"21379,44",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Gestion,Assistant informatique,398,"21874,08",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Encadrement,Directeur,608,"33415,68",byMonth151,,,,,,,
4/3/2021,1/9/2017,,2190,Missions Locales et PAIO,,,,Encadrement,Responsable de secteur,548,"30118,08",byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,1A,,,1499,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,1B,,,1499,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,1C,,,1502,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,2A,,,1499,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,2B,,,1516,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,2C,,,1572,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,2D,,,1653,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,3A,,,1585,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,3B,,,1677,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,3C,,,1798,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,3D,,,1905,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,4A,,,1791,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,4B,,,1864,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,4C,,,2048,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,4D,,,2293,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,5A,,,2139,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,5B,,,2243,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,5C,,,2582,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,5D,,,2990,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,6A,,,2631,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,6B,,,2698,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,6C,,,2991,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,6D,,,3334,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,7B,,,3890,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,7C,,,4198,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,7D,,,4538,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,8C,,,5271,byMonth151,,,,,,,
4/3/2021,1/1/2018,,1 794,Personnel des institutions de retraites complémentaires,,,,8D,,,5336,byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Praticien-conseil du service du contrôle médical.,582,,"4194,69516",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Praticien-conseil en charge d'attributions ou de missions d'ordre technique,705,,"5081,2029",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Praticien-conseil chef de service exerçant des responsabilités de management,705,,"5081,2029",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Praticien-conseil chef de service responsable d'un échelon local du contrôle médical,705,,"5081,2029",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Médecin-conseil régional adjoint,805,,"5801,9409",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Praticien conseil exerçant des responsabilités nationales,805,,"5801,9409",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Médecin-conseil régional adjoint,855,,"6162,3099",byMonth151,,,,,,,
4/3/2021,1/5/2010,,2603,praticiens-conseils du régime général de sécurité sociale,,,,Médecin-conseil national adjoint,855,,"6162,3099",byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Agent de Maintenance,298,,16390,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Comptable,390,,21450,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Animateur,386,,21230,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Animateur d'activité,292,,16060,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Assistant de direction,371,,20405,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Auxiliaire petite enfance ou de soins,300,,16500,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Cadre fédéral,578,,31790,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Chargé d'accueil,390,,21450,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Coordinateur,444,,24420,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Directeur,552,,30360,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Educateur petite enfance,384,,21120,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Intervenant technique,319,,17545,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Personnel administratif,292,,16060,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Personnel de service,292,,16060,byMonth151,,,,,,,
4/3/2021,17/1/2020,,1261,acteurs du lien social et familial,,,http://www.cpnef.com/index.php/cpnef-emploi/cpnef-emploi-conditions/cpnef-emploi-conditions-systeme-remuneration/453-cpnef-systeme-remuneration-extrait-convention-collective-chapitre-xii.html,Secrétaire,337,,18535,byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS DE PRODUCTION ,,101,"1 321,05 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS TECHNIQUES SPECIALISES,Echelon 1 ,201,"1 333,18 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS TECHNIQUES SPECIALISES,Echelon 2 ,202,"1 345,31 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 1 ,301,"1 355,93 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 2 ,302,"1 383,23 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS HAUTEMENT QUALIFIES,Echelon 1 ,401,"1 428,73 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS HAUTEMENT QUALIFIES,Echelon 2 ,402,"1 581,92 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,Chef de culture - Groupe II ,501,"2 320,55 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9851,"Exploitations de polyculture, de viticulture et d'élevage de la Vendée",,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'ENCADREMENT,Régisseur et Directeur d'exploitation - Groupe I,601,"2 896,90 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS DE PRODUCTION,Echelon 1 ,11,"1 321,05 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS DE PRODUCTION,Echelon 2 ,12,"1 327,11 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II -AGENTS TECHNIQUES SPECIALISES,Echelon 1 ,21,"1 340,76 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II -AGENTS TECHNIQUES SPECIALISES,Echelon 2 ,22,"1 358,96 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 1 ,31,"1 381,71 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 2 ,32,"1 412,05 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS HAUTEMENT QUALIFIES ,,41,"1 456,03 ",byMonth151,,,,,,,
2/3/2021,1/7/2008,,9853,Exploitations maraîchères de la Vendée,,85,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,CHEF D'EQUIPE,Cadre G III ,51,"1 556,13 ",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU I - Agents de production,Echelon 1,"1430,25",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU I - Agents de production,Echelon 2,1442.38 ,byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU II - Agents techniques spécialisés,Echelon 1,"1465,13",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU II - Agents techniques spécialisés,Echelon 2,"1507,6",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU III - Agents techniques qualifiés,Echelon 1,"1513,67",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU III - Agents techniques qualifiés,Echelon 2,"1580,4",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU IV - Agents techniques hautement qualifiés,Echelon 1,"1669,89",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,PERSONNEL D'EXECUTION ,NIVEAU IV - Agents techniques hautement qualifiés,Echelon 2,"1894,36",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL D'ENCADREMENT,NIVEAU V - Contremaître,500,"2073,36",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL D'ENCADREMENT,NIVEAU VI - Chef de culture ou d'élevage  ,600,"2461,6",byMonth151,,,,,,,
2/3/2021,1/2/2013,,9531,Exploitations de polyculture et d'élevage de la Mayenne,,53,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - PERSONNEL D'ENCADREMENT,NIVEAU VII - Régisseur ou Directeur d'exploitation ,700,"2974,25",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU I - AGENTS DE PRODUCTION ,Echelon 1 ,11,"1 280,09 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS TECHNIQUES D’EXPLOITATION,Echelon 1 ,21,"1 289,20 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU II - AGENTS TECHNIQUES D’EXPLOITATION,Echelon 2,22,"1 308,91 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 1 ,31,"1 324,08 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU III - AGENTS TECHNIQUES QUALIFIES,Echelon 2,32,"1 352,90 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU IV - AGENTS TECHNIQUES HAUTEMENT QUALIFIES ,,41,"1 418,11 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT,Echelon 1 : Contremaître ,51,"1 516,70 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT,Echelon 2 : Chef de culture ou d’élevage,52,"1 835,21 ",byMonth151,,,,,,,
2/3/2021,1/7/2007,,9721,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,NIVEAU V - AGENTS D’ENCADREMENT, Echelon 3 : Régisseur ou directeur d’exploitation,53,"2 153,71 ",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - AGENTS D'EXECUTION,Manoeuvre ,110,"1521,25",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - AGENTS D'EXECUTION,Ouvrier .,145,"1533,38",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I - AGENTS D'EXECUTION,Ouvrier qualifié,155,"1539,45",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - AGENTS D'ENCADREMENT,Chef d'équipe ,180,"1551,58",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - AGENTS D'ENCADREMENT,Contremaître ,220,"1644,1",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - AGENTS D'ENCADREMENT,Chef de cultures ,300,"2032,38",byMonth151,,,,,,,
2/3/2021,1/1/2019,,9494,Exploitations de cultures légumières de Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II - AGENTS D'ENCADREMENT,Cadres du 1er groupe ,330,"2173,43",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 1 – EMPLOIS D’EXECUTANTS,Echelon 1 ,"1539,45",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 1 – EMPLOIS D’EXECUTANTS,Echelon 2 ,1544,byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 2 – EMPLOIS SPECIALISES,Echelon 1 ,"1548,55",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 2 – EMPLOIS SPECIALISES,Echelon 2 ,"1554,62",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 3 – EMPLOIS QUALIFIES,Echelon 1 ,"1584,95",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 3 – EMPLOIS QUALIFIES,Echelon 2 ,1635,byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 4 – EMPLOIS HAUTEMENT QUALIFIES,Echelon 1 ,"1786,67",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,I – PERSONNEL D’EXPLOITATION ,NIVEAU 4 – EMPLOIS HAUTEMENT QUALIFIES,Echelon 2 ,"1947,44",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II – PERSONNEL DE BUREAU,Employé de bureau débutant ,110,"1539,45",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II – PERSONNEL DE BUREAU,Employé de bureau ,210,"1548,55",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II – PERSONNEL DE BUREAU,Employé de bureau très qualifié ,310,"1584,95",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,II – PERSONNEL DE BUREAU,Agent administratif et comptable ,410,"1786,67",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,III – PERSONNEL D’ENCADREMENT,Groupe III – Chef d’équipe ou Contremaître ,176,"1971,71",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,III – PERSONNEL D’ENCADREMENT,Groupe II – Chef de cultures ou Gérant d'exploitation,245,"2378,19",byMonth151,,,,,,,
2/3/2021,1/1/2020,,9491,"Exploitations de polyculture, de viticulture et d'élevage de la Sarthe",,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,III – PERSONNEL D’ENCADREMENT,Groupe I – Directeur d’exploitation ou Régisseur ,320,"3054,63",byMonth151,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ouvriers,1 A DEM,"10,04",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ouvriers,1 B DEM,"10,27",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ouvriers,1 C DEM,"10,46",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ouvriers,1 D DEM,"10,99",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Employés,2 A DEM,"10,28",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Employés,2 B DEM,"10,34",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Employés,2 C DEM,"10,51",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Employés,2 D DEM,"10,65",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Techniciens et agents de maîtrise,3 A DEM,"11,09",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Techniciens et agents de maîtrise,3 B DEM,"12,08",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Techniciens et agents de maîtrise,3 C DEM,"13,82",byHour,,,,,,,
2/3/2021,10/1/2019,,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Techniciens et agents de maîtrise,3 D DEM,"15,57",byHour,,,,,,,
2/3/2021,10/1/2019,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ingénieurs et cadres,4 A DEM,"2654,19",byMonth151,,,,,,,
2/3/2021,10/1/2019,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ingénieurs et cadres,4 B DEM,"2965,68",byMonth151,,,,,,,
2/3/2021,10/1/2019,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Avenant n°  17 du 15  mai 2019 (NOR  : ASET1951142M) étendu par A. 25 févr. 2020 : JO 29 févr. 2020 et applicable le 1er jour suivant la parution de son extension, et au plus tard le 1er octobre 2019 (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)",Transport de déménagement,Ingénieurs et cadres,4 C DEM,"3613,66",byMonth151,,,,,,,
2/3/2021,12/1/2018,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,110 L,"19061,39",byYear1607,,,,,,,
2/3/2021,12/1/2018,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,115 L,"19224,02",byYear1607,,,,,,,
2/3/2021,12/1/2018,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,120 L,"19305,87",byYear1607,,,,,,,
2/3/2021,12/1/2018,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,125 L,"19436,43",byYear1607,,,,,,,
2/3/2021,12/1/2018,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,138 L,"19515,7",byYear1607,,,,,,,
2/3/2021,12/1/2018,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,110 L,"19823,85",byYear1607,,,,,,,
2/3/2021,12/1/2018,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,115 L,"19992,98",byYear1607,,,,,,,
2/3/2021,12/1/2018,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,120 L,"20078,1",byYear1607,,,,,,,
2/3/2021,12/1/2018,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,125 L,"20213,89",byYear1607,,,,,,,
2/3/2021,12/1/2018,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ouvriers ,138 L,"20296,33",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Employés,110 L,"19402,12",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Employés,120 L,"19515,99",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Employés,110 L,"19984,18",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Employés,120 L,"20101,47",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,150 L,"21814,89",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,"157,5 L","21951,68",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,165 L,"22795,08",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,200 L,"26879,42",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,,"22469,34",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,,"22610,23",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,,"23478,93",byYear1607,,,,,,,
2/3/2021,12/1/2018,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Techniciens et agents de maîtrise,,"27685,8",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ingénieurs et cadres,100 L,"34689,15",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ingénieurs et cadres,"106,5 L","36952,58",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ingénieurs et cadres,113 L,"39199,24",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ingénieurs et cadres,119 L,"40993,23",byYear1607,,,,,,,
2/3/2021,12/1/2018,1-4,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  11 du 30  juill. 2018 (NOR  : ASET1851183M) étendu par A. 25  juin 2019  : JO 29  juin 2019 et applicable dès signature ; cf. Accord de révision du 20 sept. 2019 (NOR : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée),Prestations logistiques,Ingénieurs et cadres,132 L,"45808,16",byYear1607,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,110 V,"1554,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,115 V,"1554,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,120 V,"1554,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,123 V,"1554,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,128 V,"1554,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,131 V,"1578,07",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,136 V,"1591,61",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,137 V,"1596,69",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,138 V,"1625,46",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,140 V,"1637,34",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,142 V,"1653,72",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,145 V,"1671,21",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,150 V,"1711,82",byMonth151,,,,,,,
2/3/2021,3/1/2020,1,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,155 V,"1797,61",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,110 V,"1585,54",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,115 V,"1585,54",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,120 V,"1585,54",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,123 V,"1585,54",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,128 V,"1585,54",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,131 V,"1609,63",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,136 V,"1623,44",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,137 V,"1628,62",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,138 V,"1657,97",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,140 V,"1670,09",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,142 V,"1686,79",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,145 V,"1704,63",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,150 V,"1746,06",byMonth151,,,,,,,
2/3/2021,3/1/2020,2-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ouvriers,155 V,"1833,56",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,2,"1568,78",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,3,"1568,78",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,4,"1569,19",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,5,"1569,44",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,6,"1569,62",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,7,"1584,66",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,8,"1598,92",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,9,"1696,4",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,2,"1615,84",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,3,"1615,84",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,4,"1616,27",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,5,"1616,52",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,6,"1616,71",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,7,"1632,2",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,8,"1646,89",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Employés,9,"1747,29",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,1,"1713,92",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,2,"1799,19",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,3,"1885,5",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,4,"2000,51",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,5,"2113,04",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,6,"2284,21",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,7,"2455,37",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-2,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,8,"2570,34",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,1,"1765,34",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,2,"1853,17",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,3,"1942,07",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,4,"2060,53",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,5,"2176,43",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,6,"2352,74",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,7,"2529,03",byMonth151,,,,,,,
2/3/2021,3/1/2020,3-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Techniciens et agents de maîtrise,8,"2647,45",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,1,"2382,27",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,2,"2537,15",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,3,"2691,96",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,4,"2834,85",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,5,"3144,56",byMonth151,,,,,,,
2/3/2021,3/1/2020,1-4,16,Transports routiers et activités auxiliaires du transport,,,"cf. Annexe n°  4 «  Dispositions particulières aux ingénieurs et cadres  », art.  5 et 6  ; cf. Accord du 18  avr. 2002 (NOR  : ASET0250462M) étendu par A. 22  déc. 2003  : JO 7  janv. 2004  ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée) ; cf. Accord de révision du 20  sept. 2019 (NOR  : ASET1951352M) non étendu et applicable à son extension (Conclu pour une durée indéterminée)  ; cf. Avenant n°  113 du 3  mars 2020 (NOR  : ASET2050507M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  95 du 3  mars 2020 (NOR  : ASET2050504M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  93 du 3  mars 2020 (NOR  :ASET2050505M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1er mars 2020 (Conclu pour une durée indéterminée)  ; cf. Avenant n°  85 du 3  mars 2020 (NOR  : ASET2050502M) étendu par A. 17  sept. 2020  : JO 3  oct. 2020 et applicable au 1ermars2020 (Conclu pour une durée indéterminée)",Transport routier de voyageurs,Ingénieurs et cadres,6,"3454,26",byMonth151,,,,,,,
2/3/2021,7/1/2016,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 2,Transport sanitaire ,Ambulancier 1er degré groupe a,Etape 1,"9,7176",byHour,,,,,,,
2/3/2021,7/1/2016,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 3,Transport sanitaire ,Ambulancier 1er degré groupe a,Etape 2,"9,9042",byHour,,,,,,,
2/3/2021,7/1/2016,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 4,Transport sanitaire ,Ambulancier 1er degré groupe a,Etape 3,"10,0943",byHour,,,,,,,
2/3/2021,7/1/2016,1,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 5,Transport sanitaire ,Ambulancier 2e degré groupe b,Etape 1,"10,313",byHour,,,,,,,
2/3/2021,7/1/2016,2,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 6,Transport sanitaire ,Ambulancier 2e degré groupe b,Etape 2,"10,511",byHour,,,,,,,
2/3/2021,7/1/2016,3,16,Transports routiers et activités auxiliaires du transport,,,cf. Avenant n°  4 du 16  juin 2016 (NOR  : ASET1650853M) étendu par A. 6 déc. 2016 : JO 9 déc. 2016 et applicable le 1er juillet 2016 dans les conditions de son art. 7,Transport sanitaire ,Ambulancier 2e degré groupe b,Etape 3,"10,7129",byHour,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ navire de mer,Long cours,"30113,53",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ DESMM BOESMM,Long cours,"32919,87",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ pont et machine,Long cours,"28294,39",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ navire de mer,Long cours,"30113,53",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ DESMM BOESMM,Long cours,"32919,87",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ pont et machine,Long cours,"28294,39",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ navire de mer,Cabotage international,"27311,03",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ DESMM BOESMM,Cabotage international,"30113,53",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire ≥ 15 000,CQ pont et machine,Cabotage international,"25491,76",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ navire de mer,Cabotage international,"27311,03",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ DESMM BOESMM,Cabotage international,"30113,53",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ pont et machine,Cabotage international,"25491,76",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Officier - Navire < 15 000,CQ machine,Cabotage international,"22549,21",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Capitaine,Long cours,"51543,3",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Chef mécanicien,Long cours,"47624,93",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Scd capitaine et scd mécanicien,Long cours,"37677,85",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Capitaine,Long cours,"49304,29",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Chef mécanicien,Long cours,"45382,09",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Scd capitaine et scd mécanicien,Long cours,"35858,71",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Capitaine,Cabotage international,"44818,6",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Chef mécanicien,Cabotage international,"42579,58",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire ≥ 15 000,Scd capitaine et scd mécanicien,Cabotage international,"33475,95",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Capitaine,Cabotage international,"42579,58",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Chef mécanicien,Cabotage international,"40480,48",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - 3 000 ≤ Navire < 15 000,Scd capitaine et scd mécanicien,Cabotage international,"31656,68",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire < 3 000,Capitaine,Cabotage international,"38517,47",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire < 3 000,Chef mécanicien,Cabotage international,"36626,39",byYear1607,,,,,,,
2/3/2021,,,3223,Entreprises de transport et services maritimes (personnels navigants officiers),,,"cf. CC. n° 0000-74, Art. 4.3.9 ; cf. Annexe III",Fonction de directeur - Navire < 3 000,Scd capitaine et scd mécanicien,Cabotage international,"29413,83",byYear1607,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2019,Embauche,I,,"1531,22",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2020,Embauche,II,1,"1754,59",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2021,Embauche,II,2,"1879,16",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2022,Embauche,III,1,"2048,04",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2023,Embauche,III,2,"2173,79",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2024,Embauche,IV,1,"2299,55",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2025,Embauche,IV,2,"2473,21",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2026,Embauche,V,TAC,"2634,89",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2027,Embauche,V,Direction,3377,byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2028,Confirmé,I,,"1561,84",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2029,Confirmé,II,1,"1789,68",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2030,Confirmé,II,2,"1916,74",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2031,Confirmé,III,1,2089,byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2032,Confirmé,III,2,"2217,27",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2033,Confirmé,IV,1,"2345,54",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2034,Confirmé,IV,2,"2522,68",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2035,Confirmé,V,TAC,"2687,59",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2037,Maîtrisé,I,,"1639,93",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2038,Maîtrisé,II,1,"1879,16",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2039,Maîtrisé,II,2,"2012,58",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2040,Maîtrisé,III,1,"2193,45",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2041,Maîtrisé,III,2,"2328,13",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2042,Maîtrisé,IV,1,"2462,82",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2043,Maîtrisé,IV,2,"2648,81",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2044,Maîtrisé,V,TAC,"2821,97",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2047,Expert,II,1,"2010,7",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2048,Expert,II,2,"2153,46",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2049,Expert,III,1,"2346,99",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2050,Expert,III,2,"2491,1",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2051,Expert,IV,1,"2635,22",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2052,Expert,IV,2,"2834,23",byMonth151,,,,,,,
2/3/2021,2/1/2019,,7005,Caves coopératives vinicoles et leurs unions,,,cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin 2019 et applicable au 1er févr. 2053,Expert,V,TAC,"3019,51",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2020 ","Filière location de linge, blanchisseurs",Ouvriers,1.1,"1 539,42",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2021","Filière location de linge, blanchisseurs",Ouvriers,2.1,"1 554,96",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2022","Filière location de linge, blanchisseurs",Ouvriers,2.2,"1 569,84",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2023","Filière location de linge, blanchisseurs",Ouvriers,2.3,"1 593,12",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2024","Filière location de linge, blanchisseurs",Ouvriers,3.1,"1 598,39",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2025","Filière location de linge, blanchisseurs",Ouvriers,3.2,"1 622,17",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2026","Filière location de linge, blanchisseurs",Ouvriers,4.1,"1 671,92",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2027","Filière pressings, laveries",Ouvriers,A1,"1 539,42",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2028","Filière pressings, laveries",Ouvriers,A2,"1 544,73",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2029","Filière pressings, laveries",Ouvriers,A3,"1 554,57",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2030","Filière pressings, laveries",Ouvriers,B1,"1 570,49",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2031","Filière pressings, laveries",Ouvriers,B2,"1 579,15",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2032","Filière pressings, laveries",Ouvriers,B3,"1 594,11",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2033","Filière pressings, laveries",Ouvriers,C1,"1 609,09",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2034","Filière location de linge, blanchisseurs, employés",ETAM,1-1,"1 539,42",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2035","Filière location de linge, blanchisseurs, employés",ETAM,2-1,"1 554,96",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2036","Filière location de linge, blanchisseurs, employés",ETAM,3-1,"1 598,39",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2037","Filière location de linge, blanchisseurs, employés",ETAM,4-1,"1 671,92",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2038","Filière location de linge, blanchisseurs, techniciens et agents de maîtrise",ETAM,5-1,"1 719,96",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2039","Filière location de linge, blanchisseurs, techniciens et agents de maîtrise",ETAM,5-2,"1 814,78",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2040","Filière location de linge, blanchisseurs, techniciens et agents de maîtrise",ETAM,6-1,"1 926,33",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2041","Filière pressings, laveries, employés",ETAM,1-1,"1 539,42",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2042","Filière pressings, laveries, employés",ETAM,2-1,"1 554,96",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2043","Filière pressings, laveries, employés",ETAM,3-1,"1 598,39",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2044","Filière pressings, laveries, employés",ETAM,4-1,"1 671,92",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2045","Filière pressings, laveries, techniciens et agents de maîtrise",ETAM,C2,"1 632,62",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2046","Filière pressings, laveries, techniciens et agents de maîtrise",ETAM,C3,"1 655,40",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2047","Filière pressings, laveries, techniciens et agents de maîtrise",ETAM,D1,"1 874,71",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2048","Filière pressings, laveries, techniciens et agents de maîtrise",ETAM,D2,"1 899,53",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2049","Filière location de linge, blanchisseurs, pressings et laveries",Cadres,7-1,"2 277,14",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2050","Filière location de linge, blanchisseurs, pressings et laveries",Cadres,8-1,"3 009,20",byMonth151,,,,,,,
01/03/2021,1/1/2020,,2002,"Blanchisserie, laverie, location de linge, nettoyage à sec, pressing et teinturerie",,,"cf. CC. n° 3074, Art. 8.2.5 ; cf. Avenant du 14 avr. 2020 (NOR : ASET2050477M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable au 1er janv. 2051","Filière location de linge, blanchisseurs, pressings et laveries",Cadres,9-1,"3 342,94",byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 1,100,1 549,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 1,105,1 559,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 2,110,1 569,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 2,115,1 579,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 2,120,1 615,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 3,130,1 700,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 3,135,1 766,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 3,140,1 832,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 4,155,2 028,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 5,175,2 278,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 6,205,2 668,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels des activités machines à sous, accueil, gestion, technique et spectacle",Niveau 7,230,2 993,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 1,100,1 549,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 1,105,1 559,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 2,110,1 569,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 2,115,1 579,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 2,120,1 615,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 3,130,1 700,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 3,140,1 832,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 4,155,2 028,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 5,175,2 278,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 6,205,2 668,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ","Personnels de la restauration, hôtellerie",Niveau 7,230,2 993,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 1,100,1 549,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 1,105,1 559,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 2,110,1 569,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 2,120,1 579,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 3,130,1 700,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 3,140,1 832,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 3,150,1 963,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 4,160,2 093,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 4,170,2 224,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 5,175,2 278,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 5,180,2 343,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 5,190,2 473,byMonth151,,,,,,,
01/03/2021,1/9/2020,,2257,Casinos,,,"cf. CC. n° 3167, Art. 31 et s. ",Personnel des jeux traditionnels,Niveau 6,200,2 604,byMonth151,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,1,,,18 770,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,2,,,20 111,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,3,,,21 775,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,4,,,24 447,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,5,,,28 836,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,5 bis (1),,,33 222,byYear1607,,,,,,,
01/03/2021,1/1/2020,,2335,Personnel des agences générales d'assurances,,,cf. Annexe I  ; cf. Avenant n°  23 du 18  déc. 2019 (NOR  : ASET2050600M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et applicable à compter du 1er janv. 2020 ,6,,,37 608,byYear1607,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Délégué général,O - I,"2 930,16",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur général adjoint,O - I,"2 930,16",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur des productions,O - I,"2 930,16",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur des programmes,O - I,"2 930,16",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur des jeux,O - II,"2 795,66",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Secrétaire général,O - II,"2 663,79",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur administratif et financier,O - II,"2 663,79",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur financier,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur juridique,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur technique,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur des ressources humaines,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur littéraire,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur du développement,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur informatique,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur de la comptabilité,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur de la communication,O - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable administratif et financier,O - IIIA,"2 344,13",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Chef comptable,O - IIIA,"2 237,58",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des ressources humaines,O - IIIA,"2 237,58",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable du développement,O - IIIA,"2 237,58",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable informatique,O - IIIA,"2 237,58",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable juridique,O - IIIA,"2 237,58",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Contrôleur de gestion,O - IIIA,"2 131,04",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable de la trésorerie,O - IIIA,"2 131,04",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable de la communication,O - IIIA,"2 131,04",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des sites web/multimédia,O - IIIA,"2 131,04",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable de la paie,O - IIIA,"2 068,56",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable technique,O - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des services généraux,O - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Chargé de mission,O - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Attaché de direction,O - IIIA,"1 919,97",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable de la comptabilité,O - IIIB,"1 917,93",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable de l'administration du personnel,O - IIIB,"1 917,93",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Attaché de presse,O - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Collaborateur juridique,O - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Contrôleur de gestion junior,O - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Informaticien,O - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable d'exploitation,O - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Chargé d'étude,O - IIIB,"1 793,45",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Comptable,O - IV,"1 758,10",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Gestionnaire paie,O - IV,"1 758,10",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Webmestre,O - IV,"1 704,83",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Chargé des services généraux,O - IV,"1 598,27",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant de direction,O - IV,"1 598,27",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant juridique,O - IV,"1 598,27",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Secrétaire – assistant,O - V,"1 523,69",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Secrétaire – standardiste,O - V,"1 523,69",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable d'entretien,O - V,"1 470,41",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant paye,O - V,"1 470,41",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant comptable,O - V,"1 470,41",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant de la communication,O - V,"1 450,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Agent des services généraux,O - V,"1 450,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Hôtesse – standardiste,O - VI,"1 440,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Chauffeur d'entreprise,O - VI,"1 440,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Agent d'exploitation,O - VI,"1 435,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Coursier,O - VI,"1 435,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Gardien,O - VI,"1 435,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Agent d'entretien,O - VI,"1 435,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur du pôle édition. – Distribution,P - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur multimédia,P - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur produits dérivés,P - II,"2 557,23",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Directeur commercial,P - II,"2 344,13",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des ventes,P - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des produits dérivés,P - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable acquisitions,P - IIIA,"2 024,48",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Responsable des supports,P - IIIB,"1 811,38",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Gestionnaire des supports,P - IV,"1 687,95",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Vendeur,P - IV,"1 598,27",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant web/téléphonie/multimédia,P - V,"1 450,00",byMonth151,,,,,,,
01/03/2021,1/7/2012,,2642,Production audiovisuelle,,,"cf. CC. n° 3346, Annexe II ; cf. Avenant n° 2 du 15 nov. 2007 (NOR : ASET0850138M) étendu par A. 21 mai 2008 : JO 28 mai 2008  ; cf. Accord du 2  juill. 2008 (NOR  : ASET0850977M) étendu par A. 30 oct. 2008 : JO 7 nov. 2008 ; cf. Avenant n° 3 du 15 déc. 2008 (NOR : ASET0950247M) étendu par A. 10 juill. 2009 : JO 18 juill. 2009, en vigueur le 1er jour du mois qui suit la publication de son extension ; cf. Avenant n° 4 du 3 juill. 2012 (NOR : ASET1251128M) étendu par A. 22 oct. 2012 : JO 30 oct. 2012 (déposé le 14 août 2012) ; cf. Avenant n° 6 du 1er juill. 2016 (NOR : ASET1650954M) étendu par A. 17 févr. 2020 : JO 25 févr. 2020 et en vigueur à son dépôt (déposé le 27 sept. 2016) ; cf. Avenant n° 9 du 7 juill. 2017 (NOR : ASET1750857M) étendu par A. 19 déc. 2017 : JO 27 déc. 2017 et applicable au 1er août 2017 ; cf. Avenant n° 11 du 4 oct. 2019 (NOR : ASET2050145M) non étendu et en vigueur au 1er oct. 2019 ; cf. Avenant n° 13 du 31 juill. 2020 (NOR : ASET2050756M) non étendu et en vigueur au 1er septembre 2020",Emplois de catégorie A,Assistant commercial,P - V,"1 450,00",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,E1,"1 580,83",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,E2,"1 619,43",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,E3,"1 663,26",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,T1,"1 751,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,T2,"1 845,86",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,T3,"1 971,08",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,C1,"2 487,58",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,C2,"3 076,09",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon A,C3,"3 624,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,E1,"1 660,13",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,E2,"1 699,78",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,E3,"1 739,78",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,T1,"1 840,65",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,T2,"1 937,69",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,T3,"2 070,20",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,C1,"2 611,76",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,C2,"3 231,56",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon B,C3,"3 809,64",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,E1,"1 741,52",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,E2,"1 785,34",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,E3,"1 834,39",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,T1,"1 932,47",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,T2,"2 034,73",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,T3,"2 173,51",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,C1,"2 742,19",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,C2,"3 392,26",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel administratif et de service - Personnel d'ecadrement pédagogique,Echelon C,C3,"3 995,37",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,1. Primaire,"1 727,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,2. Secondaire 1er ,"1 727,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,3. Secondaire 2e cycle,"1 727,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,4. Bac + 1,"1 727,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,5. Bac + 2 non diplomant,"1 797,86",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,6. Bac + 2 diplômant,"1 900,12",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,"7. Bac + 3 diplômant, bac + 4 non diplômant","2 039,94",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,8. Bac + 4 diplômant,"2 165,16",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,9. Bac + 5 non diplômant,"2 165,16",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon A,10. Bac + 5 diplômant,"2 616,83",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,1. Primaire,"1 813,52",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,2. Secondaire 1er ,"1 813,52",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,3. Secondaire 2e cycle,"1 813,52",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,4. Bac + 1,"1 813,52",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,5. Bac + 2 non diplomant,"1 888,64",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,6. Bac + 2 diplômant,"1 995,08",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,"7. Bac + 3 diplômant, bac + 4 non diplômant","2 142,20",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,8. Bac + 4 diplômant,"2 273,68",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,9. Bac + 5 non diplômant,"2 273,68",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon B,10. Bac + 5 diplômant,"2 815,68",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,1. Primaire,"1 904,30",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,2. Secondaire 1er ,"1 904,30",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,3. Secondaire 2e cycle,"1 904,30",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,4. Bac + 1,"1 904,30",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,5. Bac + 2 non diplomant,"1 982,56",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,6. Bac + 2 diplômant,"2 095,25",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,"7. Bac + 3 diplômant, bac + 4 non diplômant","2 248,63",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,8. Bac + 4 diplômant,"2 388,46",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,9. Bac + 5 non diplômant,"2 388,46",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Personnel enseignant,Echelon C,10. Bac + 5 diplômant,"3 035,03",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,1,"21 760,11",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,2,"27 333,18",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,3,"32 015,13",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,4,"34 300,29",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,5,"36 444,58",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon A,6,"40 409,69",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,1,"22 846,34 ",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,2,"28 701,14",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,3,"34 466,20",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,4,"36 015,72",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,5,"38 360,36",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon B,6,"42 430,85",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,1,"22 846,34 ",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,2,"30 994,64",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,3,"37 224,04",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,4,"38 895,64",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,5,"41 326,88",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ",Enseignants intégrés dans des cycles diplômants générant l'obligation de recherche,Echelon C,6,"45 825,20",byYear1607,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon A,EAD 1,"1 836,58",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon A,EAD 2,"1 967,77",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon A,EAD 3,"2 033,36",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon A,EAD 4,"2 098,95",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon B,EAD 1,"1 928,41",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon B,EAD 2,"2 066,16",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon B,EAD 3,"2 135,03",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon B,EAD 4,"2 203,90",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon C,EAD 1,"2 024,83",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon C,EAD 2,"2 169,47",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon C,EAD 3,"2 241,70",byMonth151,,,,,,,
01/03/2021,1/6/2020,,2691,Enseignement privé indépendant,,,"cf. Accord du 12  janv. 2016 (NOR  : ASET1650283M) non étendu et prenant effet au 1er janvier 2016 (conclu pour une durée déterminée de 10 ans), modifié par avenant n°  1 du 5  avr. 2017 (NOR  : ASET1750560M) non étendu, n°  2 du 5 févr. 2018 (NOR : ASET1850334M) non étendu et prenant 
effet à la date du 1er janvier 2018, n° 3 du 25 févr. 2019 non étendu (NOR : ASET1950478M) et prenant effet à la date du 1er janv. 2019, conclus jusqu'au 31 déc. 2025, n° 4 du 5 mai 2020 (NOR : ASET2050929M) non étendu et prenant effet au 1er janvier 2020 ; cf. Avenant n° 40 du 7 mars 2018 (NOR : ASET1850375M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable le 1er jour du mois suivant sa date d'extension ; cf. Avenant n° 45 du 6 févr. 2019 (NOR : ASET1950463M) étendu par A. 21 août 2019 : JO 28 août 2019 et applicable au 1er jour du mois qui suit la date de son extension (conclu pour une durée indéterminée) ; cf. Avenant n° 49 du 5 mai 2020 (NOR : ASET2050639M) non étendu et applicable à compter du 1er juin 2020 (Conclu pour une durée indéterminée) ", Personnel enseignant des entreprises  de l'enseignement privé à distance,Echelon C,EAD 4,"2 314,10",byMonth151,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET2",O / E,Niveau 1,E1,"19 609,18",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET3",O / E,Niveau 1,E2,"19 783,42",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET4",O / E,Niveau 1,E3,"19 953,99",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET5",O / E,Niveau 2,E1,"20 124,29",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET6",O / E,Niveau 2,E2,"20 480,02",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET7",O / E,Niveau 2,E3,"20 895,56",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET8",O / E,Niveau 3,E1,"21 310,85",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET9",O / E,Niveau 3,E2,"21 781,77",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET10",O / E,Niveau 3,E3,"22 252,43",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET11",TAM,Niveau 4,E1,"22 700,74",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET12",TAM,Niveau 4,E2,"23 835,11",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET13",TAM,Niveau 5,E1,"24 969,34",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET14",TAM,Niveau 5,E2,"26 850,47",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET15",TAM,Niveau 6,E1,"28 731,84",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-2,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET16",TAM,Niveau 6,E2,"31 982,54",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET17",O / E,Niveau 1,E1,"20 072,39",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET18",O / E,Niveau 1,E2,"20 250,75",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET19",O / E,Niveau 1,E3,"20 425,34",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET20",O / E,Niveau 2,E1,"20 599,67",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET21",O / E,Niveau 2,E2,"20 963,80",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET22",O / E,Niveau 2,E3,"21 389,16",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET23",O / E,Niveau 3,E1,"21 814,26",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET24",O / E,Niveau 3,E2,"22 296,30",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET25",O / E,Niveau 3,E3,"22 778,08",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET26",TAM,Niveau 4,E1,"23 236,96",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET27",TAM,Niveau 4,E2,"24 398,21",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET28",TAM,Niveau 5,E1,"25 559,18",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET29",TAM,Niveau 5,E2,"27 484,79",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET30",TAM,Niveau 6,E1,"29 410,59",byYear1607,,,,,,,
01/03/2021,1/1/2020,3-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET31",TAM,Niveau 6,E2,"32 738,00",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET32",CADRES,Niveau 7,E1,"33 771,77",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET33",CADRES,Niveau 7,E2,"35 329,40",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET34",CADRES,Niveau 8,E1,"36 886,46",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET35",CADRES,Niveau 8,E2,"50 083,42",byYear1607,,,,,,,
01/03/2021,1/1/2020,1-4,3109,Industries alimentaires diverses (5 branches),,,"cf. CC. n°  0000-34, Art. 6.1  ; cf. Avenant n°  10 du 22  févr. 2018 (NOR : ASET1850648M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et en vigueur le 1er janv. 2018 ; cf. Avenant n° 14 du 12 févr. 2020 (NOR : ASET36",CADRES,Niveau 9,E1,"63 280,20",byYear1607,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2019",Emplois techniques,Cadres Groupe 2,,"2 328,41",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2020",Emplois techniques,Cadres Groupe 3,,"1 980,48",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2021",Emplois techniques,Agents de maîtrise,,"1 853,11",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2022",Emplois techniques,Employés qualifiés Groupe 1,,"1 654,28",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2023",Emplois techniques,Employés qualifiés Groupe 2,,"1 540,97",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2024",Emplois techniques,Employés,,"1 521,22",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2025",Emplois administratifs et commerciaux,Cadres Groupe 1,,"3 135,21",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2026",Emplois administratifs et commerciaux,Cadres Groupe 2,Echelon 1,"2 482,52",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2027",Emplois administratifs et commerciaux,Cadres Groupe 2,Echelon 2,"2 584,93 ",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2028",Emplois administratifs et commerciaux,Cadres Groupe 2,Echelon 3,"2 687,35",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2029",Emplois administratifs et commerciaux,Cadres Groupe 2,Echelon 4,"2 789,76",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2030",Emplois administratifs et commerciaux,Cadres Groupe 2,Echelon 5,"2 892,18",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2031",Emplois administratifs et commerciaux,Cadres Groupe 3,Echelon 1,"2 177,58",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2032",Emplois administratifs et commerciaux,Cadres Groupe 3,Echelon 2,"2 280,00",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2033",Emplois administratifs et commerciaux,Cadres Groupe 3,Echelon 3,"2 382,41",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2034",Emplois administratifs et commerciaux,Cadres Groupe 3,Echelon 4,"   2 484,82 ",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2035",Emplois administratifs et commerciaux,Cadres Groupe 3,Echelon 5,"2 587,24",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2036",Emplois administratifs et commerciaux,Agents de maîtrise,Echelon 1,"1 839,24",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2037",Emplois administratifs et commerciaux,Agents de maîtrise,Echelon 2,"1 905,81 ",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2038",Emplois administratifs et commerciaux,Agents de maîtrise,Echelon 3,"1 977,50",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2039",Emplois administratifs et commerciaux,Agents de maîtrise,Echelon 4,"2 035,88 ",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2040",Emplois administratifs et commerciaux,Agents de maîtrise,Echelon 5,"2 095,28",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2041",Emplois administratifs et commerciaux,Employés qualifiés Groupe 1,Echelon 1,"1 654,68",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2042",Emplois administratifs et commerciaux,Employés qualifiés Groupe 1,Echelon 2,"1 703,84",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2043",Emplois administratifs et commerciaux,Employés qualifiés Groupe 1,Echelon 3,"1 753,00",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2044",Emplois administratifs et commerciaux,Employés qualifiés Groupe 1,Echelon 4,"1 804,20",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2045",Emplois administratifs et commerciaux,Employés qualifiés Groupe 1,Echelon 5,"1 857,46",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2046",Emplois administratifs et commerciaux,Employés qualifiés Groupe 2,Echelon 1,"1 526,20",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2047",Emplois administratifs et commerciaux,Employés qualifiés Groupe 2,Echelon 2,"1 551,10",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2048",Emplois administratifs et commerciaux,Employés qualifiés Groupe 2,Echelon 3,"1 595,10 ",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2049",Emplois administratifs et commerciaux,Employés qualifiés Groupe 2,Echelon 4,"1 639,10",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2050",Emplois administratifs et commerciaux,Employés qualifiés Groupe 2,Echelon 5,"1 667,87",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2051",Emplois administratifs et commerciaux,Employés,Echelon 1,"1 521,22",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2052",Emplois administratifs et commerciaux,Employés,Echelon 2,"1 526,22",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2053",Emplois administratifs et commerciaux,Employés,Echelon 3,"1 531,22",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2054",Emplois administratifs et commerciaux,Employés,Echelon 4,"1 536,22",byMonth151,,,,,,,
01/03/2021,1/10/2019,,3090,Spectacle vivant (entreprises du secteur privé),,,"cf. CC. n° 0000-20, Art. VI.4 ; cf. Avenant du 3 oct. 2019 (NOR : ASET2050110M) étendu par A. 25 mai 2020 : JO 3 juin 2020 et en vigueur le 1er oct. 2055",Emplois administratifs et commerciaux,Employés,Echelon 5,"1 541,22",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2020,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Ouvrier - Employé,Niveau I,"1 558,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2021,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Ouvrier - Employé,Niveau II,"1 566,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2022,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Ouvrier - Employé,Niveau III,"1 577,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2023,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",TAM,Niveau III,"1 646,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2024,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",TAM,Niveau IV,"1 809,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2025,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",TAM,Niveau V,"1 925,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2026,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Cadre,Niveau IV,"2 354,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2027,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Cadre,Niveau V,"3 149,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2028,"Industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir ",Cadre,Niveau VI,"3 613,00",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2029,Ganterie de peau – Personnel atelier,Personnel ouvrier,I - 1,"1 548,95",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2030,Ganterie de peau – Personnel atelier,Personnel ouvrier,I - 2,"1 556,13",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2031,Ganterie de peau – Personnel atelier,Personnel ouvrier,II - 1,"1 588,60",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2032,Ganterie de peau – Personnel atelier,Personnel ouvrier,III - 1,"1 624,33",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2033,Ganterie de peau – Personnel atelier,Personnel ouvrier,III - 2,"1 655,20",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2034,Ganterie de peau – Personnel atelier,Personnel ouvrier,III - 3,"1 689,31",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2035,Ganterie de peau – Personnel atelier,Personnel ouvrier,III - 4,"1 689,31",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2036,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,I - 1,"1 548,95",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2037,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,I - 2,"1 556,80",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2038,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,II - 1,"1 580,68",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2039,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,II - 2,"1 592,98",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2040,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,II - 3,"1 656,01",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2041,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,III - 1,"1 771,82",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2042,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,III - 2,"1 898,31",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2043,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,III - 3,"2 024,77",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2044,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,III - 4,"2 151,22",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2045,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,IV - 1,"2 341,57",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2046,Ganterie de peau – Personnel atelier,Personnel employé et ETAM,IV - 2,"2 911,30",byMonth151,,,,,,,
01/03/2021,1/2/2020,,2528,"Industries de la maroquinerie, articles de voyage, chasse-sellerie, gainerie, bracelets en cuir ",,,cf. Accord du 29 janv. 2020 (NOR : ASET2050300M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable à partir du 1er févr. 2047,Ganterie de peau – Personnel atelier,Personnel cadre,V - 1,"3 169,89",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,"cf. CC. n° 3097, Art. 33 ; cf. CC. n° 3097, Art. 34 ; cf. Avenant n° 58 du 24 janv. 2017 (NOR : ASET1750271M) étendu par A. 20 juill. 2017 : JO 1er août 2017, applicable au 1er janv. 2017 ; cf. Avenant n°  61 du 11  juill. 2017 (NOR  : ASET1750931M) non étendu  ; cf. Avenant n°  63 du 12  juill. 2018 (NOR  : ASET1851101M) non étendu et applicable au 1er août 2018 ; cf. Avenant n° 66 du 1er sept. 2020 (NOR : ASET2050835M) étendu par A. 11 janv. 2021 : JO 22 janv. 2021 et en vigueur à compter de sa signature (conclu pour une durée indéterminée) ",Personnel des salles de cinéma,Niveau VIII,420,"2 736,25",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VIII,405,"2 654,94",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VIII,400,"2 611,58",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VIII,395,"2 432,70",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VII,349,"2 345,98",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VII,340,"2 270,09",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VII,325,"2 254,93",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VII,325,"2 254,93",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VII,300,"1 897,18",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VI,290,"1 842,97",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VI,285,"1 810,45",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VI,275,"1 788,77",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau VI,269,"1 772,50",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau V,265,"1 750,82",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau V,265,"1 750,82",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau V,259,"1 745,40",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau V,240,"1 626,15",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau IV,239,"1 615,31",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau IV,236,"1 604,47",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau IV,234,"1 582,79",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau IV,229,"1 571,95",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau IV,224,"1 561,10",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau III,219,"1 550,26",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau III,214,"1 544,84",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau III,194,"1 539,42 ",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau II,189,"1 539,42",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau II,184,"1 539,42 ",byMonth151,,,,,,,
01/03/2021,1/1/2020,,1307,Exploitation cinématographique,,,,Personnel des salles de cinéma,Niveau I,150,"1 539,42 ",byMonth151,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",1,,18 881,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",2,,19 181,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",3,,19 995,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",4,,21 243,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",5,,22 469,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",6,,23 943,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",7,,25 785,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",8,,28 214,byYear1607,,,,,,,
01/03/2021,1/7/2019,,0998,Equipements thermiques – Exploitation d'équipements thermiques et de génie climatique ,,,cf. Accord du 5 juill. 2019 (NOR : ASET1951348M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et prenant effet le 1er juill. 2019 (conclu pour une durée indéterminée),"Ouvriers, employés, techniciens et agents de maîtrise",9,,31 708,byYear1607,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,I,1,"1 545,42",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,II,2A,"1 547,03",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,II,2B,"1 150,07",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,III,3A,"1 551,58",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,III,3B,"1 556,13",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,IV,4A,"1 619,84",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Employé,IV,4B,"1 654,72",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Agent de maîtrise,V,5A,"1 776,06",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Agent de maîtrise,V,5B,"1 936,83",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Agent de maîtrise,VI,6A,"2 009,63",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Agent de maîtrise,VI,6B,"2 168,88",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Cadre,VII,7A,"2 259,88",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Cadre,VII,7B,"2 356,95",byMonth151,,,,,,,
01/03/2021,1/11/2020,,2683,Portage de presse,,,"cf. CC. n° 3350, Art. 10 ; cf. Avenant du 30 janv. 2018 (NOR : ASET1850919M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er  févr. 2018 suite à la signature par au moins une organisation syndicale représentative ; cf. Avenant du 8  févr. 2019 (NOR  : ASET1950538M) non étendu et en vigueur le 1er févr. 2019 ",Cadre,VIII,8,"2 689,21",byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,1A,,,1499,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,1B,,,1499,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,1C,,,1502,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,2A,,,1499,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,2B,,,1516,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,2C,,,1572,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,2D,,,1653,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,3A,,,1585,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,3B,,,1677,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,3C,,,1798,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,3D,,,1905,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,4A,,,1791,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,4B,,,1864,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,4C,,,2048,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,4D,,,2293,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,5A,,,2139,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,5B,,,2243,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,5C,,,2582,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,5D,,,2990,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,6A,,,2631,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,6B,,,2698,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,6C,,,2991,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,6D,,,3334,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,7B,,,3890,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,7C,,,4198,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,7D,,,4538,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,8C,,,5271,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1794,personnel des institutions de retraites complémentaires,,,,8D,,,5336,byMonth151,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,I,1,140,18293,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,I,2,145,18317,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,I,3,155,18330,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,II,1,170,18366,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,II,2,180,18411,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,II,3,190,18546,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,III,1,215,19133,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,III,2,225,19405,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,III,3,240,20064,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,IV,1,255,20791,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,IV,2,270,21719,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,IV,3,285,22863,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,V,1,305,24546,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,V,2,335,27294,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,V,3,365,30330,byYear1607,,,,,,,
5/3/2021,1/6/2019,,1369,"Industries métallurgiques, électriques, électroniques et connexes de Loire-Atlantique",,44,,V,3,395,33232,byYear1607,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents de production,Echelon 1,11,"1280,09",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents de production,Echelon 2,12,"1 286,16",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques spécialisés,Echelon 1,21,"1 290,71",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques spécialisés,Echelon 2,22,"1 296,78",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques qualifiés,Echelon 1,31,"1 310,43",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques qualifiés,Echelon 2,32,"1 337,73",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques hautement qualifiés,Echelon 1,41,"1 398,40",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents techniques hautement qualifiés,Echelon 2,42,"1 445,42",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents d'encadrement,Echelon 1,51,"1 580,40",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents d'encadrement,Echelon 2,52,"2 083,95",byMonth151,,,,,,,
5/3/2021,1/7/2007,,9723,Exploitations maraîchères de la Sarthe,,72,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Agents d'encadrement,Echelon 3,53,"2 552,61",byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,I,1,1 522,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,I,2,1 532,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,II,1,1 560,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,II,2,1 590,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,III,1,1 620,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,III,2,1 680,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ouvriers et employés,III,3,1 722,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,"Techniciens
et agents de maîtrise",IV,1,1 769,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,"Techniciens
et agents de maîtrise",IV,2,1 854,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,"Techniciens
et agents de maîtrise",IV,3,1 928,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ingénieurs et cadres,V,1,2 450,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ingénieurs et cadres,V,2,3 501,byMonth151,,,,,,,
5/3/2021,1/4/2019,,925,"Ingénieurs et cadres de la distribution des papiers et cartons, commerce de gros",,,,Ingénieurs et cadres,V,3,4 245,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais Expérimentaux (FTR1 ou PEX),1er échelon,,4 981,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais Expérimentaux (FTR1 ou PEX),2e échelon,,5 478,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais Expérimentaux (FTR1 ou PEX),3e échelon,,6 228,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais Expérimentaux (FTR1 ou PEX),4e échelon,,6 973,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais Expérimentaux (FTR1 ou PEX),5e échelon,,7 469,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais (FTR2 ou PE),1er échelon,,3 990,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais (FTR2 ou PE),2e échelon,,4 390,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais (FTR2 ou PE),3e échelon,,4 945,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais (FTR2 ou PE),4e échelon,,5 584,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,Pilotes d'essais (FTR2 ou PE),5e échelon,,5 982,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 * ou INE-A),1er échelon,,4 661,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 * ou INE-A),2e échelon,,5 359,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 * ou INE-A),3e échelon,,6 059,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 * ou INE-A),4e échelon,,6 757,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 * ou INE-A),5e échelon,,7 219,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-2 ou MNR ou ENE-B),1er échelon,,3 117,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-2 ou MNR ou ENE-B),2e échelon,,3 583,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-2 ou MNR ou ENE-B),3e échelon,,4 051,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-2 ou MNR ou ENE-B),4e échelon,,4 516,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-2 ou MNR ou ENE-B),5e échelon,,4 828,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 – MNE ou ENA-A),1er échelon,,3 155,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 – MNE ou ENA-A),2e échelon,,3 630,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 – MNE ou ENA-A),3e échelon,,4 102,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 – MNE ou ENA-A),4e échelon,,4 585,byMonth151,,,,,,,
5/3/2021,1/1/2018,,1612,Travail aérien (Personnel navigant des essais et réceptions).,,,,(LFTE-1 – MNE ou ENA-A),5e échelon,,4 886,byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Dactylo débutant(e),108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,employé(e) de bureau,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,employé(e) d'entretien,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,empolyé de manutention,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,hôte(sse)-standardiste,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,technicien de surface.,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,coursier,108,"1561,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,employé(e) de bureau,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Aide-comptable,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,dactylo,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,facturier(e),121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,opérateur(rice) de saisie,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,secrétaire,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,sténodactylographe.,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Comptable (1er échelon),131,"1671,64",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Comptable (2e échelon),145,"1812,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Chef comptable,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,chef de service administratif,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,secrétaire de direction.,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,Assistant(e) de direction ou secrétaire général,180,"2163,6",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Administratif et logistique,directeur de service.,180,"2163,6",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,Assistant(e),121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,technicien(ne),121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,technico-réalisateur.,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,Technicien(ne) d'exploitation.,131,"1671,64",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,Technicien(ne) de maintenance,145,"1812,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,Chef de service technique,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Technique,Directeur de service.,180,"2163,6",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Animateur(rice),121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,producteur-speaker de messages publicitaires. .,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Animateur(rice)-technico-réalisateur(rice) (1er échelon),121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Animateur(rice)-réalisateur(rice),131,"1671,64",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Animateur(rice)-technico-réalisateur(rice) (2e échelon),145,"1812,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Assistant(e) à la programmation,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,réalisateur(rice),169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,programmateur(rice),169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,Directeur(rice) de programmes,180,"2163,6",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Artistique,directeur de service,180,"2163,6",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Commercial,Agent commercial,121,"1571,24",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Commercial,Attaché commercial,145,"1812,2",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Commercial,Chef de publicité ou de ventes,169,"2053,16",byMonth151,,,,,,,
5/3/2021,01/03/2020,,1922,Radiodiffusion,,,,Commercial,Directeur de service,180,"2163,6",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Etablissements accueillant des
personnes âgées",215,,"1539,42",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Etablissements accueillant des
personnes âgées",216,,"1539,42",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",176,,"1536,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",178,,"1537,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",180,,"1538,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",181,,"1539,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",182,,"1540,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",183,,"1541,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",184,,"1542,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",185,,"1543,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",186,,"1544,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",187,,"1545,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",188,,"1546,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",189,,"1547,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",190,,"1548,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",191,,"1549,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",192,,"1550,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",193,,"1551,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",194,,"1552,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",195,,"1553,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",196,,"1554,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",197,,"1555,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",198,,"1556,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",199,,"1557,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",200,,"1558,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",201,,"1559,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",202,,"1560,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",203,,"1561,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",204,,"1562,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",205,,"1563,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",206,,"1564,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",207,,"1565,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",208,,"1566,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",209,,"1567,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",210,,"1568,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",211,,"1569,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",212,,"1570,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",213,,"1571,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",214,,"1572,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",215,,"1573,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",216,,"1574,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",217,,"1575,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",218,,"1576,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",219,,"1577,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",220,,"1578,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",221,,"1579,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",222,,"1580,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",223,,"1581,43",byMonth151,,,,,,,
10/03/2021,01/01/2020,,2264,Hospitalisation privée,,,,"Hors établissements accueillant des
personnes âgées",224,,"1582,43",byMonth151,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Agent de Maintenance,298,,"16 390,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Comptable,390,,"21 450,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Animateur,386,,"21 230,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Animateur d'activité,292,,"16 060,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Assistant de direction,371,,"20 405,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Auxiliaire petite enfance ou de soins,300,,"16 500,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Cadre fédéral,578,,"31 790,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Chargé d'accueil,390,,"21 450,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Coordinateur,444,,"24 420,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Directeur,552,,"30 360,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Educateur petite enfance,384,,"21 120,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Intervenant technique,319,,"17 545,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Personnel administratif,292,,"16 060,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Personnel de service,292,,"16 060,00",byYear1607,,,,,,,
10/03/2021,1/1/2020,,1261,Acteurs du lien social et familial,,,,Secrétaire,337,,"18 535,00",byYear1607,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Artistes dramatiques,,,"1920,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Artistes chorégrapiques,,,"1920,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,ensembles musicaux à nomenclature -  tuttiste,,,"3005,85",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,ensembles musicaux à nomenclature – soliste,,,"3117,18",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,ensembles musicaux à nomenclature – chef de pupitre,,,"3328,72",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,ensembles musicaux sans nomenclature,,,"2577,48",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,musiques actuelles,,,"2577,48",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,artistes musiciens engagés au sein d'autres entreprises,,,"2577,59",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Artistes de chœur,,,"1920,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Artistes de chœur,,,"1968,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Artistes lyriques solistes,,,"2368,28",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,artistes de cirque exploitation des spectacles ,,,"2006,76",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 1,,"3255,76",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 2,,"3353,43",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 3,,"3451,10",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 4,,"3548,77",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 5,,"3646,45",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 6,,"3744,12",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 7,,"3841,79",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 8,,"3939,46",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 9,,"4037,14",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 10,,"4134,81",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 11,,"4232,48",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 1,Echelon 12,,"4330,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 1,,"2509,71",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 2,,"2585,00",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 3,,"2660,30",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 4,,"2735,59",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 5,,"2810,88",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 6,,"2886,17",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 7,,"2961,46",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 8,,"3036,75",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 9,,"3112,04",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 10,,"3187,33",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 11,,"3262,63",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 2,Echelon 12,,"3337,92",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 1,,"2301,41",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 2,,"2370,45",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 3,,"2439,5",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 4,,"2508,54",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 5,,"2577,58",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 6,,"2646,62",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 7,,"2715,66",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 8,,"2784,71",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 9,,"2853,75",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 10,,"2922,79",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 11,,"2991,83",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 3,Echelon 12,,"3060,88",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 1,,"2107,36",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 2,,"2170,58",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 3,,"2233,80",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 4,,"2297,02",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 5,,"2360,24",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 6,,"2423,46",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 7,,"2486,68",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 8,,"2549,90",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 9,,"2613,13",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 10,,"2676,35",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 11,,"2739,57",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 4,Echelon 12,,"2802,79",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 1,,"1771,02",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 2,,"1824,15",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 3,,"1877,28",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 4,,"1930,41",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 5,,"1983,54",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 6,,"2036,67",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 7,,"2089,30",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 8,,"2142,93",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 9,,"2196,07",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 10,,"2249,20",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 11,,"2302,33",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 5,Echelon 12,,"2355,46",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 1,,"1652,93",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 2,,"1702,52",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 3,,"1752,11",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 4,,"1801,69",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 5,,"1851,28",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 6,,"1900,87",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 7,,"1950,46",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 8,,"2000,04",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 9,,"2049,63",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 10,,"2099,22",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 11,,"2148,81",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 6,Echelon 12,,"2198,40",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 1,,"1594,79",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 2,,"1642,64",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 3,,"1690,48",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 4,,"1738,33",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 5,,"1786,17",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 6,,"1834,01",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 7,,"1881,85",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 8,,"1929,70",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 9,,"1977,54",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 10,,"2025,39",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 11,,"2073,23",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 7,Echelon 12,,"2121,08",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 1,,"1558,26",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 2,,"1605,00",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 3,,"1651,75",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 4,,"1698,50",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 5,,"1745,25",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 8,Echelon 6,,"1791,99",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 7,,"1838,74",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 8,,"1885,49",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 9,,"1932,24",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 10,,"1978,98",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 11,,"2025,73",byMonth151,,,,,,,
10/03/2021,1/2/2019,,1285,Entreprises artistiques et culturelles ,,,,Groupe 9,Echelon 12,,"2072,48",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent de production,Echelon 1,,"1 457,55",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent de production,Echelon 2,,"1 477,27",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent technique spécialisé,Echelon 1,,"1 490,92",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent technique spécialisé,Echelon 2,,"1 503,05",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent technique qualifié,Echelon 2,,"1 528,83",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent technique qualifié,Echelon 1,,"1 571,30",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent hautement qualifié,Echelon 1,,"1 583,43",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Agent hautement qualifié,Echelon 2,,"1 603,15",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Contremaître,,,"1 918,63",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Chef de culture,,,"2 616,31",byMonth151,,,,,,,
10/03/2021,1/2/2015,,9532,exploitations pépinières et horticulture de Mayenne,,53,https://www.legifrance.gouv.fr/download/pdf/bocc?id=boc_20150019_0000_0035.pdf&isForGlobalBocc=false,Directeur d'exploitation,,,"3 213,89",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 1,,,"1 588,66",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 2,,,"1 609,83",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 3,,,"1 631,01",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 4,,,"1 652,20",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 5,,,"1 673,38",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 6,,,"1 705,15",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 7,,,"1 747,52",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 8,,,"1 789,88",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 9,,,"1 832,25",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 10,,,"1 874,61",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 11,,,"1 927,57",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 12,,,"2 188,60",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 13,,,"2 439,62",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 14,,,"2640,84",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 15,,,"2766,61",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 16,,,"2892,34",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 17,,,"3521,12",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 18,,,"3898,37",byMonth151,,,,,,,
10/03/2021,1/1/2020,,9497,producteurs de graines Maine et Loire,,49,https://pays-de-la-loire.dreets.gouv.fr/Les-conventions-collectives-departementales,Niveau 19,,,"5030,17",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau I,,,"1 540,00",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau II,,,"1 540,00",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau III,,,"1 567,25",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau IV,,,"1 697,04",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau V,,,"1 827,92",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau VI,,,"2 052,89",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau VII,,,"2 515,83",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau VIII,,,"3 123,69",byMonth151,,,,,,,
10/03/2021,1/1/2020,,2270,Edition phonographique,,,,Niveau IX,,,"3 865,66",byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Comptabilité,1. Assistant(e) comptable,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Comptabilité,2. Comptable,1 722,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Comptabilité,3. Responsable comptable,2 285,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Comptabilité,4. Directeur/directrice comptable,3 508,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Contrôle de gestion-analyste financier,"1. Assistant(e) contrôleur/ 
contrôleuse de gestion",1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Contrôle de gestion-analyste financier,2. Contrôleur/contrôleuse de gestion,2 268,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Contrôle de gestion-analyste financier,"3. Responsable contrôle de 
gestion",2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Contrôle de gestion-analyste financier,4. Directeur/directrice contrôle de gestion,3 508,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Trésorerie,1. Assistant(e) de trésorerie,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Trésorerie,2. Trésorier/trésorière,2 285,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Trésorerie,3. Responsable trésorerie,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Recouvrement,1. Assistant(e) recouvrement,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Recouvrement,2. Chargé(e) du recouvrement,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Recouvrement,3. Responsable du recouvrement,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Reversement,1. Assistant(e) reversement,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Reversement,2. Chargé(e) de reversement,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Reversement,3. Responsable du reversement,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Audit interne,1. Assistant(e) audit interne,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Audit interne,2. Chargé(e) d'audit interne,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Audit interne,3. Responsable de l'audit interne,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,Audit interne,4. Directeur/directrice de l'audit interne,4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Gestion/finances,DAF,"5. Directeur administratif et 
financier/directrice administrative et financière",4 634,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Paies,1. Assistant(e) paie,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Paies,2. Gestionnaire de paie,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Paies,3. Responsable de paie,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Ressources humaines,1. Assistant(e) RH,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Ressources humaines,2. Gestionnaire RH,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Ressources humaines,3. Responsable RH,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Ressources humaines,"4. Directeur/directrice des 
ressources humaines",4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Formation-recrutement,"1. Gestionnaire formation 
recrutement",1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Ressources humaines,Formation-recrutement,"3. Responsable formation 
recrutement",2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Administratif,1. Assistant(e) administratif,,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Administratif,2. Assistant(e) de direction,,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Juridique,1. Assistant(e) juridique,,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Juridique,2. Juriste,,2 318,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Juridique,3. Responsable juridique,,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Juridique,4. Directeur/directrice juridique,,4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Informatique-systèmes d'information,1. Agent(e) de maintenance,,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Informatique-systèmes d'information,"2. Technicien(ne) système 
informatique",,2 268,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Informatique-systèmes d'information,"3. Responsable services 
informatiques-systèmes 
d'information",,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Informatique-systèmes d'information,"4. Directeur/directrice services informatiques-systèmes 
d'information",,4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,2. Chauffeur(e),,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,1. Hôte(sse)-standardiste,,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,1. Agent(e) de sécurité,,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,3. Responsable de la sécurité,,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,"1. Employé(e) des services 
généraux",,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,"2. Chargé(e) des services 
généraux",,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Services généraux,"3. Responsable des services 
généraux",,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,"Presse-communication/ 
événementiel","1. Assistant(e) marketing 
communication",1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,"Presse-communication/ 
événementiel",2. Chef(fe) de produit-chef(fe) de projet,2 268,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,Promotion-partenariats,2. Chargé(e) de tournée-festival,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,Trade marketing,2. Attaché(e) de presse-chargé(e) de communication,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,Trade marketing,"3. Responsable marketing 
communication-chef(fe) de 
groupe",2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Marketing/Communication,Trade marketing,4. Directeur/directrice marketing communication,4119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Ventes-ventes internationales (y compris TV-vidéo-VAD),1. Assistant(e) commercial,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Ventes-ventes internationales (y compris TV-vidéo-VAD),2. Chargé(e) de clientèle,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Ventes-ventes internationales (y compris TV-vidéo-VAD),2. Attaché(e) commercial,2 591,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Ventes-ventes internationales (y compris TV-vidéo-VAD),3. Responsable grands comptes,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Ventes-ventes internationales (y compris TV-vidéo-VAD),4. Directeur/directrice des ventes commercial,4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Programmation,1. Assistant(e) programmateur/ programmatrice,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Programmation,"2. Programmateur/ 
programmatrice",1 914,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Programmation,2. Administrateur/administratrice des ventes,2 727,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Programmation,"3. Responsable de la 
programmation",2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Commercial/Ventes,Programmation,"4. Directeur/directrice de la 
programmation",4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Acquisitions,2. Chargé(e) des acquisitions,,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Acquisitions,3. Responsable des acquisitions,,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Acquisitions,"4. Directeur/directrice des 
acquisitions",,4 119,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Opérations,Logistique,1. Assistant(e) techn-log-achats,1 500,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Opérations,Logistique,2. Coordinateur/coordinatrice techn-log-achats,1 899,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Opérations,Logistique,3. Responsable techn-log-achats,2 904,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Opérations,Logistique,4. Directeur/directrice techn-log achats-opérations,3 990,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Opérations,Projectionniste,2. Projectionniste,1 601,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Missions transverses,Chef(fe) de mission,,2 990,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Missions transverses,Chef(fe) de projet,,2 414,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Missions transverses,Attaché(e) de projet,,2 414,byMonth151,,,,,,,
10/03/2021,20/11/2017,,892,Industrie cinématographique (Distribution des films) (Cadres et agents de maîtrise) ,,,"cf. CC. n° 3174-2, Art. 6 ; cf. Annexe I à l’accord du 20 nov. 2017 (NOR : ASET1850073M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et applicable à compter de sa date de signature ",Missions transverses,"Directeur/directrice de la 
distribution",,4 634,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétaire,,1560,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétaire,,1651,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétaire,,"1709,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétaire,,"1761,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétairetechnique /assistant,,"1852,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétairetechnique /assistant,,"1943,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétairetechnique /assistant,,2002,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,Secrétairetechnique /assistant,,2054,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,"Responsable administratif / 
assistant",,2158,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,"Responsable administratif / 
assistant",,"2242,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,"Responsable administratif / 
assistant",,2301,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,ADMINISTRATIFS,"Responsable administratif / 
assistant",,"2346,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Technicien conseiller,,1755,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Technicien conseiller,,"1839,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Technicien conseiller,,1898,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Technicien conseiller,,"1943,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur /conseiller technique,,"1852,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur /conseiller technique,,"1956,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur /conseiller technique,,2028,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur /conseiller technique,,"2086,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Chargé d'études,,"1852,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Chargé d'études,,"1956,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Chargé d'études,,2028,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Chargé d'études,,"2086,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur coordinateur,,"2034,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur coordinateur,,2145,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur coordinateur,,"2229,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,TECHNIQUES,Animateur coordinateur,,"2294,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,DIRECTION,,,2600,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,DIRECTION,,,"2710,5",byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,DIRECTION,,,2795,byMonth151,,,,,,,
10/3/2021,1/1/2014,1,7513,Centres initiatives pour valoriser l'agriculture en milieu rural (CIVAM) ,,,,DIRECTION,,,2860,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,"Employés, ouvriers",Niveau 1,,1528,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,"Employés, ouvriers",Niveau 2,,1548,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,"Techniciens, agents de maîtrise",Niveau 1,,1635,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,"Techniciens, agents de maîtrise",Niveau 2,,1753,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,Cadres,Niveau 1,,2155,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,Cadres,Niveau 2,,2610,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,Cadres de direction,Niveau 1,,2586,byMonth151,,,,,,,
12/3/2021,1/1/2019,,3220,Offices publics de l'habitat,,,,Cadres de direction,Niveau 2,,5040,byMonth151,,,,,,,
12/3/2021,1/1/2020,,700,"Ingénieurs et cadres de la production des papiers, cartons et celluloses",,,,Débutants,,,28093,byYear1607,,,,,,,
12/3/2021,1/1/2020,,700,"Ingénieurs et cadres de la production des papiers, cartons et celluloses",,,,Entre 2 et 5 ans d'ancienneté,,,31402,byYear1607,,,,,,,
12/3/2021,1/1/2015,,7503,"Distilleries coopératives viticoles et leurs unions
et SICA de distillation",,,,OUVRIERS ET EMPLOYES SPECIALISES,,,"10,084",byHour,,,,,,,
12/3/2021,1/1/2015,,7503,"Distilleries coopératives viticoles et leurs unions
et SICA de distillation",,,,OUVRIERS ET EMPLOYES QUALIFIES,,,"10,66383",byHour,,,,,,,
12/3/2021,1/1/2015,,7503,"Distilleries coopératives viticoles et leurs unions
et SICA de distillation",,,,OUVRIERS ET EMPLOYES HAUTEMENT QUALIFIES,,,"11,94954",byHour,,,,,,,
12/3/2021,1/1/2015,,7503,"Distilleries coopératives viticoles et leurs unions
et SICA de distillation",,,,AGENTS DE MAITRISE ET ASSIMILE,,,"12,70584",byHour,,,,,,,
12/3/2021,1/1/2015,,7503,"Distilleries coopératives viticoles et leurs unions
et SICA de distillation",,,,CADRE DE DIRECTION,,,"14,19323",byHour,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,1,,,"18434,56",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,2,,,"18616,48",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,3,,,"19222,88",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,4,,,"19768,64",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,5,,,"20435,68",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,6,,,"21042,08",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,7,,,"21648,48",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,8,,,"22254,88",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,9,,,"22861,28",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,10,,,"23467,68",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,11,,,"24074,08",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,12,,,"24619,84",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,13,,,"26378,4",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,14,,,"26984,8",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,15,,,28804,byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,16,,,"30623,2",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,17,,,"32442,4",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,18,,,"34200,96",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,19,,,"36687,2",byYear1607,,,,,,,
12/3/2021,01/11/2020,,1671,Maisons d'étudiants,,,,20,,,"39719,2",byYear1607,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1429,12",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1453,76",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1546,16",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1644,72",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1749,44",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"1891,12",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"2051,28",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 1,,,"2205,28",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1453,76",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1496,88",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1589,28",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1700,16",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1823,36",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"1971,2",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"2131,36",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 2,,,"2285,36",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"1546,16",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"1644,72",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"1749,44",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"1891,12",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"2051,28",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"2205,28",byMonth151,,,,,,,
12/3/2021,1/1/2018,,7008,Organismes de contrôle laitier,,,,Niveau 3,,,"2365,44",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Agent d'entretien,,"2 146,20",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Agent à domicile,,"2 160,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Préparateur de cuisine,,"2 197,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Personnel d'accompagnement,,"2 234,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Employé à domicile,,"2 234,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Aide comptable,,"2 271,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Animateur d'ACM permanent,,"2 271,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Conducteur de car,,"2 271,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Secrétaire local aide à domicile,,"2 271,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 4,Assistant éducateur,,"2 307,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Cuisinier - économe,,"2 344,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Animateur local et/ou fédéral,,"2 418,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Directeur adjoint d'ACM permanent,,"2 418,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Professeur - animateur technique,,"2 454,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Maquettiste - secrétaire de rédaction,,"2 454,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Secrétaire,,"2 454,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Auxiliaire de puériculture,,"2 454,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Conseiller conjugal,,"2 491,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Conseiller ESF,,"2 491,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Directeur d'ACM permanent,,"2 491,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Comptable,,"2 528,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Auxiliaire de vie sociale,,"2 528,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Aide soignant,,"2 528,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Educateur petite enfance,,"2 601,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Accuillant Relais Familles,,"2 601,90",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Directeur adjoint de structure PE,,"2 638,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 3,Responsable technique PE,,"2 675,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Infirmier,,"2 712,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Assistant de direction,,"2 712,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Directeur d'association,,"2 712,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Animateur de RAM,,"2 785,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Directeur de structure,,"2 859,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Responsable de secteur,,"2 859,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Chargé de missions,,"2 859,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Conseiller technique,,"2 969,40",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 2,Responsable de service,,"3 006,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 1,Délégué régional,,"3 006,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 1,Coordinateur de services de soins,,"3 226,65",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 1,Directeur ajoint de fédération,,"3 300,15",byMonth151,,,,,,,
12/3/2021,1/7/2017,,7508,Maisons Familiales Rurales,,,https://www.fnasfo.fr/wp-content/uploads/2017/01/ccnt-familles-rurales.pdf,Groupe 1,Directeur de fédération ,,"3 373,65",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 1.1,,,"1554,46",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 1.2,,,"1563,79",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 1.3,,,"1573,17",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 2.1,,,"1582,61",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 2.2,,,"1592,11",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 2.3,,,"1601,66",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 3.1,,,"1611,27",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 3.2,,,"1620,94",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 3.3,,,"1630,66",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 4.1,,,"1640,45",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 4.2,,,"1650,29",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 4.3,,,"1660,19",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 5.1,,,"1667,02",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 5.2,,,"1729,53",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 5.3,,,"1794,39",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 6.1,,,"1861,68",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 6.2,,,"1931,49",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 6.3,,,"2003,92",byMonth151,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 7.1,,,"25954,34",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 7.2,,,"27252,06",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 7.3,,,"28614,66",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 8.1,,,"33115,75",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 8.2,,,"36427,33",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 8.3,,,"40070,06",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 9.1,,,"44077,06",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 9.2,,,"48484,77",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 10.1,,,"55757,49",byYear1607,,,,,,,
11/2/2021,1/5/2020,,573,Commerces de gros,,,,Niveau 10.2,,,"66908,98",byYear1607,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Niveau maîtrise - MP,Echelon MP5,"18,46",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Niveau maîtrise - MP,Echelon MP4,"17,08",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Niveau maîtrise - MP,Echelon MP3,"15,32",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Niveau maîtrise - MP,Echelon MP2,"13,82",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Niveau maîtrise - MP,Echelon MP1,"13,07",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Chef d'équipe - CE,Echelon 3,"13,02",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Chef d'équipe - CE,Echelon 2,"12,88",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,Chef d'équipe - CE,Echelon 1,"12,18",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 3 A,"12,61",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 3 B,"12,88",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 2 A,"11,73",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 2 B,"11,93",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 1 A,"11,11",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,ATQS,Echelon 1 B,"11,29",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 3 A,"10,9",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 3 B,"11,1",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 2 A,"10,8",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 2 B,11,byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 1 A,"10,71",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AQS,Echelon 1 B,"10,89",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 3 A,"10,65",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 3 B,"10,84",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 2 A,"10,59",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 2 B,"10,78",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 1 A,"10,56",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière exploitation,AS,Echelon 1 B,"10,72",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA3,"18,29",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA2,"17,33",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Maintrîse - MA,Echelon MA1,"15,28",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 4,"13,74",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 3,"12,56",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 2,"11,41",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière administrative,Employés - EA,Echelon EA 1,"10,64",byHour,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 6,"4886,92",byMonth151,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 5,"4472,75",byMonth151,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 4,"4214,38",byMonth151,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 3,"3645,72",byMonth151,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 2,"3262,24",byMonth151,,,,,,,
11/2/2021,1/9/2012,,3043,Entreprises de propreté et services associés,,,"cf. CC. n° 3173, Art. 4.7 ; cf. Avenant n° 18 du 30 sept. 2019 (NOR : ASET1951366M) étendu par A. 31 mars 2020 : JO 7 avr. 2020 et en vigueur au 1er jour du mois suivant celui au cours duquel est publié son extension et 1er février 2020 au plus tôt ; cf. Avenant n° 19 du 4 sept. 2020 (NOR : ASET2050824M) étendu par A. 14 déc. 2020 : JO 1er janv. 2021 et applicable au 1er jour du mois suivant la publication de son extension",Fillière cadre minima conventionnels,Cadres - CA,Echelon CA 1,"2765,45",byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 1,,1963,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 2,,1914,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 3,,1864,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 4,,1823,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 5,,1766,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 6,,1714,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 7,,1682,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 8,,1649,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 9,,1622,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 10,,1601,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 11,,1584,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,"Ouviers, employés",Echelon 12,,1568,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,Maîtrîse,Echelon 25,,2484,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,Maîtrîse,Echelon 24,,2352,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,Maîtrîse,Echelon 23,,2220,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,Maîtrîse,Echelon 22,,2093,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,,Maîtrîse,Echelon 21,,2023,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Maîtrîse,Echelon 20,,1963,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Maîtrîse,Echelon 19,,1956,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Maîtrîse,Echelon 18,,1926,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Maîtrîse,Echelon 17,,1870,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau V,,5230,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau IV,Degré C,4706,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau IV,Degré B,4443,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau IV,Degré A,4182,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau III,Degré C,3921,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau III,Degré B,3659,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau III,Degré A,3396,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau II,Degré C,3135,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau II,Degré B,2874,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau II,Degré A,2613,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau I,Degré C,2483,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau I,Degré B,2352,byMonth151,,,,,,,
11/2/2021,14/11/2020,,1090,"Services de l'automobile (Commerce et réparation de l'automobile, du cycle et du motocycle – Activités connexes – Contrôle technique automobile – Formation des conducteurs)",,,"cf. CC. n° 3034, Art. 1.16 ; cf. CC. n° 3034, Art. 4.05 ; cf. Avenant n° 86 du 4 juillet 2018 (NOR : ASET1851086M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et en vigueur le 1er jour du mois suivant la publication de son extension ; cf. Avenant n° 89 du 3 juill. 2019 (NOR : ASET1951103M) étendu par A. 15 janv. 2020 : JO 22 janv. 2020 et en vigueur le 1er jour du mois suivant la publication de son extension",Cadres,Niveau I,Degré A,2220,byMonth151,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau A,,"19347,74",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau B,,"19595,98",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau C,,"19940,52",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau D,,"21380,74",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau E,,"22390,82",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau F,,"24421,68",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Techniciens,Niveau G,,"27066,72",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Cadres,Niveau H,,"29855,14",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Cadres,Niveau I,,"36476,3",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Cadres,Niveau J,,"44071,16",byYear1607,,,,,,,
11/2/2021,1/1/2019,,2120,Banque,,,"cf. CC. n° 3161, Art. 40 et s. ; cf. Annexe VI ; cf. Annexe VII ; cf. Annexe VII bis ; cf. Accord RTT du 2 ; 9 mai 2001 (NOR : ASET0150589M) étendu par A. 23 oct. 2001 : JO 1er nov. 2001 ; cf. Accord du 13 janv. 2017 (NOR : ASET1750351M) étendu par A. 21 juill. 2017 : JO 1er août 2017 et en vigueur à compter du 1er janvier 2017 ; cf. Accord du 7 févr. 2019 (NOR : ASET1950406M) non étendu et en vigueur à compter du 1er janvier 2019 (Conclu pour une durée indéterminée)",Cadres,Niveau K,,"52436,42",byYear1607,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 2,Echelon 2,"1539,31",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 3,Echelon 1,"1559,48",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 3,Echelon 2,"1606,25",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 3,Echelon 3,"1666,34",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 4,Echelon 1,"1758,48",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 4,Echelon 2,"1901,44",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 4,Echelon 3,"2044,44",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 5,Echelon 1,"2235,59",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 5,Echelon 2,"2426,28",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,"Agent d'exploitation, employé,
 administratif, technicien",Niveau 5,Echelon 3,2617,byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 1,Echelon 1,"1825,66",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 1,Echelon 2,"1926,55",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 1,Echelon 3,"2027,19",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 2,Echelon 1,"2178,65",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 2,Echelon 2,"2329,74",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 2,Echelon 3,"2480,86",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 3,Echelon 1,"2682,47",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 3,Echelon 2,"2884,04",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Agents de maîtrise,Niveau 3,Echelon 3,"3085,64",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position I,,"2425,56",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position II - A,,"3069,57",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position II - B,,"3520,03",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position III - A,,"3906,47",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position III - B,,"4485,86",byMonth151,,,,,,,
11/2/2021,1/5/2020,,1351,Entreprises de prévention et de sécurité,,,cf. Accord du 31 août 2018 (NOR : ASET1851095M) étendu par A. 15 févr. 2019 : JO 21 févr. 2019 et entrant en application à compter du 1er jour du mois suivant la publication de son extension et au plus tôt à compter du 1er janv. 2019 ; cf. Accord du 5 nov. 2019 (NOR : ASET2050075M) étendu par A. 1er avr. 2020 : JO 8 avr. 2020 et entrant en application à compter du 1er jour du mois suivant la publication de son extension,Ingénieurs et cadres,Position III - C,,"5644,96",byMonth151,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Emplois généraux sans qualification,,,18386,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Employé,,,18724,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Employé confirmé,,,19061,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Employé principal,,,20412,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Assistant,,,21763,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Assistnat confirmé,,,24464,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Assistant principal,,,25814,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Cadre,,,29191,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Cadre confirmé,,,32905,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Cadre principal,,,37295,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Chef de service,,,40671,byYear1607,,,,,,,
11/2/2021,1/1/2020,,787,Cabinets d'experts-comptables et de commissaires aux comptes,,,"cf. CC. n° 3020, Art. 5.1.1 ; cf. CC. n° 3020, Art. 5.1.2 ; cf. Avenant n° 36 du 3 mai 2013 (NOR : ASET1350859M) étendu par A. 2 oct. 2013 : JO 11 oct. 2013 ; cf. Avenant n° 24 bis du 18 févr. 2015 (NOR : ASET1550374M) étendu par A. 7 avr. 2016 : JO 20 avr. 2016 (déposé le 18 mars 2015) ; cf. Avenant n° 42 du 5 avr. 2019 (NOR : ASET1950963M) étendu par A. 23 déc. 2019 : JO 29 déc. 2019 et applicable à compter du 1er jour du mois civil suivant la parution de son extension ; cf. Avenant n° 43 du 3 avr. 2020 (NOR : ASET2050591M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable à compter du 1er jour du mois civil suivant la parution de son extension, à défaut de publication de cet arrêté d'ici le 30 sept. 2020, à compter du 1er oct. 2020 pour les cabinets adhérents des syndicats patronaux signataires",Cadre de direction,,,47424,byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau I,Indice 442,"1539,49",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau I,Indice 444,"1546,45",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau I,Indice 446,"1553,42",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau II,Indice 448,"1560,38",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau II,Indice 450,"1567,35",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau III,Indice 458,"1595,21",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Employé,Niveau III,Indice 477,"1661,39",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau IV,Indice 507,"1765,37",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau IV,Indice 534,"1859,39",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau V,Indice 548,"1908,14",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau V,Indice 563,"1960,37",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau VI,Indice 579,"2016,08",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,TAM,Niveau VI,Indice 599,"2085,72",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VII,Indice 694,"2415,12",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VII,Indice 743,"2585,64",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VII,Indice 871,"3031,08",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VII,Indice 883,"3072,84",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VIII,Indice 945,"3288,60",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VIII,Indice 1022,"3556,56",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau VIII,Indice 1099,"3824,52",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau IX,Indice 1344,"4677,12",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,,Cadres,Niveau IX,Indice 1590,"5533,20",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres,Niveau IX,Indice 1752,"6096,96",byMonth151,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VII,Indice 694,"31879,58",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VII,Indice 743,"34130,45",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VII,Indice 871,"40010,26",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VII,Indice 883,"40561,49",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VIII,Indice 945,"43409,52",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VIII,Indice1022,"46946,59",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau VIII,Indice 1099,"50483,66",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau IX,Indice 1344,"61737,98",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau IX,Indice 1590,"73038,24",byYear1607,,,,,,,
11/2/2021,1/9/2020,,2098,Prestataires de services dans le domaine du secteur tertiaire (Personnel),,,"cf. Ann. VII ; cf. Accord du 19 avr. 2016 (NOR : ASET1650653M) étendu par A. 23 août 2016, JO 30 août 2016 et en vigueur le 1er jour du mois civil suivant la publication de son extension ; cf. Accord du 12 mars 2018 (NOR : ASET1850566M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 et en vigueur le 1er jour du mois civil suivant la date de publication de son extension ; cf. Accord du 16 mars 2020 (NOR : ASET2050376M) étendu par A. 12 août 2020 : JO 22 août 2020 et en vigueur 1er jour du mois civil suivant la date de publication de son extension (conclu pour une durée indéterminée)",Cadres des effectifs commerciaux,Niveau IX,Indice 1752,"80479,87",byYear1607,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 155,,"10,42",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 160,,"10,52",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 170,,"10,73",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 175,,"10,83",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 185,,"11,16",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 190,,"11,26",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 195,,"11,36",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de fabrication,Coefficient 240,,"12,25",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 155,,"10,42",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 160,,"10,52",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 165,,"10,62",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 170,,"10,73",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 175,,"10,83",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 180,,"10,93",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 185,,"11,16",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de vente,Coefficient 190,,"11,26",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de service,Coefficient 155,,"10,42",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de service,Coefficient 160,,"10,52",byHour,,,,,,,
11/2/2021,1/3/2020,,843,Boulangerie-pâtisserie (Entreprises artisanales),,,"cf. CC. n° 3117, Art. 10 et s. ; cf. Annexe encadrement art. 3 ; cf. Avenant n° 123 du 24 mars 2020 (NOR : ASET2050462M) étendu par A. 12 août 2020 : JO 22 août 2020 et applicable au 1er mars 2020 (conclu pour une durée indéterminée)",Personnel de service,Coefficient 170,,"10,73",byHour,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,,Coefficient 100,,,"1555,00",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,,Coefficient 115,,,"1562,22",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,,Coefficient 125,,,"1567,03",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 130,,,"1569,44",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 135,,,"1571,85",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 140,,,"1574,26",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 145,,,"1576,66",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 150,,,"1579,07",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 155,,,"1581,48",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 160,,,"1583,88",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 165,,,"1586,29",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 170,,,"1588,70",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 175,,,"1591,10",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 190,,,"1598,32",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 200,,,"1603,14",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 220,,,"1612,77",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 225,,,"1615,17",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 230,,,"1617,58",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 240,,,"1687,91",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 250,,,"1758,23",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 260,,,"1828,56",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 270,,,"1898,89",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 280,,,"1969,22",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 290,,,"2039,55",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 300,,,"2109,88",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 310,,,"2180,21",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 320,,,"2250,54",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 330,,,"2320,87",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 400,,,"2813,18",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 430,,,"3024,16",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 470,,,"3305,48",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 500,,,"3516,47",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 600,,,"4219,76",byMonth151,,,,,,,
12/2/2021,13/1/2021,,1996,Pharmacie d'officine,,,cf. Accord du 10 janv. 2020 (NOR : ASET2050375M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet à compter du 1er janvier 2020 (conclu pour une durée indéterminée),Coefficient 800,,,"5626,35",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E1,,,"1560,96",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E2,,,"1605,93",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E3,,,"1610,54",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E4,,,"1646,56",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E5,,,"1671,56",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E6,,,"1691,86",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,E7,,,"1738,73",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,AM1,,,"2143,33",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,AM2,,,"2218,45",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,C1,,,"2686,95",byMonth151,,,,,,,
12/2/2021,4/2/2020,,1505,"Commerce de détail de fruits et légumes, épicerie et produits laitiers",,,,C2,,,"3032,19",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Nettoyage et entretien et éventuellement travaux divers,,,1490,byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Standardiste et/ou accueil réception,,,1523,byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire-réceptionniste et accueil,204,,"1519,80",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire-réceptionniste et accueil,205,,"1527,25",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire-réceptionniste et accueil,206,,"1534,70",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire-réceptionniste et accueil,207,,"1542,15",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire médical(e) diplômé(e),209,,"1557,05",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire médical(e) diplômé(e),210,,"1564,50",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire médical(e) diplômé(e),215,,"1601,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)","Secrétaire médicale avec création et suivi d'un dossier patient, établissement et contrôle des dossiers de remboursement et application d'une procédure qualité et identification des mesures d'hygiène et de sécurité applicables dans une entreprise de santé avec
 en plus comptabilité générale",,,"1609,20",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire technique assistante d'un cabinet de dermatologie,,,"1624,10",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Secrétaire de direction,,,"1825,25",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Agent des cabinets utilisant l'imagerie médicale (ACIM),,,"1527,25",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Manipulateur(trice) radio ayant passé le contrôle des connaissances,,,"1624,10",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Manipulateur(trice) radio diplômé(e),,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Responsable de service,,,"1825,25",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Assistant(e) des cabinets de stomatologie,,,"1624,10",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Infirmier(ère),,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Kinésithérapeute,,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Orthophoniste,,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Orthoptiste,,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Psychologue,,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)","Technicien(ne) bac F7, F7' ou équivalent",,,"1639,00",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Technicien(ne) titulaire du BTS,,,"1750,75",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Technicien(ne) niveau bac + 3 justifiant d'une formation spécifique en technique d'anatomo-cytopathologie,,,"1937,00",byMonth151,,,,,,,
12/2/2021,1/1/2017,,1147,Personnel des cabinets médicaux,,,"cf. Avenant n° 70 du 12 janv. 2017 (NOR : ASET1750273M) étendu par A. 30 juin 2017 : JO 8 juill. 2017, applicable au 1er janv. 2017 ; cf. Avenant n° 76 du 27 juin 2019 étendu par A. 20 mai 2020 : JO 26 mai 2020 et en vigueur le 1er juill. 2019 (conclu pour une durée indéterminée)",Technicien(ne) responsable de service,,,"1974,25",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 1,,,"1311,44",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2021",Groupe 2,,,"1355,575",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2022",Groupe 3,,,"1481,675",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2023",Groupe 4,,,"1576,25",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2024",Groupe 5,,,"1765,4",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2025",Groupe 6,,,"2206,75",byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,,Employés,Niveau I,Degré B,1542,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Employés,Niveau II,Degré C,1555,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Employés,Niveau II,Degré D,1565,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Employés,Niveau II,Degré E,1575,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Employés,Niveau III,Degré F,1607,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Employés,Niveau III,Degré G,1680,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Agents de maîtrise,Niveau IV,Degré H,1780,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Agents de maîtrise,Niveau IV,Degré I,1855,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Agents de maîtrise,Niveau IV,Degré J,1960,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Cadres,Niveau V,Degré K,2475,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Cadres,Niveau V,Degré L,2585,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Cadres,Niveau V,Degré M,2890,byMonth151,,,,,,,
12/2/2021,1/5/2020,,1606,Bricolage (Vente au détail en libre-service),,,cf. Accord du 11 janv. 2018 (NOR : ASET1850344M) étendu par A. 29 mai 2019 : JO 4 juin 2019 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tôt le 1er mars 2018 (conclu pour une durée indéterminée) ; cf. Accord professionnel du 7 nov. 2019 (NOR : ASET1951450M) étendu par A. 7 avr. 2020 : JO 21 avr. 2020 et applicable à partir du 1er jour du mois suivant la publication de son extension et au plus tard le 1er mars 2020 (conclu pour une durée indéterminée),Cadres,Niveau V,Degré N,3110,byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau I,,"1548,64",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau II,,"1554,95",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau II,,"1567,56",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau II,,"1619,95",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau III,,"1672,33",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau III,,"1724,71",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau III,,"1794,56",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau IV,,"1812,02",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau IV,,"1881,86",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 2.2 ; f. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Non Cadres,Niveau IV,,"1951,71",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau V,,"2021,55",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau V,,"2091,40",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau V,,"2161,24",byMonth151,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VI,Echelon A,"28321,02",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VI,Echelon B,"30748,54",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VII,Echelon A,"33176,05",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VII,Echelon B,"36412,74",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VII,Echelon C,"39649,43",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VIII,Echelon A,"44504,46",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VIII,Echelon B,"48550,32",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau VIII,Echelon C,"52596,18",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau IX,Echelon A,"55023,70",byYear1607,,,,,,,
12/2/2021,1/3/2020,,3216,Négoce des matériaux de construction,,,"cf. CC. n° 3154-00, Art. 3.2 et s. ; cf. Avenant du 27 févr. 2020 (NOR : ASET2050487M) étendu par A. 17 sept. 2020 : JO 26 sept. 2020 et en vigueur à compter du 1er mars 2020 (conclu à durée indéterminée)",Cadres,Niveau IX,Echelon B,"60687,90",byYear1607,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Employés,E2,,1625,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Employés,E3,,1696,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Techniciens,T1,,1866,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Techniciens,T2,,2063,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Techniciens,T3,,2756,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Cadres,C1,,3109,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Cadres,C2,,3816,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Cadres,C3,,4805,byMonth151,,,,,,,
12/2/2021,1/10/2020,,2205,Notariat,,,Cf. Avenant n° 1 du 8 juin 2001 (NOR : ASET0150552M) étendu par A. 25 févr. 2002 : JO 9 mars 2002 ; cf. Avenant n ° 37 du 21 févr. 2019 (NOR : ASET1950537M) non étendu et prenant effet au 1er mars 2019 ; cf. Avenant n° 40 du 17 sept. 2020 (NOR : ASET2050854M) non étendu et prenant effet au 1er oct. 2020,Cadres,C4,,5370,byMonth151,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau E1,,,"17282,48",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau E2,,,"17708,91",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau E3,,,"18272,58",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau E4,,,"19090,06",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau T1,,,"20930,37",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau T2,,,"24111,93",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau C1,,,"26220,36",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau C2,,,"35513,78",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau C3,,,"43148,74",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau C4,,,"62651,97",byYear1607,,,,,,,
12/2/2021,1/1/2013,,2128,Mutualité,,,"cf. Accord du 5 déc. 2012 (NOR : ASET1350107M) étendu par A. 16 avr. 2013 : JO 23 avr. 2013 à l'exclusion des organismes relevant des conventions collectives applicables respectivement au personnel, aux agents de direction et aux praticiens de la mutualité sociale agricole, prenant effet au 1er janvier 2013",Niveau D,,,"26220,36",byYear1607,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 145,,"1548,54",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 155,,"1569,33",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 170,,"1581,15",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 175,,"1598,28",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 185,,"1600,25",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 190,,"1643,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 200,,1730,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 205,,"1773,25",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 210,,"1816,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel des ateliers, des services techniques, du mouvement, administratif et de gestion",Coefficient 220,,1903,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 205,,"1773,25",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 210,,"1816,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 220,,1903,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 230,,"1989,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 240,,2076,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 250,,"2162,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 270,,"2335,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 280,,2422,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 300,,2595,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 310,,"2681,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 320,,2768,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 340,,2941,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,"Personnel de maîtrise des ateliers, des services techniques, du mouvement, administratif, de gestion, technicien et dessinateur",Coefficient 360,,3114,byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 300,,"2595,00",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 390,,"3373,5",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 430,,"3719,50",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 530,,"4584,50",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 630,,"5449,50",byMonth151,,,,,,,
12/2/2021,1/1/2020,,1424,Réseaux de transports publics urbains de voyageurs,,,cf. accord du 16 mars 2020 (NOR : ASET2050503M) étendu par A. 7 déc. 2020 : JO 29 déc. 2020 et applicable à compter de sa signature (Conclu pour une durée indéterminée,Personnel des ingénieurs et cadres,Coefficient 690,,"5968,50",byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,I,Echelon 1,1560,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,I,Echelon 2,1583,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,I,Echelon 3,1616,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,II,Echelon 1,1628,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,II,Echelon 2,1648,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,,Ouvriers / Employés,II,Echelon 3,1679,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,III,Echelon 1,1690,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,III,Echelon 2,1715,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,III,Echelon 3,1749,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,IV,Echelon 1,1781,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,IV,Echelon 2,1817,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Ouvriers / Employés,IV,Echelon 3,1853,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,V,Echelon 1,1864,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,V,Echelon 2,1895,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,V,Echelon 3,1947,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VI,Echelon 1,2066,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VI,Echelon 2,2149,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VI,Echelon 3,2232,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VII,Echelon 1,2383,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VII,Echelon 2,2478,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Techniciens et Agents de Maîtrise,VII,Echelon 3,2572,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,VIII,Echelon 1,2919,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,VIII,Echelon 2,3238,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,VIII,Echelon 3,3302,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,IX,Echelon 1,3946,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,IX,Echelon 2,4262,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,IX,Echelon 3,4602,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,X,Echelon 1,4980,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,X,Echelon 2,5375,byMonth151,,,,,,,
15/2/2021,1/3/2020,,1534,Entreprises de l'industrie et des commerces en gros des viandes,,,"cf. Avenant n° 87 du 21 févr. 2018 (NOR : ASET1850526M) étendu par A. 20 déc. 2018 : JO 23 déc. 2018 (déposé le 9 avril 2018) ; cf. Avenant n° 90 du 10 mars 2020 (NOR : ASET2050514M) non étendu et en vigueur à compter de sa signature pour les entreprises adhérant à une organisation professionnelle d'employeurs signataire et au plus tard à compter de son extension, prenant effet au 1er mars 2020",Cadres,X,Echelon 3,5808,byMonth151,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe A,,,19219,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe B,,,20497,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe C,,,21778,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe D,,,24245,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe E,,,28434,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe F,,,33742,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe G,,,39174,byYear1607,,,,,,,
15/2/2021,1/1/2020,,2247,Entreprises de courtage d'assurances et/ou de réassurances,,,"cf. CC. n° 3110, Art. 22 ; cf. Avenant du 1er déc. 2016 (NOR : ASET1750204M) étendu par A. 12 juin 2017 : JO 30 juin 2017 (déposé le 2 mars 2017) ; cf. Avenant du 23 janv. 2020 (NOR : ASET2050281M) non étendu (déposé le 19 févr. 2020)",Classe H,,,48019,byYear1607,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 135,,"1539,42",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 150,,"1541,92",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 160,,"1546,82",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 170,,"1552,17",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 180,,"1557,39",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel d'entretien,Coefficient 200,,"1567,18",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 210,,"1577,13",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 220,,"1603,97",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 230,,"1656,87",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 250,,"1761,56",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 260,,"1814,62",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel de secrétariat,Coefficient 270,,"1867,35",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 210,,"1577,13",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 220,,"1603,97",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 230,,"1656,87",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 240,,"1709,28",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 250,,"1761,56",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 260,,"1814,62",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 270,,"1867,35",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 280,,"1919,93",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel informaticien,Coefficient 290,,"1972,36",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 240,,"1709,28",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 250,,"1761,56",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 260,,"1814,62",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 270,,"1867,35",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 280,,"1919,93",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel qualiticien,Coefficient 290,,"1972,36",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel infirmier,Coefficient 250,,"1761,56",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel infirmier,Coefficient 260,,"1814,62",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Personnel infirmier,Coefficient 270,,"1867,35",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 240,,"1709,28",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 240,,"1709,28",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 250,,"1761,56",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 270,,"1867,35",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 280,,"1919,93",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 290,,"1972,36",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 300,,"2030,14",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 310,,"2096,78",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Pôle technique,Coefficient 350,,"2365,37",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Cadres,Coefficient 400,,"2672,81",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Cadres,Coefficient 500,,"3343,26",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Cadres,Coefficient 600,,"4015,44",byMonth151,,,,,,,
15/2/2021,1/7/2020,,959,Laboratoires de biologie médicale extrahospitaliers,,,cf. Accord du 2 juill. 2020 (NOR : ASET2050656M) étendu par A. 19 nov. 2020 : JO 1er déc. 2020 et applicable au 1er juillet 2020,Cadres,Coefficient 800,,"5355,24",byMonth151,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 230,,,18852,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 235,,,19127,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 240,,,19401,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 245,,,19675,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 250,,,19950,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 265,,,20773,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 280,,,21596,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 295,,,22419,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 310,,,23242,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 325,,,24065,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 340,,,24888,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 350,,,25437,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 360,,,25986,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 400,,,28181,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 450,,,30925,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 550,,,36412,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 625,,,40528,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 700,,,44643,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 850,,,52875,byYear1607,,,,,,,
15/2/2021,1/12/2019,,478,Sociétés financières,,,,Coefficient 900,,,55618,byYear1607,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,A,,,1480,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,B,,,1511,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,C,,,1541,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,D,,,1601,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,E,,,1641,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,F,,,1873,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,G,,,2115,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,H,,,2457,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,I,,,2810,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,J,,,3152,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,K,,,3716,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,L,,,4280,byMonth151,,,,,,,
15/2/2021,1/7/2015,,1413,Entreprises de travail temporaire (Personnels permanents) – Accord national,,,cf. Accord du 3 juill. 2015 (NOR : ASET1550862M) étendu par A. 7 déc. 2015 : JO 17 déc. 2015,M,,,4783,byMonth151,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 4 à 9,"G1, EE, OE, EQ, OQ1",,"20832,46",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 10 à 12,"G2, GQ, AQ, OQ2",,"22209,75",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 13 à 15,"G3, GHQ, OHQ",,"24270,29",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 16 à 18,"G3, GHQ, OHQ",,"27030,72",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 19 à 21,G5,,"35089,21",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 22 à 24,G6,,"36476,07",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 25 à 27,G7,,"37503,32",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 28 à 30,G8,,"42954,47",byYear1607,,,,,,,
15/2/2021,1/1/2020,,2150,Sociétés anonymes et fondations d'HLM (Personnels),,,"cf. CC. n° 3190, Art. 26 ; cf. Annexe I (Personnels dits administratifs) ; cf. Annexe II, 2. Rémunération (Personnels de surveillance et d'entretien ménager) ; cf. Annexe III, 2. Rémunération (Personnels de maintenance)",Cotation 31 à 32,G9,,"60830,42",byYear1607,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau I,Echelon 1,,"1567,20",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau I,Echelon 2,,"1584,06",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau I,Echelon 3,,"1594,80",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau II,Echelon 1,,"1605,48",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau II,Echelon 2,,"1621,41",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau II,Echelon 3,,"1636,18",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau III,Echelon 1,,"1660,07",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau III,Echelon 2,,"1759,47",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau IV,Echelon 1,,"1803,97",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau IV,Echelon 2,,"2036,07",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau IV,Echelon 3,,"2270,35",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau IV,Echelon 3,,"2383,86",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau IV,Echelon 4,,"2341,07",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau V,,,"2341,07",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau V,Cadres,,"2458,13",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VI,,,"2788,38",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VI,Cadres,,"2927,80",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VII,,,"3329,80",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VII,Cadres,,"3496,29",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VIII,,,"4027,83",byMonth151,,,,,,,
15/2/2021,1/4/2020,,1790,"Espaces de loisirs, d'attractions et culturels",,,cf. Avenant n° 64 du 27 févr. 2020 (NOR : ASET2050387M) étendu par A. 12 août 2020 : JO 22 août 2020 (déposé le 14 avr. 2020),Niveau VIII,Cadres,,"4229,22",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 11,,"1521,5",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 21,,"1523,5",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 22,,"1526,5",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 31,,1532,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 32,,1540,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP41,,1598,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP42,,1623,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP43,,1687,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 51,,1751,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents de production,AP 52,,1826,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF1,,"1521,5",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 3,,"1525,5",byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 5,,1531,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 7,,1538,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 9,,1556,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 11,,1617,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 12,,1652,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 14,,1752,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 15,,1785,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents fonctionnels,AF 16,,1850,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,1,,1540,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,2,,1558,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,3,,1619,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,4,,1675,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,5,,1778,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,6,,1915,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Agents d'encadrement,7,,2365,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 11,,2187,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 12,,2410,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 13,,2588,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 21,,2984,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 22,,3185,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 23,,3450,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 31,,3847,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 32,,4102,byMonth151,,,,,,,
15/2/2021,1/11/2019,,1411,Ameublement (Fabrication),,,"cf. CC. n° 3155, Art. 36 ; cf. Accord du 23 oct. 2019 (NOR : ASET2050112M) étendu par A. 29 mai 2020 : JO 9 juin 2020 et applicable à compter du 1er nov. 2019 (conclu pour une durée indéterminée)",Cadres,C 33,,4507,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau I,Echelon 1,"1445,38",byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau I,Echelon 2,1446,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau II,Echelon 1,"1450,00",byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau II,Echelon 2,1460,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau III,Echelon 1,1470,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau III,Echelon 2,1475,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau IV,Echelon 1,1500,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Employés,Niveau IV,Echelon 2,1550,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Agents de maîtrise,Niveau V,,1680,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Cadres,Niveau VI,,2020,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Cadres,Niveau VII,,2620,byMonth151,,,,,,,
15/2/2021,1/8/2014,,2156,Grands magasins et magasins populaires,,,cf. Accord du 18 juin 2014 (NOR : ASET1450913M) étendu par A. 24 oct. 2014 : JO 29 nov. 2014 et applicable à compter du 1er jour du mois civil qui suit son dépôt (déposé le 11 juillet 2014),Cadres,Niveau VIII,,3450,byMonth151,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe I,,,19884,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe II,,,20608,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe III,,,21967,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe IV,,,22973,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe V,,,26909,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe VI,,,35042,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe VII,,,47978,byYear1607,,,,,,,
15/2/2021,1/1/2019,,2147,Eau et assainissement (Entreprises des services d'),,,"cf. CC. n° 3302, Art. 4 ; cf. Avenant n° 15 du 21 juin 2017 (NOR : ASET1750702M) étendu par A. 28 nov. 2017 : JO 8 déc. 2017 et applicable au 1er janv. 2017 ; cf. Avenant n° 18 du 9 août 2019 (NOR : ASET1951368M) non étendu et applicable au 1er janv. 2019",Groupe VIII,,,56741,byYear1607,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau I,Coefficient 125,,1530,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau I,Coefficient 130,,1535,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau I,Coefficient 135,,1539,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau I,Coefficient 140,,1542,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau II,Coefficient 145,,1545,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau II,Coefficient 150,,1548,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau II,Coefficient 155,,1553,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau II,Coefficient 160,,1574,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau II,Coefficient 165,,1595,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 170,,1617,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 175,,1646,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 180,,1675,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 185,,1704,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 190,,1732,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau III,Coefficient 195,,1761,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 200,,1805,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 205,,1824,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 210,,1843,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 215,,1863,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 220,,1890,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IV,Coefficient 225,,1921,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 230,,1954,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 235,,1985,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 240,,2018,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 245,,2049,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 250,,2080,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau V,Coefficient 255,,2113,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 260,,2147,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 265,,2179,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 270,,2212,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 275,,2245,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 280,,2278,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 285,,2309,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 290,,2343,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VI,Coefficient 295,,2376,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 300,,2408,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 305,,2441,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 310,,2473,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 315,,2505,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 320,,2539,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 325,,2571,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 330,,2602,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 335,,2636,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 340,,2668,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VII,Coefficient 345,,2702,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VIII,Coefficient 350,,2871,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau VIII,Coefficient 400,,3097,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau IX,Coefficient 600,,4347,byMonth151,,,,,,,
15/2/2021,1/3/2019,,1586,"Industries charcutières (Salaisons, charcuteries, conserves de viandes)",,,cf. Accord du 6 mars 2019 (NOR : ASET1950590M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019,Niveau X,Coefficient 700,,4997,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie A,Débutant,"1539,42",byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie A,Maîtrisant,1570,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie A,Référant / polyvalent,1632,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie B,Débutant,1558,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie B,Maîtrisant,1589,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie B,Référant / polyvalent,1651,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie C,Débutant,1604,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie C,Maîtrisant,1636,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020","Ouvriers, employés",Catégorie C,Référant / polyvalent,1700,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie D,Débutant,1757,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie D,Maîtrisant,1792,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie D,Référant,1862,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie E,Débutant,2174,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie E,Maîtrisant,2217,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Techniciens et Agent de maîtrise,Catégorie E,Référant,2304,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie F,Débutant,2418,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie F,Maîtrisant,2466,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie F,Référant,2563,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie G,Débutant,3068,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie G,Maîtrisant,3129,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie G,Référant,3252,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie H,Débutant,,,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie H,Maîtrisant,4144,byMonth151,,,,,,,
15/2/2021,1/7/2020,,2198,Entreprises du commerce à distance,,,"cf. CC. n° 3333, Art. 27 ; cf. Accord du 24 juin 2011 (NOR : ASET1151034M) étendu par A. 9 août 2012 : JO 22 août 2012, applicable dans un délai de 18 mois au plus tard à compter de la publication de l'extension de la classification ; cf. Accord du 12 juin 2020 (NOR : ASET2050817M) étendu par A. 14 déc. 2020 : JO 6 janv. 2021 et applicable au 1er juill. 2020",Cadres,Catégorie H,Référant,4306,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 110,,,1540,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 115,,,1575,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 130,,,1605,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 140,,,1675,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 150,,,1695,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 160,,,1695,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 170,,,1705,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 180,,,1705,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 190,,,1740,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 195,,,1740,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 200,,,1870,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 210,,,1870,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 220,,,1950,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 230,,,1990,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 240,,,2180,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 250,,,2180,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 280,,,2340,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 300,,,2600,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 330,,,2750,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 350,,,3020,byMonth151,,,,,,,
15/2/2021,1/9/2020,,1431,Optique-lunetterie de détail,,,,Coefficient 380,,,3220,byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 207,,,"1569,06",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 215,,,"1612,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 225,,,"1644,75",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 240,,,"1692,00",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 240,,,"1692,00",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 250,,,"1762,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 265,,,"1868,25",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 270,,,"1903,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 285,,,"2009,25",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 300,,,"2115,00",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 350,,,"2467,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 385,,,"2714,25",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 410,,,"2890,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 450,,,"3172,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 480,,,"3384,00",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 510,,,"3595,50",byMonth151,,,,,,,
15/2/2021,1/7/2020,,1000,Avocats (Personnel salarié),,,cf. Avenant n° 124 du 15 févr. 2019 (NOR : ASET1950724M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janvier 2019 sous réserve de la publication de son extension ; cf. Avenant n° 128 du 13 mars 2020 (NOR : ASET2050367M) étendu par A. 21 juill. 2020 : JO 1er août 2020 et prenant effet au 1er juillet 2020,Coefficient 560,,,"3948,00",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.1,,,"1557,03",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.2,,,"1566,70",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.3,,,"1576,37",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.4,,,"1606,20",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.5,,,"1636,03",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau I.6,,,"1720,01",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.1,,,"1803,99",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Nievau II.2,,,"1905,51",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.3,,,"2007,03",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.4,,,"2141,92",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.5,,,"2276,81",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.6,,,"2383,04",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau II.7,,,"2489,25",byMonth151,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.1,,,"31239,89",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.2,,,"37525,48",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.3,,,"40871,93",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.4,,,"44218,38",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.5,,,"48043,96",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.6,,,"51869,53",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.7,,,"56174,71",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.8,,,"60479,88",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.9,,,"65261,08",byYear1607,,,,,,,
15/2/2021,1/2/2019,,1555,"Fabrication et commerce des produits à usage pharmaceutique, parapharmaceutique et vétérinaire",,,cf. Accord du 23 janv. 2019 (NOR : ASET1950531M) étendu par A. 21 févr. 2020 : JO 27 févr. 2020 et applicable du 1er févr. 2019 au 31 janv. 2020 (conclu pour une durée déterminée de 1 an) ; cf. Accord du 15 mai 2019 (NOR : ASET1951184M) non étendu 2020 (conclu pour une durée indéterminée) (déposé le 31 juill. 2019),Niveau III.10,,,"70042,28",byYear1607,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau I,Echelon 1,,"1590,96",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau I,Echelon 2,,"1596,19",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau I,Echelon 3,,"1601,43",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau II,Echelon 1,,"1608,71",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau II,Echelon 2,,"1611,89",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau II,Echelon 3,,"1622,36",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau III,Echelon 1,,"1631,99",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau III,Echelon 2,,"1642,98",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau III,Echelon 3,,"1692,43",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau IV,Echelon 1,,"1730,86",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau IV,Echelon 2,,"1758,38",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau IV,Echelon 3,,"1791,33",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau V,Echelon 1,,"1934,21",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau V,Echelon 2,,"2044,09",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau V,Echelon 3,,2154,byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VI,Echelon 1,,"2263,43",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VI,Echelon 2,,"2426,92",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VI,Echelon 3,,"2666,09",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VII,Echelon 1,,"3351,65",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VII,Echelon 2,,"3525,77",byMonth151,,,,,,,
17/2/2021,1/9/2020,,1978,"Fleuristes, vente et services des animaux familiers",,,cf. Accord du 13 mars 2019 (NOR : ASET1950535M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 et en vigueur le 1er jour du mois suivant la date de parution de son extension (conclu pour une durée indéterminée) ; cf. Accord du 3 mars 2020 (NOR : ASET2050408M) étendu par A. 24 juill. 2020 : JO 1er août 2020 et en vigueur à compter du 1er jour du mois civil suivant la parution de son extension (conclu pour une durée indéterminée),Niveau VII,Echelon 3,,"3699,89",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,I,Echelon 1,"1538,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,I,Echelon 2,"1540,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,I,Echelon 3,"1542,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,II,Echelon 1,"1545,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,II,Echelon 2,"1562,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,II,Echelon 3,"1575,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,III,Echelon 1,"1581,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,III,Echelon 2,"1606,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,III,Echelon 3,"1623,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,IV,Echelon 1,"1626,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,IV,Echelon 2,"1655,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Employés,IV,Echelon 3,"1672,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,V,Echelon 1,"1684,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,V,Echelon 2,"1766,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,V,Echelon 3,"1830,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,VI,Echelon 1,"1842,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,VI,Echelon 2,"1932,00",byMonth151,,,,,,,
17/2/2021,1/10/2019,,1383,"Commerces de quincaillerie, fournitures industrielles, fers, métaux et équipement de la maison (Employés et agents de maîtrise)",,,cf. Avenant du 25 janv. 2018 (NOR : ASET1850495M) étendu par A. 21 déc. 2018 : JO 23 déc. 2018 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension ; cf. Avenant du 24 janv. 2019 (NOR : ASET1950497M) étendu par A. 23 sept. 2019 : JO 27 sept. 2019 à l'exclusion du secteur de la droguerie et applicable à compter du 1er jour du mois suivant la publication de son extension,Maîtrise,VI,Echelon 3,"2020,00",byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,Echelon 1,,1542,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,Echelon 2,,1548,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau I,Echelon 3,,1554,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,Echelon 1,,1570,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,Echelon 2,,1590,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau II,Echelon 3,,1617,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,Echelon 1,,1650,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,Echelon 2,,1684,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau III,Echelon 3,,1719,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,Echelon 1,,1870,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,Echelon 2,,2023,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau IV,Echelon 3,,2191,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,Echelon 1,,2385,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,Echelon 2,,2627,byMonth151,,,,,,,
17/2/2021,1/7/2020,,1495,"Transformation des papiers et cartons et industries connexes (Ouvriers, employés, dessinateurs, techniciens et agents de maîtrise)",,,cf. Accord professionnel du 22 nov. 2006 (NOR : ASET0750115M) étendu par A. 29 mars 2007 : JO 20 avr. 2007 ; cf. Avenant n° 11 du 22 juin 2020 (NOR : ASET2050637M) étendu par A. 16 oct. 2020 : JO 31 oct. 2020 et en vigueur au 1er juill. 2020 (Conclu pour une durée indéterminée),Niveau V,Echelon 3,,2903,byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 115,,,"1542,5",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 120,,,"1583,36",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 140,,,"1588,67",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 155,,,"1594,67",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 165,,,"1601,38",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 180,,,"1608,99",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 200,,,"1617,5",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 220,,,"1626,05",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 235,,,"1661,45",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 240,,,"1679,57",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 245,,,"1697,69",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 250,,,"1715,81",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 255,,,"1733,92",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 260,,,"1752,03",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 270,,,"1788,27",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 275,,,"1806,39",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 285,,,"1842,62",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 290,,,"1860,73",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 295,,,"1878,85",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 305,,,"1915,09",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 310,,,"1933,2",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 315,,,"1951,31",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 325,,,"1987,56",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 335,,,"2023,81",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 340,,,"2041,9",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 350,,,"2078,15",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 400,,,"2259,31",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 425,,,"2349,89",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 430,,,"2368,02",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 450,,,"2440,48",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 460,,,"2476,72",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 470,,,"2512,95",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 495,,,"2603,53",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 520,,,"2694,12",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 540,,,"2766,6",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 560,,,"2839,06",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 580,,,"2911,53",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 600,,,"2983,99",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 620,,,"3056,46",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1077,"Entreprises du négoce et de l'industrie des produits du sol, engrais et produits connexes",,,cf. Avenant n° 67 du 18 janv. 2019 (NOR : ASET1950584M) étendu par A. 25 sept. 2019 : JO 2 oct. 2019 ; cf. Avenant n° 68 du 16 janv. 2020 ((NOR : ASET2050536M) étendu par A. 23 sept. 2020 : JO 3 oct. 2020 et applicable à compter du 1 er janvier 2020,Coefficient 680,,,"3273,87",byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Employés,Niveau A,,1544,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Employés,Niveau B,,1553,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Employés,Niveau C,,1599,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Employés,Niveau D,,1652,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Agents de maîtrise,Niveau E,,1763,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Agents de maîtrise,Niveau F,,1862,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Cadres,Niveau G,,2381,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Cadres,Niveau H,,3316,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Cadres,Niveau I,,3465,byMonth151,,,,,,,
17/2/2021,1/12/2020,,1487,Commerce de détail de l'horlogerie-bijouterie,,,cf. Avenant n° 23 du 26 févr. 2019 (NOR : ASET1950729M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et en vigueur 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée) ; cf. Avenant n° 24 du 17 avr. 2020 (NOR : ASET2050738M) étendu par A. 20 nov. 2020 : JO 28 nov. 2020 et en vigueur le 1er jour du mois qui suit son extension (Conclu pour une durée indéterminée),Cadres,Niveau J,,3774,byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau I,,,"1564,07",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau II,,,"1643,09",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau III,,,"1725,14",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveai IV,,,"1818,34",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau V,,,"2058,42",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau VI,Echelon 1,,"2225,56",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau VI,Echelon 2,,"2398,78",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau VII,,,"2933,65",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau VIII,,,"3528,28",byMonth151,,,,,,,
17/2/2021,1/1/2020,,1930,Métiers de la transformation des grains,,,"cf. Avenant n° 45 du 7 janv. 2016 (NOR : ASET1650309M) étendu par A. 3 mai 2016 : JO 19 mai 2016 et en vigueur le 1er janvier 2016 pour les entreprises adhérentes, le lendemain de la publication de son extension pour l'ensemble des entreprises de la branche et leurs salariés ; cf. Annexe II ; cf. Avenant n° 9 du 19 mars 2019 (NOR : ASET1950966M) étendu par A. 23 déc. 2019 : JO 28 déc. 2019 et en vigueur le 1er mars 2019 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension ; cf. Avenant n° 13 du 7 janv. 2020 (NOR : ASET2050501M) étendu par A. 26 nov. 2020 : JO 15 déc. 2020 et en vigueur le 1er janvier 2020 à l'ensemble des entreprises adhérentes aux syndicats signataires et à leurs salariés, à l'ensemble des entreprises de la branche et à leurs salariés, le lendemain de la publication de son extension",Niveau IX,,,"4415,67",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Manutentionnaire en jardinerie/ graineterie,Coefficient 155,,"1521,25",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Agent administratif 1er échelon,Coefficient 155,,"1521,25",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Employé(e) de jardinerie,Coefficient 160,,"1525,8",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Vendeur(se) 1er échelon,Coefficient 160,,"1525,8",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Hôte-hôtesse de caisse 1er échelon et/ ou hôte-hôtesse d'accueil 1er échelon,Coefficient 160,,"1525,8",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Réceptionnaire,Coefficient 160,,"1525,8",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Agent administratif 2e échelon,Coefficient 165,,"1533,39",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Vendeur(se) 2e échelon,Coefficient 170,,"1540,97",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Hôte-hôtesse de caisse 2e échelon et/ou hôte-hôtesse d'accueil 2e échelon,Coefficient 170,,"1540,97",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Secrétaire,Coefficient 175,,"1548,55",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Vendeur confirmé comptable,Coefficient 180,,"1565,23",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Gestionnaire de rayon,Coefficient 185,,"1597,09",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Responsable de rayons,Coefficient 190,,"1644,10",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Responsable de secteur,Coefficient 200,,"1716,90",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Adjoint de direction,Coefficient 220,,"1883,74",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Responsable de point de vente,Coefficient 260,,"2243,20",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Directeur,Coefficient 350,,"3033,40",byMonth151,,,,,,,
17/2/2021,1/1/2019,,1760,Jardineries et graineteries,,,cf. Accord du 27 juin 2017 (NOR : ASET1750796M) étendu par A. 6 déc. 2017 : JO 13 déc. 2017 ; cf. Accord du 11 janv. 2019 (NOR : ASET1950404M) non étendu (déposé le 4 févr. 2019),Directeur régional,Coefficient 400,,"3467,18",byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau I,Echelon 1,1458,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau I,Echelon 2,1465,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau I,Echelon 3,1467,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau II,Echelon 1,1470,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau II,Echelon 2,1474,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau II,Echelon 3,1477,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau III,Echelon 1,1482,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau III,Echelon 2,1492,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau III,Echelon 3,1497,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau IV,Echelon 1,1503,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau IV,Echelon 2,1513,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau IV,Echelon 3,1523,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau V,Echelon 1,1533,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau V,Echelon 2,1543,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Employés,Niveau V,Echelon 3,1555,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Agents de maîtrise,Niveau VI,Echelon 1,1687,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Agents de maîtrise,Niveau VI,Echelon 2,1767,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Agents de maîtrise,Niveau VI,Echelon 3,1877,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Agents de maîtrise,Niveau VII,,2019,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Cadres,Niveau I,Echelon1,2300,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Cadres,Niveau I,Echelon 2,2515,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Cadres,Nievau II,Echelon 1,2898,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Cadres,Nievau II,Echelon 2,3442,byMonth151,,,,,,,
17/2/2021,1/9/2015,,500,"Commerces de gros de l'habillement, de la mercerie, de la chaussure et du jouet",,,cf. Avenant n° 49-A du 28 janv. 2015 (NOR : ASET1550343M) étendu par A. 2 juill. 2015 : JO 16 juill. 2015 ; modifié par A. 21 juill. 2015 : JO 29 juill. 2015,Cadres,Niveau III,,3888,byMonth151,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 1,,,20652,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 2,,,21066,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 3,,,21486,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 4,,,21917,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 5,,,22354,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 6,,,23026,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 7,,,23716,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 8,,,24473,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 9,,,25354,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 10,,,26267,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 11,,,27212,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 12,,,28192,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 13,,,29206,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 14,,,30258,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 15,,,31348,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 16,,,32476,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 17,,,33646,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 18,,,34856,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 19,,,36111,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 20,,,63921,byYear1607,,,,,,,
17/2/2021,1/1/2020,,897,Services de santé au travail interentreprises,,,,Classe 21,,,72398,byYear1607,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 8,,"1539,42",byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 8,Echelon 2,1542,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 9,,1547,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 9,Echelon 2,1551,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 10,,1573,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 11,,1593,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Cadres,Niveau 12,,1603,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Agents de maîtrise,Niveau 6,,1786,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Agents de maîtrise,Niveau 7,,1993,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 8,,26248,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 8,Echelon 2,27506,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 9,,29397,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 9,Echelon 9,31288,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 10,,33598,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 11,,37797,byMonth151,,,,,,,
17/2/2021,1/11/2020,,468,Commerce succursaliste de la chaussure,,,cf. Accord du 6 juin 2019 (NOR : ASET1951187M) étendu par A. 17 févr. 2020 : JO 22 févr. 2020 et applicable à compter du mois qui suit sa signature ; cf. Accord du 6 oct. 2020 (NOR : ASET2050940M) non étendu et applicable à compter du mois qui suit sa signature,Employés,Niveau 12,,43046,byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 1,,,"1557,3944",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 2,,,"1601,4716",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 3,,,"1733,7032",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 4,,,"1832,8769",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2021",Groupe 5,,,"2052,822128",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2021",Groupe 6,,,"2561,032244",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2020",Groupe 7,,,31525,byYear1607,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2021",Groupe 8,,,36569,byYear1607,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2022",Entraîneurs,Classe A - Technicien,,"1513,2",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2023",Entraîneurs,Classe B - Technicien,,"1702,35",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2024",Entraîneurs,Classe C- Agent de maîtrise,,"1765,4",byMonth151,,,,,,,
12/2/2021,1/1/2020,,2511,Sport,,,"cf. CC. n° 3328, Art. 9.2. ; cf. Avenant n° 135 du 26 juin 2018 (NOR : ASET1851094M) étendu par A. 8 févr. 2019 : JO 14 févr. 2019 et prenant effet au 1er janv. 2019 ; cf. Avenant n° 140 du 25 mars 2019 (NOR : ASET1950658M) étendu par A. 30 oct. 2019 : JO 5 nov. 2019 et prenant effet au 1er janv. 2025",Entraîneurs,Classe D - Cadre,,34047,byYear1607,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
et employé de bureau",I a,1389,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
et employé de bureau",I b,"1481,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
et employé de bureau",I c,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
et employé de bureau",I d,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de quartier qualifié,I a,1389,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de quartier qualifié,I b,"1481,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de quartier qualifié,I c,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de quartier qualifié,I d,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
responsable d'un chantier",II a,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
responsable d'un chantier",II b,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
responsable d'un chantier",II c,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier
responsable d'un chantier",II d,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de proximité qualifié,II a,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de proximité qualifié,II b,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de proximité qualifié,II c,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Opérateur de proximité qualifié,IId,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,II a,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,II b,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,II c,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,II d,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Aide-comptable,II a,"1574,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Aide-comptable,II b,"1666,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Aide-comptable,II c,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Aide-comptable,II d,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,III a,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,III b,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,III c,"1944,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Secrétaire,III d,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Comptable,III a,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Comptable,III b,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Comptable,III c,"1944,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,Comptable,III d,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier responsable
de chantiers et/ou de projets",III a,"1759,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier responsable
de chantiers et/ou de projets",III b,1852,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier responsable
de chantiers et/ou de projets",III c,"1944,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Ouvriers et employés,"Opérateur de quartier responsable
de chantiers et/ou de projets",III d,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Secrétaire de direction,IV a,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Secrétaire de direction,IV b,"2129,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Secrétaire de direction,IV c,"2222,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Secrétaire de direction,IV d,2315,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Comptable,IV a,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Comptable,IV b,"2129,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Comptable,IV c,"2222,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Comptable,IV d,2315,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques","Coordinateur technique
de chantiers",IV a,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques","Coordinateur technique
de chantiers",IV b,"2129,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques","Coordinateur technique
de chantiers",IV c,"2222,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques","Coordinateur technique
de chantiers",IV d,2315,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Coordinateur social,IV a,"2037,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Coordinateur social,IV b,"2129,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Coordinateur social,IV c,"2222,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,"Agents de maîtrise et agents fonctionnels, Personnel d'encadrement, assistants techniques",Coordinateur social,IV d,2315,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Direction,Directeur adjoint,V a,"2592,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Direction,Directeur adjoint,V b,"2870,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Direction,Directeur,V c,"3148,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Direction,Directeur,V d,"3426,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Attaché à la délégation générale du comité national de liaison des régies de quartier,Adjoint au délégué général de la délégation générale du comité national de liaison des régies de quartier,V a,"2592,8",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Attaché à la délégation générale du comité national de liaison des régies de quartier,Adjoint au délégué général de la délégation générale du comité national de liaison des régies de quartier,V b,"2870,6",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Attaché à la délégation générale du comité national de liaison des régies de quartier,Adjoint au délégué général de la délégation générale du comité national de liaison des régies de quartier,V c,"3148,4",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Attaché à la délégation générale du comité national de liaison des régies de quartier,Adjoint au délégué général de la délégation générale du comité national de liaison des régies de quartier,V d,"3426,2",byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Délégué général du comité national de liaison des régies de quartier,Responsable de la délégation générale du comité national de liaison des régies de quartier,VI a,3704,byMonth151,,,,,,,
15/3/2021,15/3/2021,,3105,Régies de quartier,,,Simulateur Uniformation,Délégué général du comité national de liaison des régies de quartier,Responsable de la délégation générale du comité national de liaison des régies de quartier,VI b,"3889,2",byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,150,,1526,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,155,,1535,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,165,,1545,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,175,,1565,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,185,,1588,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,195,,1681,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,205,,1719,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Employé,210,,1810,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Maîtrise,220,,1891,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Maîtrise,230,,1979,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Maîtrise,250,,2155,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Maîtrise,270,,2258,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Maîtrise,275,,2367,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Cadre,320,,2804,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Cadre,350,,2973,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Cadre,370,,3189,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Cadre,410,,3557,byMonth151,,,,,,,
15/3/2021,20/3/2019,,3168,Professions de la photographie,,,,Cadre,450,,3887,byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,1.1,,"1983,60",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,1.2,,"2088,00",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,2.1,,"2186,10",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,2.1,,"2394,30",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,2.2,,"2706,60",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,2.3,,"3123,00",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,3.1,,"3490,10",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,3.2,,"4311,30",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,Cadres,3.3,,"5543,10",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,1.1,,"1558,80",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,1.2,,"1587,50",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,1.3,,"1618,50",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,2.1,,"1683,75",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,2.2,,"1786,70",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,2.3,,"1922,60",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,3.1,,"2059,80",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,3.2,,"2210,30",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1486,"Bureaux d'études techniques, cabinets d'ingénieurs-conseils, sociétés de conseils",,,,ETAM,3.3,,"2355,80",byMonth151,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 1 ,Niveau I,,"9,98",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 1 ,Niveau II,,"10,18",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 1 ,Niveau III,,"10,77",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 1 ,Niveau IV,,"11,3",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 1 ,Niveau V,,"13,36",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 2,Niveau I,,"10,03",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 2,Niveau II,,"10,31",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 2,Niveau III,,"10,83",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 2,Niveau IV,,"11,47",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 2,Niveau V,,"15,59",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 3,Niveau I ,,"10,1",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 3,Niveau II,,"10,66",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 3,Niveau III,,"11,13",byHour,,,,,,,
10/2/2021,9/1/2021,,1979,"Hôtels, cafés, restaurants",,,cf. Avenant n°  28 du 13  avr. 2018 (NOR  : ASET1850860M) étendu par A. 27 déc. 2018 : JO 30 déc. 2018 et applicable le 1er jour du mois suivant la publication de son extension (Conclu à durée indéterminée),Echelon 3,Niveau V,,"21,83",byHour,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",1 B ,Après 6 mois,,"1576,58",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",1 A ,6 premiers mois,,"1574,98",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",2 B,Après 6 mois,,"1589,32",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",2 A ,6 premiers mois,,"1576,58",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",3 B ,Après 12 mois,,"1608,43",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",3 A,12 premiers mois,,"1590,91",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",4 B,Après 24 mois,,"1699,20",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",4 A,24 premiers mois,,"1612,41",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",5,,,"1799,53",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",6,,,"1903,04",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",7,,,"2477,93",byMonth151,,,,,,,
10/2/2021,9/1/2021,,2216,commerce de détail et de gros à prédominance alimentaire,,,"cf. Avenant n° 78 du 19 sept. 2019 (NOR : ASET1951403M) étendu par A. 7 déc. 2020 : JO 26 déc. 2020 et applicable à compter du 1er jour du mois suivant la publication de son arrêté d’extension, y compris au sein des DROM, et sous réserve que celui-ci ne comporte pas d'exclusion remettant en cause les montants des minima conventionnels hiérarchiques fixés (conclu pour une durée déterminée de 3 ans",8,,,"3333,10",byMonth151,,,,,,,
11/2/2021,,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie A,,,"1452,6",byMonth151,,,,,,,
11/2/2021,,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie B,,,"1457,98",byMonth151,,,,,,,
11/2/2021,,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie C,,,"1592,48",byMonth151,,,,,,,
11/2/2021,,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie D,,,"1726,98",byMonth151,,,,,,,
11/2/2021,1/1/2013,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie E,,,"1974,46",byMonth151,,,,,,,
11/2/2021,1/1/2013,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie F,,,"2141,24",byMonth151,,,,,,,
11/2/2021,1/1/2013,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie G,,,"2383,34",byMonth151,,,,,,,
11/2/2021,1/1/2013,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie H,,,2959,byMonth151,,,,,,,
11/2/2021,1/1/2013,1,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie I,,,"3938,16",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie A,,,"1457,98",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie B,,,"1463,36",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie C,,,"1635,52",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie D,,,"1753,88",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie E,,,"2001,36",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie F,,,"2302,64",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie G,,,"2437,14",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie H,,,"3012,8",byMonth151,,,,,,,
11/2/2021,1/1/2013,2,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie I,,,"4018,86",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie A,,,"1463,36",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie B,,,"1468,74",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie C,,,"1651,66",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie D,,,"1780,78",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie E,,,"2028,26",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie F,,,"2351,06",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie G,,,"2490,94",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie H,,,"3066,6",byMonth151,,,,,,,
11/2/2021,1/1/2013,3,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie I,,,"4099,56",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie A,,,"1468,74",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie B,,,"1474,12",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie C,,,"1673,18",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie D,,,"1807,68",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie E,,,"2055,16",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie F,,,"2404,86",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie G,,,"2544,74",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie H,,,"3120,4",byMonth151,,,,,,,
11/2/2021,1/1/2013,4,2941,"branche de l'aide, de l'accompagnement, des soins et des services à domicile",,,,Catégorie I,,,"4180,26",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,I,Niveau,A176,"1585,19",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,I,Niveau,B181,"1592,54",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,I,Niveau,C186,"1599,89",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,II,Niveau,A195,"1607,23",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,II,Niveau,B205,"1614,58",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,II,Niveau,C210,"1621,93",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,III,Niveau,A225,"1629,29",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,III,Niveau,B235,"1660,15",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,III,Niveau,C245,"1731,43",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,IV,Niveau,A260,"1836,04",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,IV,Niveau,B280,"1976,31",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,IV,Niveau,C300,"2117,71",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,V,Niveau,A320,"2245,33",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,V,Niveau,B340,"2384,45",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,V,Niveau,C365,"2560,35",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI *,Niveau,A370,"2239,36",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI *,Niveau,B375,"2398,84",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI *,Niveau,C380,"2570,73",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI,Niveau,A390,"2738,87",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI,Niveau,B430,"3053,66",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VI,Niveau,C460,"3382,79",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VII,Niveau,A500,"3766,21",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VII,Niveau,B600,"4278,53",byMonth151,,,,,,,
11/2/2021,,,1412,"entreprises d'installation sans fabrication, y compris entretien, réparation, dépannage de matériel aéraulique, thermique, frigorifique et connexes",,,,VII,Niveau,C700,"5072,49",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE1,112-115,"1 527,00",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE2,216-219,"1 540,03",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE3,320-325,"1 553,43",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE4,426-430,"1 589,90",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE5,531-536,"1 634,21",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE6,637-642,"1 699,10",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Ouvriers Employés,OE7,743-747,"1 776,37",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,"Techniciens Agents de 
maîtrise",TA1,148-152,"1 889,58",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,"Techniciens Agents de 
maîtrise",TA2,253-257,"2 015,49",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,"Techniciens Agents de 
maîtrise",TA3,358-362,"2 195,49",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,"Techniciens Agents de 
maîtrise",TA4,463-467,"2 350,53",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,"Techniciens Agents de 
maîtrise",TA5,568-572,"2 505,56",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Cadres,CA1,173-175,"2 665,81",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Cadres,CA2,276-279,"3 014,38",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Cadres,CA3,380-383,"3 512,79",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Cadres,CA4,484-487,"4 012,23",byMonth151,,,,,,,
15/2/2021,1/4/2019,,1747,activités industrielles de boulangerie et pâtisserie,,,,Cadres,CA5,588-590,"4 532,49",byMonth151,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2019,Niveau I ,Ouvriers d'exécution,Position 1,"10,06",byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2020,Niveau I ,Ouvriers d'exécution,Position 2,"10,18",byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2021,Niveau II ,Ouvriers professionnels,,"10,64",byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2022,Niveau III,Compagnons professionnels,Position 1,"11,93",byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2023,Niveau III,Compagnons professionnels,Positinon 2,"12,96",byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2024,Niveau IV,Maîtres-ouvriers ou chefs d'équipe,Position 1,14,byHour,,,,,,,
11/2/2021,7/3/2018,,1596,Bâtiment – Ouvriers (Entreprises occupant jusqu'à dix salariés),,,cf. Accord du 16 nov. 2017 (NOR : ASET1850037M) étendu par A. 18 janv. 2019 : JO 10 avr. 2019 et applicable à compter du 1er janvier 2018 cf. Avenant n° 2 du 16 nov. 2018 (NOR : ASET1950229M) non étendu et en vigueur à compter du 1er janvier 2025,Niveau IV,Maîtres-ouvriers ou chefs d'équipe,Position 2,"15,03",byHour,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",130,,,"1 513,00",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",140,,,"1 520,48",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",150,,,"1 527,96",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",160,,,"1 535,44",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",170,,,"1 542,92",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",180,,,"1 550,40",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",190,,,"1 557,88",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",215,,,"1 576,58",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",225,,,"1 584,06",byMonth151,,,,,,,
12/2/2021,1/1/2019,,45,Caoutchouc,,,"cf. Accord du 18 janv. 2018 (NOR : ASET1850475M) étendu par A. 21 déc. 2018 : JO 26 déc. 2018 et en vigueur le 1er jour du mois suivant la date de publication de son extension, (Conclu pour une durée indéterminée)",240,,,"1 595,28",byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N15,,,1798,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N14,,,1754,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N13,,,1710,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N12,,,1663,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N11,,,1618,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N10,,,1573,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N9,,,1557,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N8,,,1532,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N7,,,1530,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N6,,,1529,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N5,,,1527,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N4,,,1525,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N3,,,1522,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N2,,,1520,byMonth151,,,,,,,
15/3/2021,1/1/2019,1-3,3230,Presse d'information spécialisée,,,,N1,,,1518,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N15,,,1844,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N14,,,1800,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N13,,,1753,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N12,,,1707,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N11,,,1660,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N10,,,1612,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N9,,,1579,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N8,,,1557,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N7,,,1532,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N6,,,1530,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N5,,,1529,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N4,,,1527,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N3,,,1525,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N2,,,1522,byMonth151,,,,,,,
15/3/2021,1/1/2019,4,3230,Presse d'information spécialisée,,,,N1,,,1520,byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) technique,Niveau A,,"1542,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) technique,Niveau B,,"1633,50",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) technique,Niveau C,,"1724,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) administratif(ve),Niveau A,,"1542,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) administratif(ve),Niveau B,,"1694,00",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Assistant(e) administratif(ve),Niveau C,,"1845,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Comptable,Niveau A,,"1542,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Comptable,Niveau B,,"1694,00",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Comptable,Niveau C,,"1845,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Accompagnateur(trice) 
socioprofessionnel (le)",Niveau A,,"1724,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Accompagnateur(trice) 
socioprofessionnel (le)",Niveau B,,"1905,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Accompagnateur(trice) 
socioprofessionnel (le)",Niveau C,,"2087,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Encadrant(e) technique 
pédagogique et social",Niveau A,,"1724,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Encadrant(e) technique 
pédagogique et social",Niveau B,,"1905,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,"Encadrant(e) technique 
pédagogique et social",Niveau C,,"2087,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Chargé(e) de missions ou de projets,Niveau A,,"1905,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Chargé(e) de missions ou de projets,Niveau B,,"2087,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Chargé(e) de missions ou de projets,Niveau C,,"2268,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Responsable administratif & financier,Niveau A,,"2087,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Responsable administratif & financier,Niveau B,,"2268,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Responsable administratif & financier,Niveau C,,"2450,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Coordinateur(trice),Niveau A,,"2087,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Coordinateur(trice),Niveau B,,"2268,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Coordinateur(trice),Niveau C,,"2450,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Directeur(trice),Niveau A,,"2450,25",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Directeur(trice),Niveau B,,"2752,75",byMonth151,,,,,,,
15/3/2021,1/1/2020,,3016,Ateliers et chantiers d'insertion,,,,Directeur(trice),Niveau C,,"3055,25",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,14eme échelon,,"3870,9664",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,13eme échelon,,"3744,4336",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,12eme échelon,,"3617,9008",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,11eme échelon,,"3491,368",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,10eme échelon,,"3364,8352",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,9eme échelon,,"3238,3024",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,8eme échelon,,"3111,7696",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,7eme échelon,,"2985,2368",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,6eme échelon,,"2858,704",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,5eme échelon,,"2732,1712",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,4eme échelon,,"2605,6384",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,3eme échelon,,"2479,1056",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,2eme échelon,,"2352,5728",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 1,1er échelon,,"2226,04",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,14eme échelon,,"3608,528",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,13eme échelon,,"3514,8",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,12eme échelon,,"3421,072",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,11eme échelon,,"3280,48",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,10eme échelon,,"3139,888",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,9eme échelon,,"2999,296",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,8eme échelon,,"2858,704",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,7eme échelon,,"2718,112",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,6eme échelon,,"2596,2656",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,5eme échelon,,"2455,6736",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,4eme échelon,,"2315,0816",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,3eme échelon,,"2174,4896",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,2eme échelon,,"2033,8976",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 2,1er échelon,,"1893,3056",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,14eme échelon,,"2863,3904",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,13eme échelon,,"2807,1536",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,12eme échelon,,"2750,9168",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,11eme échelon,,"2694,68",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,10eme échelon,,"2638,4432",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,9eme échelon,,"2605,6384",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,8eme échelon,,"2436,928",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,7eme échelon,,"2272,904",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,6eme échelon,,"2062,016",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,5eme échelon,,"1874,56",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,4eme échelon,,"1757,4",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,3eme échelon,,"1663,672",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,2eme échelon,,"1569,944",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3,1er échelon,,"1476,216",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,14eme échelon,,"2839,9584",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,13eme échelon,,"2783,7216",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,12eme échelon,,"2727,4848",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,11eme échelon,,"2671,248",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,10eme échelon,,"2615,0112",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,9eme échelon,,"2582,2064",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,8eme échelon,,"2413,496",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,7eme échelon,,"2249,472",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,6eme échelon,,"2038,584",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,5eme échelon,,"1851,128",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,4eme échelon,,"1733,968",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,3eme échelon,,"1640,24",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,2eme échelon,,"1546,512",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 3 bis,1er échelon,,"1452,784",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,14eme échelon,,"2286,9632",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,13eme échelon,,"2240,0992",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,12eme échelon,,"2193,2352",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,11eme échelon,,"2146,3712",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,10eme échelon,,"2099,5072",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,9eme échelon,,"1996,4064",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,8eme échelon,,"1893,3056",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,7eme échelon,,"1790,2048",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,6eme échelon,,"1687,104",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,5eme échelon,,"1616,808",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,4eme échelon,,"1546,512",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,3eme échelon,,"1476,216",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,2eme échelon,,"1405,92",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 4,1er échelon,,"1335,624",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,14eme échelon,,"1869,8736",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,13eme échelon,,"1823,0096",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,12eme échelon,,"1776,1456",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,11eme échelon,,"1729,2816",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,10eme échelon,,"1682,4176",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,9eme échelon,,"1635,5536",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,8eme échelon,,"1588,6896",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,7eme échelon,,"1541,8256",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,6eme échelon,,"1494,9616",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,5eme échelon,,"1448,0976",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,4eme échelon,,"1401,2336",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,3eme échelon,,"1363,7424",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,2eme échelon,,"1326,2512",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 5,1er échelon,,"1260,6416",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,14eme échelon,,"1668,3584",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,13eme échelon,,"1635,5536",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,12eme échelon,,"1602,7488",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,11eme échelon,,"1569,944",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,10eme échelon,,"1537,1392",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,9eme échelon,,"1504,3344",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,8eme échelon,,"1471,5296",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,7eme échelon,,"1438,7248",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,6eme échelon,,"1405,92",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,5eme échelon,,"1373,1152",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,4eme échelon,,"1340,3104",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,3eme échelon,,"1307,5056",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,2eme échelon,,"1274,7008",byMonth151,,,,,,,
16/3/2021,1/1/2017,,2697,Personnels des structures associatives cynégétiques,,,,Niveau 6,1er échelon,,"1241,896",byMonth151,,,,,,,
16/3/2021,12/11/2019,,2357,Salariés commerciaux des sociétés d'assurances,,,"
https://www.ffa-assurance.fr › file › download 
avenant_de_revision_de_la_ccn_psb_du_12.11.2019.pdf",Niveau 1,,,18800,byYear1607,,,,,,,
16/3/2021,12/11/2019,,2357,Salariés commerciaux des sociétés d'assurances,,,"
https://www.ffa-assurance.fr › file › download 
avenant_de_revision_de_la_ccn_psb_du_12.11.2019.pdf",Niveau 2,,,20410,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Toutes longueurs,Matelot,19219,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Toutes longueurs,Matelot – Timonier,19484,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Toutes longueurs,Matelot,19795,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Toutes longueurs,Matelot – Timonier,20069,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,"Classification automoteur et convoi - Longueur convoi ou unité : 38,5 m",Timonier-conducteur,19571,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,"Classification automoteur et convoi - Longueur convoi ou unité : 38,5 m",Capitaine,20624,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,"Classification automoteur et convoi - Longueur convoi ou unité : 38,5 m",Timonier-conducteur,20158,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,"Classification automoteur et convoi - Longueur convoi ou unité : 38,5 m",Capitaine,21243,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Longueur convoi ou unité : > 70 m et < 120 m,Timonier-conducteur,21660,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Longueur convoi ou unité : > 70 m et < 120 m,Capitaine,24873,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Longueur convoi ou unité : > 70 m et < 120 m,Timonier-conducteur,22310,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises. – Flotte classique,Classification automoteur et convoi - Longueur convoi ou unité : > 70 m et < 120 m,Capitaine,25619,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot débutant,19219,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot niveau 1,19401,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot niveau 2,19484,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Maître matelot,19691,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot garde moteur,19691,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot-timonier,19691,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot débutant,,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot niveau 1,19983,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot niveau 2,20069,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Maître matelot,20282,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Matelot garde moteur,20282,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,Matelot-timonier,20282,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,Mécanicien,20531,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,Timonier-conducteur,20624,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,Maître mécanicien,21925,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,Second capitaine,22800,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,1er capitaine,25443,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – 120 mètres,1er capitaine responsable de bord,25547,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Mécanicien,21147,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Timonier-conducteur,21243,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Maître mécanicien,22582,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,Second capitaine,23484,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,1er capitaine,26207,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Toutes longueurs,1er capitaine responsable de bord,26313,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Mécanicien,20635,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Timonier-conducteur,20728,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Maître mécanicien,22028,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Second capitaine,23422,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,1er capitaine,26167,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,1er capitaine Responsable de bord,26270,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Commandant,29062,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Mécanicien,21254,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Timonier-conducteur,21350,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Maître mécanicien,22689,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Second capitaine,24125,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,1er capitaine,26952,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,1er capitaine Responsable de bord,27058,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,Classification automoteur et convoi - Longueur convoi ou unité – + 120 mètres,Commandant,29934,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Matelot,18531,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Matelot « agent de sécurité »,18632,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Matelot,18678,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Matelot « agent de sécurité »,18715,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 2,18749,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 2,18898,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 3e type de voyages et de voies,18991,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 2e type de voyages et de voies,20678,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 1er type de voyages et de voies,21669,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 3e type de voyages et de voies,19333,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 2e type de voyages et de voies,21050,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,1.00 bateaux pour excursion journalière,Capitaine de classe 1 - 1er type de voyages et de voies,22059,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Matelot,18531,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Matelot « agent de sécurité »,18632,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Matelot,18678,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Matelot « agent de sécurité »,18715,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Timonier - exploitation diurne,18734,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Timonier - autres types d'exploitation,18836,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Timonier - exploitation diurne,18849,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Timonier - autres types d'exploitation,18898,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Capitaine de classe 2,18907,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Capitaine de classe 2,19279,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - 3e type de voyages et de voies,19140,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - 1er et 2e types de voyages et de voies,23631,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - autres types,30025,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - 3e type de voyages et de voies,19485,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - 1er et 2e types de voyages et de voies,24056,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 35 m - autres types,30566,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - 1er et 2e types de voyages et de voies,26246,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - 3e type de voyages et de voies,19959,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - autres types,30650,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - 1er et 2e types de voyages et de voies,26718,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - 3e type de voyages et de voies,20318,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Flotte exploitée en relève,2.00 bateaux à cabines pour exploitations diurnes et autres,Exploitation diurne sur bateaux de + 55 m - autres types,31202,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Chef de cabine,,18773,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Maître d'hôtel,,20688,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Assistant du directeur ou du commissaire de bord,,22880,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Chef de cuisine,,26531,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Directeur ou commissaire de bord,,29208,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Chef de cabine,,19111,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Maître d'hôtel,,21061,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Assistant du directeur ou du commissaire de bord,,23292,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Chef de cuisine,,27009,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de passagers. 
– Personnel navigant, hôtellerie 
restauration 
",Directeur ou commissaire de bord,,29733,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvriers manoeuvre niveau I,1er échelon,18351,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvriers manoeuvre niveau I,2e échelon,18503,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvriers manoeuvre niveau I,3e échelon,18707,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier spécialisé niveau II,1er échelon,18962,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier spécialisé niveau II,2e échelon,19268,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier spécialisé niveau II,3e échelon,19630,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier qualifié niveau III,1er échelon,19783,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier qualifié niveau III,2e échelon,20241,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier qualifié niveau III,3e échelon,20966,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier hautement qualifié niveau IV,1er échelon,21190,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier hautement qualifié niveau IV,2e échelon,22757,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Ouvrier hautement qualifié niveau IV,3e échelon,23863,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Chef d'équipe niveau V,1er échelon,24087,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Chef d'équipe niveau V,2e échelon,24771,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Chef d'équipe niveau V,3e échelon,25973,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Ouvriers sédentaires
",Chef d'équipe niveau V,,,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau I,1er échelon,18351,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau I,2e échelon,18453,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau I,3e échelon,18605,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau II,1er échelon,18809,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau II,2e échelon,19064,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau II,3e échelon,19370,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau III,1er échelon,19726,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau III,2e échelon,20236,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau III,3e échelon,20966,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau IV,1er échelon,21190,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau IV,2e échelon,21861,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau IV,3e échelon,22757,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau V,1er échelon,22980,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau V,2e échelon,23863,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Employés sédentaires
",Niveau V,3e échelon,24771,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau I,1er échelon,22957,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau I,2e échelon,23840,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau I,3e échelon,24746,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau II,1er échelon,24970,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau II,2e échelon,25864,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau II,3e échelon,26005,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau III,1er échelon,26182,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau III,2e échelon,27146,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,"Transport fluvial de marchandises. – 
Agents de maîtrise sédentaires
",Niveau III,3e échelon,28382,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises – cadres sédentaires,Position I,,24945,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises – cadres sédentaires,Position II,,29389,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises – cadres sédentaires,Position III,,34561,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises – cadres sédentaires,Position III B,,39028,byYear1607,,,,,,,
16/3/2021,1/1/2019,,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de marchandises – cadres sédentaires,Position III C,,48432,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 1,18531,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 2,19425,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 3,22557,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 1,,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 2,19425,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Employés,Niveau 3,22557,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Agents de maîtrise,Niveau 4,23971,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Agents de maîtrise,Niveau 5,27400,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Agents de maîtrise,Niveau 4,24325,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Agents de maîtrise,Niveau 5,27805,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Cadres,Niveau 6,28722,byYear1607,,,,,,,
16/3/2021,1/1/2019,1-3,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Cadres,Niveau 7,49204,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Cadres,Niveau 6,29153,byYear1607,,,,,,,
16/3/2021,1/1/2019,4,2174,"Navigation intérieure (personnel sédentaire, transport de marchandises) ",,,,Transport fluvial de passagers,Cadres,Niveau 7,49942,byYear1607,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent portuaire saisonnier d’accueil et de nettoyage,,,"1561,47",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent technique chargé de la gestion du plan d’eau portuaire,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent administratif d’accueil,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent portuaire chargé de la surveillance et de la sécurité,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent portuaire chargé de la surveillance et la sécurité la nuit,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent technique chargé de manutention ou de conduite d’équipements spécifiques,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent administratif chargé de la gestion d’un service de manutention,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent d’entretien et de nettoyage des locaux,,,"1561,47",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Agent portuaire technique,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Plongeur,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Assistante d’administration générale,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Aide comptable,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Assistante gestion des ressources humaines,,,"1712,58",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Assistant(e) de direction,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Comptable – ressources humaines,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,"Maître de port adjoint (1er et 2e échelons), ",,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port (3e et 4e échelons) chargé de la gestion du plan d’eau,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port adjoint (1er et 2e échelons),,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port (3e et 4e échelons) chargé de la surveillance,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port adjoint (1er et 2e échelons),,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port (3e et 4e échelons) chargé de la maintenance et de l’entretien,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port adjoint (1er et 2e échelons),,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port (3e et 4e échelons) chargé de la manutention,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,"Responsable informatique, communication, animation",,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Responsable sécurité environnement,,,"2266,65",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Maître de port principal,,,"3173,31",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Chef de services,,,"3173,31",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,"Directeur technique, administratif ou financier",,,"3173,31",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1182,Ports de plaisance,,,https://www.juristique.org/conventionnel/salaire-minimum-ports-plaisance-2020,Directeur de port,,,"4281,45",byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau I,coefficient 115,,1410,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau II,coefficient 125,,1415,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau III,coefficient 160,,1443,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau IV,coefficient 200,,1581,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau V,coefficient 300,,2024,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau VI,coefficient 550,,3060,byMonth151,,,,,,,
16/3/2021,1/2/2012,,2378,Salariés permanents des entreprises de travail temporaire,,,,niveau VII,coefficient 800,,4162,byMonth151,,,,,,,
16/3/2021,16/3/2021,,2603,nationale de travail des praticiens conseils du régime général de sécurité sociale,,,http://www.drsm-idf.fr/index.php?id=33,A,,,"4285,23",byMonth151,,,,,,,
16/3/2021,16/3/2021,,2603,nationale de travail des praticiens conseils du régime général de sécurité sociale,,,http://www.drsm-idf.fr/index.php?id=33,B,,,"5190,87",byMonth151,,,,,,,
16/3/2021,16/3/2021,,2603,nationale de travail des praticiens conseils du régime général de sécurité sociale,,,http://www.drsm-idf.fr/index.php?id=33,C,,,"2927,16",byMonth151,,,,,,,
16/3/2021,16/3/2021,,2603,nationale de travail des praticiens conseils du régime général de sécurité sociale,,,http://www.drsm-idf.fr/index.php?id=33,D,,,"6295,31",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe A,281,"1485,085",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe B,286,"1511,51",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe C,299,"1580,215",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe D,312,"1648,92",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe A,286,"1511,51",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe B,292,"1543,22",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe C,306,"1617,21",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe D,321,"1696,485",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe A,286,"1511,51",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe B,292,"1543,22",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe C,312,"1648,92",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe D,321,"1696,485",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe A,291,"1537,935",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe B,298,"1574,93",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe C,319,"1685,915",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Non cadres,Groupe D,340,"1796,9",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe F,495,"2616,075",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe F,495,"2616,075",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe F,495,"2616,075",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe F,504,"2663,64",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"0-49 lits, places ou salariés","2716,49",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"0-49 lits, places ou salariés","2716,49",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"0-49 lits, places ou salariés","2716,49",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"0-49 lits, places ou salariés","2774,625",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"50-99 lits, places ou salariés","2859,185",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"50-99 lits, places ou salariés","2859,185",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"50-99 lits, places ou salariés","2859,185",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"50-99 lits, places ou salariés","2949,03",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"100-149 lits, places ou salariés","3086,44",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"100-149 lits, places ou salariés","3086,44",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"100-149 lits, places ou salariés","3086,44",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"100-149 lits, places ou salariés","3123,435",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"Plus de 150 lits, places ou salariés","3318,98",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"Plus de 150 lits, places ou salariés","3318,98",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"Plus de 150 lits, places ou salariés","3318,98",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe G,"Plus de 150 lits, places ou salariés","3408,825",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe H,472,"2494,52",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe H,472,"2494,52",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe H,472,"2494,52",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Cadres,Groupe H,487,"2573,795",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"0-49 lits, places ou salariés","3118,15",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"0-49 lits, places ou salariés","3118,15",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"0-49 lits, places ou salariés","3118,15",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"0-49 lits, places ou salariés","3223,85",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"50-99 lits, places ou salariés","3329,55",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"50-99 lits, places ou salariés","3329,55",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"50-99 lits, places ou salariés","3329,55",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"50-99 lits, places ou salariés","3435,25",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"Plus de 100 lits, places ou salariés","3540,95",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"Plus de 100 lits, places ou salariés","3540,95",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"Plus de 100 lits, places ou salariés","3540,95",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe I,"Plus de 100 lits, places ou salariés","3694,215",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"0-49 lits, places ou salariés",3171,byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"0-49 lits, places ou salariés",3171,byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"0-49 lits, places ou salariés",3171,byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"0-49 lits, places ou salariés","3276,7",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"50-99 lits, places ou salariés","3382,4",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"50-99 lits, places ou salariés","3382,4",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"50-99 lits, places ou salariés","3382,4",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"50-99 lits, places ou salariés","3488,1",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"100-200 lits, places ou salariés","3757,635",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"100-200 lits, places ou salariés","3757,635",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"100-200 lits, places ou salariés","3757,635",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe J,"100-200 lits, places ou salariés","3958,465",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"0-49 lits, places ou salariés","3752,35",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"0-49 lits, places ou salariés","3752,35",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"0-49 lits, places ou salariés","3752,35",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"0-49 lits, places ou salariés","3910,9",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"50-99 lits, places ou salariés","4233,285",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"50-99 lits, places ou salariés","4233,285",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"50-99 lits, places ou salariés","4233,285",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"50-99 lits, places ou salariés","4407,69",byMonth151,,,,,,,
16/3/2021,1/1/2020,1,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"Plus de 100 lits, places ou salariés","4708,935",byMonth151,,,,,,,
16/3/2021,1/1/2020,2,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"Plus de 100 lits, places ou salariés","4708,935",byMonth151,,,,,,,
16/3/2021,1/1/2020,3,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"Plus de 100 lits, places ou salariés","4708,935",byMonth151,,,,,,,
16/3/2021,1/1/2020,4,405,Etablissements médico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux,,,,Directeurs,Groupe K,"Plus de 100 lits, places ou salariés","4909,765",byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 1,,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 2,,1973,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 3,,2716,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 4,,2378,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 5,,2631,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 6,,2884,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 7,,3137,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,"Presse radio, photo, écrite",Groupe 8,,3491,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Rédacteur-reporter débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Reporter photo débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Reporter vidéo (ou JRI) débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Infographiste débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Rédacteur-reporter-présentateur débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Éditeur photo débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Éditeur numérique débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Rédacteur maquettiste débutant ,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 1,Secrétaire de rédaction débutant,1771,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Rédacteur-reporter junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Reporter photo junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Reporter vidéo (ou JRI) junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Infographiste junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Rédacteur-reporter-présentateur junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Éditeur photo junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Éditeur numérique junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Éditeur numérique junior ,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Rédacteur maquettiste junior,2082,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 2,Secrétaire de rédaction junior,2166,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Rédacteur-reporter confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Reporter photo confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Reporter vidéo (ou JRI) confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Infographiste confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Rédacteur-reporter-présentateur confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Éditeur photo confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Éditeur numérique confirmé ,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Rédacteur maquettiste confirmé,2246,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 3,Secrétaire de rédaction confirmé,2414,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Rédacteur-reporter expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Reporter photo expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Reporter vidéo (ou JRI) expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Infographiste expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Rédacteur-reporter-présentateur expert,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Grand reporter,2536,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Éditeur photo expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Editeur numérique expert ,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Rédacteur maquettiste expert,2410,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 4,Secrétaire de rédaction exper,2915,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Chef de rubrique ,2930,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Chef d'édition ,2930,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Chef de projet éditorial,2930,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Chef des informations,3141,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Chef de service adjoint,2636,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 5,Editorialiste,3922,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 6,Chef de service ,3030,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 6,Chef de bureau,3030,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 7,Secrétaire général de la rédaction,3509,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 7,Rédacteur en chef adjoint,3651,byMonth151,,,,,,,
16/3/2021,1/1/2020,,1480,Journalistes,,,,Presse audiovisuelle,Groupe 8,Rédacteur en chef,4282,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,100,,1280,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,120,,1309,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,125,,1316,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,130,,1324,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,135,,1331,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,142,,1341,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,152,,1355,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,163,,1371,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Ouvriers,170,,1382,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position I,A 24 ans et avant,2092,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position I,A 25 ans,2307,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position I,A 26 ans,2494,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position I,A 27 ans,2682,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (avant 3 ans),"Catégories A, B et C",2682,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (après 3 ans),Coefficient 108,2897*3057,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (après 3 ans),Coefficient 114,3218,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (après 3 ans),Coefficient 120,3379,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (après 3 ans),Coefficient 126,3540,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position II (après 3 ans),Coefficient 132,3701,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position III,Position III A,3701,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Cadres,Position III,Position III B,4828,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,115,1280,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,120,1294,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,135,1336,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,145,1364,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,125,1308,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,140,1350,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,155,1392,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,185,1476,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,123,"1302,4",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,128,"1316,4",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,136,"1338,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,150,1378,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,152,"1383,6",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,158,"1400,4",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,164,"1417,2",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,165,1420,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,205,1532,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,138,"1344,4",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,148,"1372,4",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,160,1406,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,175,1448,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,132,"1327,6",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,170,1434,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,190,1490,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Employés,Bureaux,212,"1551,6",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Bureaux d'étude,181,"1464,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Bureaux d'étude,196,"1506,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Bureaux d'étude,221,"1576,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Bureaux d'étude,234,"1613,2",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Bureaux d'étude,259,"1683,2",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Laboratoires,145,1364,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Laboratoires,160,1406,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Techniciens céramistes,225,1588,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Techniciens céramistes,260,1686,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,140,1350,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,175,1448,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,180,1462,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,206,"1534,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,255,1672,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Technicien,Organisation du travail et contrôle,210,1546,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Bureau,200,1518,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Bureau,246,"1646,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Magasins,209,"1543,2",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,175,1448,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,185,1476,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,205,1532,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,215,1560,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,230,1602,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Fabrication ou production,260,1686,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Entretien,190,1490,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Entretien,200,1518,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Entretien,225,1588,byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Entretien,246,"1646,8",byMonth151,,,,,,,
16/3/2021,1/4/2008,,1800,Céramique d'art,,,,Chefs d'équipe et agents de maîtrise,Entretien,271,"1716,8",byMonth151,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent de production,Echelon 1,110,"9,53",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent de production,Echelon 2,120,"9,53",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique spécialisé,Echelon 1,210,"9,58",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique spécialisé,Echelon 2,220,"9,62",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique qualifié,Echelon 1,310,"9,65",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique qualifié,Echelon 2,320,"9,73",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique hautement qualifié,Echelon 1,410,"9,95",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent technique hautement qualifié,Echelon 2,420,"10,15",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent d'encadrement,Echelon 1 - cadre du 3eme groupe,510,"10,5",byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent d'encadrement,Echelon 2 - cadre du 3eme groupe,520,11,byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent d'encadrement,cadre du 2eme groupe,600,13,byHour,,,,,,,
22/3/2021,1/10/2014,,9444,Maraîchage Loire-Atlantique (Entreprises maraîchères,,44,https://pays-de-la-loire.direccte.gouv.fr/sites/pays-de-la-loire.direccte.gouv.fr/IMG/pdf/conventionmaraichers44-2.pdf,Agent d'encadrement,cadre du 1er groupe,700,"17,25",byHour,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,1,1,"19882,10",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,1,2,"20016,22",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,1,3,"20150,33",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,2,1,"20916,39",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,2,2,"21632,67",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,2,3,"22326,60",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,3,1,"23679,91",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,3,2,"24356,57",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Ouvriers et employés,3,3,"25033,22",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,4,1,"26416,00",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,4,2,"27113,99",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,4,3,"27824,18",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,5,1,"29217,11",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,5,2,"29930,34",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,5,3,"30640,53",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,6,1,"31961,33",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,6,2,"32669,48",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Techniciens et Agents de maîtrise,6,3,"33378,65",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,7,1,"34097,98",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,7,2,"36208,21",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,8,1,"37626,54",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,8,2,"39038,78",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,9,1,"41165,27",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,9,2,"43287,70",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,10,1,"45408,09",byMonth151,,,,,,,
8/4/2021,5/11/2019,,7002,"5 branches, céréales, meunerie, alimentation du bétail, oléagineux",,,,Cadres,10,2,"47529,50",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",I,Embauche,,"1531,22",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",I,Confirmé,,"1561,84",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",I,Maîtrisé,,"1639,93",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",II,1,Embauche,"1754,59",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",II,1,Confirmé,"1789,68",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",II,1,Maîtrisé,"1879,16",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",II,1,Expert,"2010,70",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,"cf. Annexe I ; cf. Avenant n° 86 du 16 janv. 2019 (NOR : AGRS1997093M) étendu par A. 13 juin 2019 : JO 19 juin
 2019 et applicable au 1er févr. 2019",II,2,Embauche,"1879,16",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,II,2,Confirmé,"1916,74",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,II,2,Maîtrisé,"2012,58",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,II,2,Expert,"2153,46",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,1,Embauche,"2048,04",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,1,Confirmé,"2089,00",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,1,Maîtrisé,"2193,45",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,1,Expert,"2346,99",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,2,Embauche,"2173,79",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,2,Confirmé,"2217,27",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,2,Maîtrisé,"2328,13",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,III,2,Expert,"2491,10",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,IV,1,Embauche,"2299,55",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,IV,1,Confirmé,"2345,54",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,IV,1,Maîtrisé,"2462,82",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,IV,1,Expert,"2635,22",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,2,Embauche,"2473,21",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,2,Confirmé,"2522,68",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,2,Maîtrisé,"2648,81",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,2,Expert,"2834,23",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,TAC,Embauche,"2634,89",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,TAC,Confirmé,"2687,59",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,TAC,Maîtrisé,"2821,97",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,TAC,Expert,"3019,51",byMonth151,,,,,,,
18/02/2021,1/2/2019,,7005,"Caves coopératives vinicoles et leurs
 unions",,,,V,Direction,,"3377,00",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins agricoles,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins agricoles,2,155,"1693,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins agricoles,3,165,"1763,63",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef d'équipe plaine,1,175,"1870,51",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef de plaine,1,190,"2030,84",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef de plaine,2,205,"2191,17",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef de plaine,3,220,"2351,50",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur de camion,1,140,"1588,69",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur de camion,2,150,"1657,89",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur de camion,3,160,"1730,18",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur de camion,4,165,"1763,63",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins manutention,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins manutention,2,140,"1588,69",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Conducteur engins manutention,3,150,"1657,89",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Assistant de fabrication,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Assistant de fabrication,2,145,"1622,52",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Assistant de fabrication,3,150,"1657,89",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Pilote d'installation,1,155,"1693,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Pilote d'installation,2,165,"1763,63",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Pilote d'installation,3,175,"1870,51",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef d'équipe fabrication,1,185,"1977,40",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable fabrication,1,210,"2244,61",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable d'usine,1,240,"2565,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent d'entretien,1,145,"1622,52",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent d'entretien,2,160,"1730,18",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent d'entretien,3,180,"1923,96",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef d'équipe entretien,1,195,"2084,28",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable atelier,1,205,"2191,17",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable atelier,2,225,"2404,94",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent de silo,1,145,"1622,52",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent de silo,2,155,"1693,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef d'équipe silo,1,170,"1817,07",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable stockage,1,210,"2244,61",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Ouvrier de déshydratation,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Secrétaire,1,155,"1693,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Secrétaire,2,175,"1870,51",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Secrétaire de direction,1,205,"2191,17",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Assistante de direction,1,240,"2565,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent administratif,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent administratif,2,145,"1622,52",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Agent administratif,3,155,"1693,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Comptable,1,175,"1870,51",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Comptable,2,190,"2030,84",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Comptable,3,205,"2191,17",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable comptabilité,1,215,"2298,06",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable comptabilité,2,225,"2404,94",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Chef comptable,1,240,"2565,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Contrôleur de gestion,1,225,"2404,94",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable ressources humaines,1,225,"2404,94",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Responsable ressources humaines,2,240,"2565,27",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Aide laborantin,1,135,"1551,78",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Aide laborantin,2,140,"1588,69",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Aide laborantin,3,150,"1657,89",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Technico commercial,1,185,"1977,40",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Technico commercial,2,200,"2137,73",byMonth151,,,,,,,
12/4/2021,1/2/2020,,7023,Entreprises agricoles de déshydratation,,,https://www.legifrance.gouv.fr/conv_coll/id/KALITEXT000042086375/?idConteneur=KALICONT000038263817,Technico commercial,3,220,"2351,50",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 5ème degré Documentalistes catégorie 4,320,"1511,2",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 5ème degré Documentalistes catégorie 4,320,"1511,2",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 5ème degré Documentalistes catégorie 4,323,"1525,3675",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 5ème degré Documentalistes catégorie 4,326,"1539,535",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 4ème degré Documentalistes catégorie 3,329,"1553,7025",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 4ème degré Documentalistes catégorie 3,329,"1553,7025",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 4ème degré Documentalistes catégorie 3,333,"1572,5925",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 4ème degré Documentalistes catégorie 3,340,"1605,65",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 3ème degré Documentalistes catégorie 2,332,"1567,87",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 3ème degré Documentalistes catégorie 2,332,"1567,87",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 3ème degré Documentalistes catégorie 2,340,"1605,65",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 3ème degré Documentalistes catégorie 2,348,"1643,43",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 2ème degré Documentalistes catégorie 1,350,"1652,875",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 2ème degré Documentalistes catégorie 1,350,"1652,875",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 2ème degré Documentalistes catégorie 1,358,"1690,655",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 2ème degré Documentalistes catégorie 1,367,"1733,1575",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 1er degré,416,"1964,56",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 1er degré,416,"1964,56",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 1er degré,428,"2021,23",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,enseignants et documentalistes,Enseignants 1er degré,441,"2082,6225",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,< Licence,346,"1633,985",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,< Licence,346,"1633,985",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,< Licence,356,"1681,21",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,< Licence,366,"1728,435",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Licence,391,"1846,4975",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Licence,391,"1846,4975",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Licence,402,"1898,445",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Licence,413,"1950,3925",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Ingénieur – Mastère,421,"1988,1725",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Ingénieur – Mastère,421,"1988,1725",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Ingénieur – Mastère,432,"2040,12",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,formateurs,Ingénieur – Mastère,443,"2092,0675",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,assistants éducateurs de la vie scolaire,320,,"1511,2",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,assistants éducateurs de la vie scolaire,321,,"1515,9225",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,assistants éducateurs de la vie scolaire,322,,"1520,645",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,assistants éducateurs de la vie scolaire,323,,"1525,3675",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,323,"1525,3675",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,328,"1548,98",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,334,"1577,315",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,345,"1629,2625",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,356,"1681,21",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,367,"1733,1575",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,379,"1789,8275",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,391,"1846,4975",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,406,"1917,335",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,422,"1992,895",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,436,"2059,01",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,450,"2125,125",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,333,"1572,5925",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,338,"1596,205",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,344,"1624,54",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,355,"1676,4875",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,366,"1728,435",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,377,"1780,3825",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,389,"1837,0525",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,401,"1893,7225",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,416,"1964,56",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,432,"2040,12",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,446,"2106,235",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie A,460,"2172,35",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,340,"1605,65",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,351,"1657,5975",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,361,"1704,8225",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,373,"1761,4925",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,386,"1822,885",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,400,1889,byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,415,"1959,8375",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,430,"2030,675",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,447,"2110,9575",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,464,"2191,24",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,479,"2262,0775",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,495,"2337,6375",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,350,"1652,875",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,361,"1704,8225",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,371,"1752,0475",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,383,"1808,7175",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,396,"1870,11",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,410,"1936,225",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,425,"2007,0625",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,440,"2077,9",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,457,"2158,1825",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,474,"2238,465",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,489,"2309,3025",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,éducateurs de la vie scolaire,Catégorie B,505,"2384,8625",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,355,,"1676,4875",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,370,,"1747,325",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,385,,"1818,1625",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,401,,"1893,7225",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,419,,"1978,7275",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,438,,"2068,455",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,458,,"2162,905",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,478,,"2257,355",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,500,,"2361,25",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,522,,"2465,145",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,545,,"2573,7625",byMonth151,,,,,,,
12/4/2021,1/9/2020,,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,responsables de la vie scolaire,569,,"2687,1025",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie III,320,"1511,2",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie III,321,"1515,9225",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie III,322,"1520,645",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie III,323,"1525,3675",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,324,"1530,09",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,325,"1534,8125",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,326,"1539,535",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,327,"1544,2575",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,330,"1558,425",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,331,"1563,1475",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,332,"1567,87",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,334,"1577,315",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie II,337,"1591,4825",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,347,"1638,7075",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,347,"1638,7075",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,347,"1638,7075",byMonth151,,,,,,,
12/4/2021,1/9/2020,1,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,352,"1662,32",byMonth151,,,,,,,
12/4/2021,1/9/2020,2,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,362,"1709,545",byMonth151,,,,,,,
12/4/2021,1/9/2020,3,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,362,"1709,545",byMonth151,,,,,,,
12/4/2021,1/9/2020,4,7520,Enseignement agricole privé,,,https://www.snec-cftc.fr/dossiers/essentiels/essentiels-agricole-prive.pdf,P.A.T,Catégorie I,371,"1752,0475",byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Employés,Niveau I,1529,1529,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Employés,Niveau II,1545,1545,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Employés,Niveau III,1575,1575,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Employés,Niveau IV,1674,1674,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Employés,Niveau V,1778,1778,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Agents de maîtrise,Niveau I,1890,1890,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Agents de maîtrise,Niveau II,2069,2069,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Cadres,Niveau I,2822,2822,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Cadres,Niveau II,3385,3385,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Cadres,Niveau III,3939,3939,byMonth151,,,,,,,
13/04/2021,01/01/2019,,1761,"Commerce de gros des tissus, tapis et linge de maison",,,,Cadres,Niveau IV,5068,5068,byMonth151,,,,,,,`;

const { data } = PapaParse.parse(conventionsCsvString, {
  header: true,
});

const conventionsCollectivesSalaries = data.map((element: any) => {
    const idCc = element['ID CC'] as string;
    const annees = element['Année'] as string;
    let anneesRange = range(1,4 + 1);
    if(annees){
        const splitted = annees.split("-");
        if(splitted.length === 2){
            const [startAge, endAge] = splitted.map(x => parseInt(x, 10));
            anneesRange = range(startAge, endAge + 1);
        }
        if(splitted.length === 1){
            anneesRange = range(parseInt(annees,10), parseInt(annees,10) + 1);
        }
    }
    return {
        id: idCc,
        name: element['Nom CC'] as string,
        category: element['Catégorie'] as string,
        subCategory: element['Sous-catégorie'] as string,
        subSubCategory: element['Sous-sous-catégorie'] as string,
        amount: element['Salaire'] ? parseFloat((element['Salaire'] || "").replace(/,/g,".")) : 0,
        amountBase: element['Base'] as string,
        anneesRange,
        opco: opcos.find(o => parseInt(o.id) === parseInt(idCc))?.opco
    }
});

const conventionsCollectives = orderBy(uniqBy(conventionsCollectivesSalaries,x => x.id),x => parseInt(x.id, 10))

export { conventionsCollectives, conventionsCollectivesSalaries };
