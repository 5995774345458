import { parseISO } from 'date-fns';
import { CalculatorValues } from '../Calculator';

const result: CalculatorValues = {
    "company": {
        "identified": true,
        "workforce": "2",
        "location": "pdl",
        "recruited": null,
        "apprenticeShipMass": null,
        "branch": "3217"
    },
    "beneficiary": {
        "identified": false,
        "birthdate": parseISO("2002-03-10T13:29:31.482Z"),
        "age": 19,
        "situation": [],
        "apprenticeShip": false,
    },
    "formation": {
        "formation": "36M33601",
        "tutorAge": null,
        "details": true
    },
    "contract": {
        "apprenticeShip": {
            "cost": 8000,
            "duration": 24
        },
        "pro": {
            "cost": null,
            "durationMonth": null,
            "durationHour": null
        }
    },
    "config": {
        "userType": "pro"
    },
    "targettedJob":{
        "startDate": parseISO("2021-03-20T13:26:11.293Z"),
        "duration": 24,
        "wantedLevel": "5",
        salaryRow: {
            id: '211',
            name: 'Industries de carrières et de matériaux',
            category: 'Cadres',
            subCategory: 'X',
            subSubCategory: '2',
            amount: 1548.40,
            amountBase: 'byMonth151',
            opco: undefined,
            anneesRange: [1, 2, 3, 4]
          },
    }
}

export default result