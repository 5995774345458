import { SelectOption } from "../types/SelectOption";
import { TipSelectOption } from "../types/TipSelectOption";
import { subYears } from "date-fns";
import { conventionsCollectives } from "../utils/conventionsCollectives";
import { UserType } from "./Calculator";
import { Yup } from "../utils/localizedYup";

export interface BeneficiaryValues {
    identified: boolean|null,
    birthdate: Date|null,
    age: number|null,
    situation: string[],
    apprenticeShip: boolean,
}

export interface BeneficiaryOptions {
    wantedLevel: SelectOption[],
    situation: TipSelectOption[]
}

export const validateBeneficiarySchema = Yup.object({
    identified: Yup.boolean().required(),
    age: Yup.number().required(),
    wantedLevel: Yup.string(),
    situation: Yup.array().of(Yup.string()),
    apprenticeShip: Yup.boolean().required()
});

export const getBeneficiaryOptions = (userType: UserType) => {
    const result = {
        wantedLevel: [
            {value: "1", name: "Certificats de qualification professionnelle (CQP)"},
            {value: "2", name: "CAP, BEP (niveau 3/V)"},
            {value: "3", name: "Bac professionnel (niveau 4 ou IV)"},
            {value: "4", name: "Bac+2 (niveau 5/III)"},
            {value: "5", name: "Bac+3 (niveau 6/II)"},
            {value: "6", name: "Bac+4 (niveau 6/II)"},
            {value: "7", name: "Bac+5 (niveau I)"},
            {value: "8", name: "Bac+8 (niveau I)"}
        ],
        situation: [ 
            {value: "3e", name: "Aura-t-il terminé sa classe de 3ème à la date de début du contrat ?", tip: <div>
                <p>Il n'y a pas de limite d'âge pour l'établissement d'un contrat quand l'apprenti est reconnu travailleur handicapé et le contrat peut être conclu pour 4 ans maximum.</p>
                <p>Il existe également une aide à l’embauche en contrat d’apprentissage d’une personne handicapée proposée par l’Agefiph.</p>
            </div>},
            {value: "handicap", name: "Est-il reconnu travailleur handicapé ?", tip: <div>
                <p>Il n'y a pas de limite d'âge pour l'établissement d'un contrat quand l'apprenti est reconnu travailleur handicapé et le contrat peut être conclu pour 4 ans maximum.</p>
                <p>Il existe également une aide à l’embauche en contrat d’apprentissage d’une personne handicapée proposée par l’Agefiph.</p>
            </div>},
            {value: "sport", name: "Est-il sportif de haut niveau en reconversion ?", tip: <div>
                <p>Il n'y a pas de limite d'âge pour l'établissement d'un contrat quand l'apprenti est une personne inscrite en tant que sportif de haut niveau. Le contrat peut être conclu pour 4 ans maximum.</p>
            </div>},
            {value: "company_creator", name: "Est-il créateur ou repreneur d'entreprise ?", tip: <div>
                <p>Il n'y a pas de limite d'âge pour l'établissement d'un contrat quand l'apprenti envisage de créer ou reprendre une entreprise supposant l'obtention d'un diplôme (exemple : dispositif d'aide individualisée Acre, Nacre ou Cape)</p>
            </div>},
            {value: "already_apprentice", name: "Est-il déjà apprenti, ou a-t-il déjà effectué un contrat d'apprentissage ayant expiré moins d'un an avant la date de début envisagée pour le nouveau contrat ?", 
            tip: null},
            {value: "already_apprentice_inferior_level", parent:"already_apprentice", name: "À l'issue du précédent contrat, le titulaire a obtenu un diplôme de niveau inférieur à celui visé via le nouveau contrat ?", 
        tip: null},
        {value: "already_apprentice_broken", parent:"already_apprentice", name: "Le précédent contrat a été rompu pour des raisons indépendantes de sa volonté ?", 
        tip: null},
        {value: "already_apprentice_fail", parent:"already_apprentice", name: "Le bénéficiaire a échoué à l'obtention du titre ou diplôme et prolonge son contrat ?", 
        tip: null},
        ]
    };

    //TODO: garbage
    if(userType === "student"){
        result.situation = result.situation.map(s => ({...s,name:s.name.replace("Est-il", "Etes-vous")}));
        const troisieme = result.situation.find(s => s.value === "3e");
        if(troisieme){
            troisieme.name = "Aurez-vous terminé votre classe de 3ème à la date de début du contrat ?";
        }
    }

    return result;
}