import { Yup } from "../utils/localizedYup";

export interface ContractValues {
    apprenticeShip: {
        cost: number|null;
        duration: number|null;
    }
    pro: {
        cost: number|null;
        durationMonth: number|null;
        durationHour: number|null;
    }
}

export const validateContractSchema = Yup.object({
    apprenticeShip: Yup.object().shape({
        cost: Yup.number().nullable().when('duration', {
            is: (duration: number) => duration,
            then: Yup.number().required()
        }),
        duration: Yup.number().nullable().min(0).max(48).when('cost', {
            is: (cost: number) => cost,
            then: Yup.number().required().min(0).max(48)
        })
    }, [['duration', 'cost']]),
    pro: Yup.object().shape({
        cost: Yup.number().nullable().when(['durationMonth', 'durationhour'], {
            is: (durationMonth: number, durationHour: number) => durationMonth || durationHour,
            then: Yup.number().required()
        }),
        durationMonth: Yup.number().nullable().min(6).max(48).when(['cost', 'durationHour'], {
            is: (cost: number, durationHour: number) => cost || durationHour,
            then: Yup.number().required().min(6).max(48)
        }),
        durationHour: Yup.number().nullable().min(0).when(['cost', 'durationMonth'], {
            is:  (cost: number, durationMonth: number) => cost || durationMonth,
            then: Yup.number().required().min(0)
        })
    }, [
        ['cost', 'durationMonth'],
        ['cost', 'durationHour'],
        ['durationMonth', 'durationHour']
    ])
});