import { createMuiTheme } from "@material-ui/core";
import type {} from '@material-ui/lab/themeAugmentation';

import green from '@material-ui/core/colors/green';

export const margin = 20;
export const marginPx = margin + "px";

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: green[600]
        }
    },
    overrides: {
        MuiInputBase: {
            root: {
                margin: `${marginPx} 0`,
                width: "100%"
            }
        },
        MuiFormControl: {
            root: {
                width: "100%",
            },
            marginNormal: {
                marginTop: "0",
                marginBottom: "0"
            }
        },
        MuiIconButton: {
            root:  {
                padding: "5px"
            }
        },
        MuiButtonBase: {
            root: {
                verticalAlign: "sub"
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: marginPx
            }
        },
        MuiFormGroup: {
            root: {
                margin: marginPx
            }
        },
        MuiTypography: {
            gutterBottom: {
              marginBottom: marginPx,
            },
        },
        MuiAlert: {
            root: {
                marginBottom: marginPx
            }
        },
        MuiInputLabel: {
            root: {
                display: "inline",
                marginRight: "10px",
                marginBottom: marginPx
            }
        },
        MuiToggleButtonGroup: {
            root: {
                width: "100%",
                margin: `${marginPx} 0`
            }
        },
        MuiToggleButton: {
            root: {
                flex: "1",
                padding: `11px ${marginPx}`
            }
        }
    },
});

export default theme;