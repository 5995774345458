import PapaParse from 'papaparse';

const opcosString = `OPCO,ID CC
OPCO AFDAS, 0086
OPCO AFDAS, 0214
OPCO AFDAS, 0306
OPCO AFDAS, 0394
OPCO AFDAS, 0509 
OPCO AFDAS, 0598
OPCO AFDAS, 0693 
OPCO AFDAS, 0698
OPCO AFDAS, 0716 
OPCO AFDAS, 0781
OPCO AFDAS, 0892
OPCO AFDAS, 1016
OPCO AFDAS, 1018
OPCO AFDAS, 1083
OPCO AFDAS, 1194 
OPCO AFDAS, 1281
OPCO AFDAS, 1285
OPCO AFDAS, 1307
OPCO AFDAS, 1480
OPCO AFDAS, 1563
OPCO AFDAS, 1631
OPCO AFDAS, 1734 
OPCO AFDAS, 1790
OPCO AFDAS, 1874
OPCO AFDAS, 1895
OPCO AFDAS, 1909
OPCO AFDAS, 1922
OPCO AFDAS, 2021
OPCO AFDAS, 2121
OPCO AFDAS, 2148
OPCO AFDAS, 2257
OPCO AFDAS, 2372
OPCO AFDAS, 2397 
OPCO AFDAS, 2411
OPCO AFDAS, 2412
OPCO AFDAS, 2511
OPCO AFDAS, 2642
OPCO AFDAS, 2683
OPCO AFDAS, 2717
OPCO AFDAS, 2770
OPCO AFDAS, 3090
OPCO AFDAS, 3097
OPCO AFDAS, 3221
OPCO AFDAS, 3225
OPCO AFDAS, 3230
OPCO Santé,1001
OPCO Santé,2046
OPCO Santé,2264
OPCO Santé,29
OPCO Santé,405
OPCO Santé,413
OPCO Santé,783
OPCO Santé,897
OPCO Uniformation,218
OPCO Uniformation,1031
OPCO Uniformation,1261
OPCO Uniformation,1278
OPCO Uniformation,1316
OPCO Uniformation,1518
OPCO Uniformation,1588
OPCO Uniformation,1794
OPCO Uniformation,2128
OPCO Uniformation,2150
OPCO Uniformation,2190
OPCO Uniformation,2336
OPCO Uniformation,2526
OPCO Uniformation,2603
OPCO Uniformation,2666
OPCO Uniformation,2668
OPCO Uniformation,2727
OPCO Uniformation,2768
OPCO Uniformation,2793
OPCO Uniformation,2796
OPCO Uniformation,2797
OPCO Uniformation,2798
OPCO Uniformation,2847
OPCO Uniformation,2941
OPCO Uniformation,3016
OPCO Uniformation,3105
OPCO Uniformation,3220`;

const { data } = PapaParse.parse(opcosString, {
    header: true,
  });

export const opcos = data.map((element: any) => ({
    id: element['ID CC'] as string,
    opco: element['OPCO'] as string,
}));
