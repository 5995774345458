import { SelectOption } from "../types/SelectOption";
import { TipSelectOption } from "../types/TipSelectOption";
import { addYears, startOfDay, subYears } from "date-fns";
import { conventionsCollectives } from "../utils/conventionsCollectives";
import { UserType } from "./Calculator";
import { Yup } from "../utils/localizedYup";
import { Nullable } from "../api/api";

export interface TargettedJobValues {
    wantedLevel: string|null,
    fastLicense?: Nullable<boolean>,
    masterTwo?: Nullable<boolean>,
    salaryRow: typeof conventionsCollectives[0] | null,
    startDate: Date,
    duration: number|null
}

export const validateTargettedJobSchema = Yup.object({
    wantedLevel: Yup.string(),
    startDate: Yup.date().min(startOfDay(new Date())).max(addYears(new Date(), 10)),
    duration: Yup.number().required().min(6).max(48),
});