import { Nullable } from "../api/api";
import { SelectOption } from "../types/SelectOption";
import { conventionsCollectives } from "../utils/conventionsCollectives";
import { Yup } from "../utils/localizedYup";

export interface CompanyValues {
    identified: boolean|null,
    branch: string|null,
    workforce: string|null,
    location: string|null,
    recruited?: Nullable<boolean>,
    apprenticeShipMass: boolean|null
}

export interface CompanyOptions {
    branches: SelectOption[],
    workforces: SelectOption[],
    locations: SelectOption[]
}

export const validateCompanySchema = Yup.object({
    branch: Yup.string().required(),
    workforce: Yup.string().required(),
    location: Yup.string(),
    apprenticeShipMass: Yup.boolean().nullable(),
});

export const getCompanyOptions = () => {
    return {
        branches: conventionsCollectives.map(x => ({value:x.id,name:`${x.name} (${x.id})`})),
        workforces: [
            {value: "1", name: "- de 11"},
            {value: "2", name: "11 à 49"},
            {value: "3", name: "50 à 249"},
            {value: "4", name: "250 et plus"}
        ],
        locations: [
            {value: "pdl", name: "Région Pays de La Loire"},
            {value: "other", name: "Autre"},
        ]
    };
}