import React, { useEffect, useState } from 'react';
import Company from './Company';
import { createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import Beneficiary from './Beneficiary';
import Formation from './Formation';
import { Form, Formik } from 'formik';
import { initialValues, tabsToDisplay, validateCalculatorSchema } from '../models/Calculator';
import Contract from './Contract';
import Result from './Result';
import { margin, marginPx } from '../theme';
import { compareContracts } from '../api/api';
import { CompareResult, initialResults } from '../models/CompareResult';
import { withNameSpace } from '../utils/utils';
import TargettedJob from './TargettedJob';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formColumn: {
      '& > *': {
        backgroundColor: "#f3f3f3",
        padding: "30px",
        marginBottom: marginPx
      }
	},
	resultColumn: {
		position: "relative"
	}
}));

export const Calculator: React.FC = () => {
	const classes = useStyles();
	const [fixed, setFixed] = useState<boolean>(false);
	const [value, setValue] = React.useState(0);
	const [results, setResults] = useState<CompareResult[]>(initialResults);

  	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Typography variant="h3" color="primary" gutterBottom align="center">
					Simulateur de calcul de rémunération <br />et d'aides aux employeurs
				</Typography>
			</Grid>
			<Formik
				initialValues={initialValues}
				onSubmit={values => {
					compareContracts(values).then((results) => {
						setResults(results);
					});
				}}
				onReset={values => {
					
				}}
				validationSchema={validateCalculatorSchema}
			>
				{({values, errors, touched, handleSubmit, ...formikProps}) => {
					const companyElement = <Company namespace="company" values={values.company} errors={errors.company} touched={touched.company} {...formikProps} config={values.config}/>;
					const benecificiaryElement = <Beneficiary namespace="beneficiary" values={values.beneficiary} valuesCompany={values.company} errors={errors.beneficiary} touched={touched.beneficiary} {...formikProps} config={values.config}/>;
					return <Form onSubmit={handleSubmit}>
						<Grid item container spacing={2}>
							<Grid item md={12}>
								<Tabs
									value={tabsToDisplay.indexOf(values.config.userType)}
									indicatorColor="primary"
									textColor="primary"
									onChange={(event,newValue) => formikProps.setFieldValue(withNameSpace("config", "userType"), tabsToDisplay[newValue])}
									aria-label="disabled tabs example"
									centered
								>
									<Tab label="Je suis employeur" />
									<Tab label="Je suis apprenti" />
								</Tabs>
							</Grid>
							<Grid item md={12} container id="form-column" className={classes.formColumn}>
								<Grid item md={6}>
									{benecificiaryElement}
								</Grid>
								<Grid item md={6}>
									{companyElement}
								</Grid>
								<Grid item md={12}>
									<TargettedJob namespace="targettedJob" values={values.targettedJob} valuesCompany={values.company} errors={errors.targettedJob} touched={touched.targettedJob} {...formikProps}/>
								</Grid>
								{values.config.userType === "pro" && <Grid item md={values.formation.details ? 6 : 12}>
									<Formation namespace="formation" values={values.formation} valuesCompany={values.company} errors={errors.formation} touched={touched.formation} {...formikProps} branch={values.company.branch}/>
								</Grid>}
								{values.formation.details && (
									<Grid item md={6}>
										<Contract namespace="contract" values={values.contract} errors={errors.contract} touched={touched.contract} {...formikProps} duration={values.targettedJob.duration}/>
									</Grid>
								)}
							</Grid>
							<Grid item md={12} id="result-column" className={classes.resultColumn}>
								<Result values={values} results={results} id="results" errors={errors} fixed={fixed}/>
							</Grid>
						</Grid>
					</Form>
				}}
			</Formik>	
		</Grid>
  	)
};
