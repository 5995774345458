type Tip = Record<string, JSX.Element>;

const CompanyTip: Tip = {
    branch: <p>
        En fonction de la Convention collective de votre entreprise, les montants de rémunaration peuvent varier, ainsi que le montant de prise en charge des coûts pédagogiques par votre branche professionnelle. <br/>
        <br/>
    </p>,
    workforce: <p>
        L’effectif en équivalent temps plein (ETP) impacte l’attribution de l’aide unique de l’État pour le contrat d’apprentissage.
    </p>,
    location: <p>
        Dans le cadre du calcul du salaire minimum conventionnel , seules les conventions collectives régionales ou nationales concernant les Pays de la Loire sont utilisées. Dans le cas contraire, le calcul se fait sur la base du SMIC.
    </p>,
    recruited: <p>
        Uniformation fait bénéficier aux primo-employeurs de modalités plus favorables de financement du contrat de professionnalisation : quelle que soit la branche professionnelle dont vous relevez, le financement horaire est porté à 15€ heures (contre un montant légal de 9,15€).
    </p>,
    apprenticeShipMass: <p>
        Le seuil minimum de 5% d’alternant est un critère d’éligibilité à l’aide exceptionnelle de l’État pour les entreprises de 250 salariés ou plus, recrutant des apprentis entre le 1er juillet 2020 et le 31 décembre 2021.
Ce seuil d’alternant est une estimation pour l’année 2021. Il est déclaré lors de l’embauche. Si le seuil n’est pas atteint, l’entreprise devra rembourser le montant de l’aide.
    </p>
}

const BeneficiaryTip: Tip = {
    identified: <p>
        Vous pouvez calculer un coût et un reste à charge précis en fonction d'informations détaillées sur le bénéficiaire. Si vous ne les avez pas en votre disposition, le calcul se fera sur une base générique.
    </p>
}

const FormationTip: Tip = {
    filter: <div>
        <p>Le choix d’une formation est indispensable au calcul du montant financé sur le coût pédagogique des contrats d’apprentissage, chaque formation faisant l’objet d’un montant unique défini par France compétences ou par votre branche professionnelle.</p>

        <p dir="ltr">Vous pouvez saisir un libellé de formation, ou un thème de formation.</p>

        <p dir="ltr">Ce moteur de recherche regroupe l’ensemble des formations recensées par France compétences, éligibles au contrat d’apprentissage et au contrat de professionnalisation, et les certificats de qualification professionnelle (CQP) accessibles au contrat de professionnalisation pour votre branche professionnelle.</p>

        <p dir="ltr">Cas des contrat de professionnalisation permettant des parcours de formation sur-mesure</p>

        <p dir="ltr">À titre expérimental pour une durée de 3 ans, le contrat de professionnalisation pourra définir un parcours de formation « sur-mesure » qui ne sera pas nécessairement qualifiant ou certifiant.</p>

        <p dir="ltr">Les employeurs et les opérateurs de compétences pourront également définir eux-mêmes, en accord avec le salarié, les compétences à acquérir dans le cadre d’un contrat de professionnalisation. <a href="https://travail-emploi.gouv.fr/formation-professionnelle/formation-en-alternance-10751/article/contrat-de-professionnalisation">Plus d’information sur le site du ministère du travail</a></p>

        <p dir="ltr">Si vous êtes dans ce cas de figure, vous pouvez laisser vide la zone de recherche de formation. Les principaux calculs pourront être effectués en ce qui concerne le contrat de professionnalisation. En revanche le contrat d’apprentissage ne pourra être mobilisé.</p>
    </div>,
    startDate: <p>
        Si vous n'avez pas rentré de date de naissance précise, cette information permet d'ajuster le montant  de la rémunération en fonction de l'âge supposé du bénéficiaire
    </p>,
    duration: <div>
        <p>Le contrat d’apprentissage doit être conclu pour une durée comprise entre 6 et 36 mois, ou jusqu’à 48 mois dans l’un des cas suivants : </p>

        <p>Le bénéficiaire est reconnu travailleur handicapé,</p>
        <p>Le bénéficiaire est une personne inscrite en tant que sportif de haut niveau</p>
    </div>,
    tutorAge: <p>
        Les personnes d’au moins 45 ans qui assurent la fonction de tuteur font l’objet d’une aide à l’exercice de la fonction plus élevée.
    </p>
}

const apprenticeContractTip: Tip = {
    cost: <p>
        Votre branche professionnelle finance tout ou partie de votre formation. 
Indiquez ici le montant TTC de la formation envisagée, pour obtenir le reste à charge.
    </p>,
    duration: <div>
        <p>Les durées d'une même formation peuvent varier selon les programmes pédagogiques des écoles et CFA.
Si vous connaissez la durée exacte en mois, saisissez-la ici. 
</p>
    </div>
}

const proContractTip: Tip = {
    cost: <div>
        <p>Les coûts pédagogiques facturés par les organismes de formation peuvent être plus élevés que les montants financés.</p>
        <p>Si vous avez connaissance du montant TTC facturé, saisissez-le ici pour permettre le calcul d’un montant réel restant à votre charge.</p>
    </div>,
    durationMonth: <p>
        Pour une même formation, les durées peuvent varier selon les programmes pédagogiques des organismes de formation. Si vous connaissez la durée exacte en mois, saisissez-la ici.
    </p>,
    durationHour: <p>
        La prise en charge du coût pédagogique est basée sur un montant horaire. Pour pouvoir calculer un montant de prise en charge réel, veuillez saisir le nombre d’heures de formations facturées par l’organisme de formation, si vous en avez connaissance.
    </p>
}

export {
    CompanyTip,
    BeneficiaryTip,
    FormationTip,
    apprenticeContractTip,
    proContractTip
};