import { LeakAddTwoTone } from "@material-ui/icons";
import { differenceInYears } from "date-fns/esm";
import { CalculatorValues } from "../models/Calculator";
import { CompareResult, ContractResult } from "../models/CompareResult";
import { Formation } from "../types/entities";
import { SelectOption } from "../types/SelectOption";
import { conventionsCollectivesSalaries } from "../utils/conventionsCollectives";
import { differenceInYearsIgnoringHours, getAgeRatio, getYears, SMIC } from "../utils/utils";

// Fake delay and timeout for Mocking the API requests
const delay = 50;

export type Nullable<T> = T | null | undefined;

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Mock to generate random numbers
 * 
 * @param min 
 * @param max 
 */
function getRandomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

/**
 * Round to 2 decimals
 * @param num 
 */
export function roundToTwo(num: number) { 
    return Math.round( num * 100 + Number.EPSILON ) / 100
}

/**
 * Mock API fetch
 * 
 * @param data 
 */
async function fetchApiMock<T>(data: T): Promise<T> { 
    await timeout(delay);

    return data as T;
}

export const allFormations: SelectOption[] = []

const fetchFormations = async () => {
    const result = await fetch("/formationsMapping.json")
    const parsedFormations:Formation[] = await result.json()

    allFormations.push(...parsedFormations.map(f => ({value: f.codeDiplome, name: `[${f.codeDiplome}]${f.libelle}`, fullObject: f, idCcs: f.codesIdcc.split(",").map(x => x.trim())})))
}

const fetchFormationsToAwaitIfWanted = fetchFormations();


/**
 * Search some sample values
 * 
 * @param value 
 */
const getFormationOptions = async(value: string, idCc: string): Promise<SelectOption[]> => {
    if(!allFormations.length){
        await fetchFormationsToAwaitIfWanted;
    }

    const regex = new RegExp(value, 'i');

    const filteredOptions = allFormations
        .filter(option => regex.test(option.name))
        .filter(option => (option as unknown as any).idCcs.includes(idCc));

    return fetchApiMock<SelectOption[]>(filteredOptions);
}

/**
 * Get the results to compare the contracts 
 * based on the submitted form values
 * 
 * @param values 
 */
const compareContracts = async(values: CalculatorValues): Promise<CompareResult[]> => {
    const results: CompareResult[] = [];
    let remainingMonths = values.targettedJob.duration || 12;
    const allYears = getYears(values.targettedJob.duration);
    for(const year of allYears) {
        const yearRatio = Math.min(remainingMonths, 12) / 12
        results.push(generateYearResults(values, yearRatio, year));
        remainingMonths-=12;
    }

    return fetchApiMock<CompareResult[]>(results);
}

const HEBDO_BASE = 151.67;
const CHARGES_PATRONALES = 0.071;
const CHARGES_SALARIALES = 0.22;
function generateYearResults(values: CalculatorValues, yearRatio: number, yearIndex: number) {
    let realAgeAtContractStart = values.beneficiary.age as number + 1;
    if(values.beneficiary.birthdate){
        realAgeAtContractStart = differenceInYearsIgnoringHours(values.targettedJob.startDate, values.beneficiary.birthdate);
    }
    const realAgeAtContractStartWithYear = realAgeAtContractStart + yearIndex;

    let appropriateSalaryRow = values.targettedJob.salaryRow;
    if(appropriateSalaryRow){
        const correspondingLines = conventionsCollectivesSalaries.filter(ccs => 
            ccs.id === appropriateSalaryRow?.id && 
            ccs.category === appropriateSalaryRow.category &&
            ccs.subCategory === appropriateSalaryRow.subCategory &&
            ccs.subSubCategory === appropriateSalaryRow.subSubCategory
        );
        if(correspondingLines.length > 1){
            const potentialReplacingSalaryRow = correspondingLines.find(l => l.anneesRange.includes(yearIndex + 1))
            if(potentialReplacingSalaryRow){
                appropriateSalaryRow = potentialReplacingSalaryRow;
            }
        }
    }

    const conventional = appropriateSalaryRow?.amount || 0;
    let conventionalAmount = conventional;
    let periodicity = "year";
    let amountBase = "";
    if(appropriateSalaryRow?.amountBase){
        amountBase = appropriateSalaryRow?.amountBase;
        if(amountBase.startsWith("byMonth")){
            conventionalAmount = 12 * conventional;
            periodicity = "month";
        }
        if(amountBase.startsWith("byHour")){
            conventionalAmount = HEBDO_BASE * 12 * conventional;
            periodicity = "month";
        }
    }

    let yearIndexWithExceptions = yearIndex;
    if(values.targettedJob.fastLicense || values.targettedJob.masterTwo){
        yearIndexWithExceptions += 1;
    }
    let ageRatio = getAgeRatio(realAgeAtContractStartWithYear, yearIndexWithExceptions, values.company.branch, values.formation.formation);

    let amount = SMIC;
    if(realAgeAtContractStartWithYear >= 21){
        amount = Math.max(SMIC,conventionalAmount);
    }

    const baseAmount = amount;
    
    const chargesPatronales = CHARGES_PATRONALES;
    const chargesRatio = (1 + chargesPatronales);
    const baseBrutAmount = baseAmount * yearRatio * ageRatio;
    amount = baseBrutAmount * chargesRatio;

    //montant non soumis à charges
    const baseAmoutNonSoumisCharges = 0.79 * SMIC * yearRatio;
    const baseAmountSoumisACharges = Math.max(0, baseBrutAmount - baseAmoutNonSoumisCharges);
    const baseAmountSoumisAChargesAvecCharges = baseAmountSoumisACharges * (1 - CHARGES_SALARIALES);
    const baseNetAmount = Math.min(baseAmoutNonSoumisCharges, baseBrutAmount) + baseAmountSoumisAChargesAvecCharges;

    const details = {
        baseAmount,
        yearRatio,
        ageRatio,
        baseBrutAmount,
        conventionalAmount,
        chargesRatio,
        realAgeAtContractStart,
        baseAmoutNonSoumisCharges,
        baseAmountSoumisACharges,
        baseAmountSoumisAChargesAvecCharges,
        baseNetAmount,
        amountBase
    }

    let aideAmount = 0;
    let aideExceptionnelle = 0;

    if(yearIndex === 0){
        aideExceptionnelle = (realAgeAtContractStartWithYear < 18 ? 5000 : 8000) * yearRatio;
    }

    let aideUnique = 0;
    if(aideExceptionnelle === 0){
        const mapping = {
            1: 2000,
            2: 1200,
            3: 1200
        }
        aideUnique = mapping[yearIndex as 1] as any;
        aideUnique *= yearRatio;
    }

    aideAmount += aideExceptionnelle + aideUnique;

    let aideHandicapBase = 1000;
    let aideHandicap = 0;
    if(yearIndex < 4 && values.beneficiary.situation.includes("handicap")){
        aideHandicap = yearRatio * aideHandicapBase;
    }
    aideAmount += aideHandicap;

    let aideOpco = 0;
    if(yearIndex === 0){
        if(appropriateSalaryRow?.opco === "OPCO Uniformation"){
            aideOpco = 230 * 12 * yearRatio;
        }
        if(appropriateSalaryRow?.opco === "OPCO Santé"){
            aideOpco = 230 * 12 * yearRatio;
        }
        if(appropriateSalaryRow?.opco === "OPCO AFDAS" && ["1", "2"].includes(values.company?.workforce || "")){
            aideOpco = 115 * 12 * yearRatio;
        }
    }

    aideAmount += aideOpco;

    const allFormationCost = values.contract.apprenticeShip.cost;
    const allFormationDuration = parseInt(String(values.contract.apprenticeShip.duration));

    let formationCostAmount = 0;
    if(allFormationCost && allFormationCost > 0 && allFormationDuration > 0){
        formationCostAmount = (allFormationCost / allFormationDuration) * 12 * yearRatio;
    }

    const matching = allFormations.filter(x => x.value === values.formation.formation);

    let elementMatching = matching.find(element => ((element as any).fullObject.codesIdcc || "").split(",").map((x: string) => x.trim()).includes(values.company.branch))
    
    let opcoFormationAmount = 0;
    //Default value
    elementMatching = elementMatching || matching[0];
    if(elementMatching){
        opcoFormationAmount = (elementMatching as any).fullObject.valeurNum / (values.targettedJob.duration || 12) * yearRatio * 12;
        opcoFormationAmount = Math.min(formationCostAmount,opcoFormationAmount);
    }

    const formationCostIncludingAide = formationCostAmount - opcoFormationAmount;

    const apprenticeResults: ContractResult = {
        cost: roundToTwo(amount),
        baseNetAmount: roundToTwo(baseNetAmount),
        baseBrutAmount: roundToTwo(baseNetAmount),
        ageRatio,
        remaining: Math.max(0,roundToTwo(amount - aideAmount + formationCostIncludingAide)),
        salaryRow: appropriateSalaryRow,
        aideAmount: roundToTwo(aideAmount),
        opcoFormationAmount,
        elementMatching,
        aideUnique,
        aideExceptionnelle,
        aideHandicap,
        aideOpco,
        periodicity,
        formationCostAmount,
        details,
        monthsToConsider: 12 * yearRatio
    };

    const results: CompareResult = {
        apprenticeShip: apprenticeResults,
        pro: apprenticeResults,
    }
    return results;
}

export {
    getFormationOptions,
    compareContracts
};