import { CalculatorValues } from '../Calculator';
const result: CalculatorValues = {
  company: {
    identified: null,
    branch: '',
    workforce: null,
    location: 'pdl',
    recruited: null,
    apprenticeShipMass: null,
  },
  beneficiary: {
    identified: null,
    birthdate: null,
    age: null,
    situation: [],
    apprenticeShip: false,
  },
  formation: {
    formation: null,
    tutorAge: null,
    details: null,
  },
  contract: {
    apprenticeShip: {
      cost: null,
      duration: null,
    },
    pro: {
      cost: null,
      durationMonth: null,
      durationHour: null,
    },
  },
  config: {
    userType: "pro"
  },
  targettedJob:{
    wantedLevel: '',
    salaryRow: null,
    startDate: new Date(),
    duration: 0,
  }
};

export default result
