import React from 'react';
import { HtmlTooltip } from './HtmlTooltip';
import HelpIcon from '@material-ui/icons/Help';
import { ClickAwayListener, createStyles, IconButton, makeStyles } from '@material-ui/core';

interface HelpTipProps {
    tip: JSX.Element,
    style?: any;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const useStyles = (extendsStyle:any) => makeStyles(() =>
    createStyles({
        ...extendsStyle
    })
);

export function HelpTip(props: HelpTipProps) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const classes = useStyles(props.style || {})();

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <HtmlTooltip
                className={classes.helpTip}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <React.Fragment>
                        {props.tip}
                    </React.Fragment>
                }
            >
                <IconButton color="primary" onClick={handleTooltipOpen} component="span">
                    <HelpIcon/>
                </IconButton>
            </HtmlTooltip>
        </ClickAwayListener>
    )
};


