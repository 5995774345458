import { addYears, startOfDay } from 'date-fns';
import { Yup } from '../utils/localizedYup';
export interface FormationValues {
    formation: string|null;
    tutorAge: boolean|null; 
    details: boolean|null;
}

export const validateFormationSchema = Yup.object({
    formation: Yup.string().required(),
    tutorAge: Yup.boolean().nullable(),
    details: Yup.boolean().nullable()
});