import { Checkbox, createStyles, FormControlLabel, FormGroup, InputLabel, Link, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';
import { NaturePeopleSharp } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { differenceInYears, subYears } from 'date-fns';
import React, { useEffect, useState } from 'react';
import AssertRadio from '../fields/AssertRadio';
import { BeneficiaryOptions, BeneficiaryValues, getBeneficiaryOptions } from '../models/Beneficiary';
import { CompanyValues } from '../models/Company';
import { HelpTip } from '../tooltips/HelpTip';
import { BeneficiaryTip } from '../tooltips/Tips';
import { CalculatorProps } from '../types/CalculatorProps';
import { conventionsCollectivesSalaries } from '../utils/conventionsCollectives';
import { hasError, uniqWithoutEmpty, withNameSpace } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    tipOption: {
        display: "flex",
        "& > label": {
            marginBottom: "0px"
        }
    },
    ml: {
        marginLeft: "5px"
    }
}));

interface BeneficiaryProps extends CalculatorProps<BeneficiaryValues>{
    valuesCompany: CompanyValues;
}

export default function Beneficiary(props: BeneficiaryProps) {
    const userType = props.config?.userType || "pro";
    const beneficiaryOptions = getBeneficiaryOptions(userType);

    const [category, setCategory] = useState<string>("");
    const [subCategory, setSubCategory] = useState<string>("");
    const [subSubCategory, setSubSubCategory] = useState<string>("");

    const {values, setFieldValue, handleChange, handleBlur, errors, touched, namespace, valuesCompany} = props;

    const branch = valuesCompany.branch;

    let categories: string[] = [];
    let subCategories: string[] = [];
    let subSubCategories: string[] = [];

    if(branch){
        const rowsMatchingBranch = conventionsCollectivesSalaries.filter(x => x.id === branch)
        categories = uniqWithoutEmpty(rowsMatchingBranch.map(x => x.category));
        if(category){
            subCategories = uniqWithoutEmpty(rowsMatchingBranch.filter(x => x.category === category).map(x => x.subCategory))
        }
        if(category && subCategory){
            subSubCategories = uniqWithoutEmpty(rowsMatchingBranch.filter(x => x.category === category && x.subCategory === subCategory).map(x => x.subSubCategory))
        }
    }

    useEffect(() => {
        if(!branch){
            return;
        }
        let rowsMatching = conventionsCollectivesSalaries.filter(x => x.id === branch)
        rowsMatching = rowsMatching.filter(x => !category || x.category === category)
        rowsMatching = rowsMatching.filter(x => !subCategory || x.subCategory === subCategory)
        rowsMatching = rowsMatching.filter(x => !subSubCategory || x.subSubCategory === subSubCategory)
        setFieldValue(withNameSpace(namespace, "salaryRow"), rowsMatching[0]);
    },[branch, category, subCategory, subSubCategory])

    const classes = useStyles();

    const setBirthDate = (date: Date | null) => {
        setFieldValue(withNameSpace(namespace, "birthdate"), date);

        if(date) {
            const age = differenceInYears(new Date(), date);
            setFieldValue(withNameSpace(namespace, "age"), age);
        }
    }

    return (
        <div>
            <Typography variant="h4" color="primary" gutterBottom>
                {userType === "pro" ? "Le bénéficiaire" : "Votre situation"}
            </Typography>

            {beneficiaryOptions && (
                <React.Fragment>
                    {userType === "pro" && <>
                        <AssertRadio
                            label="J'ai déjà identifié le bénéficiaire"
                            tip={BeneficiaryTip.identified}
                            required={true}
                            value={values.identified}
                            hasError={hasError(errors, touched, "identified")}
                            id="beneficiary-identified"
                            handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "identified"), value)}
                        />

                        {values.identified !== false && (
                            <Typography variant="body1">
                                En fonction de la date de naissance du bénéficiaire, il est éligible ou non au contrat d'apprentissage et sa rémunération est impactée. 
                                Si vous ne la connaissez pas, indiquez son âge et il sera automatiquement mis à jour avec une année en plus à la date de début de contrat.
                            </Typography>
                        )}
                        <br />
                        </>
                    }

                    {values.identified !== false && <><InputLabel error={hasError(errors, touched, "birthdate")} id="beneficiary-birthdate">
                        Date de naissance
                    </InputLabel>
                     <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        onBlur={handleBlur}
                        initialFocusedDate={subYears(new Date(), 16)}
                        value={values.birthdate}
                        onChange={(date) => setBirthDate(date)}
                        invalidDateMessage="Date invalide"
                    /></>}

                    <React.Fragment>
                        <InputLabel required error={hasError(errors, touched, "age")} id="beneficiary-age">
                            Âge
                        </InputLabel>
                        <TextField 
                            name={withNameSpace(namespace, "age")} 
                            type="number"
                            value={values.age}
                            onBlur={handleBlur}
                            onChange={(event) => {
                                setFieldValue(withNameSpace(namespace, "birthdate"), null);
                                setFieldValue(withNameSpace(namespace, "age"), parseInt(event.target.value))
                                if(values.situation.includes("3e")){
                                    setFieldValue(withNameSpace(namespace, "situation"), values.situation.filter(s => s !== "3e"))
                                }
                            }}
                        />
                    </React.Fragment>

                    {values.age != null && values.age <= 15 && !values.situation.includes("3e") && <Alert severity="warning">
                        <AlertTitle>Bénéficiaire de 15 ans ou moins</AlertTitle>
                        L'âge minimum de 16 ans peut être abaissé à 15 ans si le jeune a atteint cet âge entre la rentrée scolaire et le 31 décembre de l'année civile, et qu'il a terminé son année de 3ème
                    </Alert>}
                    
                    {values.age != null && values.age >= 30 && (values.situation.length === 0 || (values.situation.length === 1 && values.situation.includes("already_apprentice"))) && 
                    <Alert severity="warning">
                        <AlertTitle>Bénéficiaire de 30 ans ou plus</AlertTitle>
                        Les contrat d’apprentissage peuvent être conclus pour des personnes de 30 ans ou plus, sous réserve de l’une des conditions suivantes :<br />
                        <ul>
<li>L’apprenti est reconnu travailleur handicapé</li>
<li>L’apprenti est une personne inscrite en tant que sportif de haut niveau</li>
<li>L’apprenti envisage de créer ou reprendre une entreprise supposant l'obtention d'un diplôme (exemple : dispositif d'aide individualisée Acre, Nacre ou Cape)</li>
<li>L’apprenti a obtenu un diplôme de niveau inférieur dans le cadre d’un précédent contrat d’apprentissage.</li>
<li>L’apprenti a déjà effectué un contrat d’apprentissage, qui a été rompu pour des raisons indépendantes de sa volonté.</li>
</ul>
<p>Dans ces 2 derniers cas, il ne doit pas s'écouler plus d'1 an entre les 2 contrats.</p>
                    </Alert>}

                    {values.identified !== false && (
                        <React.Fragment>
                            <InputLabel id="beneficiary-situation">
                                Situation du bénéficiaire
                            </InputLabel>
                            <FormGroup>
                                {(
                                    beneficiaryOptions.situation.filter(bo => bo.value !== "3e" || values.age === 15).map((situationOption, index) => {
                                        let spaces = "";
                                        if(situationOption.parent){
                                            if(!values.situation.includes(situationOption.parent)){
                                                return null;
                                            }
                                            spaces = "--- ";
                                        }
                                        return <div className={classes.tipOption} key={"tip" + index}>
                                            <FormControlLabel
                                                key={index}
                                                control={<Checkbox 
                                                    value={situationOption.value} 
                                                    onChange={handleChange} 
                                                    name={withNameSpace(namespace, "situation")}
                                                    checked={values.situation.includes(situationOption.value)} />}
                                                label={spaces + situationOption.name}
                                            />
                                            {situationOption.tip && <HelpTip tip={situationOption.tip}/>}
                                        </div>
                                        })
                                )}
                            </FormGroup>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}