import { Checkbox, createStyles, FormControlLabel, FormGroup, InputLabel, Link, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';
import { NaturePeopleSharp } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { addYears, differenceInYears, startOfDay, subYears } from 'date-fns';
import React, { useEffect, useState } from 'react';
import AssertRadio from '../fields/AssertRadio';
import { BeneficiaryOptions, BeneficiaryValues, getBeneficiaryOptions } from '../models/Beneficiary';
import { CompanyValues } from '../models/Company';
import { TargettedJobValues } from '../models/TargettedJob';
import { HelpTip } from '../tooltips/HelpTip';
import { BeneficiaryTip, FormationTip } from '../tooltips/Tips';
import { CalculatorProps } from '../types/CalculatorProps';
import { conventionsCollectivesSalaries } from '../utils/conventionsCollectives';
import { hasError, uniqWithoutEmpty, withNameSpace } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    tipOption: {
        display: "flex",
        "& > label": {
            marginBottom: "0px"
        }
    },
    ml: {
        marginLeft: "5px"
    }
}));

interface TargettedJobProps extends CalculatorProps<TargettedJobValues>{
    valuesCompany: CompanyValues;
}

export default function TargettedJob(props: TargettedJobProps) {
    const userType = props.config?.userType || "pro";
    const beneficiaryOptions = getBeneficiaryOptions(userType);

    const [category, setCategory] = useState<string>("");
    const [subCategory, setSubCategory] = useState<string>("");
    const [subSubCategory, setSubSubCategory] = useState<string>("");

    const {values, setFieldValue, handleChange, handleBlur, errors, touched, namespace, valuesCompany} = props;

    const branch = valuesCompany.branch;

    let categories: string[] = [];
    let subCategories: string[] = [];
    let subSubCategories: string[] = [];

    if(branch){
        const rowsMatchingBranch = conventionsCollectivesSalaries.filter(x => x.id === branch)
        categories = uniqWithoutEmpty(rowsMatchingBranch.map(x => x.category));
        if(category){
            subCategories = uniqWithoutEmpty(rowsMatchingBranch.filter(x => x.category === category).map(x => x.subCategory))
        }
        if(category && subCategory){
            subSubCategories = uniqWithoutEmpty(rowsMatchingBranch.filter(x => x.category === category && x.subCategory === subCategory).map(x => x.subSubCategory))
        }
    }

    useEffect(() => {
        if(!branch){
            return;
        }
        let rowsMatching = conventionsCollectivesSalaries.filter(x => x.id === branch)
        rowsMatching = rowsMatching.filter(x => !category || x.category === category)
        rowsMatching = rowsMatching.filter(x => !subCategory || x.subCategory === subCategory)
        rowsMatching = rowsMatching.filter(x => !subSubCategory || x.subSubCategory === subSubCategory)
        setFieldValue(withNameSpace(namespace, "salaryRow"), rowsMatching[0]);
    },[branch, category, subCategory, subSubCategory])

    const months = Array.from(new Array(43), (x, i) => i + 6);

    return (
        <div>
            <Typography variant="h4" color="primary" gutterBottom>
                Le poste visé
            </Typography>

            <InputLabel id="beneficiary-wanted-level">
                        Niveau de diplôme visé
                    </InputLabel>
                    <Select
                        labelId="beneficiary-wanted-level"
                        value={values.wantedLevel}
                        displayEmpty
                        onChange={(e) => {
                            handleChange(e)
                            setFieldValue(withNameSpace(namespace, "fastLicense"), null)
                            setFieldValue(withNameSpace(namespace, "masterTwo"), null)
                        }}
                        name={withNameSpace(namespace, "wantedLevel")}
                    >
                        <MenuItem value="" disabled>Sélectionnez le niveau de diplôme visé</MenuItem>
                        {beneficiaryOptions.wantedLevel.map((wantedLevelOption, index) => (
                            <MenuItem key={index} value={wantedLevelOption.value}>{wantedLevelOption.name}</MenuItem>
                        ))}
                    </Select>
            {values.wantedLevel === "5" && <AssertRadio
                label="Le diplôme visé est-il une licence professionelle préparée en 1 an au lieu de 2 ?"
                tip={<span>L'apprenti préparant une licence professionnelle en 1 an bénéficie d'une rémunération correspondant à une 2ème année de contrat.</span>}
                required={true}
                value={values.fastLicense == null ? null : values.fastLicense}
                hasError={hasError(errors, touched, "fastLicense")}
                id="targettedjob-fastLicense"
                handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "fastLicense"), value)}
            />}
            {values.wantedLevel === "7" && <AssertRadio
                label="Le diplôme visé est-il un Master 2 ?"
                tip={<span>L'apprenti préparant un Master 2 bénéicie d'une rémunération correspondant à une 2ème année de contrat</span>}
                required={true}
                value={values.masterTwo == null ? null : values.masterTwo}
                hasError={hasError(errors, touched, "masterTwo")}
                id="targettedjob-masterTwo"
                handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "masterTwo"), value)}
            />}
            {categories.length > 0 && <>
                <InputLabel id="beneficiary-category">
                    Catégorie
                </InputLabel>
                <Select
                    labelId="beneficiary-category"
                    value={category}
                    displayEmpty
                    onChange={(e) => {
                        setCategory(e.target.value as string);
                        setSubCategory("");
                        setSubSubCategory("");
                    }}
                    name="beneficiary-category"
                >
                    <MenuItem value="" disabled>Sélectionnez la catégorie</MenuItem>
                    {categories.map((categoryName) => (
                        <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                    ))}
                </Select>
            </>}
            {subCategories.length > 0 && <>
                <InputLabel id="beneficiary-sub-category">
                    Sous-catégorie
                </InputLabel>
                <Select
                    labelId="beneficiary-sub-category"
                    value={subCategory}
                    displayEmpty
                    onChange={(e) => {
                        setSubCategory(e.target.value as string)
                        setSubSubCategory("");
                    }}
                    name="beneficiary-sub-category"
                >
                    <MenuItem value="" disabled>Sélectionnez la sous-catégorie</MenuItem>
                    {subCategories.map((categoryName) => (
                        <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                    ))}
                </Select>
            </>}
            {subSubCategories.length > 0 && <>
                <InputLabel id="beneficiary-sub-sub-category">
                    Sous sous-catégorie
                </InputLabel>
                <Select
                    labelId="beneficiary-sub-sub--category"
                    value={subSubCategory}
                    displayEmpty
                    onChange={(e) => setSubSubCategory(e.target.value as string)}
                    name="beneficiary-sub-sub--category"
                >
                    <MenuItem value="" disabled>Sélectionnez la sous sous-catégorie</MenuItem>
                    {subSubCategories.map((categoryName) => (
                        <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                    ))}
                </Select>
            </>}

            <InputLabel error={hasError(errors, touched, "startDate")} required id="formation-start">
                Date visée de début de contrat
            </InputLabel>
            <HelpTip tip={FormationTip.startDate}/>
            <KeyboardDatePicker
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                value={values.startDate}
                minDate={startOfDay(new Date())}
                maxDate={addYears(new Date(), 10)}
                onBlur={handleBlur}
                onChange={(date) => setFieldValue(withNameSpace(namespace, "startDate"), date)}
                invalidDateMessage="Date invalide"
            />

            <InputLabel required error={hasError(errors, touched, "duration")} id="formation-duration">
                Durée du contrat
            </InputLabel>
            <HelpTip tip={FormationTip.duration}/>
            <Select
                labelId="formation-duration"
                value={values.duration}
                displayEmpty
                onChange={handleChange}
                onBlur={handleBlur}
                name={withNameSpace(namespace, "duration")}
            >
                <MenuItem value={0} disabled>Sélectionnez la durée</MenuItem>
                {months.map((month) => (
                    <MenuItem key={month} value={month}>{month} mois</MenuItem>
                ))}
            </Select>
        </div>
    )
}