import { CircularProgress, createStyles, debounce, FormControlLabel, InputAdornment, InputLabel, makeStyles, MenuItem, Select, Switch, TextField, Theme, Typography } from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { addYears, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { getFormationOptions } from '../api/api';
import AssertRadio from '../fields/AssertRadio';
import { FormationValues } from '../models/Formation';
import { HelpTip } from '../tooltips/HelpTip';
import { FormationTip } from '../tooltips/Tips';
import { CalculatorProps } from '../types/CalculatorProps';
import { SelectOption } from '../types/SelectOption';
import { hasError, withNameSpace } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterLabel: {
        marginRight: "30px",
        display: "flex"
    },
    filterContainer: {
        display: "flex",
        alignItems: "flex-start"
    }
}));

interface FormationProps extends CalculatorProps<FormationValues> {
    branch: string | null;
    valuesCompany: any;
}

export default function Formation(props: FormationProps) {
    const [formationOptions, setFormationOptions] = useState<SelectOption[]>([]);
    const [open, setOpen] = React.useState(false);
    const [branchError, setBranchError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    // Deconstruct from Formik props
    const {values, setFieldValue, handleChange, handleBlur, errors, touched, namespace, branch} = props;

    const onChangeHandle = debounce((value: string) => {
        if(!branch) {
            setBranchError(true);
            return;
        } else {
            setBranchError(false);
        }
        setLoading(true);
        getFormationOptions(value, branch).then((formationOptions: SelectOption[]) => {
            setFormationOptions(formationOptions);
            setLoading(false);
        });
    }, 500);

    React.useEffect(() => {
        if (!open) {
            setFormationOptions([]);
        }
    }, [open]);

    return (
        <div>
            <Typography variant="h4" color="primary" gutterBottom>
                La formation
            </Typography>

            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, value) => setFieldValue(withNameSpace(namespace, "formation"), value ? value.value : "")}
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionLabel={option => option.name}
                options={formationOptions}
                loading={loading}
                renderInput={params => (
                    <TextField
                    {...params}
                    onChange={ev => {
                        // Doon't fire API if the user delete or not entered anything
                        if (ev.target.value !== "" || ev.target.value !== null) {
                            onChangeHandle(ev.target.value);
                        }
                    }}
                    error={hasError(errors, touched, "formation")}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchSharp />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    />
                )}
            />

            {hasError(errors, touched, "formation") && (
                <Alert severity="error">Veuillez sélectionner une formation</Alert>
            )}

            {branchError && (
                <Alert severity="warning">Vous devez sélectionner une branche professionnelle avant de rechercher une formation.</Alert>
            )}

            <Typography variant="h4" color="primary" gutterBottom>
                Durées et montants facturés
            </Typography>

            <AssertRadio
                label={
                    (
                        <React.Fragment>
                            Disposez-vous d'un devis de prestataire de formation, ou du détail de durée et de montant facturé ?
                            <br/>
                            Ces informations permettront de déterminer un reste à charge réel.
                        </React.Fragment>   
                    )
                }
                value={values.details}
                id="formation-details"
                handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "details"), value)}
            />
        </div>
    )
}
