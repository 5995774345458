import { parseISO } from "date-fns"

const result = {
    "company": {
        "identified": true,
        "branch": "1518",
        "workforce": "2",
        "location": "pdl",
        "recruited": null,
        "apprenticeShipMass": null
    },
    "beneficiary": {
        "identified": true,
        "birthdate": null,
        "age": 21,
        "situation": [],
        "apprenticeShip": false,
        "salaryRow": {
            "id": "1518",
            "name": "Animation",
            "category": "A",
            "subCategory": "",
            "subSubCategory": "",
            "amount": 1548.4,
            "amountBase": "byMonth151",
            "opco": "OPCO Uniformation"
        }
    },
    "formation": {
        "formation": "36M33601",
        "tutorAge": null,
        "details": true
    },
    "contract": {
        "apprenticeShip": {
            "cost": "8000",
            "duration": "10"
        },
        "pro": {
            "cost": null,
            "durationMonth": null,
            "durationHour": null
        }
    },
    "config": {
        "userType": "pro"
    },
    "targettedJob": {
        "wantedLevel": "",
        "salaryRow": {
            "id": "1518",
            "name": "Animation",
            "category": "D",
            "subCategory": "",
            "subSubCategory": "",
            "amount": 1896,
            "amountBase": "byMonth151",
            "opco": "OPCO Uniformation"
        },
        "startDate": parseISO("2021-03-15T16:39:47.366Z"),
        "duration": 10
    }
}

export default result