import { AppBar, Box, Button, createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import React from "react";
import EuroIcon from '@material-ui/icons/Euro';
import { marginPx } from "../theme";
import { CompareResult } from "../models/CompareResult";
import { CalculatorValues, findGetParameterWhenNotInReactContext, UserType } from "../models/Calculator";
import { FormikErrors } from "formik";
import { Alert, AlertTitle } from "@material-ui/lab";
import { omit } from "lodash";
import { Nullable, roundToTwo } from "../api/api";

interface ResultProps {
    fixed: boolean;
    results: CompareResult[];
    errors: FormikErrors<CalculatorValues>;
    id: string;
    values: CalculatorValues;
}

const resultStyles = makeStyles((theme: Theme) =>
  createStyles({
    indication: {
        marginTop: marginPx,
        background: "#ebf4f9",
        padding: marginPx
    },
    errors: {
        marginTop: marginPx,
        padding: marginPx,
        alignItems: "center",
        justifyContent: "center"
    },
    centered: {
        textAlign: "center",
        color: "#004b9c"
    },
    submit: {
        width: "100%",
        marginTop: marginPx
    },
    fixedColumn: {
		position: "fixed",
		top: marginPx
	}
}));

export default function Result(props: ResultProps) {
    const [value, setValue] = React.useState(0);

    const {fixed, id, results, errors, values} = props;
    const userType = values.config.userType || "pro";

    const classes = resultStyles();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const showDetails = findGetParameterWhenNotInReactContext("showDetails");

    return (
        <div className={`${classes.centered} ${fixed ? classes.fixedColumn : ''}`} id={id}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {results.map((year, index) => (
                        <Tab key={index} label={`Année ${index + 1}`} />
                    ))}
                </Tabs>
            </AppBar>
            {results.map((yearresults, index) => (
                <YearPanel value={value} key={index} results={yearresults} index={index} userType={userType}>
                    Année {index + 1}
                </YearPanel>
            ))}

            {Object.keys(errors).length > 0 && (
                <Alert className={classes.errors} severity="error">
                    Le formulaire contient des erreurs {showDetails ? <div>
                        <pre style={{textAlign:"start", width:"600px"}}>{JSON.stringify(errors, null, 4)}</pre>
                        <pre style={{textAlign:"start", width:"600px"}}>{JSON.stringify(values, null, 4)}</pre>
                    </div> : null}
                </Alert>
            )}
            <Box className={classes.indication}>
                <Typography variant="body1">
                    Résultats à titre indicatif, sans valeur contractuelle.
                </Typography>
            </Box>
            <Box className={classes.indication}>
                <Typography variant="body1">
                    Malgré tous nos efforts pour mettre à jour ce simulateur, une erreur peut toujours s'être glissée. Merci de nous la signaler dans ce cas ici : <a href="mailto:simulateur@myfutu.re">simulateur@myfutu.re</a>

                </Typography>
            </Box>
            {showDetails && <textarea id="to-copy" style={{opacity:0}} value={JSON.stringify(values, null, 4)}></textarea>}
            {showDetails && <Button variant="contained" type="button" size="large" color="secondary" className={classes.submit} onClick={() => {
                const toCopy = document.getElementById( 'to-copy' );
                (toCopy as any).select();
                document.execCommand( 'copy' );
                return false;
            }}>
                Copier en tant que persona
            </Button>}
            <Button variant="contained" type="submit" size="large" color="secondary" className={classes.submit}>
                Lancer le simulateur
            </Button>
        </div>
    ) 
}

const panelStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBox: {
        padding: marginPx,
        textAlign: "center",
        backgroundColor: "#7fa2c8",
        color: "#fff"
    },
    title: {
        fontSize: "1.8rem"
    },
    comparator: {
        marginTop: marginPx
    },
    borderClear: {
        borderRight: "2px solid #fff"
    },
    costs: {
        textAlign: "center",
        color: "#004b9c",
        padding: marginPx,
        background: "#ebf4f9"
    },
    borderFilled: {
        borderRight: "2px solid #7fa0cc"
    }
}));

interface YearPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    results: CompareResult;
    userType: UserType;
}

function YearPanel(props: YearPanelProps) {
    const { children, value, index, results, ...other } = props;

    const classes = panelStyles();

    const showDetails = findGetParameterWhenNotInReactContext("showDetails");

    const periodicityRendered = results.apprenticeShip.periodicity === "month" ? "(par mois)" : "(par an)";

    const handlePeriodicity = (value: Nullable<number>) => {
        if(results.apprenticeShip.periodicity !== "month"){
            return roundToTwo(value || 0);
        }
        const monthsToConsider = results.apprenticeShip.monthsToConsider || 12;
        return roundToTwo((value || 0) / monthsToConsider);
    }

    const elementMatching = (results.apprenticeShip.elementMatching || {}) as any;
    elementMatching["fullObject"] = omit(elementMatching.fullObject || {},["codeDiplome","codesIdcc"]);

    let amountBaseExplanation = "base 35 heures par semaine, hors primes";
    if(["byMonth156", "byMonth152"].includes(results.apprenticeShip.details?.amountBase)){
        amountBaseExplanation = "base 36 heures par semaine (dont 1 heure supplémentaire), hors primes";
    }
    if(["byMonth165"].includes(results.apprenticeShip.details?.amountBase)){
        amountBaseExplanation = "base 38 heures par semaine (dont 3 heures supplémentaires), hors primes";
    }
    if(["byMonth174"].includes(results.apprenticeShip.details?.amountBase)){
        amountBaseExplanation = "base 40 heures par semaine (dont 5 heures supplémentaires), hors primes";
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`year-${index}`}
        {...other}
      >
        {value === index && (
            <Grid item container className={classes.comparator}>
                <Grid item md={12}>
                    <Box className={`${classes.costs} ${classes.borderFilled}`}>
                        {props.userType === "student" && <>
                            <Typography variant="h5">
                                Rémunération {periodicityRendered} [{amountBaseExplanation}] : {handlePeriodicity(results.apprenticeShip.baseNetAmount)} <EuroIcon/>
                            </Typography>
                        </>}
                        {props.userType === "pro" && <Box mt={2}>
                            <Typography variant="h5">
                                Coût salariaux {periodicityRendered} [{amountBaseExplanation}] : {handlePeriodicity(results.apprenticeShip.cost)} <EuroIcon/>
                            </Typography>
                            <Typography variant="h5">
                                Aides {periodicityRendered} : {handlePeriodicity(results.apprenticeShip.aideAmount)} <EuroIcon/>
                            </Typography>
                            <Typography variant="h5">
                                Coût de la formation {periodicityRendered} : {handlePeriodicity(results.apprenticeShip.formationCostAmount)} <EuroIcon/>
                            </Typography>
                            <Typography variant="h5">
                                Prise en charge de la formation {periodicityRendered} : {handlePeriodicity(results.apprenticeShip.opcoFormationAmount)} <EuroIcon/>
                            </Typography>
                            <br />
                            <Typography variant="h5">
                                Reste à charge {periodicityRendered} : {handlePeriodicity(results.apprenticeShip.remaining)} <EuroIcon/>
                            </Typography>
                        </Box>}
                        <p>
                            Le résultat présenté est une estimation évaluée en fonction des planchers légaux (pourcentage du SMIC), et comprend les charges patronales.<br />
                            Lorsque le mode de calcul conventionnel est plus favorable à l’apprenti, c’est celui-ci qui prévaut.<br />
                            L’opérateur de compétence est seul compétent pour effectuer le contrôle du salaire.<br />
                            Les données du simulateur sont indicatives, les aides sont octroyées par les services instructeurs compétents.<br />
                            La simulation est réalisée selon les plafonds réglementaires
                        </p>
                        {showDetails && <div>
                            Age ratio : {results.apprenticeShip.ageRatio}
                            <br />
                            Salary raw : {JSON.stringify(results.apprenticeShip.salaryRow)}
                            <br />
                            Aide amout : {results.apprenticeShip.aideAmount}
                            <br />
                            
                            Aide unique : {JSON.stringify(results.apprenticeShip.aideUnique)}
                            <br />
                            Aide exceptionnelle : {JSON.stringify(results.apprenticeShip.aideExceptionnelle)}
                            <br />
                            Aide handicap : {JSON.stringify(results.apprenticeShip.aideHandicap)}
                            <br />
                            Aide tuteur OPCO : {results.apprenticeShip.aideOpco}
                            <br />
                            Détails : {JSON.stringify(results.apprenticeShip.details, null, 4)}
                            <br />
                        </div>}
                    </Box>
                </Grid>
            </Grid>
        )}
      </div>
    );
  }
