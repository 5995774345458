import { InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { ContractValues } from '../models/Contract';
import { HelpTip } from '../tooltips/HelpTip';
import { apprenticeContractTip, proContractTip } from '../tooltips/Tips';
import { CalculatorProps } from '../types/CalculatorProps';
import { hasError, withNameSpace } from '../utils/utils';

const euroAdornment = <React.Fragment>
    <EuroIcon/> TTC
</React.Fragment>;

const monthAdornment = <span>mois</span>;

interface ContractProps extends CalculatorProps<ContractValues> {
    duration: number | null;
}

export default function Contract(props: ContractProps) {
    // Deconstruct from Formik props
    const {values, duration} = props;

    return (
        <div>
            <Typography variant="h5" color="primary" gutterBottom>
                Contrat d'apprentissage
            </Typography>

            <ContractField name="apprenticeShip.cost" value={values.apprenticeShip.cost} tip={apprenticeContractTip.cost}
                label="Coût pédagogique facturé" adornment={euroAdornment} {...props}/>
            <ContractField name="apprenticeShip.duration" value={values.apprenticeShip.duration} tip={apprenticeContractTip.duration}
                label="Durée totale de formation en mois" adornment={monthAdornment} {...props}/>

            {values.apprenticeShip.duration && (!duration || duration > values.apprenticeShip.duration) && (
                <Alert severity="warning">
                    <AlertTitle>DURÉE DE FORMATION INFERIEURE À LA DURÉE DU CONTRAT</AlertTitle>
                    Le financement du contrat d'apprentissage est basé sur la durée du contrat. Vous pouvez modifier la durée du contrat si celle-ci doit être ajustée au regard de la durée de formation.
                </Alert>
            )}
            {values.apprenticeShip.duration && (duration && duration < values.apprenticeShip.duration) && (
                <Alert severity="warning">
                    <AlertTitle>DURÉE DE FORMATION SUPERIEURE À LA DURÉE DU CONTRAT</AlertTitle>
                    La durée de la formation ne peut pas être supérieure à la durée du contrat précédemment saisie. Pour permettre le calcul du financement, il est considéré que la durée de formation saisie correspond à la durée du contrat.
                    <br/>Vous pouvez modifier la durée du contrat si celle-ci doit être ajustée au regard de la durée de formation.
                </Alert>
            )}
        </div>
    )
}

interface ContractFieldProps extends CalculatorProps<ContractValues> {
    value: number|null;
    name: string;
    label: string;
    namespace: string;
    adornment: JSX.Element;
    tip: JSX.Element;
}

const ContractField = (props: ContractFieldProps) => {
    const {namespace, name, value, setFieldValue, label, tip, adornment, errors, touched} = props;
    
    const [contractValue, setContractValue] = useState<number|null>(value);

    const controlledChange = (e: React.ChangeEvent<any>) => {
        const filteredValue = e.target.value.replace(/\D/g, '');

        setContractValue(filteredValue);
    }

    const controlledBlur = (e: any) => {
        setFieldValue(withNameSpace(namespace, name), contractValue);
    }

    return (
        <React.Fragment>
            <InputLabel error={hasError(errors, touched, name)}>
                {label}
            </InputLabel>
            <HelpTip tip={tip}/>
            <TextField 
                name={withNameSpace(namespace, name)} 
                type="text"
                value={contractValue}
                onChange={controlledChange}
                onBlur={controlledBlur}
                InputProps={{
                    inputMode: 'numeric',
                    endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>
                }}
            />
        </React.Fragment>
    )
}