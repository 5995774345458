import { InputLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Field } from 'formik';
import React from 'react';
import { HelpTip } from '../tooltips/HelpTip';

interface AssertRadioProps {
    label: string|JSX.Element;
    value: boolean|null;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    required?: boolean;
    id?: string;
    tip?: JSX.Element;
    hasError?: boolean | undefined;
}

export default function AssertRadio(props: AssertRadioProps) {
    const required = props.required ? props.required : false;

    return (
        <React.Fragment>
            <InputLabel required={required} error={props.hasError} id={props.id}>
                {props.label}
            </InputLabel>

            {props.tip && (
                <HelpTip tip={props.tip}/>
            )}

            <Field
                component={ToggleButtonGroup}
                value={props.value}
                onChange={props.handleChange}
                exclusive
            >
                <ToggleButton value={true} key="yes" aria-label="Oui">
                    Oui
                </ToggleButton>
                <ToggleButton value={false} key="no" aria-label="Non">
                    Non
                </ToggleButton>
            </Field>
        </React.Fragment>
    )
}