import DateFnsUtils from '@date-io/date-fns';
import { Container, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Calculator } from './components/Calculator';
import theme from './theme';
import frLocale from "date-fns/locale/fr";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <Container maxWidth="lg">
          <Calculator/>
        </Container>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
