import { differenceInYears, parse, parseISO } from "date-fns";
import { FormikErrors, FormikTouched, getIn } from "formik";
import { uniq, range } from "lodash";
import { Nullable } from "../api/api";
import { CalculatorValues } from "../models/Calculator";
import { pourcentages } from "./conventionsCollectivesPourcentages";

export const withNameSpace = (namespace: string, property: string) => {
    return `${namespace}.${property}`;
}

export const hasError = (errors: FormikErrors<any> | undefined, touched: FormikTouched<any> | undefined, field: string) => {
    return touched && getIn(touched, field) && errors && typeof getIn(errors, field) !== 'undefined';
}

export const getYears = (months: number|null) => {
    let years = 1;
    if(months) {
        years = Math.ceil(months / 12);
    }

    return range(0, years);
}

const specialFormations = ["26033202",
"26033203",
"36033206",
"36033207",
"46033202",
"56033102",
"32033205",
"56033103",
"26033101",
"16033101",
"26033104",
"36033110"]

export const getAgeRatio = (realAgeAtContractStartWithYear: number, yearIndex: number, idCc: Nullable<string> = "default", idFormation: Nullable<string> = null) => {
    let appropriatePourcentages = pourcentages.filter(p => p.idCc === idCc)
    if(!appropriatePourcentages.length){
        appropriatePourcentages = pourcentages.filter(p => p.idCc === "default")
    }
    
    if(idFormation && specialFormations.includes(idFormation)){
        appropriatePourcentages = pourcentages.filter(p => p.idCc === "formationSpeciale")
    }

    const rowMapping = appropriatePourcentages.find(p => p.ageRange.includes(realAgeAtContractStartWithYear))

    return (rowMapping as any)[("annee" + (yearIndex + 1))] / 100;
}

export const uniqWithoutEmpty = <T>(elements: T[]) => uniq(elements).filter(Boolean)

export const SMIC = 18654;

export const removeHours = (aDate: Date) => {
    const isoStringDate = aDate.toISOString();
    return parseISO(isoStringDate.substr(0,10) + "T00:00:00Z");
}
export const differenceInYearsIgnoringHours = (futureDate: Date, oldDate: Date) => {
    return differenceInYears(removeHours(futureDate), removeHours(oldDate));
}