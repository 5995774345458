import { InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import AssertRadio from '../fields/AssertRadio';
import { CompanyOptions, CompanyValues, getCompanyOptions } from '../models/Company';
import { HelpTip } from '../tooltips/HelpTip';
import { CompanyTip } from '../tooltips/Tips';
import { CalculatorProps } from '../types/CalculatorProps';
import { hasError, withNameSpace } from '../utils/utils';

export default function Company(props: CalculatorProps<CompanyValues>) {
    const [companyOptions, setCompanyOptions] = useState<CompanyOptions|null>(null);

    // Deconstruct from Formik props
    const {values, setFieldValue, handleChange, handleBlur, errors, touched, namespace, config} = props;

    const userType = config?.userType || "pro";

    //TODO: maybe become async in the future!
    useEffect(() => {
        setCompanyOptions(getCompanyOptions());
    },[]);

    return (
        <div>
            <Typography variant="h4" color="primary" gutterBottom>
                L'entreprise
            </Typography>

            {userType === "student" && <AssertRadio
                label="Connaissez-vous l'entreprise"
                required={true}
                value={values.identified}
                hasError={hasError(errors, touched, "identified")}
                id="company-identified"
                handleChange={(event, value) => setFieldValue(withNameSpace(namespace, "identified"), value)}
            />}

            {(values.identified || userType === "pro") && companyOptions && (
                <React.Fragment>
                    <InputLabel required error={hasError(errors, touched, "branch")} id="company-branch">
                        Convention collective 
                    </InputLabel>
                    <HelpTip tip={CompanyTip.branch}/>
                    <Autocomplete
                        id="company-branch-select"
                        value={companyOptions.branches.find(co => co.value === values.branch)}
                        options={companyOptions.branches}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        onChange={(e,value) => {setFieldValue(withNameSpace(namespace, "branch"), value?.value)}}
                    />

                    <InputLabel required error={hasError(errors, touched, "workforce")} id="company-workforce">
                        Effectif de l'entreprise
                    </InputLabel>
                    <HelpTip tip={CompanyTip.workforce}/>
                    <Field
                        component={ToggleButtonGroup}
                        value={values.workforce}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "workforce"), value)}
                        exclusive
                    >
                        {companyOptions.workforces.map((workforceOption, index) => (
                            <ToggleButton name={withNameSpace(namespace, "workforce")} key={index} value={workforceOption.value}>
                                {workforceOption.name}
                            </ToggleButton>
                        ))}
                    </Field>

                    {/*TODO: improve */}
                    {values.workforce === "4" && (
                        <AssertRadio
                            label="En 2021, le pourcentage de salariés en alternance sera-t-il d’au moins 5% des effectifs ?"
                            tip={CompanyTip.apprenticeShipMass}
                            value={values.apprenticeShipMass}
                            id="company-mass"
                            handleChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => setFieldValue(withNameSpace(namespace, "apprenticeShipMass"), value)}
                        />
                    )}

                    <InputLabel id="company-location">
                        Localisation
                    </InputLabel>
                    <HelpTip tip={CompanyTip.location}/>
                    <Select
                        labelId="company-location"
                        id="company-location-select"
                        value={values.location}
                        onChange={handleChange}
                        name={withNameSpace(namespace, "location")}
                    >
                        {companyOptions.locations.map((locationOption, index) => (
                            <MenuItem key={index} value={locationOption.value}>{locationOption.name}</MenuItem>
                        ))}
                    </Select>
                </React.Fragment>
            )}
        </div>
    )
}
