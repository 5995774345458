export interface ContractResult {
    cost: number;
    remaining: number; 
    ageRatio: number;
    salaryRow?: any;
    aideAmount?: number;
    elementMatching?: any;
    aideExceptionnelle?: number;
    aideUnique?: number;
    aideHandicap?: number;
    baseNetAmount?: number;
    baseBrutAmount?: number;
    aideOpco?: number;
    periodicity?: string;
    opcoFormationAmount?: number;
    formationCostAmount?: number;
    details?: any;
    monthsToConsider?: number;
}

export interface CompareResult {
    pro: ContractResult;
    apprenticeShip: ContractResult;
}

export const initialResults: CompareResult[] = [{
    pro: {
        cost: 0,
        remaining: 0,
        ageRatio: 0
    },
    apprenticeShip: {
        cost: 0,
        remaining: 0,
        ageRatio: 0
    }
}];